import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useOn2024ComplexContext } from '../common/Context';
import SummaryHeader from './detailContents/sub020303/SummaryHeader';
import Questions from './detailContents/sub020303/QuestionsSlide';
import useQuestions from '../js/custom-hooks/useQuestions';

const Sub020303 = () => {
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { navigate, location } = useOn2024ComplexContext();
  const examSettingId = location.state.exam_question_setting_id;

  const { questions } = useQuestions(examSettingId);

  useEffect(() => {
    fetchExamDetail();
  }, []);

  const fetchExamDetail = async () => {
    try {
      const { data: examQuestionSettingData } = await supabase
        .from('exam_question_setting')
        .select()
        .eq('delete_yn', 'N')
        .eq('id', examSettingId);

      setSummaryDetails(examQuestionSettingData[0] || []);

    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/sub0203');
  };

  const handleDistribute = (e) => {
    e.preventDefault();
    updateQuestionSettingEditable();
    navigate('/sub020304', { state: { exam_question_setting_id: examSettingId } });
  };

  const updateQuestionSettingEditable = async () => {
    try {
      const { error } = await supabase
        .from('exam_question_setting')
        .update({ is_editable: false })
        .eq('id', examSettingId);

      if (error) {
        console.error('Failed to update editable:', error);
      }

    } catch (err) {
      console.error('Error updating editable:', err);
    }
  };

  return (
    <>
      <div className="layout">
        <div className="publish_prev">
          <SummaryHeader exams={questions} summaryDetails={summaryDetails} />
          <Questions exams={questions} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
        </div>
      </div>
      <div className="btn_group">
        <button onClick={handleCancel} className="btn btn-ani btn-secondary lg">취소</button>
        <button onClick={handleDistribute} className="btn btn-ani btn-primary lg">배포하기</button>
      </div>
    </>
  );
};

export default Sub020303;
