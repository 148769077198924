import { useState, useRef, useCallback, useEffect } from "react";

function Q4DragClock({ bgSrc, handleClockHands }) {
    const [hourRotation, setHourRotation] = useState(0);
    const [minuteRotation, setMinuteRotation] = useState(0);
    const [secondRotation, setSecondRotation] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedHand, setSelectedHand] = useState(null);
    const [offsetAngle, setOffsetAngle] = useState(0);
    const [currentMinute, setCurrentMinute] = useState(0);

    const clockRef = useRef(null);
    const hourHandRef = useRef(null);
    const minuteHandRef = useRef(null);
    const secondHandRef = useRef(null);

    useEffect(() => {
        initializeClock();
    }, []);

    const initializeClock = () => {
        const currentDate = new Date();
        const seconds = currentDate.getSeconds();
        const minutes = currentDate.getMinutes();
        const hours = currentDate.getHours();

        setSecondRotation(seconds * 6);
        setMinuteRotation(minutes * 6);
        setHourRotation((hours % 12) * 30 + minutes * 0.5);
        setCurrentMinute((minutes + 45) % 60);
    };

    const calculateRotation = useCallback((clientX, clientY) => {
        const rect = clockRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const deltaX = clientX - centerX;
        const deltaY = clientY - centerY;
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        return (angle + 180) % 360; // Normalize to 0-360 range, with 0 at the top
    }, []);

    const startDrag = useCallback((event) => {
        if (event.target.classList.contains('hand')) {
        event.preventDefault();
        const hand = event.target;
        setSelectedHand(hand);
        setIsDragging(true);

        const clientX = event.clientX || (event.touches && event.touches[0].clientX);
        const clientY = event.clientY || (event.touches && event.touches[0].clientY);
        const clickAngle = calculateRotation(clientX, clientY);
        const currentRotation = parseFloat(hand.style.transform.replace('rotate(', '').replace('deg)', '')) || 0;
        setOffsetAngle(clickAngle - currentRotation);
        }
    }, [calculateRotation]);

    const drag = useCallback((event) => {
        if (isDragging && selectedHand) {
        event.preventDefault();
        const clientX = event.clientX || (event.touches && event.touches[0].clientX);
        const clientY = event.clientY || (event.touches && event.touches[0].clientY);
        const rotationDegree = (calculateRotation(clientX, clientY) - offsetAngle + 360) % 360;

        if (selectedHand === hourHandRef.current) {
            setHourRotation(rotationDegree);
        } else if (selectedHand === minuteHandRef.current) {
            setMinuteRotation(rotationDegree);
        } else if (selectedHand === secondHandRef.current) {
            setSecondRotation(rotationDegree);
        }
        }
    }, [isDragging, selectedHand, offsetAngle, calculateRotation]);

    const endDrag = useCallback(() => {
        if (isDragging) {
            setIsDragging(false);
            if (selectedHand === minuteHandRef.current) {
                const minute = Math.round((minuteRotation / 360) * 60 + 45) % 60;
                setCurrentMinute(minute);
                handleClockHands(minute.toString());
            }
            setSelectedHand(null);
        }
    }, [isDragging, selectedHand, minuteRotation]);
    return (
        <div 
            className="stwrap" 
            onMouseMove={drag}
            onMouseUp={endDrag}
            onMouseLeave={endDrag}
            onTouchMove={drag}
            onTouchEnd={endDrag}
            style={{ userSelect: 'none'}}
            >
            <div className="dragWrap type4">
                <div 
                className="clock" 
                ref={clockRef} 
                onMouseDown={startDrag} 
                onTouchStart={startDrag}
                style={{ touchAction: 'none' }}
                >
                <div className="hand hour" ref={hourHandRef} style={{ transform: `rotate(${hourRotation}deg)` }}>시간</div>
                <div className="hand minute" ref={minuteHandRef} style={{ top: '49%', transform: `rotate(${minuteRotation}deg)` }}>분</div>
                <div className="hand second" ref={secondHandRef} style={{ transform: `rotate(${secondRotation}deg)` }}>초</div>
                <div className="clockDot"></div>
                <div className="clock_bg">
                    <img src={bgSrc} alt="Clock background" onDragStart={(e) => e.preventDefault()} />
                </div>
                </div>
                <p>{currentMinute}분 </p>
            </div>
        </div>
    )
}

export default Q4DragClock;