import React, { useEffect, useState } from 'react';
import { useOn2024ComplexContext } from '../common/Context';
import { subject } from '../enum/enum';
import { getKeyByValue } from '../js/helper-functions/convertEnum';
import { supabase } from '../supabaseClient';
import { InfoCards } from './detailContents/sub020301/InfoCards';


const Sub020301 = () => {
  const [assessmentInfo, setAssessmentInfo] = useState({});
  const [students, setStudents] = useState([]);
  const { navigate, location } = useOn2024ComplexContext();
  const examId = location?.state?.exam_question_setting_id;
  const classCode = location?.state?.class_code;
  const remainingTime = { minutes: 0, seconds: 0 };
  const [allScoringComplete, setAllScoringComplete] = useState(false);

  useEffect(() => {
    setAllScoringComplete(areAllStudentsScoringComplete(students));
  }, [students]);

  useEffect(() => {
    fetchCurrentExam();
    fetchStudents();
    fetchRealTimeStudentData();

    const subscription = supabase
      .channel('student_answer_sheet_temp')
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'student_answer_sheet_temp' }, handleUpdate)
      .subscribe();

      return () => {
        subscription.unsubscribe();
      };
  }, []);



  // 학생의 진행률/채점을 실시간으로 화면에 업데이트
  useEffect(() => {
    handleScoringStatusUpdate();
    handleStudentProgressUpdate();
    handleStudentStatusUpdate();


  }, [examId]);

  const areAllStudentsScoringComplete = (students) => {
    return students.every(student => {
      // 학생이 결시(채점불가)인 경우 true 반환 (이 학생은 검사에서 제외)
      if (student.student_type !== "1") return true;

      // 그 외의 경우, completed_status가 2(채점완료)인지 확인
      return student.completed_status === 2;
    });
  };

  // 화면에 학생 채점 상태를 실시간으로 업데이트
  const handleScoringStatusUpdate = (payload) => {
    const subscription = supabase
      .channel('question_creation_participation')
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'question_creation_participation',
          filter: `exam_question_setting_id=eq.${examId}`
        },
        (payload) => {
          const { new: newData } = payload;

          setStudents(prevStudents => prevStudents.map(student =>
            student.student_id === newData.student_id
              ? { ...student, completed_status: newData.completed_status }
              : student
          ));
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  };

  // 학생 접속 상태를 실시간으로 업데이트
  const handleStudentStatusUpdate = () => {
    const subscription = supabase
      .channel('notification_student_question_attendance')
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'notification_student_question_attendance',
          filter: `class_code=eq.${classCode}`
        },
        (payload) => {
          const { new: newData } = payload;

          setStudents(prevStudents => prevStudents.map(student =>
            student.student_id === newData.student_id
              ? { ...student, student_status: newData.student_status }
              : student
          ));
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  };

  const fetchCurrentExam = async () => {
    try {
      const { data, error } = await supabase
        .from('exam_question_setting')
        .select()
        .eq('delete_yn', 'N')
        .eq('id', examId);
        if (error) throw error;
        setAssessmentInfo(data[0]);
    } catch (error) {
      console.error('Error fetching current exam:', error);
    }
  };



  const handleStudentProgressUpdate = () => {
    const subscription = supabase
      .channel('student_answer_sheet_temp')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'student_answer_sheet_temp',
          filter: `exam_question_setting_id=eq.${examId}`
        },
        handleStudentNewProgress
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }

  // 화면에 학생 진행률 실시간으로 업데이트
  const handleStudentNewProgress = async (payload) => {

    // Fetch the updated data for the specific student
    const { data: updatedAnswerSheet, error } = await supabase
      .from('student_answer_sheet_temp')
      .select()
      .eq('exam_question_setting_id', examId)
      .eq('student_id', payload.new.student_id);

    if (error) {
      console.error('Error fetching updated answer sheet:', error);
      return;
    }

    setStudents(prevStudents => {
      return prevStudents.map(student => {
        if (student.student_id === payload.new.student_id) {
          return {
            ...student,
            answers: updatedAnswerSheet
          };
        }
        return student;
      });
    });
  };


  const fetchStudents = async () => {
    try {
      const combinedStudentData = [];

      const { data: qcpData } = await supabase
        .from('question_creation_participation')
        .select()
        .eq('exam_question_setting_id', examId);

        const allStudentIds = qcpData.map((item) => item.student_id);
      try {
        const { data: studentData } = await supabase
          .from('student')
          .select()
          .in(
            'student_id',
            qcpData.map((item) => item.student_id)
          );
        const { data: notificationStudentQuestionAttendance } = await supabase
          .from('notification_student_question_attendance')
          .select()
          .eq('class_code', classCode)
          .in('student_id', allStudentIds);

          const { data: studentAnswerSheetTemp, error } = await supabase
            .from('student_answer_sheet_temp')
            .select()
            .eq('exam_question_setting_id', examId)
            .in('student_id', allStudentIds);
            if (error) throw error;


          for (let i=0; i<qcpData.length; i++) {
            combinedStudentData.push({
              ...qcpData[i],
              ...studentData.find((student) => student.student_id === qcpData[i].student_id),
              ...notificationStudentQuestionAttendance.find((student) => student.student_id === qcpData[i].student_id),
              remarks: qcpData[i].remarks,
              answers: [],
            });
          }
          setStudents(combinedStudentData);
          setStudents(combinedStudentData.map((student) => (
            {...student,
              answers: studentAnswerSheetTemp.filter((answer) => answer.student_id === student.student_id)
            }
          )));
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    } catch (error) {
      console.error('Error fetching assessment info:', error);
    }
  };

  const fetchRealTimeStudentData = async () => {
    console.log('studentid array', students.map((student) => student.student_id))
    try {

    } catch (error) {
      console.error('Error fetching real-time student data:', error);
    }
  };

  const handleUpdate = (payload) => {
    setStudents((prev) =>
      prev.map((student) =>
        student.student_id === payload.new.student_id ? {
          ...student, ...student.answers.map((answer) =>
            answer.id === payload.new.id ?
              { ...answer, ...payload.new } :
              answer
          )
        } :
        student
      )
    )
  };

  const handleNoteChange = (id, text) => {
    setStudents(prev => [...prev].map(student =>
      student.student_id === id ? { ...student, remarks: text } : student
    ));
  };

  const handleUpdateRemark = async (id, text) => {
    try {
      const { data, error } = await supabase
        .from('question_creation_participation')
        .update({ remarks: text })
        .eq('exam_question_setting_id', examId)
        .eq('student_id', id);
      if (error) {
        console.error('Error updating remarks:', error);
      }
    } catch (error) {
      console.error('Error updating remarks:', error);
    }
  }

  const handleScoringAction = async (id, state) => {
    // Implement scoring logic here
    if(state === "score1" || state === "score3" || state === "score5") return null;
    else if(state === "score2") {
      const {error} = await supabase
        .from('question_creation_participation')
        .update({ completed_status: 1 })
        .eq('student_id', id)
        .eq('exam_question_setting_id', examId)
    }
    navigate('/sub020306', {state: {
      exam_question_setting_id: examId,
      student_id: id
    }})
  };

  //출제목록으로 이동
  const handleMovePage = () => {
    navigate('/sub0203');
  }

  // 시험 채점 상태 업데이트
  const updateQuestionSettingGradingStatus = async () => {
    if (!allScoringComplete) {
      alert("모든 학생의 채점이 완료되지 않았습니다.");
      return;
    }

    try {
      const { error } = await supabase
        .from('exam_question_setting')
        .update({ grading_status: 3 })
        .eq('id', examId);

      if (error) {
        console.error('Failed to update grading status:', error);
        alert("채점 상태 업데이트에 실패했습니다.");
      } else {
        alert("채점이 성공적으로 종료되었습니다.");
        navigate('/sub0203');
      }
    } catch (err) {
      console.error('Error updating grading status:', err);
      alert("채점 상태 업데이트 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="assessment-dashboard layout">
      <SummarySection assessmentInfo={assessmentInfo} />
      <InfoCards
        students={students}
        assessmentInfo={assessmentInfo}
        submissionStatus={assessmentInfo.submissionStatus}
        remainingTime={remainingTime}
      />
      <ScoringStatusSection
        students={students}
        assessmentInfo={assessmentInfo}
        onNoteChange={handleNoteChange}
        onScoringAction={handleScoringAction}
        handleUpdateRemark={handleUpdateRemark}
      />
      <div className="btn_group">
        <button className="btn btn-ani btn-secondary lg" onClick={handleMovePage}>
          출제목록으로
        </button>
        <button
          className='btn btn-ani btn-primary lg'
          onClick={updateQuestionSettingGradingStatus}
        >
        채점 종료
        </button>
      </div>
    </div>
  );
};

const SummarySection = ({ assessmentInfo }) => (
  <ul className="summary_wrap">
    <li className="item_date">
      <b>{assessmentInfo.reg_date?.slice(0,4)}</b>년
      <b>{assessmentInfo.reg_date?.slice(5,7)}</b>월
      <b>{assessmentInfo.reg_date?.slice(8,10)}</b>일
    </li>
    <li className="item_subject">
      <i className="ico_logo type1">온평가</i>
      <b>{getKeyByValue(subject, assessmentInfo.subject)}</b>
      <span className="state tx">{assessmentInfo.grade}학년 {assessmentInfo.class || 0}반</span>
    </li>
    <li className="item_state">
      평가가 <b>{assessmentInfo.distribution_status === 2 ? "평가 중" : "채점 중"}</b> 입니다.
    </li>
  </ul>
);



const ScoringStatusSection = ({ students, assessmentInfo, onNoteChange, onScoringAction, handleUpdateRemark }) => (
  <div className="scoring-status-section">
    <div className="title_box">
      <strong className="subtitle">
        <i className="hico hico2"></i>
        채점현황
        <span><i className="badge badge4"></i>제출이 완료된 학생들은 채점하실 수 있습니다.</span>
      </strong>
      <ul className="badge_wrap">
        <li><i className="badge badge1">접속</i></li>
        <li><i className="badge badge2">결시</i></li>
        <li><i className="badge badge3">10분 이상 무응답</i></li>
        <li><i className="badge badge4">제출완료</i></li>
      </ul>
    </div>
    <div className="table_wrap">
      <table className="tbl_basic tbl_list center table-hover">
        <thead>
          <tr>
            <th>학년</th>
            <th>반</th>
            <th>번호</th>
            <th>이름</th>
            <th>평가일</th>
            <th>진행률</th>
            <th>접속상태</th>
            <th className="th_deep">채점</th>
            <th>비고</th>
            <th className="th_deep">비고수정</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <StudentRow
              key={student.student_id}
              student={student}
              assessmentInfo={assessmentInfo}
              onNoteChange={onNoteChange}
              onScoringAction={onScoringAction}
              handleUpdateRemark={handleUpdateRemark}
            />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const StudentRow = ({ student, assessmentInfo, onNoteChange, onScoringAction, handleUpdateRemark }) => {
  const status = getScoringButtonClass(student);
  return (
    <tr>
      <th scope="row">{student.grade}</th>
      <td>{student.class_number}</td>
      <td>{student.student_number}</td>
      <td>{student.student_name}</td>
      <td>{student.participation_date?.slice(0, 10) || '-'}</td>
      <td>{Math.round((student.answers.length / assessmentInfo.question_count)*100) || 0}%</td>
      <td>
        <i className={`badge badge${styleStudentStatus(student.student_status)}`}>{student.student_status}</i>
      </td>
      <td>
        <button
          className={`btn btn-ani ${status}`}
          onClick={() => onScoringAction(student.student_id, status)}
        >
          {status === "score5" ? "채점완료" : status === "score4" ? "채점중" : status === "score3" ? "채점전" : status === "score2" ? "시작하기" : "채점불가"}
        </button>
      </td>
      <td className="td_left">
        <input
          type="text"
          value={student.remarks || ''}
          onChange={(e) => onNoteChange(student.student_id, e.target.value)}
          placeholder="입력하세요"
        />
      </td>
      <td>
        <button
          className={`micon ${student.note ? 'ico6' : 'ico5'}`}
          onClick={() => handleUpdateRemark(student.student_id, student.remarks)}
        >
          {student.note ? '수정완료' : '수정'}
        </button>
      </td>
    </tr>
  );
}


const styleStudentStatus = (status) => {
  return Number(status * 1);
};

// completed_status: 0(채점 전), 1(채점 중), 2(채점 완료)
const getScoringButtonClass = (student) => {
  if(student.student_type !== "1") return 'score1'
  if(student.completed_status === 2) return 'score5'
  if(student.completed_status === 0 && student.student_status !== "4") return 'score3'
  if(student.completed_status === 0 && student.student_status === "4") return 'score2'
  return 'score4'
};

export default Sub020301;