import React, { Suspense, useEffect, useRef, useState, useCallback, useMemo } from "react";
import QuestionContent from "./QuestionContent";
import parse, { domToReact } from 'html-react-parser';
import QuestionTitle from "./QuestionTitle";

const ConnectType1Import = ({ grading, question, examFileCode, answers, currentQuestion, handleAnswerChange }) => {
    const [htmlFileAsString, setHtmlFileAsString] = useState('');
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [connections, setConnections] = useState(answers?.[currentQuestion]?.split(", ") || []);
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [dotPositions, setDotPositions] = useState({});
    const [lineCount, setLineCount] = useState(0);
    const [duplication, setDuplication] = useState(false);

    const checkDuplication = () => {
        const elements = {}
        question.answer.split(", ").forEach((a) => a.split("-").forEach((e) => {
            if(elements[e]) {
                setDuplication(true);
                return;
            }
            elements[e] = true;
        }))
    }

    useEffect(() => {
        if(JSON.stringify(connections) === JSON.stringify(answers?.[currentQuestion]?.split(", "))) return;
        handleAnswerChange(connections.join(", "));
    }, [connections]);

    useEffect(() => {
        checkDuplication()

        if(answers?.[currentQuestion]?.length) {
            const reverseMappedConnections = answers[currentQuestion].split(", ");
            setConnections(reverseMappedConnections);
        } else {
            setConnections([]);
        }
        const fetchHtmlFile = async () => {
            try {
                const response = await fetch(`/html/custom_type/${examFileCode}.html`);
                const html = await response.text();
                const bodyContent = html.match(/<body[^>]*>([\s\S]*?)<\/body>/i)?.[1];
                setHtmlFileAsString(bodyContent);
            } catch (error) {
                console.error('Error fetching HTML file:', error);
                setHtmlFileAsString('');
            }
        }

        if (examFileCode) fetchHtmlFile();
    }, [examFileCode]);

    const updateDotPositions = useCallback(() => {
        if (!containerRef.current) return;
    
        const containerRect = containerRef.current.getBoundingClientRect();
        const fields = containerRef.current.querySelectorAll('.field');
        const newDotPositions = {};
        let count = 0;
    
        if (question.detail_type === "type1") {
            // Existing logic for type1
            fields.forEach((field) => {
                if (field.classList.contains('canvasWrap')) return;
    
                const line = field.classList.contains('left') ? 'left' : 'right';
                const dots = field.querySelectorAll('.dot_l, .dot_r');
    
                if (dots.length > 0) {
                    newDotPositions[line] = Array.from(dots).map((dot, dotIndex) => ({
                        id: dot.id,
                        dataName: dot.closest('li').querySelector('.bullet.circle.sm')?.textContent || '',
                        x: dot.getBoundingClientRect().left - containerRect.left + dot.offsetWidth / 2,
                        y: dot.getBoundingClientRect().top - containerRect.top + dot.offsetHeight / 2,
                        index: dotIndex,
                        side: dot.classList.contains('dot_l') ? 'left' : 'right'
                    })).sort((a, b) => a.index - b.index);
    
                    count++;
                }
            });
        } else if (question.detail_type === "type2") {
            // New logic for type2
            newDotPositions.left = [];
            newDotPositions.center = [];
            newDotPositions.right = [];
    
            fields.forEach((field) => {
                if (field.classList.contains('canvasWrap')) return;
    
                let line;
                if (field.classList.contains('left')) line = 'left';
                else if (field.classList.contains('center')) line = 'center';
                else if (field.classList.contains('right')) line = 'right';
                else return;
    
                const dots = field.querySelectorAll('.dot_l, .dot_r');
    
                if (dots.length > 0) {
                    newDotPositions[line] = Array.from(dots).map((dot, dotIndex) => ({
                        id: dot.id,
                        dataName: dot.closest('li').querySelector('.bullet.circle.sm')?.textContent || '',
                        x: dot.getBoundingClientRect().left - containerRect.left + dot.offsetWidth / 2,
                        y: dot.getBoundingClientRect().top - containerRect.top + dot.offsetHeight / 2,
                        index: dotIndex,
                        side: dot.classList.contains('dot_l') ? 'left' : 'right'
                    })).sort((a, b) => a.index - b.index);
    
                    count++;
                }
            });
        } else if (question.detail_type === "type3") {
            // Existing logic for type3
            const inners = containerRef.current.querySelectorAll('.inner');
            newDotPositions.left = [];
            newDotPositions.right = [];
    
            inners.forEach((inner, innerIndex) => {
                const innerFields = inner.querySelectorAll('.field');
                
                innerFields.forEach((field) => {
                    if (field.classList.contains('canvasWrap')) return;
    
                    const line = field.classList.contains('left') ? 'left' : 'right';
                    const dots = field.querySelectorAll('.dot_l, .dot_r');
    
                    if (dots.length > 0) {
                        const dotPositions = Array.from(dots).map((dot, dotIndex) => ({
                            id: dot.id,
                            dataName: dot.closest('li').querySelector('.bullet.circle.sm')?.textContent || '',
                            x: dot.getBoundingClientRect().left - containerRect.left + dot.offsetWidth / 2,
                            y: dot.getBoundingClientRect().top - containerRect.top + dot.offsetHeight / 2,
                            index: dotIndex,
                            innerIndex,
                            side: dot.classList.contains('dot_l') ? 'left' : 'right'
                        }));
    
                        newDotPositions[line].push(...dotPositions);
                    }
                });
            });
    
            newDotPositions.left.sort((a, b) => a.innerIndex - b.innerIndex || a.index - b.index);
            newDotPositions.right.sort((a, b) => a.innerIndex - b.innerIndex || a.index - b.index);
            count = inners.length;
        }
    
        setDotPositions(newDotPositions);
        setLineCount(count);
    }, [question.detail_type]);

    useEffect(() => {
        const timer = setTimeout(() => {
            updateDotPositions();
            window.addEventListener('resize', updateDotPositions);
        }, 100);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', updateDotPositions);
        };
    }, [htmlFileAsString, updateDotPositions]);

    const drawConnections = useCallback(() => {
        if (!canvasRef.current || !containerRef.current) return;

        const canvas = canvasRef.current;
        const container = containerRef.current;
        const rect = container.getBoundingClientRect();

        canvas.width = rect.width;
        canvas.height = rect.height;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        connections.forEach(connection => {
            const [start, end] = connection.split('-');
            const startDot = dotPositions.left?.find(dot => dot.dataName === start) || dotPositions.right?.find(dot => dot.dataName === start);
            const endDot = dotPositions.left?.find(dot => dot.dataName === end) || dotPositions.right?.find(dot => dot.dataName === end);

            if (startDot && endDot) {
                ctx.beginPath();
                ctx.moveTo(startDot.x, startDot.y);
                ctx.lineTo(endDot.x, endDot.y);
                ctx.strokeStyle = "#90cdf4";
                ctx.lineWidth = 2;
                ctx.stroke();
            }
        });

        if (dragging && dragStart) {
            ctx.beginPath();
            ctx.moveTo(dragStart.x, dragStart.y);
            ctx.lineTo(mousePosition.x, mousePosition.y);
            ctx.strokeStyle = "#90cdf4";
            ctx.lineWidth = 2;
            ctx.stroke();
        }
    }, [connections, dragging, dragStart, mousePosition, dotPositions]);

    useEffect(() => {
        drawConnections();
        window.addEventListener('resize', drawConnections);
        return () => window.removeEventListener('resize', drawConnections);
    }, [drawConnections]);

    const handleStart = useCallback((e) => {
        e.preventDefault();
        const touch = e.touches ? e.touches[0] : e;
        const dot = touch.target;
        if (dot.classList.contains('dot_l') || dot.classList.contains('dot_r')) {
            const rect = containerRef.current.getBoundingClientRect();
            const dotRect = dot.getBoundingClientRect();
            const center = {
                x: dotRect.left - rect.left + dotRect.width / 2,
                y: dotRect.top - rect.top + dotRect.height / 2
            };
            const line = dot.closest('.field').classList.contains('left') ? 'left' : 'right';
            const side = dot.classList.contains('dot_l') ? 'left' : 'right';
            const dataName = dot.getAttribute('data-name');
            setDragStart({ ...center, line, side, id: dot.id, dataName });
            setMousePosition(center);
            setDragging(true);
        }
    }, []);

    const handleMove = useCallback((e) => {
        if (containerRef.current && dragging) {
            if (e.type === 'touchmove') {
                e.preventDefault(); // Prevent scrolling on touch devices
            }
            const touch = e.touches ? e.touches[0] : e;
            const rect = containerRef.current.getBoundingClientRect();
            setMousePosition({
                x: touch.clientX - rect.left,
                y: touch.clientY - rect.top,
            });
        }
    }, [dragging]);

    const sortConnection = useCallback((connection, startDot, endDot) => {
        const [start, end] = connection.split('-');
        if (startDot && endDot) {
            return startDot.x < endDot.x ? `${start}-${end}` : `${end}-${start}`;
        }
        return start < end ? connection : `${end}-${start}`;
    }, []);

    const handleEnd = useCallback((e) => {
        if (dragging && dragStart) {
            const touch = e.changedTouches ? e.changedTouches[0] : e;
            const endElement = document.elementFromPoint(touch.clientX, touch.clientY);
            const endDot = endElement.querySelector('i[data-name]') || endElement;
            
            if (endDot && endDot.getAttribute('data-name')) {
                const endDataName = endDot.getAttribute('data-name');
                const endLine = endDot.closest('.field').classList.contains('left') ? 'left' : 'right';

                if (dragStart.line !== endLine) {
                    const newConnection = dragStart.dataName < endDataName 
                        ? `${dragStart.dataName}-${endDataName}`
                        : `${endDataName}-${dragStart.dataName}`;

                    setConnections(prev => {
                        if (prev.includes(newConnection)) return prev;
                        if (duplication) {    
                            const updatedConnections = [...prev, newConnection];
                            const sortedConnections = updatedConnections.sort();
                            return sortedConnections;
                        } else {
                            const updatedConnections = [...prev]
                            const [start, end] = newConnection.split("-")
                            const filteredConnections = updatedConnections.filter((d => {
                                return !d.includes(start) && !d.includes(end)
                            }))
                            filteredConnections.push(newConnection)
                            const sortedConnections = filteredConnections.sort()
                            return sortedConnections
                        }
                    });
                }
            }
        }
        setDragging(false);
        setDragStart(null);
    }, [dragging, dragStart]);

    const handleUnlink = useCallback((dataName) => {
        setConnections(prev => {
            const updatedConnections = prev.flatMap(conn => {
                const [start, end] = conn.split('-');
                if (start === dataName || end === dataName) {
                    return [];
                }
                return [conn];
            });
            return updatedConnections;
        });
    }, []);

    const findBulletSpan = (node) => {
        if (node.attribs && node.attribs.class && node.attribs.class.includes('bullet circle sm')) {
            return node;
        }
        if (node.children) {
            for (let child of node.children) {
                const result = findBulletSpan(child);
                if (result) return result;
            }
        }
        return null;
    };

    const getDataName = (node) => {
        const bulletSpan = findBulletSpan(node);
        if (bulletSpan && bulletSpan.children && bulletSpan.children.length > 0) {
            return bulletSpan.children[0].data ? bulletSpan.children[0].data.trim() : '';
        }
        return '';
    };

    const handleDotInteraction = useCallback((e, dataName, side, line) => {
        if (e.type === 'touchstart') {
            // For touch events, preventDefault() should be called on the original event
            e.preventDefault();
        }
        console.log('Dot interaction started:', { dataName, side, line });
        const rect = containerRef.current.getBoundingClientRect();
        const clientX = e.clientX || (e.touches && e.touches[0].clientX);
        const clientY = e.clientY || (e.touches && e.touches[0].clientY);
        const center = {
            x: clientX - rect.left,
            y: clientY - rect.top
        };
        setDragStart({ ...center, line, side, id: `dot-${side}-${line}-${dataName}`, dataName });
        setMousePosition(center);
        setDragging(true);
    }, []);

    const replaceInner = useCallback((domNode) => {
        let newElement = domNode;

        if (domNode.attribs?.class === 'q_layout' && domNode.name === 'div') {
            newElement = (
                <div className="q_layout">
                    {grading && (
                        <ul className="question_info">
                            <li>{question.context}</li>
                            <li>난이도 {question.level}</li>
                        </ul>
                    )}
                    {domToReact(domNode.children, { replace: replaceInner })}
                </div>
            );
        }
        if (domNode.attribs?.class?.includes('dot_l') || domNode.attribs?.class?.includes('dot_r')) {
            const side = domNode.attribs.class.includes('dot_l') ? 'left' : 'right';
            const listItem = domNode.parent;
            const dataName = getDataName(listItem);

            const line = listItem.parent.parent.attribs.class.includes('left') ? 'left' : 'right';
            const id = `dot-${side}-${line}-${dataName}`;

            newElement = (
                <div
                    style={{
                        position: 'relative',
                        width: '50px',
                        height: '50px',
                        display: 'inline-block',
                        cursor: 'pointer',
                        userSelect: 'none',
                    }}
                    onMouseDown={(e) => handleDotInteraction(e, dataName, side, line)}
                    onTouchStart={(e) => handleDotInteraction(e, dataName, side, line)}
                >
                    <i
                        id={id}
                        className={domNode.attribs.class}
                        data-name={dataName}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                        }}
                    ></i>
                </div>
            );
        }
        if (domNode.name === 'canvas') {
            newElement = <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}></canvas>;
        }
        if (domNode.attribs?.class === 'q_tit' && question?.qtitle) {
            return (
                <QuestionTitle question={question} currentQuestion={currentQuestion} />
            )
        }
        if (domNode.attribs?.class === 'q_wrap' && domNode.children.length === 1 && domNode.children[0].type === 'text') {
            const currentClasses = domNode.attribs.class ? domNode.attribs.class.split(' ') : [];
            if (!currentClasses.includes('hidden')) {
                currentClasses.push('hidden');
            }
            domNode.attribs.class = currentClasses.join(' ');
        }
        if (domNode.name === 'button' && domNode.attribs?.class === 'unlink') {
            const listItem = domNode.parent;
            const dataName = getDataName(listItem);
            newElement = (
                <button 
                    className="unlink"
                    data-name={dataName}
                    onClick={() => handleUnlink(dataName)}
                ></button>
            );
        }
        if (domNode.name === 'li' && domNode.attribs?.['data-name']) {
            const dataName = getDataName(domNode);
            newElement = {
                ...domNode,
                attribs: {
                    ...domNode.attribs,
                    'data-name': dataName
                }
            };
        }
        return newElement;
    }, [handleStart, currentQuestion, handleUnlink, canvasRef]);

    const replace = (domNode) => {
        if(domNode.attribs?.class === 'q_content' && domNode.name === 'div') {
            const newClassName = `q_content${grading === 'Y' ? ' grade_check true' : grading === 'N' ? ' grade_check wrong' : ''}`
            return (
                <div {...domNode.attribs} className={newClassName}>
                    {domToReact(domNode.children, { replace: replaceInner })}
                </div>
            );
        }
        return replaceInner(domNode);
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('touchmove', handleMove, { passive: false });
            containerRef.current.addEventListener('touchend', handleEnd);
            containerRef.current.addEventListener('touchcancel', handleEnd);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('touchmove', handleMove);
                containerRef.current.removeEventListener('touchend', handleEnd);
                containerRef.current.removeEventListener('touchcancel', handleEnd);
            }
        };
    }, [handleMove, handleEnd]);

    if (!examFileCode) {
        return null;
    }

    return (
        <Suspense fallback={<div>Loading.....</div>}>
            <QuestionContent>
                <div
                    ref={containerRef}
                    style={{ position: 'relative' }}
                    onMouseMove={handleMove}
                    onMouseUp={handleEnd}
                    onMouseLeave={handleEnd}
                >
                    {parse(htmlFileAsString, { replace })}
                </div>
            </QuestionContent>
        </Suspense>
    );
}

export default ConnectType1Import;