import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { QRCodeSVG } from 'qrcode.react';
import { supabase } from '../supabaseClient';
import { useOn2024ComplexContext } from '../common/Context';
import { subject } from '../enum/enum';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Sub020304 = () => {

  const { teacherInfo, navigate, location } = useOn2024ComplexContext();
  const [students, setStudents] = useState([]);
  const [connectedStudents, setConnectedStudents] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [classCode, setClassCode] = useState(null);
  const [examSetting, setExamSetting] = useState(null);
  const exam_question_setting_id = location?.state?.exam_question_setting_id;
  const { teacher_id } = teacherInfo;
  const [examChartData, setExamChartData] = useState([0, 0, 0])

  useEffect(() => {
    if (!teacherInfo) return;
    const fetchStudents = async () => {

      const { data: selectStudent, error: selectStudentError } = await supabase
        .from('question_creation_participation')
        .select('student_id')
        .eq('exam_question_setting_id', exam_question_setting_id)

      if (selectStudentError) {
        console.error('Student data fetch error:', selectStudentError);
        return;
      }

      const { data: studentData, error: studentError } = await supabase
        .from('student')
        .select('*')
        .eq('teacher_id', teacher_id)
        .eq('delete_yn', 'N')
        .in('student_id', selectStudent.map((student) => student.student_id))
        .order('student_number')

      if (studentError) {
        console.error('Student data fetch error:', studentError);
        return;
      }

      const { data: attendanceData, error: attendanceError } = await supabase
        .from('notification_student_question_attendance')
        .select('*')
        .eq('teacher_id', teacher_id)
        .eq('class_code', classCode)

      if (attendanceError) {
        console.error('Attendance data fetch error:', attendanceError);
        return;
      }

      const { data: examSettingData, error: examSettingError } = await supabase
        .from('exam_question_setting')
        .select('*')
        .eq('teacher_id', teacher_id)
        .eq('id', exam_question_setting_id)
        .eq('delete_yn', 'N');
      setClassCode(examSettingData[0]?.class_code);
      setExamSetting(examSettingData[0])
      setExamChartData([examSettingData[0].exam_level_high, examSettingData[0].exam_level_middle, examSettingData[0].exam_level_low])

      if (examSettingError) {
        console.error('Class code fetch error:', examSettingError);
        return;
      }

      // console.log('Attendance data:', attendanceData); // 추가된 로그
      // console.log('studentData : ', studentData);

      const updatedStudents = studentData.map((student) => {
        const attendance = attendanceData.find(
          (attendance) => attendance.student_id === student.student_id
        );
        const studentType = attendance ? attendance.student_type : null;
        let status = '';
        if (studentType === '1') {
          status = 'connected';
        } else if (studentType === '2' || studentType === '3') {
          status = 'off';
        }

        //console.log(`Student ${student.student_name}: type=${studentType}, status=${status}`); // 추가된 로그

        return {
          id: student.student_id,
          name: student.student_name,
          status: status,
          initialStatus: status,
          studentType: studentType,
          academicYear: student.year
        };
      });

      setStudents(updatedStudents);
      setTotalStudents(updatedStudents.length);
      setConnectedStudents(updatedStudents.filter(s => s.status === 'connected').length);
    };

    fetchStudents();

    // Supabase Realtime subscription
    const channel = supabase
      .channel('custom-all-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notification_student_question_attendance',
          filter: `teacher_id=eq.${teacherInfo.teacher_id}`,
        },
        (payload) => {
          setStudents(prevStudents =>
            prevStudents.map(student =>
              student.id === payload.new.student_id
                ? {
                  ...student,
                  status: payload.new.student_type === '1' ? 'connected' : 'off',
                  studentType: payload.new.student_type,
                  directlyConnected: payload.new.student_type === 1
                }
                : student
            )
          );
        }
      )
      .subscribe();

    // Cleanup subscription on component unmount
    return () => {
      supabase.removeChannel(channel);
    };
  }, [teacherInfo, classCode]);

  const updateStudentType = async (student_id, student_type) => {
    try {
      const { error } = await supabase
        .from('notification_student_question_attendance')
        .update({ student_type })
        .eq('teacher_id', teacherInfo.teacher_id)
        .eq('student_id', student_id)
        .eq('class_code', classCode)

      if (error) {
        console.error('Failed to update student type:', error);
      } else {
        console.log(`Student type updated to ${student_type} for student_id: ${student_id}`);
      }
    } catch (err) {
      console.error('Error updating student type:', err);
    }
  };

  const deleteStudentAttendance = async (student_id) => {
    try {
      const { error } = await supabase
        .from('notification_student_question_attendance')
        .delete()
        .eq('teacher_id', teacherInfo.teacher_id)
        .eq('student_id', student_id)
        .eq('class_code', classCode)

      if (error) {
        console.error('Failed to delete student attendance:', error);
      } else {
        console.log(`Attendance record deleted for student_id: ${student_id}`);
      }
    } catch (err) {
      console.error('Error deleting student attendance:', err);
    }
  };
  console.log(examSetting)
  const insertStudentAttendance = async (student_id) => {
    try {
      const { data, error } = await supabase
        .from('notification_student_question_attendance')
        .upsert({
          academic_year: teacherInfo.academic_year,
          teacher_id: teacherInfo.teacher_id,
          subject_code: examSetting.subject,
          episode_number: examSetting.episode_number,
          class_code: classCode,
          student_id: student_id,
          student_status: '2',
          student_type: '3'
        }, { onConflict: ['academic_year', 'teacher_id', 'subject_code', 'episode_number', 'class_code', 'student_id'] });

      if (error) {
        console.error('Failed to insert student attendance:', error);
      } else {
        console.log(`Attendance record inserted for student_id: ${student_id}`);
      }
    } catch (err) {
      console.error('Error inserting student attendance:', err);
    }
  };

  const toggleStudentStatus = (id) => {
    setStudents(prevStudents =>
      prevStudents.map(student => {
        if (student.id === id) {
          let newStatus, newStudentType;
          if (student.status === 'connected') {
            newStatus = 'off';
            newStudentType = 2;
            updateStudentType(id, newStudentType);
          } else if (student.status === 'off') {
            newStatus = '';
            newStudentType = null;
            deleteStudentAttendance(id);
          } else {
            newStatus = 'off';
            newStudentType = 3;
            insertStudentAttendance(id);
          }

          return {
            ...student,
            status: newStatus,
            studentType: newStudentType
          };
        }
        return student;
      })
    );
  };

  useEffect(() => {
    setConnectedStudents(students.filter(student => student.status === 'connected').length);
  }, [students]);

  const handleSend = () => {
    const disconnectedStudents = students.filter(student => student.status === '');
    if (disconnectedStudents.length > 0) {
      const studentNames = disconnectedStudents.map(student => student.name).join(', ');
      alert(`${studentNames} 학생이 접속하지 않아 전송할 수 없습니다.`);
    } else {
      if(connectedStudents === 0) return alert('접속한 학생이 없습니다.')
      if (window.confirm("시험을 시작합니다.")) {
        // Insert data into the notification_exam_start table
        const insertExamStart = async () => {

          let examStartDateTime = new Date();
          try {

            // console.log('students: ' + JSON.stringify(students));

            // Assuming all students have the same academic_year, use the first student's academic_year
            const academicYear = students.length > 0 ? students[0].academicYear : null;

            if (!academicYear) {
              alert('학생 정보에 학년 정보가 없습니다. 관리자에게 문의하세요.');
              return;
            }

            const { error: examStartError } = await supabase
              .from('notification_exam_start')
              .insert([
                {
                  academic_year: academicYear,               // Use the academic_year from the first student
                  teacher_id: teacherInfo.teacher_id,        // Use the teacher_id from teacherInfo
                  subject_code: Number(examSetting.subject),                      // Set subject_code to '국어'
                  episode_number: examSetting.episode_number,                       // Set episode_number to '1'
                  class_code: classCode,                      // Set class_code to 'a54321'
                  exam_started: true,
                  exam_start_datetime: examStartDateTime           // Add exam_start_time as the current date and time
                  // Add any other necessary fields here
                }
              ]);

            if (examStartError) {
              console.error('Failed to insert exam start record:', examStartError);
              alert('시험 시작 정보 등록 중 오류가 발생했습니다. 다시 시도해주세요.');
              return; // Exit the function if the insert fails
            }

            await updateQuestionSettingStatus(exam_question_setting_id);
            await updateParticipationDate(exam_question_setting_id, examStartDateTime);

            // If insertion succeeds, proceed to navigate to the next page
            navigate('/sub020305', {
              state: { exam_question_setting_id },
            });

          } catch (err) {
            console.error('Error inserting exam start record:', err);
            alert('시험 시작 정보 등록 중 오류가 발생했습니다.');
          }
        };

        insertExamStart();
      }

    }
  };

  const updateQuestionSettingStatus = async (examQuestionSettingId) => {
    const { error: distributionStatusUpdateError } = await supabase
      .from('exam_question_setting')
      .update({ distribution_status: 2, grading_status: 2 })
      .eq('id', examQuestionSettingId)

    if (distributionStatusUpdateError) {
      console.error(distributionStatusUpdateError)
      return; // Exit the function if the insert fails
    }
  }

  const updateParticipationDate = async (examQuestionSettingId, startDateTime) => {
    const { error } = await supabase
      .from('question_creation_participation')
      .update({ participation_date: startDateTime })
      .eq('exam_question_setting_id', examQuestionSettingId);

    if (error) {
      console.error('Failed to update participation date:', error);
      throw error; // 에러를 던져서 호출하는 쪽에서 처리할 수 있게 합니다.
    }
  };

  const chartData = {
    labels: ['상', '중', '하'],
    datasets: [{
      data: examChartData,
      backgroundColor: ['rgb(255, 86, 86)', 'rgb(0, 211, 171)', 'rgb(255, 184, 62)'],
      barThickness: 40,
      borderRadius: 6,
      borderSkipped: false,
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    }
  };

  if(!examSetting) return <div>...Loading</div>

  return (
    <>
      <div className="layout">
        <div className="prev_info">
          <div className="info_box">
            <div>
              <div className="text_box">
                <div className="item_subject">
                  <i className="ico_logo type1">온평가</i>
                  <b>{Object.keys(subject).find(key => subject[key] === Number(examSetting.subject))}</b>
                  <span className="state tx">{examSetting.grade}학년 {examSetting.term}학기</span>
                </div>
                <span><em>총 문제수</em><b>{examSetting.question_count}</b>문제</span>
                <ul>
                  <li>선택형 {examSetting.question_count - Math.round(examSetting.question_type_ratio*examSetting.question_count/100)}</li>
                  <li>서답형 {Math.round(examSetting.question_type_ratio*examSetting.question_count/100)}</li>
                </ul>
                <div className="unit__wrap">
                  {!!examSetting.unit1 && <span className="unit__item">{examSetting.unit1}단원</span>}
                  {!!examSetting.unit2 && <span className="unit__item">{examSetting.unit2}단원</span>}
                  {!!examSetting.unit3 && <span className="unit__item">{examSetting.unit3}단원</span>}
                </div>
              </div>
              <div className="graph_wrap" style={{ width: '220px', height: '80px' }}>
                <Bar data={chartData} options={chartOptions} />
                <ul>
                  <li>상</li>
                  <li>중</li>
                  <li>하</li>
                </ul>
              </div>
            </div>
            <div>
              <div className="class_code_wrap">
                <span>학급코드</span>
                <div className="class_code">{classCode}</div>
              </div>
              <div className="qrcode">
                {examSetting &&
                  <QRCodeSVG value={`http://localhost:3000/exam_auth?year='${examSetting.year}'&teacher_id='${teacher_id}'&subject='${Object.keys(subject).find(key => subject[key] === Number(examSetting.subject))}'&episode_number='${examSetting.episode_number}'&class_code=${classCode}`} />}
              </div>
            </div>
          </div>
        </div>

        <div className="title_box">
          <strong className="subtitle">
            <i className="hico hico2"></i>
            학생 접속현황
            <span>학생을 클릭하시면 결시 체크가 됩니다.</span>
          </strong>
        </div>

        <div className="connect_wrap">
          <div className="connect_summary">
            <span className="pers"><b>{connectedStudents}명</b><em>/</em>{totalStudents}명</span>
            <ul className="badge_wrap">
              <li><i className="badge badge1">접속</i></li>
              <li><i className="badge badge2">결시</i></li>
            </ul>
          </div>
          <div className="connect_item_wrap">
            {students.map(student => (
              <a
                key={student.id}
                className={`connect_item ${student.status === 'connected' ? 'on' : student.status === 'off' ? 'off' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleStudentStatus(student.id);
                }}
              >
                <i></i><span>{student.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="btn_group">
        <a className="btn btn-ani btn-primary lg" onClick={handleSend}>전송하기</a>
      </div>
    </>
  );
};

export default Sub020304;
