import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../common/AuthProvider';
import { useOn2024ComplexContext } from '../../common/Context';
import { getMenuItems } from '../../layout/Menu';


const SubHeader = () => {
    const { teacherInfo } = useOn2024ComplexContext();
    const [teacherName, setTeacherName] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const { logout, userRole } = useAuth();
    const [largeMenuName, setLargeMenuName] = useState('');
    const [largeMenuLink, setLargeMenuLink] = useState('');
    const [subMenuName, setSubMenuName] = useState('');
    const [subMenuLink, setSubMenuLink] = useState('');


    const location = useLocation();

    useEffect(() => {
        updateTeacherInfo();
        updateMenuInfo();
    }, [location, teacherInfo]);

    function updateTeacherInfo() {
        setTeacherName(teacherInfo?.teacher_name || '');
        setSchoolName(teacherInfo?.schoolData?.school_name || '');
    }

    function updateMenuInfo() {
        const currentPath = location.pathname;

        for (const item of getMenuItems(userRole)) {
            if (currentPath.startsWith(item.link)) {
                setLargeMenuName(item.name);
                setLargeMenuLink(item.link);

                if (item.children) {
                    const matchingChild = item.children.find(child => currentPath === child.link);
                    if (matchingChild) {

                        setSubMenuName(matchingChild.name);
                        setSubMenuLink(matchingChild.link);

                    } else {
                        setSubMenuName('');
                        setSubMenuLink('');
                    }
                } else {
                    setSubMenuName('');
                    setSubMenuLink('');
                }
                return;
            }
        }

        // If no matching menu item is found in the menuItems array
        setLargeMenuName('');
        setLargeMenuLink('');
        setSubMenuName('');
        setSubMenuLink('');
    }

    const handleLogout = () => {
        logout();
    };

    return (
        <section className="sub_header">
            <div className="login_info">
                <div className="info_item">
                    <i className="sicon ico1"></i>
                    <span className="name"><b>{teacherName}</b>선생님</span>
                    <span className="schoolname">{schoolName}</span>
                </div>
                <button type="button" className="logout" onClick={handleLogout}>로그아웃</button>
            </div>

            <div id="location">
                <Link to="/sub010" className="item home"><span>홈</span></Link>
                {largeMenuLink && <Link to={largeMenuLink} className="item">{largeMenuName}</Link>}
                {subMenuLink && <Link to={subMenuLink} className="item">{subMenuName}</Link>}
            </div>

            <div className="sub_title">
                {/* <h1>{largeMenuName}</h1> */}
                <h1>{subMenuName}</h1>
                <p>{subMenuName ? `${subMenuName}를(을) 관리합니다.` : ''}</p>
            </div>
        </section>
    );
};

export default SubHeader;