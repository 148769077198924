import React, { useState, useEffect } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import RoundTab from "./detailContents/sub040101/RoundTab";
import SubjectTab from "./detailContents/sub040101/SubjectTab";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

// 학생 목록 컴포넌트
const StudentList = ({ students, activeStudent, setActiveStudent }) => (
    <div className="lcon">
      {students.map((student, index) => (
        <a
          key={student.id}
          href="#"
          className={activeStudent === student.id ? 'current' : ''}
          onClick={() => setActiveStudent(student.id)}
        >
          <span>{index + 1}</span> {student.name}
        </a>
      ))}
    </div>
  );
  
  // 회차별 탭 컴포넌트
//   const RoundTab = ({ activeRound, setActiveRound, roundData }) => (
//     <div className="tab-body" id="tab2">
//       <div className="tabin">
//         <div className="tabtop">
//           <div className="tabin-header">
//             {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((round) => (
//               <button
//                 key={round}
//                 onClick={() => setActiveRound(round)}
//                 className={activeRound === round ? 'active' : ''}
//               >
//                 {round}
//               </button>
//             ))}
//           </div>
//         </div>
//         <div className="tabin-body" id={`tabin${activeRound}`}>
//           <h3>{roundData.subject} - {roundData.units.join(', ')}</h3>
//           <p>응시일: {roundData.date}</p>
//           <p>총 문항 수: {roundData.totalQuestions}</p>
//           <p>정답률: {roundData.correctRate}%</p>
//         </div>
//       </div>
//     </div>
//   );
  
  // 과목별 탭 컴포넌트
//   const SubjectTab = ({ activeSubject, setActiveSubject, subjectData }) => (
//     <div className="tab-body" id="tab3">
//       <div className="tabin">
//         <div className="tabin-header">
//           {['국어', '수학', '사회', '과학', '영어'].map((subject, index) => (
//             <button
//               key={index}
//               onClick={() => setActiveSubject(subject)}
//               className={activeSubject === subject ? 'active' : ''}
//             >
//               {subject}
//             </button>
//           ))}
//         </div>
//         <div className="tabin-body" id={`tabin3-${activeSubject}`}>
//           <h3>{activeSubject} 성적 분석</h3>
//           <p>평균 점수: {subjectData.averageScore}</p>
//           <p>최고 점수: {subjectData.highestScore}</p>
//           <p>최저 점수: {subjectData.lowestScore}</p>
//         </div>
//       </div>
//     </div>
//   );

const Sub040101 = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const [activeStudent, setActiveStudent] = useState(1);
    const [activeRound, setActiveRound] = useState(10);
    const [activeSubject, setActiveSubject] = useState('국어');
  
    // 차트 데이터 상태
    const [chartData, setChartData] = useState({
      growthTrend: null,
      subjectAverages: null,
      doughnutChart: null,
    });
  
    // 더미 데이터
    const [students, setStudents] = useState([]);
    const [roundData, setRoundData] = useState({});
    const [subjectData, setSubjectData] = useState({});
  
    useEffect(() => {
      // 더미 데이터 설정
      setStudents([
        { id: 1, name: '홍길동' },
        { id: 2, name: '김철수' },
        { id: 3, name: '이영희' },
      ]);
  
      setRoundData({
        subject: '과학',
        units: ['지구와 달의 운동', '여러 가지 기체'],
        date: '2025.4.3',
        totalQuestions: 20,
        correctRate: 75,
      });
  
      setSubjectData({
        averageScore: 85,
        highestScore: 95,
        lowestScore: 70,
      });
  
      // 차트 데이터 초기화
      setChartData({
        growthTrend: {
          labels: ['', '원본', '1차', '2차', '3차', '4차', '5차', '6차', '7차', '8차', '9차', '10차'],
          datasets: [{
            label: '성적 변화',
            backgroundColor: 'rgb(133, 196, 255)',
            borderColor: 'rgb(133, 196, 255)',
            borderWidth: 2,
            data: [0, 20, 30, 20, 50, 40, 50, 90, 60, 80, 50, 55],
          }]
        },
        subjectAverages: {
          labels: ['영어', '과학', '사회', '수학', '국어'],
          datasets: [{
            label: '과목별 평균',
            backgroundColor: [
              'rgba(201, 133, 255, .3)',
              'rgba(66, 211, 177, .3)',
              'rgba(255, 174, 53, .3)',
              'rgba(230, 142, 171, .3)',
              'rgba(133, 196, 255, .3)',
            ],
            borderColor: [
              'rgba(201, 133, 255, 1)',
              'rgba(66, 211, 177, 1)',
              'rgba(255, 174, 53, 1)',
              'rgba(230, 142, 171, 1)',
              'rgba(133, 196, 255, 1)',
            ],
            borderWidth: 1,
            data: [300, 400, 500, 785, 900],
          }]
        },
        doughnutChart: {
          labels: ['정답', '오답'],
          datasets: [{
            data: [60, 40],
            backgroundColor: ["#3157DD", "transparent"],
            borderWidth: 0,
            borderRadius: 30,
          }]
        }
      });
    }, []);
  
    const renderGrowthTrendChart = () => {
      if (!chartData.growthTrend) return null;
      return (
        <Line
          data={chartData.growthTrend}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
            },
            scales: {
              y: {
                min: 0,
                max: 100,
                ticks: { stepSize: 20 },
              },
            }
          }}
        />
      );
    };
  
    const renderSubjectAveragesChart = () => {
      if (!chartData.subjectAverages) return null;
      return (
        <Bar
          data={chartData.subjectAverages}
          options={{
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
            },
            scales: {
              x: {
                min: 0,
                max: 1000,
                ticks: { stepSize: 100 },
              },
            }
          }}
        />
      );
    };
  
    const renderDoughnutChart = () => {
      if (!chartData.doughnutChart) return null;
      return (
        <Doughnut
          data={chartData.doughnutChart}
          options={{
            cutout: '85%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false },
            },
          }}
        />
      );
    };

  return (
    <div className="layout">
      <div className="btn_group">
        <a href="#" className="btn btn-ani btn-outline-secondary">
          <i className="sicon ico_save"></i>저장하기
        </a>
      </div>
      <div className="colwrap">
        <StudentList students={students} activeStudent={activeStudent} setActiveStudent={setActiveStudent} />

        <div className="rcon">
          <div className="tabs">
            <div className="tab-header">
              <button onClick={() => setActiveTab("tab1")} className={activeTab === "tab1" ? "active" : ""}>
                평가기록
              </button>
              <button onClick={() => setActiveTab("tab2")} className={activeTab === "tab2" ? "active" : ""}>
                회차별
              </button>
              <button onClick={() => setActiveTab("tab3")} className={activeTab === "tab3" ? "active" : ""}>
                과목별
              </button>
            </div>

            {activeTab === "tab1" && (
              <div className="tab-body" id="tab1">
                <div className="chart_group">
                  <div className="chart_item num5">
                    <div className="title_box">
                      <strong className="subtitle">
                        <i className="hico2 hico10"></i>성적 변화 추이
                      </strong>
                    </div>
                    <div className="chart_item_con">
                      <div className="graph_wrap">{renderGrowthTrendChart()}</div>
                    </div>
                  </div>

                  <div className="chart_item num5">
                    <div className="title_box">
                      <strong className="subtitle">
                        <i className="hico2 hico9"></i>과목별 평균점수
                      </strong>
                    </div>
                    <div className="chart_item_con">
                      <div className="graph_wrap">{renderSubjectAveragesChart()}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "tab2" && (
              <RoundTab activeRound={activeRound} setActiveRound={setActiveRound} roundData={roundData} />
            )}

            {activeTab === "tab3" && (
              <SubjectTab activeSubject={activeSubject} setActiveSubject={setActiveSubject} subjectData={subjectData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sub040101;
