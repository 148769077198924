export const teacher = 'teacher';
export const student = 'student';
export const admin = 'admin';



export const subject = {
    '국어' : 1,
    '수학' : 2,
    '영어' : 3,
    '사회' : 4,
    '과학' : 5,
};

export const subjectName = {
    1: '국어',
    2: '수학',
    3: '영어',
    4: '사회',
    5: '과학'
};



//문제유형(1:라디오버튼,2:체크박스,3:핫스폿,4:끌어놓기,5:짝연결하기,6:아래로펼치기,7:단답입력,8:서술입력)

export const qtype = {
    '라디오버튼' : 1,
    '체크박스': 2,
    '핫스폿': 3,
    '끌어놓기': 4,
    '짝연결하기': 5,
    '아래로펼치기': 6,
    '단답입력': 7,
    '서술입력': 8
};

export const qType = {
    '선택형' : 1,
    '서답형' : 2,
    '모두' : 3,
};

//퍼센트
export const percent = {
    '10%' : 0.1,
    '20%' : 0.2,
    '30%' : 0.3,
    '40%' : 0.4,
    '50%' : 0.5,
    '60%' : 0.6,
    '70%' : 0.7,
    '80%' : 0.8,
    '90%' : 0.9,
    '100%' : 1,
};

// 학생 접속상태
export const studentStatus = {
    '접속': 'A',
    '결시': 'B',
    '10분 이상 무응답': 'C',
    '제출완료': 'D',
};
