import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { subject } from '../../enum/enum';

const ExamHeader = ({ examStartDateTime, questionInfo, totalQuestions, currentQuestion, answers, onQuestionClick, exam_question_setting_id, onTimeUp }) => {
  const [remainingTime, setRemainingTime] = useState('40분 00초');
  const [isPaused, setIsPaused] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [qInfo, setQInfo] = useState({
    grade: 0,
    term: 0,
    unit: 0,
    property: '',
  });

  const convertToKoreanTime = (dateTime) => {
    if (!dateTime) return null;
    const date = new Date(dateTime);
    return new Date(date.getTime() + 9 * 60 * 60 * 1000); // Add 9 hours to convert to Korean time
  };

  const calculateRemainingTime = () => {
    if (!examStartDateTime) return 40 * 60; // 40 minutes in seconds

    const now = new Date();
    const startTime = convertToKoreanTime(examStartDateTime);
    const elapsedSeconds = Math.floor((now - startTime) / 1000);
    const totalExamSeconds = 40 * 60; // 40 minutes in seconds

    return Math.max(totalExamSeconds - elapsedSeconds, 0);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}분 ${String(remainingSeconds).padStart(2, '0')}초`;
  };

  useEffect(() => {
    setQInfo(questionInfo);
  }, [questionInfo]);

  useEffect(() => {
    const subscribeToExamUpdates = () => {
      return supabase
        .channel('notification_exam_start')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public', table: 'notification_exam_start', filter: `exam_question_setting_id=${exam_question_setting_id}` },
          payload => {
            const updatedData = payload.new;
            setIsPaused(updatedData.is_pause);
          }
        )
        .subscribe();
    };

    let timeTimer;
    if (!isPaused && examStartDateTime) {
      const updateRemainingTime = () => {
        const remainingSeconds = calculateRemainingTime();
        setRemainingTime(formatTime(remainingSeconds));

        if (remainingSeconds <= 0) {
          clearInterval(timeTimer);
          onTimeUp();  // Call the callback when time is up
        }
      };

      updateRemainingTime(); // Initial update
      timeTimer = setInterval(updateRemainingTime, 1000);
    }

    const subscription = subscribeToExamUpdates();

    return () => {
      clearInterval(timeTimer);
      supabase.channel('notification_exam_start').unsubscribe();
    };
  }, [isPaused, examStartDateTime]);

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <div id="question_header">
      <header>
        <button type="button" className="bn_close bk">나가기</button>
        <div className="hd_subject">
          <h2 className="ico_logo type3">온평가</h2>
          <strong>{Object.keys(subject)[questionInfo.subject - 1]}</strong>
          <span className="state tx">{`${qInfo.grade}학년 ${qInfo.term}학기 ${qInfo.unit}단원`}</span>
          <p>{qInfo.property}</p>
        </div>

        <div className="q_info">
          <div className="conut_wrap">
            <span className="time">
              <i className="sicon ico_time">시계</i><em id="remain_time">{remainingTime}</em>
            </span>
            <span className="count">
              <em>{currentQuestion + 1}</em> /{totalQuestions} 문항
            </span>
          </div>

          <div className="question_list_wrap">
            <span>문제풀이현황</span>

            <input type="checkbox" id="toggle" checked={isListOpen} onChange={toggleList} />
            <label htmlFor="toggle" className="toggleSwitch"><span className="toggleButton"></span></label>

            <div className={`question_list_box ${isListOpen ? 'open' : ''}`}>
              <div className="title">
                <strong>전체문항</strong>
                <ul>
                  <li>답변완료</li>
                  <li>미답변</li>
                  <li>현재위치</li>
                </ul>
              </div>
              <ul className="question_list">
                {[...Array(totalQuestions)].map((_, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      className={`qnum ${(answers[index] !== '' && answers[index] !== null) ? 'end' : ''} ${index === currentQuestion ? 'ing' : ''}`}
                      onClick={() => onQuestionClick(index)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
              </ul>

              <span><b>{answers.filter(a => a === '' || a === null).length}</b>문제 남았어요.</span>
            </div>
          </div>
        </div>
      </header>
      {isPaused && (
        <div className="exam-overlay">
          <div className="overlay-content">
            시험이 일시 중지되었습니다. 잠시만 기다려 주세요.
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamHeader;