import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [showTopButton, setShowTopButton] = useState(false);
  const [familySiteOpen, setFamilySiteOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleFamilySite = (e) => {
    e.preventDefault();
    setFamilySiteOpen(!familySiteOpen);
  };

  return (
    <div id="footer">
      <div className="inner">
        <div className="floating">
          <a href="#wrap" className={`btn_top ${showTopButton ? 'show' : ''}`} onClick={scrollToTop}>
            상단위로 이동
          </a>
        </div>
        <div className="footer_l">
          <div className="guide_list">
            <a href="#">이용안내</a>
            <a href="#">개인정보처리방침</a>
            <a href="#">이메일무단수집거부</a>
          </div>
          <div className="footer_info">
            <address>(54538)32255 충남 홍성군 홍북면 선화로 22</address>
            <span className="tel">Tel.041-000-0000</span>
            <span className="fax">Fax.041-000-000</span>
          </div>
          <p className="footer_copy">COPYRIGHT© 2024 충청남도교육청. ALL RIGHTS RESERVED.</p>
        </div>
        <div className="footer_r" id="familysite">
          <div className={`designSelect ${familySiteOpen ? 'on' : ''}`}>
            <div className="lay">
              <strong><a href="#familysite" onClick={toggleFamilySite}>패밀리사이트</a></strong>
            </div>
            {familySiteOpen && (
              <div className="lists">
                <ul>
                  <li><a href="#">패밀리사이트1</a></li>
                  <li><a href="#">패밀리사이트2</a></li>
                  <li><a href="#">패밀리사이트3</a></li>
                  <li><a href="#">패밀리사이트4</a></li>
                  <li><a href="#">패밀리사이트5</a></li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;