function QuestionTitle({ question, currentQuestion }) {
  return (
    <h4 className="q_tit">
      <em className="question_number">{currentQuestion + 1}번</em>
      <span className="txt">{question?.qtitle}</span>
    </h4>
  );
}

export default QuestionTitle;
