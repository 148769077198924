import { useDrop } from "react-dnd";
import useMultipleAnswers from "../../js/custom-hooks/useDragAndDrop";

function Q4DropArea({ dropAreaId, dropAreaLabel, dragItems, allowMultiple, allowDuplicate, handleDrop, ...props }) {
    const [, drop] = useDrop(() => ({
        accept: ['img', 'text'],
        drop: (item) => {
            if (allowMultiple) {
                if (allowDuplicate) {
                    addDuplicateDragItem(item)
                } else {
                    addUniqueDragItem(item)
                }
            } else {
                addSingleDragItem(item)
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));
    
    const { droppedItems, addSingleDragItem, addUniqueDragItem, addDuplicateDragItem, removeClickedItem, resetMultipleDragItems: resetDroppedItems } = useMultipleAnswers(
        dropAreaId,
        dragItems,
        handleDrop
    );

    const renderValue = (item) => {
        const img = item?.components?.find(component => component.type === 'img');
        const div = item?.components?.find(component => component.type === 'div');
        const textArray = item?.components?.filter(component => component.type === 'text');
        const span = item?.components?.find(component => component.type === 'span');
        if (img) {
            return <img src={img.src} alt={`Image ${img.src}`} style={{ maxWidth: '100%', maxHeight: '100%' }} className={img.className} />;
        } 
        if (div) {
            return <div className={div.className}>{div.value}</div>;
        }
        if (textArray) {
            return (
                <>
                    {span &&
                        <span className={span.className}>
                            {span.value}
                        </span>
                    }
                    {textArray?.map(t=> t.value)}
                </>
            )
        }
    }

    return (
        allowMultiple ? (
            <div className='draginput many' ref={drop} {...props}>
                {droppedItems?.map(item => (
                    <div
                    key={item.uuid}
                    className="draggable"
                    style={{ marginBottom: '4px', cursor: 'pointer' }}
                    onClick={() => removeClickedItem(item)}
                >
                    {renderValue(item)}
                </div>
                ))}
                
            </div>
        ) : (
            <div className="draginput" ref={drop} {...props} >
                {dropAreaLabel && <span>{dropAreaLabel} </span>}
                <div onClick={() => removeClickedItem(droppedItems?.[0])}>{renderValue(droppedItems?.[0])}</div>
            </div>
        )
    );
};

export default Q4DropArea;