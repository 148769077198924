import React, { useEffect, useState } from 'react';
import { useOn2024ComplexContext } from '../../../common/Context';
import { supabase } from '../../../supabaseClient';
import { subject, subject as subjectEnum } from '../../../enum/enum';

// 예시 데이터
const exampleData = {
  dates: [
    '2024년 4월 15일',
    '2024년 4월 14일',
    '2024년 4월 13일',
    '2024년 4월 12일',
    '2024년 4월 11일',
    '2024년 4월 10일',
    '2024년 4월 9일',
  ],
};

const ClassHistory = () => {
  const { teacherInfo } = useOn2024ComplexContext();
  const [students, setStudents] = useState([]); // 유저에게 보이는 모든 학생들
  const [availableSubjects, setAvailableSubjects] = useState([]); // 유저에게 보이는 모든 학생들
  const [examHistoryBySubject, setExamHistoryBySubject] = useState([]); // 유저에게 보이는 모든 학생들
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(1);
  const [selectedExam, setSelectedExam] = useState({});
  const { navigate } = useOn2024ComplexContext();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    fetchExamHistory();
    fetchExamQuestionSettings();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedExam?.id) {
        try {
          // 프로시저 호출 및 매개변수 전달
          let { data, error } = await supabase.rpc('get_students_by_exam_question_setting', {
            exam_id: selectedExam.id,
          });

          if (error) throw error;

          setStudents(data);
        } catch (error) {
          console.error('Error fetching students:', error.message);
        }
      }
    };

    fetchStudents();
  }, [selectedExam]);

  const fetchExamHistory = async () => {
    try {
      const { data, error } = await supabase
        .from('exam_question_setting')
        .select()
        .eq('delete_yn', 'N')
        .eq('year', currentYear)
        .eq('teacher_id', teacherInfo.teacher_id)
        .order('subject');
      if (error) throw error;
      if (data) {
        const rearrangedData = rearrangeArray(data);
        setSelectedSubject(rearrangedData[0]?.subject);
        setExamHistoryBySubject(rearrangedData);
        setSelectedExam(rearrangedData[0]);
        setAvailableSubjects(Array.from(new Set(rearrangedData.map((exam) => parseInt(exam.subject)))));
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const rearrangeArray = (data) => {
    // Group by subject and date (ignoring time)
    const groupedBySubjectAndDate = data.reduce((acc, item) => {
      const subject = item.subject;
      const dateKey = item.reg_date.split('T')[0]; // Get date part only

      if (!acc[subject]) acc[subject] = {};
      if (!acc[subject][dateKey] || new Date(item.date) > new Date(acc[subject][dateKey].date)) {
        acc[subject][dateKey] = item;
      }

      return acc;
    }, {});

    // Flatten the grouped structure back to an array
    return Object.values(groupedBySubjectAndDate).flatMap((subjectGroup) => Object.values(subjectGroup));
  };

  const fetchExamQuestionSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('exam_question_setting')
        .select('*')
        .eq('delete_yn', 'N')
        .eq('teacher_id', teacherInfo.teacher_id)
        .order('subject', { ascending: true });
      if (error) throw error;
    } catch (error) {
      console.error('Error fetching exam_question_setting:', error);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setSelectedExam(examHistoryBySubject.filter((exam) => exam.subject == e.target.value)[0]);
  };
  const handleSeeFullExam = () => {
    navigate('/sub020301', { state: { exam_question_setting_id: selectedExam.id } });
  };

  return (
    <div className="class_history_wrap">
      <div className="class_history line_box">
        <div className="title_box">
          <div className="select_wrap">
            <label htmlFor="select3">출제이력</label>
            <select className="select no-line" id="select3" value={selectedSubject} onChange={handleSubjectChange}>
              {availableSubjects.map(
                (subjectKey) =>
                  subjectKey && (
                    <option key={subjectKey} value={subjectKey}>
                      {Object.keys(subjectEnum).find((key) => subject[key] === subjectKey)}
                    </option>
                  )
              )}
            </select>
          </div>
          <div className="select_wrap">
            <select
              className="select"
              value={JSON.stringify(selectedExam)}
              onChange={(e) => setSelectedExam(JSON.parse(e.target.value))}
            >
              {examHistoryBySubject
                .filter((each) => each.subject == selectedSubject)
                .map((exam) => (
                  <option key={exam.id} value={JSON.stringify(exam)}>
                    {exam.reg_date.slice(0, 10)}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="histor_book_wrap">
          <div className="histor_book">
            <ul>
              {selectedExam && (
                <>
                  <li>
                    <strong>
                      {selectedExam.grade}-{selectedExam.class}
                    </strong>
                  </li>
                  <li>
                    {selectedExam.grade}학년 {selectedExam.term}학기
                  </li>
                  <li>
                    {selectedExam.unit1 && <i className="state tx">{selectedExam.unit1}단원</i>}
                    {selectedExam.unit2 && <i className="state tx">{selectedExam.unit2}단원</i>}
                    {selectedExam.unit3 && <i className="state tx">{selectedExam.unit3}단원</i>}
                  </li>
                </>
              )}
            </ul>
          </div>
          <button className="btn btn-ani btn-outline-primary" onClick={handleSeeFullExam}>
            보기
          </button>
        </div>
      </div>
      <div className={`histor_list ${isExpanded ? 'active' : ''}`}>
        <button className={`expand_btn ${isExpanded ? 'active' : ''}`} onClick={toggleExpand}>
          <strong>
            참여학생 목록
            <span>
              <b>{students?.length}명</b> / 20명
            </span>
          </strong>
          <i className={`icon arr_m ${isExpanded ? 'active' : ''}`}></i>
        </button>
        <div className={`expand_box ${isExpanded ? 'active' : ''}`}>
          <div className="table_wrap">
            <table className="tbl_basic type3 center">
              <caption>학생 목록</caption>
              <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">번호</th>
                  <th scope="col">이름</th>
                  <th scope="col">비고</th>
                </tr>
              </thead>
              <tbody>
                {students?.map((student) => (
                  <tr key={student.student_id}>
                    <td>{student.student_number}</td>
                    <td>{student.student_name}</td>
                    <td>{student.notice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassHistory;
