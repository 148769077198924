import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import QuestionContainer from "../components/question/QuestionContainer";
import { qtype } from "../enum/enum";
import useAnswers from "../js/custom-hooks/useAnswers";

function Sub999() {
    const [ questions, setQuestions ] = useState([]);
    const [ selectedQType, setSelectedQType ] = useState(questions[0]?.qtype || 1);
    const [ selectedDetailType, setSelectedDetailType ] = useState(questions[0]?.detail_type || 'type1');
    const [ questionIndex, setQuestionIndex ] = useState(0);
    const { answers, handleAnswers, resetAnswers } = useAnswers(160, 'student_answer_sheet_temp');

    useEffect(() => {
        fetchQuestion();
    }, [selectedQType, selectedDetailType]);

    const handleAnswerChange = (value) => {
        handleAnswers(questionIndex, value)
    }

    const fetchQuestion = async () => {
        const { data: questionsData } = await supabase
        .from('exam_question_bank')
        .select()
        // .eq('id', 1)
        // .eq('exam_file_code', 'eng1_611_m122')
        .eq('qtype', selectedQType)
        .eq('detail_type', selectedDetailType)

        if (questionsData?.length) {
            const { data: answersData } = await supabase
                .from('exam_question_example')
                .select()
                .in('p_id', questionsData.map(q => q.id))

                const joinedQuestionsAndAnswers = questionsData.map(q => ({
                    ...q,
                    options: answersData.filter(a => Number(a.p_id) === q.id)
                }))
            setQuestions(joinedQuestionsAndAnswers)
            resetAnswers()
            setQuestionIndex(0)
        } else {
            setQuestions([])
        }
    }

    
    const getDetailTypeArray = (numOfTypes) => {
        return Array.from({length: numOfTypes}, (_, i) => `type${i+1}`)
    }

    const buttonConfig = [{
        qtype: qtype['라디오버튼'],
        numOfTypes: 10,
    }, {
        qtype: qtype['체크박스'],
        numOfTypes: 10,
    }, {
        qtype: qtype['핫스폿'],
        numOfTypes: 24,
    }, {
        qtype: qtype['끌어놓기'],
        numOfTypes: 13,
    }, {
        qtype: qtype['짝연결하기'],
        numOfTypes: 4,
    }, {
        qtype: qtype['아래로펼치기'],
        numOfTypes: 6,
    }, {
        qtype: qtype['단답입력'],
        numOfTypes: 10,
    }, {
        qtype: qtype['서술입력'],
        numOfTypes: 3,
    }]

    const handleChange = (e) => {
        setSelectedQType(e.target.value);
        setSelectedDetailType('type1');
    }

    return (
        <div>
            <h2>Sub999</h2>
            <label htmlFor="qtype">qtype</label>
                <select value={selectedQType} onChange={handleChange}>
                    <option value={1}>1-radio</option>
                    <option value={2}>2-check</option>
                    <option value={3}>3-hotspot</option>
                    <option value={4}>4-drag</option>
                    <option value={5}>5-connect</option>
                    <option value={6}>6-select</option>
                    <option value={7}>7-shortAnswer</option>
                    <option value={8}>8-longAnswer</option>
                </select>
                    {getDetailTypeArray(buttonConfig.find(button => button.qtype == selectedQType).numOfTypes).map((type, index) => <button className="btn" key={index} onClick={() => setSelectedDetailType(type)}>{type}</button>)}

                <div>
                    <button className="btn" onClick={() => setQuestionIndex(questionIndex - 1)}>prev</button>
                    {questionIndex + 1} / {questions.length}
                    <button className="btn" onClick={() => setQuestionIndex(questionIndex + 1)}>next</button>
                </div>
                <div id="question" className="question">
                    <QuestionContainer answers={answers} question={questions[questionIndex]} currentQuestion={questionIndex} handleAnswerChange={handleAnswerChange} />
                </div>
        </div>
    );
}

export default Sub999