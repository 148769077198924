import React, { useEffect, useState, useRef } from 'react';
import { useOn2024ComplexContext } from './common/Context';
import { admin } from './enum/enum';

const AdminLogin = () => {
    const { adminLogin, navigate, location, setAdminInfo } = useOn2024ComplexContext();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    // useRef 훅을 사용하여 input 요소에 접근
    const idInputRef = useRef(null);

    useEffect(() => {
        // 컴포넌트가 렌더링된 후 id가 "id"인 input에 포커스 설정
        if (idInputRef.current) {
            idInputRef.current.focus();
        }
    }, []);


    // 로그인 계정 입력시 이벤트
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Login attempt with ID:', id, 'and password:', password);

        const loginEmail = id + '@test.co.kr';
        const loginPassword = password;
        const gradeManage = '1';

        const adminInfo = {
            email: loginEmail,
            password: loginPassword,
            grade_manage: gradeManage
        }

        try {
            const { error } = await adminLogin(loginEmail, loginPassword);
            if (error) throw error;
        } catch (error) {
            const errorMsg = String(error);
            console.log(errorMsg);
            if (errorMsg.indexOf('Invalid login') > -1) alert('계정이 존재하지 않습니다.');
            return;
        }

        setAdminInfo({ adminInfo });
        const { from } = location.state || { from: { pathname: "/" + admin + "/exam_question_list" } };
        navigate(from);

    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100" style={{width: '500px', display: 'flex', justifyContent: 'center', position: 'absolute', left: '50%', top: '40%', transform: 'translate(-50%, -40%' }}>
            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                <h1 className="text-2xl font-bold mb-6 text-center">Admin Login</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div style={{marginTop: '30px'}}>
                        <label htmlFor="id" className="block text-sm font-medium text-gray-700 mb-1">ID</label>
                        <input
                            type="text"
                            id="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            ref={idInputRef} // ref 속성을 추가하여 요소에 접근
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="아이디 입력"
                        />
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="비밀번호 입력"
                        />
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            로그인
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;