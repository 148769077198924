function ExamFooter({ currentQuestion, questionsInfo, goToPreviousQuestion, goToNextQuestion }) {
    return (
        <div className="q_footer">
            <button
                className="btn btn-secondary save_prev"
                onClick={goToPreviousQuestion}
                disabled={currentQuestion === 0}
            >
                이전문제
                <i className="icon arr_b"></i>
            </button>
            <button
                className="btn btn-primary save_next"
                onClick={goToNextQuestion}
            >
                {currentQuestion === questionsInfo.length - 1 ? '제출하기' : '다음문제'}
                <i className="icon arr_b"></i>
            </button>
        </div>
    )
}

export default ExamFooter;