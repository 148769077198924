import React, { useState,useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// 예시 데이터
const roundsData = [
  {
    round: 10,
    studentName: '홍길동',
    school: '충남초등학교',
    grade: 5,
    class: 1,
    number: 6,
    subject: '과학',
    units: ['1. 지구와 달의 운동', '2. 여러 가지 기체'],
    date: '2025.4.3',
    totalQuestions: 20,
    difficulty: { high: 5, medium: 10, low: 5 },
    correctRate: 75,
    summary: [
      '홍길동 학생은 낮과 밤이 생기는 까닭을 잘 알고 관련된 문항의 정답률이 높은 모습을 보이고 있으며,',
      '지구의 공전과 별자리의 관계, 계절에 따른 별자리 관련 문항을 풀이하는 데 어려움을 보이고 있습니다'
    ],
    questions: [
      {
        number: '01',
        unit: '2.지구와 달의 운동',
        standard: '[6과09-01]',
        element: '낮과 밤이 생기는 까닭',
        correct: true,
        difficulty: '하',
        studentAnswer: '4,5',
        correctAnswer: '4,5',
        responseTime: '20초',
        question: '가방의 수에 따른 국어책의 수와 수학책의 수를 나눗셈으로 비교하려고 합니다. 알맞은 말을 골라 ○표 하세요.',
        explanation: '발표를 할 때는 듣는 사람을 바라보며 알맞은 목소리로 말하고, 중요한 내용은 쓰면서 듣고, 발표를 들을 때에는 발표하는 친구 얼굴을 보면서 바른 자세로 듣고, 궁금한 내용이 있으면 손을 들고 기회를 얻어 질문합니다.'
      },
      // 더 많은 문제 데이터...
    ]
  },
  {
    round: 9,
    studentName: '홍길동',
    school: '충남초등학교',
    grade: 5,
    class: 1,
    number: 6,
    subject: '수학',
    units: ['1. 지구와 달의 운동', '2. 여러 가지 기체'],
    date: '2025.4.3',
    totalQuestions: 20,
    difficulty: { high: 5, medium: 10, low: 5 },
    correctRate: 75,
    summary: [
      '홍길동 학생은 낮과 밤이 생기는 까닭을 잘 알고 관련된 문항의 정답률이 높은 모습을 보이고 있으며,',
      '지구의 공전과 별자리의 관계, 계절에 따른 별자리 관련 문항을 풀이하는 데 어려움을 보이고 있습니다'
    ],
    questions: [
      {
        number: '01',
        unit: '2.지구와 달의 운동',
        standard: '[6과09-01]',
        element: '낮과 밤이 생기는 까닭',
        correct: true,
        difficulty: '하',
        studentAnswer: '4,5',
        correctAnswer: '4,5',
        responseTime: '20초',
        question: '가방의 수에 따른 국어책의 수와 수학책의 수를 나눗셈으로 비교하려고 합니다. 알맞은 말을 골라 ○표 하세요.',
        explanation: '발표를 할 때는 듣는 사람을 바라보며 알맞은 목소리로 말하고, 중요한 내용은 쓰면서 듣고, 발표를 들을 때에는 발표하는 친구 얼굴을 보면서 바른 자세로 듣고, 궁금한 내용이 있으면 손을 들고 기회를 얻어 질문합니다.'
      },
      // 더 많은 문제 데이터...
    ]
  },
  
];

const RoundTab = () => {
  const [activeRound, setActiveRound] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [currentRoundData, setCurrentRoundData] = useState(null);
  const [isOv, setIsOv] = useState(false);
  const [isTestActive, setIsTestActive] = useState(false);

  useEffect(() => {
    // 초기 activeRound 설정
    const initialRound = roundsData.length > 0 ? roundsData[0].round : null;
    setActiveRound(initialRound);
  }, []);

  useEffect(() => {
    // activeRound가 변경될 때마다 currentRoundData 업데이트
    if (activeRound !== null) {
      const data = roundsData.find(data => data.round === activeRound);
      setCurrentRoundData(data || null);
    }
  }, [activeRound]);

  const handleShareClick = () => {
    // 공유하기 기능 구현
    console.log('공유하기 clicked');
  };

  const handleQuestionClick = (questionNumber) => {
    setExpandedQuestion(expandedQuestion === questionNumber ? null : questionNumber);
    setIsOv(!isOv);
    setIsTestActive(!isTestActive);
  };

  const renderDoughnutChart = () => {
    if (!currentRoundData) return null;
    return (
      <Doughnut
        data={{
          labels: ['정답', '오답'],
          datasets: [{
            data: [currentRoundData.correctRate, 100 - currentRoundData.correctRate],
            backgroundColor: ["#3157DD", "transparent"],
            borderWidth: 0,
          }]
        }}
        options={{
          cutout: '85%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
          },
        }}
      />
    );
  };

  if (!currentRoundData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tab-body" id="tab2" style={{ display: 'block' }}>
      <a href="#" onClick={handleShareClick} className="btn btn-ani btn-outline-secondary btn_share">
        <i className="sicon ico_share"></i>공유하기
      </a>
      <div className="tabin">
        <div className="tabtop">
          <div className="tabin-header">
            {roundsData.map((data) => (
              <button
                key={data.round}
                rel={`tabin${data.round}`}
                className={activeRound === data.round ? 'active' : ''}
                onClick={() => setActiveRound(data.round)}
              >
                {data.round}
              </button>
            ))}
          </div>
        </div>
        <div className="tabin-body" id={`tabin${activeRound}`} style={{ display: 'block' }}>
          <div className="tabcon">
            <div className="tit_info">
              <span className="tit">
                <b>{currentRoundData.studentName}</b>의 <b>{currentRoundData.round}회</b>차 기록
              </span>
              <ul className="student_info">
                <li><span>학교명</span>{currentRoundData.school}</li>
                <li><span>학년</span>{currentRoundData.grade}</li>
                <li><span>반</span>{currentRoundData.class}</li>
                <li><span>번</span>{currentRoundData.number}</li>
              </ul>
            </div>
            <div className="text_info">
              <div className="info_box">
                <div className="item_subject">
                  <span className="tit">
                    <i className="ico_logo type1">온평가</i>
                    <b>{currentRoundData.subject}</b>
                  </span>
                  <ul className="subject_list">
                    {currentRoundData.units.map((unit, index) => (
                      <li key={index}>{unit}</li>
                    ))}
                  </ul>
                </div>
                <div className="text_box col3">
                  <div className="item">
                    <b>응시일</b>
                    <p>{currentRoundData.date}</p>
                  </div>
                  <div className="item ico2">
                    <b>총 문항 수</b>
                    <p>{currentRoundData.totalQuestions}문항</p>
                    <span>
                      상 {currentRoundData.difficulty.high} / 
                      중 {currentRoundData.difficulty.medium} / 
                      하 {currentRoundData.difficulty.low}
                    </span>
                  </div>
                  <div className="doughnut-chart" id="doughnutChart">
                    <span>전체정답률<b>{currentRoundData.correctRate}%</b></span>
                    {renderDoughnutChart()}
                  </div>
                </div>
              </div>
              <div className="totalcommt">
                <strong>종합의견</strong>
                <ul>
                  {currentRoundData.summary.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="title_box">
                <strong className="subtitle">평가 문항정보</strong>
              </div>
              <table className="tbl_basic center table-hover">
                <caption>평가문항정보</caption>
                <colgroup>
                  <col style={{width:"5%"}} />
                  <col style={{width:"20%"}} />
                  <col style={{width:"20%"}} />
                  <col style={{width:"auto"}} />
                  <col style={{width:"7%"}} />
                  <col style={{width:"5%"}} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">번호</th>
                    <th scope="col">단원</th>
                    <th scope="col">성취기준</th>
                    <th scope="col">평가요소</th>
                    <th scope="col">정답여부</th>
                    <th scope="col">보기</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRoundData.questions.map((question, index) => (
                    <React.Fragment key={index}>
                      <tr className={isTestActive ? "test_item active" : "test_item"} id={`test_item${index + 1}`}>
                        <th scope="row">{question.number}</th>
                        <td>{question.unit}</td>
                        <td>{question.standard}</td>
                        <td>{question.element}</td>
                        <td>
                          <i className={`sicon ${question.correct ? 'ico_o' : 'ico_x'}`}>
                            {question.correct ? '정답' : '오답'}
                          </i>
                        </td>
                        <td>
                          <a href={`#test_item${index + 1}`} className={isOv ? 'btnView ov' :`btnView`} onClick={() => handleQuestionClick(index)}>
                            자세히보기
                          </a>
                        </td>
                      </tr>
                      {expandedQuestion === index && (
                        <tr className="test_detail">
                          <td colSpan="8">
                            <ul className="tit_list">
                              <li><b>문항난이도</b>{question.difficulty}</li>
                              <li><b>학생응답</b>{question.studentAnswer}</li>
                              <li className="correct"><b>정답</b>{question.correctAnswer}</li>
                              <li><b>응답시간</b>{question.responseTime}</li>
                            </ul>
                            <div className="qinfo_wrap">
                              <div>
                                <div className="q_content">
                                  <h4 className="q_tit">
                                    <em className="question_number">{question.number}번</em>
                                    <span className="txt">{question.question}</span>
                                  </h4>
                                  {/* 문제 내용 렌더링 */}
                                </div>
                              </div>
                            </div>
                            <div className="comment_wrap">
                              <b>해설</b>
                              <div className="comment">
                                {question.explanation}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundTab;