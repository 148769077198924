import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// DragImage 컴포넌트 수정
const DragImage = ({ src, alt, onDelete, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'draggable',
    item: { src, type: 'image', imgNum },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ display: 'inline-flex', alignItems: 'center', cursor: 'move' }}>
      <img
        src={src}
        alt={alt}
        data-drag-num={imgNum}
        style={{
          cursor: 'pointer',
          opacity: isDragging ? 0.5 : 1,
        }}
      />

      {/* <button onClick={() => onDelete(imgNum)} style={{ }}>
        X
      </button> */}

    </div>
  );
};

// DropInputForImage 컴포넌트 (Type3용)
const DropInputForImage = ({ id, onDrop, value }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'draggable',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        width: '50px',
        height: '50px',
        border: '1px solid #aaa',
        backgroundColor: isOver ? '#f0f8ff' : '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${value})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {!value && ''}
    </div>
  );
};

// DragItem 컴포넌트 (모든 Type 용)
const DragItem = ({ word, onDelete, isDeletable = true }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'word',
    item: { type: 'word', word },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className='draggable'
      ref={drag}
      style={{
        padding: '10px 12px',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      {word}
      {/* 추후 선생님 에디터 구현 시 활용
      {isDeletable && onDelete && (
        <button
          onClick={() => onDelete(word)}
          style={{ marginLeft: '10px', cursor: 'pointer' }}
        >
          X
        </button>
      )} */}
    </div>
  );
};


// DropArea 컴포넌트 수정
const DropArea = ({ id, label, value, onDrop, showLabel = true }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['draggable', 'word'],
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const renderValue = () => {
    if (typeof value === 'object' && value !== null) {
      if (value.src) {
        return <img src={value.src} alt={`Image ${value.imgNum}`} style={{ maxWidth: '100%', maxHeight: '100%' }} />;
      }
      return JSON.stringify(value);
    }
    return value || '';
  };

  return (
    <div
      ref={drop}
      style={{
        width: '150px',
        padding: '10px',
        border: '1px solid #aaa',
        backgroundColor: isOver ? '#f0f8ff' : '#fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '30px',
        borderRadius: '4px',
        // marginBottom: '10px',
        margin: '0 5px 10px'
      }}
    >
      {showLabel && <span>{label} </span>}
      <div>{renderValue()}</div>
    </div>
  );
};

// DraggableDropInput 컴포넌트 (Type2 용)
const DraggableDropInput = ({ id, onDrop, value, position, onDragEnd, onDelete, imageDimensions, realName }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['word', 'draggable'], // 'word'와 'draggable' 타입 모두 받아들이도록 수정
    drop: (item) => {
      if (item.type === 'word') {
        onDrop(id, item.word);
      } else if (item.type === 'image') {
        onDrop(id, { src: item.src, imgNum: item.imgNum });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'dropzone',
    item: { id, position },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const clientOffset = monitor.getClientOffset();
      if (item && dropResult && clientOffset) {
        onDragEnd(id, { x: clientOffset.x, y: clientOffset.y });
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const { width = 1, height = 1 } = imageDimensions || {};

  return (
    <div
      ref={drag}
      style={{
        position: 'absolute',
        top: `${(position.y / height) * 100}%`,
        left: `${(position.x / width) * 100}%`,
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'auto',
        display: 'flex',
        alignItems: 'center'
      }}
      data-real-name={realName}
    >
      <div
        ref={drop}
        style={{
          width: '150px',
          padding: '5px',
          border: '1px solid #aaa',
          backgroundColor: isOver ? '#f0f8ff' : '#fff',
          opacity: isDragging ? 0.5 : 1,
          textAlign: 'center',
          borderRadius: '4px',
          height: '35px'
        }}
      >
        {typeof value === 'string' && value.startsWith('data:image') ? (
          <img src={value} alt="Dropped" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        ) : (
          value || ''
        )}
      </div>
      <button
        onClick={() => onDelete(id)}
        style={{
          marginLeft: '5px',
          cursor: 'pointer',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: 0,
        }}
      >
        -
      </button>
    </div>
  );
};

// WordList 컴포넌트 (공통)
const WordList = ({ wordList, onDeleteWord, onAddWord, newWord, setNewWord, inputRef, handleImageUpload, images, handleImageDelete }) => (
  <>
    {/* 선생님 전용 */}
    {/* <h3>끌어놓으세요 항목 추가</h3>
    <div style={{ marginTop: '20px' }}>
      <form onSubmit={onAddWord}>
        <input
          ref={inputRef}
          type="text"
          value={newWord}
          onChange={(e) => setNewWord(e.target.value)}
          placeholder="단어 추가"
          style={{ marginRight: '10px' }}
        />
        <button type="submit">단어 추가</button>
      </form>
    </div>
    <div style={{ marginTop: '10px' }}>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
    </div> */}
    <div className='dragBtnBox' style={{ display: 'flex', flexWrap: 'wrap' }}>
      {Array.isArray(wordList) && wordList.map((item, index) => {
        if (typeof item === 'string') {
          return <DragItem key={index} word={item} onDelete={onDeleteWord} />;
        } else if (typeof item === 'object' && item.src) {
          return (
            <DragImage
              key={index}
              src={item.src}
              alt={`Image ${item.imgNum}`}
              onDelete={() => handleImageDelete(item.imgNum)}
              imgNum={item.imgNum}
            />
          );
        }
        return null;
      })}
    </div>
  </>
);

// DraggableInput 컴포넌트 (Type2 용)
const DraggableInput = ({ onDrop }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'new-dropzone',
    item: { type: 'new-dropzone' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ['word', 'draggable'],
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        onDrop(item);
      }
    },
  }));

  return (
    <div ref={drop}>
      <div
        ref={drag}
        style={{
          cursor: 'move',
          opacity: isDragging ? 0.5 : 1,
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '3px',
          backgroundColor: '#f0f0f0',
          display: 'inline-block',
        }}
      >
        드래그하세요
      </div>
    </div>
  );
};

const calculateDropAreaResults = (dropAreas) => {
  return dropAreas
    .map((area) => {
      if (!area.value) return null;
      if (typeof area.value === 'object' && 'imgNum' in area.value) {
        return `${area.label}-${area.value.imgNum}`;
      } else if (typeof area.value === 'string') {
        return `${area.label}-${area.value}`;
      }
      return null;
    })
    .filter((result) => result !== null);
};



// Type1 컴포넌트 수정
const Type1 = ({ wordList, onDrop, onAddWord, onDeleteWord, dropAreas, newWord, setNewWord, inputRef, handleImageUpload, images, handleImageDelete, onLabelChange, labelType }) => {
  const [results, setResults] = useState([]);

    useEffect(() => {
      //updateResults();
      setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
    }, [dropAreas]);

    // const updateResults = () => {
    //   const newResults = dropAreas.map(area => {
    //     if (!area.value) return null;
    //     if (typeof area.value === 'object' && 'imgNum' in area.value) {
    //       return `${area.label}-${area.value.imgNum}`;
    //     } else if (typeof area.value === 'string') {
    //       return `${area.label}-${area.value}`;
    //     }
    //     return null;
    //   }).filter(result => result !== null);
    //   setResults(newResults);
    // };

  const handleLocalDrop = (id, item) => {
    onDrop(id, item);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  const handleLocalDeleteWord = (word) => {
    onDeleteWord(word);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  const handleLocalImageDelete = (imgNum) => {
    handleImageDelete(imgNum);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  return (
    <>
      <WordList
        wordList={wordList}
        onDeleteWord={handleLocalDeleteWord}
        onAddWord={onAddWord}
        newWord={newWord}
        setNewWord={setNewWord}
        inputRef={inputRef}
        handleImageUpload={handleImageUpload}
        images={images}
        handleImageDelete={handleLocalImageDelete}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '30px' }}>
        <h3 style={{ marginRight: '10px', marginTop: '0' }}>드롭 영역</h3>
        <select value={labelType} onChange={(e) => onLabelChange(e.target.value)}>
          <option value="㉠">㉠ 시리즈</option>
          <option value="㉮">㉮ 시리즈</option>
          <option value="①">① 시리즈</option>
          <option value="㈀">㈀ 시리즈</option>
          <option value="A">A 시리즈</option>
        </select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {dropAreas.map(area => (
          <DropArea
            key={area.id}
            id={area.id}
            label={area.label}
            value={area.value}
            onDrop={handleLocalDrop}
          />
        ))}
      </div>
      <ResultArea results={results} />
    </>
  );
};


// Type2 컴포넌트
const Type2 = ({ wordList, onDrop, onAddWord, onDeleteWord, newWord, setNewWord, inputRef }) => {
  const [image, setImage] = useState(null);
  const [dropZones, setDropZones] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [results, setResults] = useState([]); // 결과를 저장할 상태 추가
  const imageRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageLoad = () => {
    if (imageRef.current) {
      setImageDimensions({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
    }
  };

  const handleNewDropZone = (clientX, clientY) => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const scaleX = imageRef.current.naturalWidth / rect.width;
      const scaleY = imageRef.current.naturalHeight / rect.height;

      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY;

      const newId = `dropzone-${Date.now()}`;
      setDropZones((prevZones) => {
        const newRealName = `A${prevZones.length + 1}`;
        return [...prevZones, { id: newId, x, y, value: '', realName: newRealName }];
      });
    }
  };

  const [, drop] = useDrop(() => ({
    accept: 'new-dropzone',
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        handleNewDropZone(clientOffset.x, clientOffset.y);
      }
    },
  }));

  const handleDropZoneDragEnd = (id, newPosition) => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const scaleX = imageRef.current.naturalWidth / rect.width;
      const scaleY = imageRef.current.naturalHeight / rect.height;

      const x = (newPosition.x - rect.left) * scaleX;
      const y = (newPosition.y - rect.top) * scaleY;

      setDropZones((prevZones) =>
        prevZones.map((zone) =>
          zone.id === id ? { ...zone, x, y } : zone
        )
      );
    }
  };

  const handleWordDrop = (id, word) => {
    setDropZones((prevZones) =>
      prevZones.map((zone) =>
        zone.id === id ? { ...zone, value: word } : zone
      )
    );
  };

  const handleDeleteDropZone = (id) => {
    setDropZones((prevZones) => {
      const updatedZones = prevZones.filter((zone) => zone.id !== id);
      return updatedZones.map((zone, index) => ({
        ...zone,
        realName: `A${index + 1}`
      }));
    });
  };

  const handleCheckAnswers = () => {
    const result = dropZones
      .sort((a, b) => a.realName.localeCompare(b.realName))
      .map((zone) => `${zone.realName}-${zone.value}`);
    setResults(result);
  };

  const handleDraggableInputDrop = (item) => {
    const newId = `dropzone-${Date.now()}`;
    setDropZones((prevZones) => {
      const newRealName = `A${prevZones.length + 1}`;
      return [...prevZones, { id: newId, x: 0, y: 0, value: item.word || item.src, realName: newRealName }];
    });
  };

  return (
    <>
      <div>
        <WordList
          wordList={wordList}
          onDeleteWord={onDeleteWord}
          onAddWord={onAddWord}
          newWord={newWord}
          setNewWord={setNewWord}
          inputRef={inputRef}
        />
        <h3>이미지 업로드</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
          <DraggableInput onDrop={handleDraggableInputDrop} />
        </div>
        {image && (
          <div
            ref={drop}
            style={{
              position: 'relative',
              marginTop: '20px',
              display: 'inline-block',
              overflow: 'hidden',
            }}
          >
            <img
              ref={imageRef}
              src={image}
              alt="업로드된 이미지"
              style={{ width: '100%', height: 'auto' }}
              onLoad={handleImageLoad}
            />
            {dropZones.map((zone) => (
              <DraggableDropInput
                key={zone.id}
                id={zone.id}
                onDrop={handleWordDrop}
                value={zone.value}
                position={{ x: zone.x, y: zone.y }}
                onDragEnd={handleDropZoneDragEnd}
                onDelete={handleDeleteDropZone}
                imageDimensions={imageDimensions}
                realName={zone.realName}
              />
            ))}
          </div>
        )}
        <button onClick={handleCheckAnswers} style={{ marginTop: '20px' }}>
          정답확인
        </button>
      </div>
      {results.length > 0 && (
        <ResultArea results={results} />
      )}
    </>
  );
};

// Type3 컴포넌트
const Type3 = () => {
  const [inputs, setInputs] = useState({ input1: '', input2: '', input3: '' });
  const [results, setResults] = useState([]);

  const handleDrop = (id, item) => {
    if (item.type === 'image') {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [id]: item.src,
      }));
    }
  };

  const handleCheckAnswers = () => {
    const result = Object.keys(inputs).map((key, index) => {
      const realName = `A${index + 1}`;
      const value = inputs[key].includes('img_o.svg') ? 1 : 0;
      return `${realName}: ${value}`;
    });
    setResults(result);
  };

  return (
    <div>
      <div className='dragBtnBox' style={{ display: 'flex', gap: '10px' }}>
        <DragImage src="images/q_common/img_o.svg" alt="O" />
        <DragImage src="images/q_common/img_x.svg" alt="X" />
      </div>

      <h3>문장</h3>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} data-real-name="A1">
          <span>1. 나는 친구랑 사이좋게 지낸다.</span>
          <DropInputForImage id="input1" onDrop={handleDrop} value={inputs.input1} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} data-real-name="A2">
          <span>2. 나는 다른 사람을 배려하며 말한다.</span>
          <DropInputForImage id="input2" onDrop={handleDrop} value={inputs.input2} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} data-real-name="A3">
          <span>3. 나는 자주 줄임말을 사용한다.</span>
          <DropInputForImage id="input3" onDrop={handleDrop} value={inputs.input3} />
        </div>
      </div>

      <button
        onClick={handleCheckAnswers}
        style={{
          marginTop: '20px',
          fontWeight: 'bold',
          fontSize: '20px',
          padding: '10px 20px'
        }}>
        정답확인버튼
      </button>

      {results.length > 0 && (
        <ResultArea results={results} />
      )}
    </div>
  );
};


const Type4DragItem = ({ text }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type4-text',
    item: { text },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="draggable"
    >
      {text}
    </div>
  );
};

// Type4DropArea 컴포넌트
const Type4DropArea = ({ id, value, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type4-text',
    drop: (item) => onDrop(id, item.text),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    canDrop: () => true, // 항상 드롭 가능하도록 설정
    hover: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const dropTargetOffset = monitor.getSourceClientOffset();

      if (clientOffset && dropTargetOffset) {
        // hover 동안의 위치를 조건부로 계산하여 드롭 조건을 유연하게 설정
      }
    }
  }));

  return (
    <div
      ref={drop}
      className="draginput"
      style={{
        minHeight: '30px',
        padding: '5px',
        width: '400px', // 드롭 영역의 너비를 늘려서 쉽게 드롭되도록
        height: '40px', // 높이도 동일하게 조정
        border: '1px solid #aaa',
        backgroundColor: isOver ? '#e0f7fa' : '#fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
      }}
    >
      {value || ''}
    </div>
  );
};


// Type4: 문항 컴포넌트 type10 핸드폰+대화형
const Type4 = () => {
  const [dropAreas, setDropAreas] = useState({
    1: ''
  });
  const [results, setResults] = useState([]);

  const handleDrop = (id, text) => {
    setDropAreas((prev) => ({
      ...prev,
      [id]: text,
    }));
  };

  useEffect(() => {
    const newResults = Object.keys(dropAreas).map((key) => {
      return `${key}-${dropAreas[key] || ''}`;
    });
    setResults(newResults);
  }, [dropAreas]);

  return (
    <>
      <div className='stwrap' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center', gap: '2rem', width: '100%', margin: '0 auto', padding: '50px', background: '#fff' }}>
        <div className="dragWrap type10">
          <div className="dragBtnBox">
            <Type4DragItem text="뭘 그런 걸로 화를 내니? 너 지금 너무 예민한 것 같아." />
            <Type4DragItem text="그런 말을 들었구나. 이번 기회에 살을 빼는 건 어떨까?" />
          </div>
          <div className="dragAnswerBox">
            <div className="bgBox phone">
              <div className="bgCont">
                <ul className="chatBox type2">
                  <li className="chat_item left">
                    <span className="chat_img blue female1"></span>
                    <div className="bubble">
                      서현아! 피아노 학원은 잘 다녀왔어?
                    </div>
                  </li>
                  <li className="chat_item right">
                    <span className="chat_img pink female2"></span>
                    <div className="bubble">
                      어 혜진아, 방금 집에 도착 했어. 근데 갑자기 무슨 일이야?
                    </div>
                  </li>
                  <li className="chat_item left">
                    <span className="chat_img blue female1"></span>
                    <div className="bubble">
                      오늘 내가 속상한 일이 있어서...
                    </div>
                  </li>
                  <li className="chat_item right">
                    <span className="chat_img pink female2"></span>
                    <div className="bubble">
                      쉬는 시간에 우리 반 남자애들이 나보고 뚱뚱하다고 막 놀리는거야...
                    </div>
                  </li>
                  <li className="chat_item right">
                    <span className="chat_img pink female2"></span>
                    <div className="bubble">
                      <Type4DropArea id="1" value={dropAreas[1]} onDrop={handleDrop} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};


// Type5 컴포넌트: 문항 컴포넌트 type1
const Type5 = ({ wordList, onDrop, onAddWord, onDeleteWord, dropAreas, newWord, setNewWord, inputRef, handleImageUpload, images, handleImageDelete, onLabelChange, labelType }) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    // updateResults();
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출

  }, [dropAreas]);

  // const updateResults = () => {
  //   const newResults = dropAreas.map(area => {
  //     if (!area.value) return null;
  //     if (typeof area.value === 'object' && 'imgNum' in area.value) {
  //       return `${area.label}-${area.value.imgNum}`;
  //     } else if (typeof area.value === 'string') {
  //       return `${area.label}-${area.value}`;
  //     }
  //     return null;
  //   }).filter(result => result !== null);
  //   setResults(newResults);
  // };

  const handleLocalDrop = (id, item) => {
    onDrop(id, item);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  const handleLocalDeleteWord = (word) => {
    onDeleteWord(word);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  const handleLocalImageDelete = (imgNum) => {
    handleImageDelete(imgNum);
    setResults(calculateDropAreaResults(dropAreas)); // 공통 함수 호출
  };

  return (
    <>
      <WordList
        wordList={wordList}
        onDeleteWord={handleLocalDeleteWord}
        onAddWord={onAddWord}
        newWord={newWord}
        setNewWord={setNewWord}
        inputRef={inputRef}
        handleImageUpload={handleImageUpload}
        images={images}
        handleImageDelete={handleLocalImageDelete}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '50px' }}>
        {dropAreas.map(area => (
          <DropArea
            key={area.id}
            id={area.id}
            label={area.label}
            value={area.value}
            onDrop={handleLocalDrop}
          />
        ))}
      </div>
      <div style={{ marginTop: '20px' }}>
        <h4>결과:</h4>
        {dropAreas.map((area, index) => {
          let resultValue = '';
          if (typeof area.value === 'object' && area.value !== null) {
            if (area.value.imgNum) {
              resultValue = `${area.value.imgNum}`;
            } else {
              resultValue = JSON.stringify(area.value);
            }
          } else {
            resultValue = area.value || '';
          }
          return <p key={index}>{`${area.label}-${resultValue}`}</p>;
        })}
      </div>
    </>
  );
};

// Type6 컴포넌트: 문항 컴포넌트 type1
const Type6 = ({ wordList, onDrop, onAddWord, onDeleteWord, newWord, setNewWord, inputRef, handleImageUpload, images, handleImageDelete }) => {
  const [localDropAreas, setLocalDropAreas] = useState([
    { id: 'input1', label: '㉠', value: '' },
    { id: 'input2', label: '㉡', value: '' },
    { id: 'input3', label: '㉢', value: '' },
    { id: 'input4', label: '㉣', value: '' }
  ]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    // updateResults();
    setResults(calculateDropAreaResults(localDropAreas)); // 공통 함수 호출
  }, [localDropAreas]);

  // const updateResults = () => {
  //   const newResults = localDropAreas.map((area, index) => {
  //     let resultValue = '';
  //     if (typeof area.value === 'object' && area.value !== null) {
  //       if (area.value.imgNum) {
  //         resultValue = `${area.value.imgNum}`;
  //       } else {
  //         resultValue = JSON.stringify(area.value);
  //       }
  //     } else {
  //       resultValue = area.value || '';
  //     }
  //     return `${area.label}-${resultValue}`;
  //   });
  //   setResults(newResults);
  // };

  const handleLocalDrop = (id, item) => {
    setLocalDropAreas(prevAreas =>
      prevAreas.map(area =>
        area.id === id ? { ...area, value: item.type === 'image' ? { src: item.src, imgNum: item.imgNum } : item.word } : area
      )
    );
  };

  return (
    <>
      <h3>이미지를 끌어놓으세요.</h3>
      <WordList
        wordList={images}
        onDeleteWord={handleImageDelete}
        onAddWord={onAddWord}
        newWord={newWord}
        setNewWord={setNewWord}
        inputRef={inputRef}
        handleImageUpload={handleImageUpload}
        images={images}
        handleImageDelete={handleImageDelete}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '50px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ margin: '0 10px' }}>동생이 색연필을 빌려 달래요.</span>
          <DropArea
            id={localDropAreas[0].id}
            label={localDropAreas[0].label}
            value={localDropAreas[0].value}
            onDrop={handleLocalDrop}
            showLabel={false}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <span style={{ margin: '0 10px' }}>"싫어,</span>
          <DropArea
            id={localDropAreas[1].id}
            label={localDropAreas[1].label}
            value={localDropAreas[1].value}
            onDrop={handleLocalDrop}
            showLabel={false}
          />
          <span style={{ margin: '0 10px' }}>네 것도 있잖아."</span>
          <DropArea
            id={localDropAreas[2].id}
            label={localDropAreas[2].label}
            value={localDropAreas[2].value}
            onDrop={handleLocalDrop}
            showLabel={false}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <span style={{ margin: '0 10px' }}>"그래?</span>
          <DropArea
            id={localDropAreas[3].id}
            label={localDropAreas[3].label}
            value={localDropAreas[3].value}
            onDrop={handleLocalDrop}
            showLabel={false}
          />
          <span style={{ margin: '0 10px' }}>그럼 이제부터 언니한테 내 인형 안 빌려줄 거야."</span>
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};

// Type7: 문항 컴포넌트 Type5 테이블
const Type7 = ({ wordList, onDrop, onAddWord, onDeleteWord }) => {
  const [localDropAreas, setLocalDropAreas] = useState([
    { id: 'input1', label: '㉠', value: '' },
    { id: 'input2', label: '㉡', value: '' },
    { id: 'input3', label: '㉢', value: '' },
    { id: 'input4', label: '㉣', value: '' },
    { id: 'input5', label: '㉤', value: '' },
  ]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    //  updateResults();
    setResults(calculateDropAreaResults(localDropAreas)); // 공통 함수 호출
  }, [localDropAreas]);

  // const updateResults = () => {
  //   const newResults = localDropAreas.map((area) => {
  //     let resultValue = area.value || '';
  //     return `${area.label}-${resultValue}`;
  //   });
  //   setResults(newResults);
  // };

  const handleLocalDrop = (id, word) => {
    setLocalDropAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === id ? { ...area, value: word } : area
      )
    );
  };

  const handleDeleteDroppedWord = (id) => {
    setLocalDropAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === id ? { ...area, value: '' } : area
      )
    );
  };

  const getDescriptionForWord = (word) => {
    const descriptions = {
      '㉠': '냉장고 적정용량 유지하기, 절기별옷 보온 기능 사용 줄이기, 물은 받아서 사용하기, 텔레비전 시청 시간 줄이기',
      '㉡': '음식물 쓰레기 줄이기, 저탄소 제품 구매하기, 과대포장 제품 안사기',
      '㉢': '대중교통 이용하기, 가까운 거리는 걷거나 자전거 이용하기',
      '㉣': '물티슈 덜 쓰기, 다회용 컵 사용하기',
      '㉤': '나무 심기 운동에 참여하기, 탄소흡수원의 중요성 알고 보호하기',
    };
    return descriptions[word] || '';
  };

  return (
    <>
      <div className='dragBtnBox' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
        {wordList.map((word, index) => (
          <DragItem key={index} word={word} onDelete={onDeleteWord} />
        ))}
      </div>
      <div className='dragAnswerBox'>
        <ul className='dragtable' style={{ listStyleType: 'none', padding: 0, marginTop: '20px' }}>
          <li className='dragtable_tr'>
            <div className="dragtable_th">탄소중립</div>
            <div className="dragtable_td">대기 중 온실가스 농도 증가를 막기 위해 인간 활동에 의해 배출하는 탄소량과 흡수, 제거하는 탄소량을 같게 함으로써 실질적인 탄소 배출량을 '0'으로 만드는 것</div>
          </li>
          {localDropAreas.map((area) => (
            <li className='dragtable_tr' key={area.id}>
              <div className="dragtable_th">
                {area.value ? (
                  <Draggable
                    id={area.id}
                    word={area.value}
                    onDelete={handleDeleteDroppedWord}
                  />
                ) : (
                  <DropTarget id={area.id} onDrop={handleLocalDrop} value={area.value} />
                )}
              </div>
              <div className="dragtable_td">{getDescriptionForWord(area.label)}</div>
            </li>
          ))}
        </ul>
      </div>
      <ResultArea results={results} />
    </>
  );
};




const getDescriptionForWord = (word) => {
  const descriptions = {
    '자원순환': '냉장고 적정용량 유지하지, 절기별옷 보온 기능 사용 줄이기, 물은 받아서 사용하기, 텔레비전 시청 시간 줄이기',
    '에너지 절약': '음식물 쓰레기 줄이기, 저탄소 제품 구매하기, 과대포장 제품 안사기',
    '친환경 소비': '대중교통 이용하기, 가까운 거리는 걷거나 자전거 이용하기',
    '친환경 이동': '물티슈 덜 쓰기, 다회용 컵 사용하기',
    '흡수원 보호': '나무 심기 운동에 참여하기, 탄소흡수원의 중요성 알고 보호하기',
  };
  return descriptions[word] || '';
};

// Draggable 컴포넌트
const Draggable = ({ id, word, onDelete }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'word',
    item: { word },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="draginput"
      ref={drag}
    >
      {word}
    </div>
  );
};

// DropTarget 컴포넌트 (Type7 용)
const DropTarget = ({ id, onDrop, value }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'word',
    drop: (item) => onDrop(id, item.word),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      className='draginput'
      ref={drop}
    >
      {value || ''}
    </div>
  );
};

// Type8 전용 DragItem 컴포넌트
const Type8DragItem = ({ word, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type8-word',
    item: { word, imgNum }, // imgNum을 item에 포함
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className='draggable'
      data-drag-num={imgNum}
      style={{
        padding: '8px 12px',
        margin: '4px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: isDragging ? '#f0f0f0' : '#fff',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {word}
    </div>
  );
};

// Type8 전용 DropArea 컴포넌트
const Type8DropArea = ({ id, label, items, onDrop, onRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type8-word',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className='dragitem'>
      <div className='bullet tit'>{label}</div>
      <div className='draginput many'>
        {items.map((item, index) => (
          <div
            key={index}
            className="draggable"
            style={{ marginBottom: '4px', cursor: 'pointer' }}
            onClick={() => onRemove(id, item)}
          >
            {item.word}
          </div>
        ))}
      </div>
    </div>
  );
};


/// Type8 컴포넌트: 문항 컴포넌트 type6 중복으로 끌어놓기
const Type8 = () => {
  const [words] = useState([
    { word: '사냥', imgNum: 1 },
    { word: '꾼', imgNum: 2 },
    { word: '장난', imgNum: 3 },
    { word: '꾸러기', imgNum: 4 },
    { word: '새', imgNum: 5 },
    { word: '파랗다', imgNum: 6 }
  ]);

  const [dropArea1, setDropArea1] = useState([]);
  const [dropArea2, setDropArea2] = useState([]);
  const [results, setResults] = useState([]);

  const handleDrop = (id, item) => {
    if (id === 'area1') {
      setDropArea1((prev) => {
        const isDuplicate = prev.some(existingItem => existingItem.imgNum === item.imgNum);
        if (!isDuplicate) {
          const newDropArea1 = [...prev, item];
          updateResults(newDropArea1, dropArea2);
          return newDropArea1;
        }
        return prev;
      });
    } else if (id === 'area2') {
      setDropArea2((prev) => {
        const isDuplicate = prev.some(existingItem => existingItem.imgNum === item.imgNum);
        if (!isDuplicate) {
          const newDropArea2 = [...prev, item];
          updateResults(dropArea1, newDropArea2);
          return newDropArea2;
        }
        return prev;
      });
    }
  };

  const handleRemove = (id, item) => {
    if (id === 'area1') {
      setDropArea1((prev) => {
        const newDropArea1 = prev.filter(existingItem => existingItem.imgNum !== item.imgNum);
        updateResults(newDropArea1, dropArea2);
        return newDropArea1;
      });
    } else if (id === 'area2') {
      setDropArea2((prev) => {
        const newDropArea2 = prev.filter(existingItem => existingItem.imgNum !== item.imgNum);
        updateResults(dropArea1, newDropArea2);
        return newDropArea2;
      });
    }
  };

  const updateResults = (area1, area2) => {
    setResults([
      `1-${area1.map(item => item.word).join(', ')}`,
      `2-${area2.map(item => item.word).join(', ')}`
    ]);
  };

  useEffect(() => {
    updateResults(dropArea1, dropArea2);
  }, [dropArea1, dropArea2]);

  return (
    <>
      <div className='dragWrap type6' style={{ padding: '20px' }}>
        <div className='dragBtnBox' style={{ display: 'flex', marginBottom: '20px' }}>
          {words.map((word, index) => (
            <Type8DragItem key={index} word={word.word} imgNum={word.imgNum} />
          ))}
        </div>

        <div className='dragAnswerBox'>
          <Type8DropArea
            id="area1"
            label="뜻을 더해 주는 말"
            items={dropArea1}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
          <Type8DropArea
            id="area2"
            label="뜻이 있는 낱말"
            items={dropArea2}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};

// Type9 전용 DragItem 컴포넌트
const Type9DragItem = ({ src, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type9-image',
    item: { src, imgNum },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        display: 'inline-block',
        margin: '0 5px',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
      data-drag-num={imgNum} // imgNum 속성 유지
    >
      <img src={src} alt={`agg${imgNum}`} />
    </div>
  );
};


// Type9 전용 DropArea 컴포넌트 수정
const Type9DropArea = ({ id, label, items, onDrop, onRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type9-image',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className='dragitem'>
      <span className='bullet circle'>{label}</span>
      <div className='draginput many'>
        {items.length > 0 ? (
          items.map((item, index) => (
            <div
              key={item.uniqueId} // 고유 ID를 key로 사용
              className="draggable"
              data-drag-num={item.imgNum}
              onClick={() => onRemove(id, item.uniqueId)} // uniqueId를 사용하여 특정 요소만 제거
            >
              <img src={item.src} alt={`dropped-${index}`} style={{ width: '50px', height: '50px', margin: '0 5px', cursor: 'pointer' }} />
            </div>
          ))
        ) : (
          <span className="placeholder"></span>
        )}
      </div>
    </div>
  );
};


// Type9 컴포넌트: 문항 컴포넌트 type6
const Type9 = () => {
  const [dropArea1, setDropArea1] = useState([]);
  const [dropArea2, setDropArea2] = useState([]);
  const [dropArea3, setDropArea3] = useState([]);
  const [dropArea4, setDropArea4] = useState([]);
  const [results, setResults] = useState([]); // 결과를 저장할 상태 추가

  const handleDrop = (id, item) => {
    const newItem = {
      ...item,
      uniqueId: `${item.imgNum}-${Date.now()}`, // 고유 ID 생성
    };

    if (id === 'area1') {
      setDropArea1((prev) => {
        const newArea1 = [...prev, newItem];
        updateResults(newArea1, dropArea2, dropArea3, dropArea4);
        return newArea1;
      });
    } else if (id === 'area2') {
      setDropArea2((prev) => {
        const newArea2 = [...prev, newItem];
        updateResults(dropArea1, newArea2, dropArea3, dropArea4);
        return newArea2;
      });
    } else if (id === 'area3') {
      setDropArea3((prev) => {
        const newArea3 = [...prev, newItem];
        updateResults(dropArea1, dropArea2, newArea3, dropArea4);
        return newArea3;
      });
    } else if (id === 'area4') {
      setDropArea4((prev) => {
        const newArea4 = [...prev, newItem];
        updateResults(dropArea1, dropArea2, dropArea3, newArea4);
        return newArea4;
      });
    }
  };

  const handleRemove = (id, uniqueId) => {
    if (id === 'area1') {
      setDropArea1((prev) => {
        const newArea1 = prev.filter(existingItem => existingItem.uniqueId !== uniqueId);
        updateResults(newArea1, dropArea2, dropArea3, dropArea4);
        return newArea1;
      });
    } else if (id === 'area2') {
      setDropArea2((prev) => {
        const newArea2 = prev.filter(existingItem => existingItem.uniqueId !== uniqueId);
        updateResults(dropArea1, newArea2, dropArea3, dropArea4);
        return newArea2;
      });
    } else if (id === 'area3') {
      setDropArea3((prev) => {
        const newArea3 = prev.filter(existingItem => existingItem.uniqueId !== uniqueId);
        updateResults(dropArea1, dropArea2, newArea3, dropArea4);
        return newArea3;
      });
    } else if (id === 'area4') {
      setDropArea4((prev) => {
        const newArea4 = prev.filter(existingItem => existingItem.uniqueId !== uniqueId);
        updateResults(dropArea1, dropArea2, dropArea3, newArea4);
        return newArea4;
      });
    }
  };

  const updateResults = (area1, area2, area3, area4) => {
    setResults([
      `가-${area1.map(item => item.imgNum).join(',')}`,
      `나-${area2.map(item => item.imgNum).join(',')}`,
      `다-${area3.map(item => item.imgNum).join(',')}`,
      `라-${area4.map(item => item.imgNum).join(',')}`,
    ]);
  };

  useEffect(() => {
    updateResults(dropArea1, dropArea2, dropArea3, dropArea4);
  }, [dropArea1, dropArea2, dropArea3, dropArea4]);

  return (
    <>
      <div className='dragWrap type6' style={{ padding: '20px' }}>
        <span className='caption'>달걀 한 개는 10개, 두 개는 100개를 의미합니다.</span>
        <div className='dragBtnBox'>
          <div className='draggable'>
            <Type9DragItem src="/images/q_common/agg1.png" imgNum={1} />
          </div>
          <div className='draggable'>
            <Type9DragItem src="/images/q_common/agg2.png" imgNum={2} />
          </div>
        </div>

        <div className='dragAnswerBox'>
          <Type9DropArea
            id="area1"
            label="가"
            items={dropArea1}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
          <Type9DropArea
            id="area2"
            label="나"
            items={dropArea2}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
          <Type9DropArea
            id="area3"
            label="다"
            items={dropArea3}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
          <Type9DropArea
            id="area4"
            label="라"
            items={dropArea4}
            onDrop={handleDrop}
            onRemove={handleRemove}
          />
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};



// Type10DragItem 컴포넌트 수정
const Type10DragItem = ({ word, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type10-word',
    item: { word, imgNum },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className='draggable'
      data-drag-num={imgNum}
      style={{
        padding: '8px 12px',
        margin: '4px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: isDragging ? '#f0f0f0' : '#fff',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {word}
    </div>
  );
};

// DropArea 컴포넌트
const Type10DropArea = ({ id, label, description, items, onDrop, onRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type10-word',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className='dragitem'>
      {label && <span className='bullet tit'>{label}</span>}
      <div className='draginput many'>
        {description}
        {items.map((item, index) => (
          <div
            key={index}
            className="draggable"
            data-drag-num={item.imgNum}
            onClick={() => onRemove(id, item.imgNum)} // 클릭하면 제거하는 로직 추가
          >
            {item.word}
          </div>
        ))}
      </div>
    </div>
  );
};



// Type10 컴포넌트: 문항 컴포넌트 type7 벤다이어그램
const Type10 = () => {
  const [words] = useState([
    { word: '유교 문화의 영향을 받았다.', imgNum: 1 },
    { word: '현재 통일 국가를 이루었다.', imgNum: 2 },
    { word: '민족 전쟁을 치룬 분단의 역사가 있다.', imgNum: 3 },
    { word: '분단국가로 남아 현재 휴전국 상태이다.', imgNum: 4 },
  ]);
  const [dropArea1, setDropArea1] = useState([]);
  const [dropArea2, setDropArea2] = useState([]);
  const [dropArea3, setDropArea3] = useState([]);
  const [results, setResults] = useState([]);

  const handleDrop = (id, item) => {
    const dropAreasMap = {
      area1: setDropArea1,
      area2: setDropArea2,
      area3: setDropArea3,
    };

    const setItems = dropAreasMap[id];

    // 상태 업데이트 함수를 현재 상태를 기반으로 작성
    setItems((prevItems) => {
      // 중복 체크: 동일한 imgNum이 있으면 추가하지 않음
      const isDuplicate = prevItems.some((existingItem) => existingItem.imgNum === item.imgNum);

      if (!isDuplicate) {
        return [...prevItems, item]; // 이전 상태에 새로운 아이템 추가
      }

      return prevItems; // 중복인 경우 이전 상태 그대로 유지
    });
  };

  const handleRemove = (id, imgNum) => {
    const dropAreasMap = {
      area1: setDropArea1,
      area2: setDropArea2,
      area3: setDropArea3,
    };

    const setItems = dropAreasMap[id];

    setItems((prevItems) => prevItems.filter((item) => item.imgNum !== imgNum));
  };

  const updateResults = (area1, area2, area3) => {
    setResults([
      `1-${area1.map(item => item.word).join(',')}`,
      `2-${area2.map(item => item.word).join(',')}`,
      `3-${area3.map(item => item.word).join(',')}`,
    ]);
  };

  useEffect(() => {
    updateResults(dropArea1, dropArea2, dropArea3);
  }, [dropArea1, dropArea2, dropArea3]);

  return (
    <>
      <div style={{ padding: '20px' }}>
        <div className='drag-items dragBtnBox'>
          {words.map((item) => (
            <Type10DragItem key={item.imgNum} word={item.word} imgNum={item.imgNum} />
          ))}
        </div>

        <div className='dragAnswerBox' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
          <div className='diagram'>
            <Type10DropArea
              id="area1"
              label="우리나라"
              description={'일본의 식민 지배를 받았다.'}
              items={dropArea1}
              onDrop={handleDrop}
              onRemove={handleRemove} // 제거 함수 전달
            />
            <Type10DropArea
              id="area2"
              label=""
              description={'다른 나라에 의해 식민 지배를 받았다.'}
              items={dropArea2}
              onDrop={handleDrop}
              onRemove={handleRemove} // 제거 함수 전달
            />
            <Type10DropArea
              id="area3"
              label="베트남"
              description={'프랑스의 식민 지배를 받았다.'}
              items={dropArea3}
              onDrop={handleDrop}
              onRemove={handleRemove} // 제거 함수 전달
            />
          </div>
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};


const Type11DragItem = ({ word }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type11-word', // 이 부분이 일치해야 합니다.
    item: { word },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className='draggable'
      style={{
        padding: '8px 12px',
        margin: '4px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: isDragging ? '#f0f0f0' : '#fff',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {word}
    </div>
  );
};

const Type11DropArea = ({ id, value, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type11-word', // 이 부분이 일치해야 합니다.
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className='dragitem'
      style={{
        minWidth: '200px',
        minHeight: '30px',
        padding: '10px',
        border: '1px solid #aaa',
        backgroundColor: isOver ? '#f0f8ff' : '#fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 10px',
      }}
    >
      {value || ''}
    </div>
  );
};

// Type11 컴포넌트: 문항 컴포넌트 type8 순서대로 끌어놓기
const Type11 = () => {
  const [dropAreas, setDropAreas] = useState([
    { id: 'area1', value: '' },
    { id: 'area2', value: '' },
    { id: 'area3', value: '' },
    { id: 'area4', value: '' },
  ]);
  const [results, setResults] = useState([]);

  const handleDrop = (id, item) => {
    setDropAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === id ? { ...area, value: item.word } : area
      )
    );
  };

  useEffect(() => {
    const newResults = dropAreas.map((area, index) => `${index + 1}-${area.value}`);
    setResults(newResults);
  }, [dropAreas]);

  return (
    <>
      <div className='stwrap' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center', gap: '2rem', width: '100%', margin: '0 auto', padding: '50px', background: '#fff', border: '1px solid #eee' }}>
        <div className='dragWrap type8'>
          <div className='dragBtnBox'>
            <Type11DragItem word="주제" />
            <Type11DragItem word="내용" />
            <Type11DragItem word="자료" />
            <Type11DragItem word="방법" />
          </div>
        </div>
        <div className='dragAnswerBox'>
          <div className='dragflow' style={{ marginTop: '50px', justifyContent: 'flex-start', width: '1000px' }}>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              모든 친구들이 함께 설명할<Type11DropArea id="area1" value={dropAreas[0].value} onDrop={handleDrop} />정하기
            </div>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              주제와 관련된 <Type11DropArea id="area2" value={dropAreas[1].value} onDrop={handleDrop} /> 함께 찾기
            </div>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              설명하고 싶은 <Type11DropArea id="area3" value={dropAreas[2].value} onDrop={handleDrop} /> 정하기
            </div>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              알맞은 설명 <Type11DropArea id="area4" value={dropAreas[3].value} onDrop={handleDrop} /> 정하기
            </div>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              내용 정리하기
            </div>
            <div className='dragitem' style={{ display: 'flex', height: '100px' }}>
              내용과 자료에 따라 설명하는 글쓰기
            </div>
          </div>
        </div>
      </div>

      <ResultArea results={results} />
    </>
  );
};


// 드래그가 가능한 이미지 아이템
const Type12DragItem = ({ src, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type12-image',
    item: { src, imgNum },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        margin: '0 5px',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <img src={src} alt={`img-${imgNum}`} />
    </div>
  );
};

// 이미지를 드롭할 수 있는 영역
const Type12DropArea = ({ id, value, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type12-image',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className='draginput'
    >
      {value && <img src={value.src} alt={`dropped-${value.imgNum}`} style={{ maxWidth: '100%', maxHeight: '100%' }} />}
    </div>
  );
};


// Type12: 문항 컴포넌트 type9 문장부호 맞추기
const Type12 = () => {
  const [dropAreas, setDropAreas] = useState([
    { id: 'drop1', value: null },
    { id: 'drop2', value: null },
    { id: 'drop3', value: null },
    { id: 'drop4', value: null },
    { id: 'drop5', value: null },
    { id: 'drop6', value: null },
    { id: 'drop7', value: null },
  ]);

  const [results, setResults] = useState([]);

  const handleDrop = (id, item) => {
    setDropAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === id ? { ...area, value: item } : area
      )
    );
  };

  useEffect(() => {
    const newResults = dropAreas.map((area, index) =>
      `${index + 1}-${area.value ? area.value.imgNum : ''}`
    );
    setResults(newResults);
  }, [dropAreas]);

  return (
    <>
      <div className="stwrap">
        <div className="dragWrap type9">
          <div className="dragBtnBox" style={{ display: 'flex', marginBottom: '20px' }}>
            {[...Array(7)].map((_, index) => (
              <Type12DragItem
                key={index}
                src={`/images/q_common/drage_t10_ex_btn${index + 1}.png`}
                imgNum={index + 1}
              />
            ))}
          </div>
          <div className="dragAnswerBox">
            <div className="dragitem">
              <span className="caption">
                <span className="bullet tit">상황 1</span> 방학이 끝나고 친구 시우를 만나서 인사를 나누었다.
              </span>
              <ul className="dragmark_group" style={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
                <li><Type12DropArea id="drop1" value={dropAreas[0].value} onDrop={handleDrop} /></li>
                <li>시</li>
                <li>우</li>
                <li>야</li>
                <li><Type12DropArea id="drop2" value={dropAreas[1].value} onDrop={handleDrop} /></li>
                <li>잘</li>
                <li></li>
                <li>지</li>
                <li>냈</li>
                <li>니</li>
                <li><Type12DropArea id="drop3" value={dropAreas[2].value} onDrop={handleDrop} /></li>
                <li><Type12DropArea id="drop4" value={dropAreas[3].value} onDrop={handleDrop} /></li>
              </ul>
            </div>
            <div className="dragitem">
              <span className="caption">
                <span className="bullet tit">상황 2</span> 심청전 책을 읽고 마음 속으로 이런 생각이 들었다.
              </span>
              <ul className="dragmark_group" style={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
                <li><Type12DropArea id="drop5" value={dropAreas[4].value} onDrop={handleDrop} /></li>
                <li>심</li>
                <li>청</li>
                <li>이</li>
                <li>는</li>
                <li></li>
                <li>정</li>
                <li>말</li>
                <li></li>
                <li>착</li>
                <li>하</li>
                <li>구</li>
                <li>나</li>
                <li><Type12DropArea id="drop6" value={dropAreas[5].value} onDrop={handleDrop} /></li>
                <li><Type12DropArea id="drop7" value={dropAreas[6].value} onDrop={handleDrop} /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        <ResultArea results={results} />
    </>
  );
};



// Type13DragItem 컴포넌트
const Type13DragItem = ({ imgSrc, text, imgNum }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'type13-item',
    item: { imgSrc, text, imgNum },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="draggable"
      data-drag-num={imgNum}
    >
      <div className="dargcard">
        <img src={imgSrc} alt={`DragItem ${imgNum}`} />
        <span>{text}</span>
      </div>
    </div>
  );
};

// Type13DropArea 컴포넌트
const Type13DropArea = ({ id, label, value, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'type13-item',
    drop: (item) => onDrop(id, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div className="dragtable_tr">
      <div className="dragtable_th">{label}</div>
      <div className="dragtable_td">
        <div
          ref={drop}
          className="draginput"
        >
          {value && (
            <div className="draggable" data-drag-num={value.imgNum}>
              <div className='dargcard'>
                <img src={value.imgSrc} alt={`Dropped ${value.imgNum}`} />
                <span>{value.text}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Type13 컴포넌트
const Type13 = () => {
  const [dropAreas, setDropAreas] = useState([
    { id: 'area1', label: '양반', value: null },
    { id: 'area2', label: '중인', value: null },
    { id: 'area3', label: '상민', value: null },
    { id: 'area4', label: '천민', value: null },
  ]);

  const [dragItems] = useState([
    { imgSrc: '/images/q_common/drag_t12ex1.png', text: '궁궐에서 그림을 그리거나 외국 사신을 맞이하며 통역을 담당하기도 했다.', imgNum: 1 },
    { imgSrc: '/images/q_common/drag_t12ex2.png', text: '관리가 되거나 유교의 가르침이 담긴 책을 공부하고 지역의 선비들과 여러 가지 주제로 토론하기도 했다.', imgNum: 2 },
    { imgSrc: '/images/q_common/drag_t12ex3.png', text: '나라와 개인의 재산으로 여겨져 양반의 집이나 관공서에서 허드렛일이나 물건을 만드는 일을 하기도 했다.', imgNum: 3 },
    { imgSrc: '/images/q_common/drag_t12ex4.png', text: '대부분 농사를 지으며 나라에 큰 공사나 일이 있을 때 불려 가기도 했으며 나라에 세금을 내기도 했다.', imgNum: 4 },
  ]);

  const handleDrop = (id, item) => {
    setDropAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === id ? { ...area, value: item } : area
      )
    );
  };

  useEffect(() => {
    const results = dropAreas.map((area, index) => `${index + 1}-${area.value ? area.value.imgNum : ''}`);
    setResults(results);
  }, [dropAreas]);

  const [results, setResults] = useState([]);

  return (
    <>
      <div className='stwrap' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center', gap: '2rem', width: '100%', margin: '0 auto', padding: '50px', background: '#fff' }}>
        <div className="dragWrap type11">
          <div className="dragBtnBox">
            {dragItems.map((item) => (
              <Type13DragItem key={item.imgNum} imgSrc={item.imgSrc} text={item.text} imgNum={item.imgNum} />
            ))}
          </div>
          <div className="dragAnswerBox">
            <ul className="dragtable">
              {dropAreas.map((area) => (
                <Type13DropArea
                  key={area.id}
                  id={area.id}
                  label={area.label}
                  value={area.value}
                  onDrop={handleDrop}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ResultArea results={results} />
    </>
  );
};


const Type14 = () => {
  const [hourRotation, setHourRotation] = useState(0);
  const [minuteRotation, setMinuteRotation] = useState(0);
  const [secondRotation, setSecondRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedHand, setSelectedHand] = useState(null);
  const [offsetAngle, setOffsetAngle] = useState(0);
  const [currentMinute, setCurrentMinute] = useState(0);

  const clockRef = useRef(null);
  const hourHandRef = useRef(null);
  const minuteHandRef = useRef(null);
  const secondHandRef = useRef(null);

  useEffect(() => {
    initializeClock();
  }, []);

  const initializeClock = () => {
    const currentDate = new Date();
    const seconds = currentDate.getSeconds();
    const minutes = currentDate.getMinutes();
    const hours = currentDate.getHours();

    setSecondRotation(seconds * 6);
    setMinuteRotation(minutes * 6);
    setHourRotation((hours % 12) * 30 + minutes * 0.5);
    setCurrentMinute((minutes + 45) % 60);
  };

  const calculateRotation = useCallback((clientX, clientY) => {
    const rect = clockRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const deltaX = clientX - centerX;
    const deltaY = clientY - centerY;
    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
    return (angle + 180) % 360; // Normalize to 0-360 range, with 0 at the top
  }, []);

  const startDrag = useCallback((event) => {
    if (event.target.classList.contains('hand')) {
      event.preventDefault();
      const hand = event.target;
      setSelectedHand(hand);
      setIsDragging(true);

      const clientX = event.clientX || (event.touches && event.touches[0].clientX);
      const clientY = event.clientY || (event.touches && event.touches[0].clientY);
      const clickAngle = calculateRotation(clientX, clientY);
      const currentRotation = parseFloat(hand.style.transform.replace('rotate(', '').replace('deg)', '')) || 0;
      setOffsetAngle(clickAngle - currentRotation);
    }
  }, [calculateRotation]);

  const drag = useCallback((event) => {
    if (isDragging && selectedHand) {
      event.preventDefault();
      const clientX = event.clientX || (event.touches && event.touches[0].clientX);
      const clientY = event.clientY || (event.touches && event.touches[0].clientY);
      const rotationDegree = (calculateRotation(clientX, clientY) - offsetAngle + 360) % 360;

      if (selectedHand === hourHandRef.current) {
        setHourRotation(rotationDegree);
      } else if (selectedHand === minuteHandRef.current) {
        setMinuteRotation(rotationDegree);
      } else if (selectedHand === secondHandRef.current) {
        setSecondRotation(rotationDegree);
      }
    }
  }, [isDragging, selectedHand, offsetAngle, calculateRotation]);

  const endDrag = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      if (selectedHand === minuteHandRef.current) {
        const minute = Math.round((minuteRotation / 360) * 60 + 45) % 60;
        setCurrentMinute(minute);
      }
      setSelectedHand(null);
    }
  }, [isDragging, selectedHand, minuteRotation]);

  return (
    <div 
      className="stwrap" 
      onMouseMove={drag}
      onMouseUp={endDrag}
      onMouseLeave={endDrag}
      onTouchMove={drag}
      onTouchEnd={endDrag}
    >
      <div className="dragWrap type4">
        <div 
          className="clock" 
          ref={clockRef} 
          onMouseDown={startDrag} 
          onTouchStart={startDrag}
        >
          <div className="hand hour" ref={hourHandRef} style={{ transform: `rotate(${hourRotation}deg)` }}>시간</div>
          <div className="hand minute" ref={minuteHandRef} style={{ top: '49%', transform: `rotate(${minuteRotation}deg)` }}>분</div>
          <div className="hand second" ref={secondHandRef} style={{ transform: `rotate(${secondRotation}deg)` }}>초</div>
          <div className="clockDot"></div>
          <div className="clock_bg">
            <img src="/images/q_common/clock_bg_ex.png" alt="Clock background" onDragStart={(e) => e.preventDefault()} />
          </div>
        </div>
        <ResultArea results={[`${currentMinute}분`]} />
      </div>
    </div>
  );
};


// ResultArea 컴포넌트
const ResultArea = ({ results }) => (
  <div style={{ marginTop: '20px' }}>
    <h4>결과:</h4>
    {results.map((result, index) => (
      <p key={index}>{result}</p>
    ))}
  </div>
);


// DragAndDrop 컴포넌트 수정
const DragAndDrop = () => {
  const [selectedType, setSelectedType] = useState('type1');
  const [generalWordList, setGeneralWordList] = useState(['넓은', '깊은', '엄청', '예쁜', '옹기종기', '주렁주렁']);
  const [type7WordList] = useState(['자원순환', '에너지 절약', '친환경 소비', '친환경 이동', '흡수원 보호']);
  const [newWord, setNewWord] = useState('');
  const [dropAreas, setDropAreas] = useState([
    { id: 'input1', label: '㉠', value: '' },
    { id: 'input2', label: '㉡', value: '' }
  ]);
  const [images, setImages] = useState([]);
  const inputRef = useRef(null);
  const [labelType, setLabelType] = useState('㉠');
  const [type6Images, setType6Images] = useState([
    { src: '/images/q_common/img_o.svg', imgNum: 1 },
    { src: '/images/q_common/img_x.svg', imgNum: 2 }
  ]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleDrop = (id, item) => {
    let value;
    if (item.type === 'word') {
      value = item.word;
    } else if (item.type === 'image') {
      value = { src: item.src, imgNum: item.imgNum };
    } else {
      value = item; // 기타 경우 처리
    }

    setDropAreas(prevAreas =>
      prevAreas.map(area =>
        area.id === id ? { ...area, value } : area
      )
    );
  };


  const handleLabelChange = (newLabel) => {
    setLabelType(newLabel);
    updateLabels(newLabel);
  };

  const updateLabels = (startLabel) => {
    const labelSeries = {
      '㉠': ['㉠', '㉡', '㉢', '㉣', '㉤'],
      '㉮': ['㉮', '㉯', '㉰', '㉱', '㉲'],
      '①': ['①', '②', '③', '④', '⑤'],
      '㈀': ['㈀', '㈁', '㈂', '㈃', '㈄'],
      'A': ['A', 'B', 'C', 'D', 'E'],
    };

    const series = labelSeries[startLabel];
    setDropAreas(prevAreas =>
      prevAreas.map((area, index) => ({
        ...area,
        label: series[index] || series[series.length - 1]
      }))
    );
  };

  const handleAddWord = (e) => {
    e.preventDefault();
    const trimmedWord = newWord.trim();
    if (trimmedWord && !generalWordList.includes(trimmedWord)) {
      setGeneralWordList(prevList => [...prevList, trimmedWord]);
      setNewWord('');
    }
    inputRef.current.focus();
  };


  const getWordListForCurrentType = () => {
    return selectedType === 'type7' ? type7WordList : generalWordList;
  };

  const handleDeleteWord = (wordToDelete) => {
    if (selectedType === 'type7') return; // Type7에서는 단어 삭제를 비활성화
    setGeneralWordList((prevList) => prevList.filter((word) => word !== wordToDelete));
    setDropAreas((prevAreas) =>
      prevAreas.map(area =>
        area.value === wordToDelete ? { ...area, value: '' } : area
      )
    );
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImages(prevImages => [
          ...prevImages,
          { src: event.target.result, imgNum: prevImages.length + 1 }
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (imgNum) => {
    setImages(prevImages => {
      const newImages = prevImages.filter(img => img.imgNum !== imgNum);
      const updatedImages = newImages.map((img, index) => ({ ...img, imgNum: index + 1 }));

      // 드롭 영역에서도 삭제된 이미지 제거
      setDropAreas((prevAreas) =>
        prevAreas.map(area =>
          area.value && typeof area.value === 'object' && area.value.imgNum === imgNum
            ? { ...area, value: '' }
            : area
        )
      );

      return updatedImages;
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ padding: '50px 100px' }}>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="type-select">컴포넌트 유형 선택:</label>
          <select
            id="type-select"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="type1">(X)Type 1</option>
            <option value="type2">Type 2</option>
            <option value="type3">Type 3</option>
            <option value="type4">Type 4</option>
            <option value="type5">Type 5</option>
            <option value="type6">Type 6</option>
            <option value="type7">Type 7</option>
            <option value="type8">Type 8</option>
            <option value="type9">Type 9</option>
            <option value="type10">Type 10</option>
            <option value="type11">Type 11</option>
            <option value="type12">Type 12</option>
            <option value="type13">Type 13</option>
            <option value="type14">Type 14</option>

          </select>
        </div>

        {selectedType === 'type1' && (
          <Type1
            wordList={getWordListForCurrentType()}
            onDrop={handleDrop}
            onAddWord={handleAddWord}
            onDeleteWord={handleDeleteWord}
            dropAreas={dropAreas}
            newWord={newWord}
            setNewWord={setNewWord}
            inputRef={inputRef}
            handleImageUpload={handleImageUpload}
            images={images}
            handleImageDelete={handleImageDelete}
            onLabelChange={handleLabelChange}
            labelType={labelType}
          />
        )}

        {selectedType === 'type2' && (
          <Type2
            wordList={getWordListForCurrentType()}
            onDrop={handleDrop}
            onAddWord={handleAddWord}
            // onDeleteWord={handleDeleteWord}
            newWord={newWord}
            setNewWord={setNewWord}
            inputRef={inputRef}
          />
        )}

        {selectedType === 'type3' && <Type3 />}

        {selectedType === 'type4' && <Type4 />}

        {selectedType === 'type5' && (
          <Type5
            wordList={getWordListForCurrentType()}
            onDrop={handleDrop}
            onAddWord={handleAddWord}
            // onDeleteWord={handleDeleteWord}
            dropAreas={dropAreas}
            newWord={newWord}
            setNewWord={setNewWord}
            inputRef={inputRef}
            handleImageUpload={handleImageUpload}
            images={images}
            handleImageDelete={handleImageDelete}
            onLabelChange={handleLabelChange}
            labelType={labelType}
          />
        )}

        {selectedType === 'type6' && (
          <Type6
            wordList={getWordListForCurrentType()}
            onDrop={handleDrop}
            onAddWord={handleAddWord}
            // onDeleteWord={handleDeleteWord}
            newWord={newWord}
            setNewWord={setNewWord}
            inputRef={inputRef}
            handleImageUpload={handleImageUpload}
            images={type6Images}
            handleImageDelete={handleImageDelete}
          />
        )}

        {selectedType === 'type7' && (
          <Type7
            wordList={getWordListForCurrentType()}
            onDrop={handleDrop}
            onAddWord={handleAddWord}
            // onDeleteWord={handleDeleteWord}
            newWord={newWord}
            setNewWord={setNewWord}
            inputRef={inputRef}
            handleImageUpload={handleImageUpload}
            images={images}
            handleImageDelete={handleImageDelete}
          />
        )}

        {selectedType === 'type8' && <Type8 />}
        {selectedType === 'type9' && <Type9 />}
        {selectedType === 'type10' && <Type10 />}
        {selectedType === 'type11' && <Type11 />}
        {selectedType === 'type12' && <Type12 />}
        {selectedType === 'type13' && <Type13 />}
        {selectedType === 'type14' && <Type14 />}


      </div>
    </DndProvider>
  );
};


export default DragAndDrop;

