import { useState, useEffect } from 'react';
import { getKeyByValue } from '../../../js/helper-functions/convertEnum';
import { subject } from '../../../enum/enum';

const InfoCards = ({ students, assessmentInfo, remainingTime }) => {
    const [countdown, setCountDown] = useState({ minutes: 0, seconds: 0 });
    
    useEffect(() => {
        setCountDown(remainingTime);
        // Simulating countdown
        const timer = setInterval(() => {
            setCountDown(prev => {
            if (prev.seconds > 0) {
                return { ...prev, seconds: prev.seconds - 1 };
            } else if (prev.minutes > 0) {
                return { minutes: prev.minutes - 1, seconds: 59 };
            } else {
                clearInterval(timer);
                return { minutes: 0, seconds: 0 };
            }
            });
        }, 1000);
    
        return () => clearInterval(timer);
    }, []);
    
    return (
        <ul className="info_card">
        <InfoCardItem label="평가대상" value={`${assessmentInfo.grade}학년 ${assessmentInfo.class || '#0'}반`} />
        <InfoCardItem label="평가과목" value={getKeyByValue(subject, assessmentInfo.subject)} />
        <InfoCardItem label="제출현황" value={`${students.filter(s => s.student_status === "4").length}명 / ${students.filter(s => s.student_type !== "3").length}명`} />
        <InfoCardItem label="남은시간" value={`${countdown.minutes}분 ${countdown.seconds}초`} />
        </ul>
    );
}

const InfoCardItem = ({ label, value }) => (
    <li className="card__item">
    <span className="item num1"><b>{label}</b></span>
    <span className="item num2"><b>{value}</b></span>
    </li>
);
    

export {InfoCardItem, InfoCards};