import React from 'react';
import { subject } from '../../../enum/enum';

const SubjectSelection = ({ sub, publisher, onChange, publishers, fetchExamData }) => {
  const showPublishers = sub === '영어'
  const subjects = Object.keys(subject);
  const handleChangePublisher = (textBook) => {
    const isAdd = !publisher.includes(textBook)
    if(isAdd) {
      onChange("publisher", [...publisher, textBook])
    } else {
      onChange("publisher", [...publisher].filter(p => textBook !== p))
    }
  }

  return (
    <li className="create__item num2">
      <span className="item__title"><em className="number">2</em>과목</span>
      <div className="item__box flex-col">
        <ul className="subject__select">
          {subjects.map((s, index) => (
            <li key={index}>
              <input
                type="radio"
                id={`subject${index + 1}`}
                name="subject_radio"
                value={s}
                checked={sub === s}
                onChange={(e) => onChange('subject', e.target.value)}
              />
              <label htmlFor={`subject${index + 1}`} className="box_btn">{s}</label>
            </li>
          ))}
        </ul>
        {showPublishers && (
          <div className="publisher_select">
            <span><em className="required"><span className="sr-only">필수선택</span></em>출판사 선택</span>
            <ul>
              {publishers.map((p, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    id={`publisher${index + 1}`}
                    name="publisher_checkbox"
                    value={p.text_book}
                    checked={publisher.includes(p.text_book)}
                    onChange={() => handleChangePublisher(p.text_book)}
                  />
                  <label htmlFor={`publisher${index + 1}`}>{p.text_book}</label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </li>
  );
};

export default SubjectSelection;