import React, { useState, useEffect, Suspense } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import DynamicQuestionImport from '../components/question/DynamicQuestionImport';
import QuestionContent from '../components/question/QuestionContent';
import QuestionContainer from '../components/question/QuestionContainer';
import { supabase } from '../supabaseClient';

ChartJS.register(CategoryScale, LinearScale, BarElement);

const Sub020102 = () => {
  const [examFileCode, setExamFileCode] = useState('');

  return (
    <>
      <div className="layout">
        <PrevInfo />
        <PrevBox examFileCode={examFileCode} setExamFileCode={setExamFileCode} />
      </div>
      <ButtonGroup />
    </>
  );
};

const PrevInfo = () => {
  const chartData = {
    labels: ['상', '중', '하'],
    datasets: [{
      backgroundColor: ['rgb(255, 86, 86)', 'rgb(0, 211, 171)', 'rgb(255, 184, 62)'],
      barThickness: 40,
      borderRadius: 6,
      borderSkipped: false,
      data: [2, 10, 5],
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    }
  };

  return (
    <div className="prev_info">
      <div className="info_box">
        <div className="text_box">
          <strong className="totla__title"><b>5</b>학년 <b>1</b>학기 <b>영어(동아)</b></strong>
          <span><em>총 문제수</em><b>25</b>문제</span>
          <ul>
            <li>선택형 19</li>
            <li>서답형 6</li>
          </ul>
        </div>
        <div className="graph_wrap">
          <div className="infoChart_wrap">
            <Bar data={chartData} options={chartOptions} />
          </div>
          <ul>
            <li>상</li>
            <li>중</li>
            <li>하</li>
          </ul>
        </div>
      </div>
      <InfoList />
    </div>
  );
};

const InfoList = () => {
  const [questions, setQuestions] = useState([
    { id: 1, content: '대화의 특성 파악', difficulty: '중', points: 4 },
    { id: 2, content: '문장 구조 이해', difficulty: '상', points: 5 },
    { id: 3, content: '어휘 활용', difficulty: '하', points: 3 },
    { id: 4, content: '문법 규칙 적용', difficulty: '중', points: 4 },
    { id: 5, content: '독해 능력 평가', difficulty: '상', points: 5 },
  ]);

  const handleDelete = (id) => {
    setQuestions(questions.filter(q => q.id !== id));
  };

  return (
    <div className="info_list">
      <div className="title_box">
        <strong className="subtitle">
          <i className="hico hico1"></i>
          문제목록
        </strong>
        <button type="submit" className="btn btn-ani btn-quaternary" onClick={() => handleDelete(questions[questions.length - 1].id)}>
          <i className="sicon ico3"></i>선택삭제
        </button>
      </div>
      <div className="table_wrap">
        <table className="tbl_basic center table-hover">
          <caption>문제 목록</caption>
          <colgroup>
            <col style={{width:"10%"}} />
            <col style={{width:"10%"}} />
            <col style={{width:"40%"}} />
            <col style={{width:"10%"}} />
            <col style={{width:"10%"}} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col"><input type="checkbox" id="allcheck" name="check"/><label htmlFor="allcheck"></label></th>
              <th scope="col">번호</th>
              <th scope="col">평가내용</th>
              <th scope="col">난이도</th>
              <th scope="col">배점</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={question.id}>
                <th scope="row"><input type="checkbox" id={`check${index}`} name="check"/><label htmlFor={`check${index}`}></label></th>
                <td>{question.id}</td>
                <td>{question.content}</td>
                <td>{question.difficulty}</td>
                <td>{question.points}</td>
              </tr>
            ))}
          </tbody>
        </table>  
      </div>
    </div>
  );
};

const PrevBox = ({ examFileCode, setExamFileCode }) => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [localExamFileCode, setLocalExamFileCode] = useState(examFileCode);
  

  const handleExamFileCodeChange = () => {
    setExamFileCode(localExamFileCode);
  };

  return (
    <div className="prev_box">
      <div className="title_box" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong className="subtitle2">미리보기</strong>
          <div className="select_wrap" style={{ marginLeft: '10px' }}>
            <select className="select" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
              <option value="asc">문제유형 오름차순</option>
              <option value="desc">문제유형 내림차순</option>
            </select>
          </div>
        </div>
        <div className="exam-file-code-input" style={{ display: 'flex', alignItems: 'center' }}>
          <label htmlFor="examFileCode" style={{ marginRight: '5px' }}>시험지 파일 코드:</label>
          <input
            type="text"
            id="examFileCode"
            value={localExamFileCode}
            onChange={(e) => setLocalExamFileCode(e.target.value)}
            style={{ padding: '5px', width: '150px', marginRight: '5px' }}
          />
          <button 
            onClick={handleExamFileCodeChange}
            className="btn btn-ani btn-file"
            style={{ padding: '5px 10px' }}
          >
            변경
          </button>
        </div>
      </div>
      <div className="prev_list">
        <PreviewItem examFileCode={examFileCode} />
      </div>
    </div>
  );
};

const PreviewItem = ({examFileCode}) => {
  const [question, setQuestion] = useState(null); // 새로운 state를 추가합니다.

  useEffect(() => {
    const fetchQuestion = async () => {
      const {data, error} = await supabase
        .from("exam_question_bank")
        .select("*")
        .eq("exam_file_code", examFileCode)
  
      setQuestion(data[0])
    }
    
    fetchQuestion();
// examFileCode가 변경될 때마다 key를 변경합니다.
  }, [examFileCode]);

  if(question === null) return <></>

  return (
    <div className='prev__item'>
      <QuestionContainer answers={[]} currentQuestion={1} question={question} handleAnswerChange={() => []} selectedQType={question?.qtype} selectedDetailType={question?.detail_type} />
      <ul className="btn_group">
        <li>
          <i className="state tx">{(question?.qtype === 7 || question?.qtype === 8) ? "서답형" : "선택형"}</i>
          <i className={`state tx diff${question?.level === '상' ? 1 : question?.level === '중' ? 2 : 3}`}>{question?.level}</i>
          <span className="state tx gr">{question?.context}</span>
        </li>
        <li>
          <a href="#" className="btn btn-ani btn-blank report"><i className="sicon ico7"></i>오류신고</a>
          <button type="submit" className="sicon ico6">삭제</button>
        </li>
      </ul>
    </div>
  );
};

const ButtonGroup = () => (
  <div className="btn_group">
    <a href="/sub0201.html" className="btn btn-ani btn-secondary lg">이전으로</a>
    <a href="/sub0203.html" className="btn btn-ani btn-primary lg">문제저장</a>
  </div>
);

export default Sub020102;