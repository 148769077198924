import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider, useAuth } from './common/AuthProvider';
import LoginPage from './LoginPage';
import { On2024ComplexProvider } from './common/Context';
import Header from './layout/Header';
import { teacher, student, admin } from './enum/enum';
import MainLayout from './contents/layout/MainLayout';
import ExamLayout from './contents/layout/ExamLayout';
import EnhancedRefreshPrevention from './EnhancedRefreshPrevention';
import AdminLogin from './adminLogin';
import AdminMainLayout from './contents/layout/AdminMainLayout';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Q4DragPreview from './components/question/Q4DragPreview';
import Publishing from './Publishing';

const TeacherLayout = () => (
  <div id="wrapper">
    <Header />
    <MainLayout />
  </div>
);

const AdminLayout = () => (
  <div id="wrapper">
    <Header />
    <AdminMainLayout />
  </div>
);

// const StudentLayout = () => (
//   <div id="wrapper">
//     <Header />
//     <MainLayout />
//   </div>
// );

const CustomDndProvider = ({ children }) => {
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  return isTouchDevice ? (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      {children}
      <Q4DragPreview />
      </DndProvider>
  ) : (
    <DndProvider backend={HTML5Backend}>
      {children}
    </DndProvider>
  );
};


const queryClient = new QueryClient();

const App = () => {
  // fetch('http://localhost:5000/api/users')
  // .then(response => response.json())
  // .then(data => console.log(data));
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <On2024ComplexProvider>
            <CustomDndProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/adminLogin" element={<AdminLogin />} />
                <Route path="/publishing" element={<Publishing />} />
                <Route
                  path="/admin/*"
                  element={
                    <ProtectedRoute role={admin}>
                      <AdminLayout />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <ProtectedRoute role={teacher}>
                      <TeacherLayout />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path="/*"
                  element={
                    <ProtectedRoute role={student}>
                      <StudentLayout />
                    </ProtectedRoute>
                  }
                /> */}

                <Route path="/examLayout" element={<ExamLayout />} />
              </Routes>
            </CustomDndProvider>
          </On2024ComplexProvider>
        </Router>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <EnhancedRefreshPrevention message="작업 중인 내용이 있습니다. 정말로 페이지를 떠나시겠습니까?" />
    </QueryClientProvider>
  );
};

export default App;


const ProtectedRoute = ({ children, role }) => {
  const { user, userRole } = useAuth();

  // console.log('user:', user);
  // console.log('userRole:', userRole);
  // console.log('required role:', role);

  if (!user || userRole !== role) {
    // console.log('교사 로그인이 필요합니다.');
    return <Navigate to="/login" replace />;
  } else {
    console.log('ProtectedRoute: 로그인 성공!');
  }

  return children;
};
