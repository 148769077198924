import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import GradeSelection from './detailContents/sub0201/GradeSelection';
import SubjectSelection from './detailContents/sub0201/SubjectSelection';
import UnitSelection from './detailContents/sub0201/UnitSelection';
import QuestionTypeSelection from './detailContents/sub0201/QuestionTypeSelection';
import QuestionCountSelection from './detailContents/sub0201/QuestionCountSelection';
import DifficultySelection from './detailContents/sub0201/DifficultySelection';
import StudentList from './detailContents/sub0201/StudentList';
import ExamSummary from './detailContents/sub0201/ExamSummary';
import ActionButtons from './detailContents/sub0201/ActionButtons';
import { subject } from '../enum/enum';
import { supabase } from '../supabaseClient';
import { useOn2024ComplexContext } from '../common/Context';
import ClassCodeInput from './detailContents/sub0201/ClassCodeInput';

const CreateExam = () => {
  const { teacherInfo, navigate, location } = useOn2024ComplexContext();
  const [prevSelectStudents, setPrevSelectStudents] = useState([])
  const [examSettingId, setExamSettingId] = useState(location?.state?.exam_question_setting_id);
  const [classCode, setClassCode] = useState(null)
  const screenType = location?.state?.type;
  const [distributionStatus, setDistributionStatus] = useState(false)
  const [examData, setExamData] = useState({
    grade: teacherInfo.grade_manage,
    semester: '',
    subject: '',
    publisher: [],
    units: [],
    questionType: 0,
    questionTypes: { multiple: 0, shortAnswer: 0 },
    questionCount: 0,
    difficulty: { high: 0, medium: 0, low: 0 },
    selectedStudents: [],
  });
  const [examNum, setExamNum] = useState(null)

  const fetchStudents = async () => {
    const year = new Date().getFullYear().toString();
    const { data, error } = await supabase
      .from('student')
      .select('*')
      .eq('year', year)
      .eq('teacher_id', teacherInfo.teacher_id)
      .eq('delete_yn', 'N')
      .order('student_number', { ascending: true });
    if (error) throw error;
    return data;
  };
  const fetchExamSetting = async () => {
    if (!examSettingId) return null;
    const { data, error } = await supabase.from('exam_question_setting').select('*').eq('id', examSettingId).eq('delete_yn', 'N');
    if (error) throw error;
    return data[0];
  };
  const fetchSelectStudent = async () => {
    if (!examSettingId) return [];
    const { data, error } = await supabase
      .from('question_creation_participation')
      .select('student_id')
      .eq('exam_question_setting_id', examSettingId);
    if (error) throw error;
    const transformedData = data.map(({ student_id }) => student_id)
    setPrevSelectStudents(transformedData);
    return transformedData;
  };
  const fetchExamData = async (grade, semester, subjectName, publisher = null) => {
    if (!subjectName) return null;
    const gradeTermPattern = `${grade}-${semester}-%`;
    const year = new Date().getFullYear();
    let query = supabase
      .from('exam_question_bank')
      .select('grade_term_unit, unit_name, text_book')
      .eq('year', year)
      .ilike('grade_term_unit', gradeTermPattern)
      .eq('subject', Number(subject[subjectName]));
    if (publisher) {
      query = query.in('text_book', publisher);
    }
    const { data, error } = await query;
    if (error) throw error;
    return Array.from(new Set(data.map((item) => JSON.stringify(item))))
      .map((item) => JSON.parse(item))
      .sort((a, b) => Number(a.grade_term_unit.split('-')[2]) - Number(b.grade_term_unit.split('-')[2]));
  };
  const fetchPublisherData = async () => {
    if (!examData.subject) return null;
    const year = new Date().getFullYear();
    const gradeTermPattern = `${examData.grade}-${examData.semester}-%`;
    const { data, error } = await supabase
      .from('exam_question_bank')
      .select('text_book')
      .ilike('grade_term_unit', gradeTermPattern)
      .eq('year', year)
      .eq('subject', Number(subject[examData.subject]));
    if (error) throw error;
    const uniqueData = Array.from(new Set(data?.map((item) => JSON.stringify(item))))
      ?.map((item) => JSON.parse(item))
      ?.sort();
    return uniqueData;
  };
  const fetchExamNumberByDifficulty = async (units) => {
    if (!units.length) return [0, 0, 0];
    const year = new Date().getFullYear();
    const grade_term_unit = units.map((unit) => `${examData.grade}-${Number(examData.semester)}-${unit}`);
    let query = supabase
      .from('exam_question_bank')
      .select('level')
      .eq('year', year)
      .eq('subject', subject[examData.subject])
      .in('grade_term_unit', grade_term_unit);
    if (examData.publisher.length) {
      query = query.in('text_book', examData.publisher);
    }
    const { data, error } = await query;
    if (error) throw error;
    const difficultyNum = [0, 0, 0];
    data.forEach((d) => {
      if (d.level === '상') difficultyNum[0]++;
      else if (d.level === '중') difficultyNum[1]++;
      else if (d.level === '하') difficultyNum[2]++;
    });
    return difficultyNum;
  };
  const {
    data: students,
    isLoading: studentsLoading,
    error: studentsError,
  } = useQuery({
    queryKey: ['students', teacherInfo.teacher_id],
    queryFn: fetchStudents,
    enabled: !!teacherInfo.teacher_id,
  });
  const { data: examSettingData } = useQuery({
    queryKey: ['examSetting', examSettingId],
    queryFn: fetchExamSetting,
    enabled: !!examSettingId,
  });
  const { data: selectedStudents } = useQuery({
    queryKey: ['selectedStudents', examSettingId],
    queryFn: fetchSelectStudent,
    enabled: !!examSettingId,
  });
  const { data: examBankData, refetch: refetchExamData } = useQuery({
    queryKey: ['examData', examData.grade, examData.semester, examData.subject, examData.publisher],
    queryFn: () =>
      fetchExamData(
        examData.grade,
        examData.semester,
        examData.subject,
        examData.subject === '영어' ? examData.publisher : null
      ),
    enabled: !!examData.subject && (examData.subject !== '영어' || !!examData.publisher),
  });
  const { data: publishers } = useQuery({
    queryKey: ['publishers', examData.grade, examData.semester, examData.subject],
    queryFn: fetchPublisherData,
    enabled: !!examData.subject && examData.subject === '영어',
  });
  const { data: difficulty } = useQuery({
    queryKey: ['difficulty', examData.grade, examData.semester, examData.subject, examData.publisher, examData.units],
    queryFn: () => fetchExamNumberByDifficulty(examData.units),
    enabled: !!examData.units.length,
  });

  useEffect(() => {
    setExamData({
      grade: teacherInfo.grade_manage,
      semester: '',
      subject: '',
      publisher: [],
      units: [],
      questionType: 0,
      questionTypes: { multiple: 0, shortAnswer: 0 },
      questionCount: 0,
      difficulty: { high: 0, medium: 0, low: 0 },
      selectedStudents: [],
    })
    setClassCode(null)
  }, [screenType])
  useEffect(() => {
    if (examSettingData && selectedStudents) {
      const transformedData = {
        grade: examSettingData.grade,
        semester: examSettingData.term,
        subject: Object.keys(subject)[Number(examSettingData.subject) - 1],
        publisher: examSettingData.text_book.length ? examSettingData.text_book.split(',') : [],
        units: [examSettingData.unit1, examSettingData.unit2, examSettingData.unit3]
          .filter((unit) => unit !== null && unit !== 0 && unit !== "0")
          .map((unit) => unit.toString()),
        questionType: Number(examSettingData.question_type),
        questionTypes: {
          multiple: 100 - examSettingData.question_type_ratio,
          shortAnswer: examSettingData.question_type_ratio,
        },
        questionCount: examSettingData.question_count,
        difficulty: {
          high: parseInt(examSettingData.exam_level_high),
          medium: parseInt(examSettingData.exam_level_middle),
          low: parseInt(examSettingData.exam_level_low),
        },
        selectedStudents,
      };
      setExamData(transformedData);
      setClassCode(examSettingData.class_code);
      console.log(examSettingData.distribution_status)
      setDistributionStatus(!!examSettingData.distribution_status)
    }
  }, [examSettingData, selectedStudents]);
  useEffect(() => {
    if (!examData || !examData.units || examData.units.length === 0) return;
  
    const totalQuestions = examData.questionCount;
    const unitCount = examData.units.length;
  
    // 단원별 문제 수 계산
    const baseUnitQuestions = Math.floor(totalQuestions / unitCount);
    const remainingQuestions = totalQuestions % unitCount;
    
    const unitQuestions = examData.units.map((_, index) => {
      if (index < remainingQuestions) {
        return baseUnitQuestions + 1;
      }
      return baseUnitQuestions;
    });
  
    const examNumByUnit = unitQuestions.map(unitTotal => {
      // 문제 유형별 문제 수 계산
      const multipleForUnit = Math.round(unitTotal * examData.questionTypes.multiple / 100);
      const shortAnswerForUnit = unitTotal - multipleForUnit;
  
      const calculateByDifficulty = (count, difficulties) => {
        return {
          high: Math.round(count * difficulties.high / 100),
          medium: Math.round(count * difficulties.medium / 100),
          low: count - Math.round(count * difficulties.high / 100) - Math.round(count * difficulties.medium / 100)
        };
      };
  
      return {
        multiple: calculateByDifficulty(multipleForUnit, examData.difficulty),
        shortAnswer: calculateByDifficulty(shortAnswerForUnit, examData.difficulty)
      };
    });
  
    setExamNum(examNumByUnit);
  }, [examData]);

  const handleDataChange = (field, value) => {
    if(screenType === "preview") return null;
    const prev = examData;
    let curr = { ...prev, [field]: value };
    if (field === 'subject') {
      curr = { ...curr, units: [], publisher: [] };
    } else if (field === 'publisher') {
      curr = { ...curr, units: [] };
    }
    if (field === 'units') {
      curr = { ...curr, questionCount: curr.units.length < 3 ? curr.units.length * 10 : 25 };
    } else if (field === 'questionTypes') {
      let newQuestionType = 0;
      if (value.multiple > 0 && value.shortAnswer > 0) {
        newQuestionType = 3;
      } else if (value.multiple > 0) {
        newQuestionType = 1;
      } else if (value.shortAnswer > 0) {
        newQuestionType = 2;
      }
      curr = { ...curr, questionType: newQuestionType };
    }
    setExamData(curr);
  };
  const LastEpisode = async () => {
    const year = new Date().getFullYear();
    const { data, error } = await supabase
      .from('exam_question_setting')
      .select('episode_number, delete_yn, episode_view_number')
      .eq('year', year)
      .eq('school_id', teacherInfo.school_id)
      .eq('term', Number(examData.semester))
      .eq('subject', subject[examData.subject])
      .order('episode_number', { ascending: false })

    if (error) {
      console.error('Error:', error);
      return null;
    }
    const episodeNumber = data[0]?.episode_number ? data[0]?.episode_number + 1 : 1;
    const viewEpisodeNumber = data.filter((a) => a.delete_yn === 'N')[0]?.episode_view_number
      ? data.filter((a) => a.delete_yn === 'N')[0].episode_view_number + 1 
      : 1;
    console.log("episodeViewNumber::::::::::::", viewEpisodeNumber)
    
    return [episodeNumber, viewEpisodeNumber]
  };
  const checkQuestionNum = async () => {
    if (!examNum) return null;
    
    const grade_term_units = examData.units.map((unit) => `${examData.grade}-${examData.semester}-${unit}`);
    let query = supabase
      .from('exam_question_bank')
      .select('*')
      .in('grade_term_unit', grade_term_units)
      .eq('subject', subject[examData.subject]);
    if (examData.publisher.length) {
      query = query.in('text_book', examData.publisher);
    }
  
    const { data, error } = await query;
    
    if (error) {
      throw new Error('Error fetching question bank: ' + error.message);
    }
    
    const isShortAnswer = (qtype) => qtype === 7 || qtype === 8;
    
    // Filter and select questions for each unit
    const selectedQuestions = examNum.flatMap((unitExamNum, unitIndex) => {
      const unitQuestions = data.filter(q => q.grade_term_unit === grade_term_units[unitIndex]);
      
      const selectQuestions = (questions, count, isShortAnswerType) => {
        return questions
          .filter(q => isShortAnswerType ? isShortAnswer(q.qtype) : !isShortAnswer(q.qtype))
          .sort(() => Math.random() - 0.5)
          .slice(0, count);
      };
  
      return [
        ...selectQuestions(unitQuestions.filter(q => q.level === '상'), unitExamNum.multiple.high, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '중'), unitExamNum.multiple.medium, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '하'), unitExamNum.multiple.low, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '상'), unitExamNum.shortAnswer.high, true),
        ...selectQuestions(unitQuestions.filter(q => q.level === '중'), unitExamNum.shortAnswer.medium, true),
        ...selectQuestions(unitQuestions.filter(q => q.level === '하'), unitExamNum.shortAnswer.low, true)
      ];
    });
  
    return selectedQuestions.length === examData.questionCount;
  };
  const handleClassCodeSave = async (code) => {
    const firstChar = code.slice(0, 1);
    const checkLowerCase = firstChar === firstChar.toLowerCase() && firstChar !== firstChar.toUpperCase()
    const checkNumber = Number(code.slice(1, code.length)) >= 10000 && Number(code.slice(1, 6)) <= 99999
    if(!checkLowerCase) return alert('첫번째 글자는 소문자로 설정해주세요.')
    if(!checkNumber) return alert('첫글자를 제외한 나머지 글자는 10000 이상 1000000 미만의 숫자로 구성해주세요.')
    if(code === classCode) return alert('학급코드가 변경되지 않았습니다.')
    const year = new Date().getFullYear();
    const { data: checkClassCode, error: checkClassCodeError  } = await supabase
      .from('exam_question_setting')
      .select('class_code')
      .eq('year', year)
      .eq('teacher_id', teacherInfo.teacher_id)
      .eq('class_code', code)
      .eq('delete_yn', 'N')
      if (checkClassCodeError) {
        console.error('checkClassCodeError:', checkClassCodeError)
      return null
    }
    if(checkClassCode.length) return alert('사용할 수 없는 학급코드입니다.')
    const { error: updateClassCodeError } = await supabase
      .from('exam_question_setting')
      .update({ class_code : code })
      .eq('id', examSettingId)
      .eq('delete_yn', 'N')
    
    alert('학급코드가 수정되었습니다.')
    if (updateClassCodeError) {
      console.error('Error updating data:', updateClassCodeError)
      return null
    }
    setClassCode(code)
  };
  const handleSave = async (type) => {
    try {
      const [episode_number, episode_view_number] = await LastEpisode();
      const saveData = [
        {
          grade: Number(examData.grade),
          class: teacherInfo.class_manage,
          term: Number(examData.semester),
          subject: Number(subject[examData.subject]),
          text_book: examData.publisher.join(','),
          unit1: examData.units[0],
          unit2: examData.units[1] ? examData.units[1] : 0,
          unit3: examData.units[2] ? examData.units[2] : 0,
          question_count: examData.questionCount,
          question_type: examData.questionType,
          question_type_ratio: examData.questionTypes.shortAnswer,
          exam_level_high: examData.difficulty.high,
          exam_level_middle: examData.difficulty.medium,
          exam_level_low: examData.difficulty.low,
          temp_yn: type === 'save' ? 'Y' : 'N',
          distribution_status: 0,
          school_id: teacherInfo.school_id,
          episode_number,
          teacher_id: teacherInfo.teacher_id,
          delete_yn: 'N',
          episode_view_number
        },
      ];

      console.log(saveData)
      let exam_question_setting_id = examSettingId;
      if (examSettingId) {
        console.log('수정')
        await supabase.from('exam_question_setting').update(saveData).eq('id', exam_question_setting_id).eq('delete_yn', 'N');
      } else {
        console.log('등록')
        await supabase.from('exam_question_setting').insert(saveData);
        const { data } = await supabase
          .from('exam_question_setting')
          .select('id')
          .eq('teacher_id', teacherInfo.teacher_id)
          .eq('delete_yn', 'N')
          .order('reg_date', { ascending: false })
          .limit('1');
        exam_question_setting_id = data[0].id;
        setExamSettingId(exam_question_setting_id);
      }
      if (type === 'preview') {
        await handleUpdateStudents(exam_question_setting_id);
      }
      // 성공 메시지를 사용자에게 표시하는 로직 추가
      return exam_question_setting_id;
    } catch (error) {
      console.error('Error saving exam:', error);
      // 에러 메시지를 사용자에게 표시하는 로직 추가
    }
  };
  const handleUpdateStudents = async (exam_question_setting_id) => {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const deleteStudents = prevSelectStudents.filter((s) => !examData.selectedStudents.includes(s));
    const addStudents = examData.selectedStudents.filter((s) => !prevSelectStudents.includes(s));
    addStudents.forEach(async (studentId) => {
      const { data, error } = await supabase.from('question_creation_participation').insert({
        exam_question_setting_id,
        student_id: studentId,
        registration_date: formattedDate,
        registrant: teacherInfo.teacher_id,
        modification_date: formattedDate,
        modifier: teacherInfo.teacher_id,
      });
      if (error) throw error;
    });
    if (deleteStudents.length) {
      try {
        const { error } = await supabase
        .from('question_creation_participation')
        .delete()
        .eq('exam_question_setting_id', exam_question_setting_id)
        .in('student_id', deleteStudents);
  
        if (error) throw error;
      } catch (error) {
        console.error('Error deleting students:', error);
      }
    }
  };
  const checkExamData = (type) => {
    if (!examData.grade) return alert('학년을 선택해주세요');
    if (!examData.semester) return alert('학기를 선택해주세요');
    if (!examData.subject) return alert('과목을 선택해주세요');
    if (type === 'save') return true;
    if (!examData.units[0]) return alert('출제범위를 선택해주세요');
    if (!examData.questionType) return alert('출제유형을 선택해주세요');
    if (!(examData.difficulty.high + examData.difficulty.medium + examData.difficulty.low === 100))
      return alert('난이도를 설정해주세요');
    if (!examData.selectedStudents.length) return alert('출제대상을 선택해주세요');
    return true;
  };
  const handlePreSave = async () => {
    const result = checkExamData('save');
    if (result) await handleSave('save');
  };
  const handlePreview = async () => {
    const result = checkExamData('preview');
    const checked = await checkQuestionNum();
    if(!checked) return alert('문항수가 부족합니다. 다시 설정해주세요.');
    if (result) {
      const exam_question_setting_id = await handleSave('preview');
      navigate('/sub020101', {
        state: {
          exam_question_setting_id,
          examData,
          classCode: !!classCode,
        },
      });
    }
  };
  const handleDetail = () => {
    if(!distributionStatus) {
      return alert('저장된 문제가 존재하지 않습니다.')
    }
    navigate('/sub020101', {
      state: {
        exam_question_setting_id: examSettingId,
        examData,
        screenType: "preview"
      },
    });
  }
  if (studentsLoading) return <div>Loading...</div>;
  if (studentsError) return <div>Error loading students: {studentsError.message}</div>;
  return (
    <>
      <div className="layout">
        <ul className="create_box line_box">
          <GradeSelection grade={examData.grade} semester={examData.semester} onChange={handleDataChange} />
          <SubjectSelection
            sub={examData.subject}
            publisher={examData.publisher}
            onChange={handleDataChange}
            publishers={publishers || []}
          />
          <UnitSelection units={examData.units} availableUnits={examBankData || []} onChange={handleDataChange} />
          <QuestionTypeSelection
            questionTypes={examData.questionTypes}
            onChange={handleDataChange}
          />
          <QuestionCountSelection questionCount={examData.questionCount} />
          <DifficultySelection
            difficultyNum={difficulty || [0, 0, 0]}
            difficulty={examData.difficulty}
            onChange={handleDataChange}
          />
        </ul>
        <div className="inner">
          {classCode && <ClassCodeInput screenType={screenType} classCode={classCode} onSave={handleClassCodeSave} />}
          <StudentList
            students={students || []}
            selectedStudents={examData.selectedStudents}
            onChange={handleDataChange}
          />
        </div>
      </div>
      <ExamSummary examData={examData} students={students} />
      <ActionButtons onSave={handlePreSave} onPreview={handlePreview} onDetail={handleDetail} screenType={screenType} />
    </>
  );
};
export default CreateExam;