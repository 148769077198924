import React from 'react';

const QuestionCountSelection = ({ questionCount }) => {
  const counts = [10, 20, 25];

  return (
    <li className="create__item num5">
      <span className="item__title"><em className="number">5</em>문제수</span>
      <div className="item__box">
        <ul className="subject__select">
          {counts.map((count, index) => (
            <li key={count}>
              <input
                type="radio"
                id={`countkey${index + 1}`}
                name="count_radio"
                value={count}
                checked={questionCount === count}
                readOnly
              />
              <label htmlFor={`countkey${index + 1}`} className="box_btn">{count}개</label>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default QuestionCountSelection;