import React, { useState, useCallback, useMemo } from 'react';
import { supabase } from '../../supabaseClient';
import { useOn2024ComplexContext } from '../../common/Context';
import { qtype, subject } from '../../enum/enum';
import { useQuery } from '@tanstack/react-query';

const ITEMS_PER_PAGE = 10;

const QuestionTable = React.memo(({ questions, selectedQuestions, onSelectAll, onCheckboxChange, onEdit, onTextareaChange, onTypeChange }) => {
  const thArray = ['과목', '학년', '성취코드', '문항번호', '파일명'];
  const thDeepArray = ['성취기준/문제/정답', '문제유형', '사용유무', '수정'];

  return (
    <div className="table_wrap">
      <table className="tbl_basic tbl_list center table-hover">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                id="allcheck"
                checked={selectedQuestions.length === questions.length && questions.length > 0}
                onChange={onSelectAll}
              />
              <label htmlFor="allcheck"></label>
            </th>
            {
              thArray.map((item, index) => (
                <th key={`th-${index}`}>{item}</th>
              ))
            }
            {
              thDeepArray.map((item, index) => (
                <th className='th_deep' key={`th-deep-${index}`}>{item}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {questions.map(question => (
            <tr key={question.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedQuestions.includes(question.id)}
                  onChange={() => onCheckboxChange(question.id)}
                  name="check"
                  id={`check${question.id}`}
                />
                <label htmlFor={`check${question.id}`}></label>
              </td>
              <td>{question.subject}</td>
              <td>{question.grade}</td>
              <td>{question.achievementCode}</td>
              <td className="td_left">{question.questionNumber}</td>
              <td><a href="#">{question.fileName}</a></td>
              <td className="td_left">
                <ul className="standard_info">
                  <li>{question.achievementStandard}</li>
                  <li>{question.question}</li>
                  <li>
                    <textarea
                      value={question.answer}
                      onChange={(e) => onTextareaChange(question.id, e.target.value)}
                    />
                  </li>
                </ul>
              </td>
              <td>
                <select
                  value={question.type}
                  onChange={(e) => onTypeChange(question.id, e.target.value)}
                >
                  {Object.entries(qtype).map(([key, value]) => (
                    <option key={value} value={value}>
                      {key}
                    </option>
                  ))}
                </select>
              </td>
              <td><i className={`state tx ${question.isUsed === 'Y' ? '' : 'end'}`}> {question.isUsed === 'Y' ? '사용중' : '미사용'} </i></td>
              <td><a href="#" className="micon ico5" onClick={() => onEdit(question.id)}>수정</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

const ExamQuestionList = () => {
  const [filters, setFilters] = useState({
    subject: '',
    grade: '',
    type: '',
    searchCondition: 'questionNumber',
    searchKeyword: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);  // questions 상태 추가
  const { navigate } = useOn2024ComplexContext();

  const fetchQuestions = useCallback(async ({ queryKey }) => {
    const [_key, { filters, currentPage }] = queryKey;

    let query = supabase
      .from('exam_question_bank')
      .select('*', { count: 'exact' });

    // Apply filters
    if (filters.subject) query = query.eq('subject', filters.subject);
    if (filters.grade) query = query.eq('grade', filters.grade);
    if (filters.type) query = query.eq('qtype', filters.type);
    if (filters.searchKeyword) {
      query = query.or(`exam_number.ilike.%${filters.searchKeyword}%,exam_file_code.ilike.%${filters.searchKeyword}%`);
    }

    // Apply pagination
    const from = (currentPage - 1) * ITEMS_PER_PAGE;
    const to = from + ITEMS_PER_PAGE - 1;
    query = query.range(from, to);

    const { data, error, count } = await query;

    if (error) throw error;

    const mappedData = data.map(item => ({
      id: item.id,
      subject: item.subject,
      grade: item.grade,
      achievementCode: item.exam_number.match(/\[.*?\]/)?.[0] || '',
      questionNumber: item.exam_number,
      fileName: item.exam_file_code,
      achievementStandard: item.property,
      question: item.qtitle,
      answer: item.answer,
      type: item.qtype,
      isUsed: item.use_yn
    }));

    setQuestions(mappedData); // questions 상태 업데이트
    return {
      data: mappedData,
      count,
    };
  }, []);

  // react query 사용법
  // React Query를 사용하여 데이터를 가져온다.
  const { data, error, isFetching } = useQuery({
    queryKey: ['questions', { filters, currentPage }], // 쿼리의 고유 키로, questions과 { filters, currentPage } 객체를 사용하여 쿼리를 식별
    queryFn: fetchQuestions, // 데이터를 가져오는 함수로, fetchQuestions 함수를 사용
    keepPreviousData: true, // 페이지가 변경될 때 이전 데이터를 유지하여 사용자 경험을 향상
    staleTime: 5000, // 5000ms 동안 데이터를 신선하게 간주하여 다시 가져오지 않고 캐시된 데이터를 사용
    placeholderData: (previousData) => previousData, // 데이터를 로드하는 동안 이전 데이터를 잠시 표시할 수 있도록 설정
  });


  const handleEdit = useCallback((id) => {
    navigate(`/admin/exam_question_detail/${id}`);
  }, [navigate]);

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    setCurrentPage(1);
  }, []);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleCheckboxChange = useCallback((id) => {
    setSelectedQuestions(prev =>
      prev.includes(id) ? prev.filter(qId => qId !== id) : [...prev, id]
    );
  }, []);

  const handleSelectAll = useCallback((e) => {
    setSelectedQuestions(e.target.checked && data ? data.data.map(q => q.id) : []);
  }, [data]);

  const handleDelete = useCallback(() => {
    console.log('Deleting questions with IDs:', selectedQuestions);
  }, [selectedQuestions]);

  const handleAdd = useCallback(() => {
    console.log('Adding new question');
  }, []);

  const handleTypeChange = useCallback((id, newType) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.id === id ? { ...question, type: newType } : question
      )
    );
  }, []);

  const handleTextareaChange = useCallback((id, newText) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.id === id ? { ...question, answer: newText } : question
      )
    );
  }, []);

  const renderSearchSection = useMemo(() => (
    <form className="search_wrap" onSubmit={handleSearch}>
      <div className="inner">
        <div className="select_wrap">
          <label htmlFor="subject">과목</label>
          <select name="subject" value={filters.subject} onChange={handleFilterChange}>
            <option value="">선택</option>
            {Object.entries(subject).map(([key, value]) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
          </select>
        </div>
        <div className="select_wrap">
          <label htmlFor="grade">학년</label>
          <select name="grade" value={filters.grade} onChange={handleFilterChange}>
            <option value="">선택</option>
            {Array.from({ length: 6 }, (_, i) => i + 1).map(grade => (
              <option key={grade} value={grade}>{grade}</option>
            ))}
          </select>
        </div>
        <div className="select_wrap">
          <label htmlFor="type">유형</label>
          <select name="type" value={filters.type} onChange={handleFilterChange}>
            <option value="">선택</option>
            {Object.entries(qtype).map(([key, value]) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="inner">
        <div className="select_wrap">
          <label htmlFor="searchCondition">검색조건</label>
          <select name="searchCondition" value={filters.searchCondition} onChange={handleFilterChange}>
            <option value="questionNumber">문항번호</option>
            <option value="achievementCode">성취코드</option>
            <option value="fileName">파일명</option>
          </select>
        </div>
        <div className="search_bar">
          <input
            type="text"
            name="searchKeyword"
            value={filters.searchKeyword}
            onChange={handleFilterChange}
            placeholder="검색어를 입력하세요"
          />
          <button type="submit" className="btn btn-ani btn-secondary">검색</button>
        </div>
      </div>
    </form>
  ), [filters, handleFilterChange, handleSearch]);

  const renderPagination = useMemo(() => {
    if (!data || !data.count) return null;

    const totalPages = Math.ceil(data.count / ITEMS_PER_PAGE);
    const currentPageGroup = Math.floor((currentPage - 1) / 10);
    const startPage = currentPageGroup * 10 + 1;
    const endPage = Math.min(startPage + 9, totalPages);

    return (
      <div className="paginate">
        <a href="#" className="dir first" onClick={() => setCurrentPage(1)}>첫페이지로 이동</a>
        <a href="#" className="dir prev" onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}>이전페이지로 이동</a>
        <ul>
          {[...Array(endPage - startPage + 1)].map((_, index) => {
            const pageNumber = startPage + index;
            return (
              <li key={pageNumber}>
                <a
                  href="#"
                  className={currentPage === pageNumber ? 'active' : ''}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </a>
              </li>
            );
          })}
        </ul>
        <a href="#" className="dir next" onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}>다음페이지로 이동</a>
        <a href="#" className="dir last" onClick={() => setCurrentPage(totalPages)}>마지막페이지로 이동</a>
      </div>
    );
  }, [data, currentPage]);

  const questionTableProps = useMemo(() => ({
    questions,
    selectedQuestions,
    onSelectAll: handleSelectAll,
    onCheckboxChange: handleCheckboxChange,
    onEdit: handleEdit,
    onTextareaChange: handleTextareaChange,
    onTypeChange: handleTypeChange
  }), [questions, selectedQuestions, handleSelectAll, handleCheckboxChange, handleEdit, handleTextareaChange, handleTypeChange]);

  return (
    <section id="content" className="list">
      {renderSearchSection}
      <div className="title_box">
        <strong className="subtitle">
          <i className="hico hico1"></i>
          문항목록
        </strong>
        <div className="btn_group">
          <button onClick={handleDelete} className="btn btn-ani btn-delete">
            <i className="sicon ico3"></i>선택삭제
          </button>
          <button onClick={handleAdd} className="btn btn-ani btn-primary">
            <i className="sicon ico2"></i>등록하기
          </button>
        </div>
      </div>
      {isFetching && (
        <div className="loading-indicator" style={{ position: 'absolute', top: '10px', right: '10px' }}>
          데이터 업데이트 중...
        </div>
      )}
      {error ? (
        <div className="error-message">오류 발생: {error.message}</div>
      ) : (
        <QuestionTable {...questionTableProps} />
      )}
      {renderPagination}
    </section>
  );
};

export default ExamQuestionList;
