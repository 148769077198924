import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../common/AuthProvider';

const MenuItem = ({ item }) => {
  return (
    <li className="depth1-item">
      <Link className="active" to={item.link}>
        <i className={`nicon ${item.icon}`}></i>{item.name}
      </Link>
      {item.children && (
        <ul className="depth2">
          {item.children.map((child, index) => (
            <li key={index} className="depth2__item">
              <Link to={child.link}>{child.name}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export const menuItems = [
  {
    name: '학급관리',
    link: '/sub0101',
    icon: 'ico1',
    env: 'all',
    children: [
      { name: '학급정보', link: '/sub0101', env: 'all' },
      { name: '애진의 작업장', link: '/sub999', env: 'dev' },
    ]
  },
  {
    name: '출제내역',
    link: '/sub0203',
    icon: 'ico5',
    env: 'all',
    children: [
      { name: '출제내역', link: '/sub0203', env: 'all' },
      { name: '채점목록', link: '/sub020301', env: 'dev' },
      { name: '배포하기', link: '/sub020303', env: 'dev' },
      { name: '전송하기', link: '/sub020304', env: 'dev' },
      { name: '평가진행', link: '/sub020305', env: 'dev' },
      { name: '채점하기', link: '/sub020306', env: 'dev' },
    ]
  },
  {
    name: '문제은행',
    link: '/sub0201',
    icon: 'ico2',
    env: 'all',
    children: [
      { name: '생성하기', link: '/sub0201', env: 'all' },
      { name: '상세보기', link: '/sub020101', env: 'dev' },
      { name: '미리보기', link: '/sub020102', env: 'dev' },
    ]
  },
  {
    name: 'AI출제',
    link: '/sub0301',
    icon: 'ico3',
    env: 'all',
    children: [
      { name: '생성하기', link: '/sub0301', env: 'all' },
      { name: 'AI 데이터활용', link: '/sub0302', env: 'all' },
    ]
  },
  {
    name: '통계',
    link: '/sub0401',
    icon: 'ico4',
    env: 'all',
    children: [
      { name: '학급통계', link: '/sub0401', env: 'all' },
      { name: '학생별통계', link: '/sub040101', env: 'all' }
    ]
  },
  {
    name: '문항컴포넌트(테스트용)',
    link: '/drag-and-drop',
    icon: 'ico5',
    env: 'dev',
    children: [
      { name: '짝연결하기(temp)', link: '/connect-items', env: 'dev' },
      { name: '짝연결하기(2)', link: '/connect-item', env: 'dev' },
      { name: '끌어놓기', link: '/drag-and-drop', env: 'dev' },
      { name: '핫스폿', link: '/hotspot', env: 'dev' },
    ]
  },
  // {
  //   name: '관리자통계',
  //   link: '/sub0501',
  //   icon: 'ico4',
  // }
];

const isProductionURL = () => {
  return window.location.hostname.includes('dev.withrun.co.kr');
};

export const getMenuItems = (userRole) => {

    switch (userRole) {
      case 'teacher':
        return menuItems;
      case 'admin':
        return adminMenuItems;
      default:
        return [];
    }
}


const Menu = () => {
  const env = isProductionURL() ? 'op' : 'dev';
  const { userRole } = useAuth();


  const filteredMenuItems = getMenuItems(userRole).filter(item =>
    item.env === 'all' || item.env === env
  ).map(item => ({
    ...item,
    children: item.children?.filter(child =>
      child.env === 'all' || child.env === env
    )
  }));

  return (
    <div className="gnb">
      <ul className="gnb__depth1">
        {filteredMenuItems.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>
    </div>
  );
};

export const adminMenuItems = [
  {
    name: '문항관리',
    link: '/admin/exam_question_list',
    icon: 'ico1',
    env: 'all',
    children: [
      { name: '문항 내역', link: '/admin/exam_question_list', env: 'all' },
    ]
  }
];

export const AdminMenu = () => {
  const env = isProductionURL() ? 'op' : 'dev';

  const filteredMenuItems = adminMenuItems.filter(item =>
    item.env === 'all' || item.env === env
  ).map(item => ({
    ...item,
    children: item.children?.filter(child =>
      child.env === 'all' || child.env === env
    )
  }));

  return (
    <div className="gnb">
      <ul className="gnb__depth1">
        {filteredMenuItems.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>
    </div>
  );
};
export default Menu;
