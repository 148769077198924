import React, { useState, useEffect, useRef } from 'react';
import bodymovin from 'lottie-web';
import { supabase } from './supabaseClient';
import { useOn2024ComplexContext } from './common/Context';
import Footer from './layout/Footer';
import QrScanner from 'react-qr-scanner';
import { subject } from './enum/enum'

const LoginPage = () => {
  const { setActiveModal, activeModal } = useOn2024ComplexContext();
  const [examInfo, setExamInfo] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);
  const openModal = (modalId) => {
    console.log(modalId);
    setActiveModal(modalId);
    document.body.classList.add('open');
  };
  const closeModal = () => {
    setActiveModal(null);
    document.body.classList.remove('open');
  };

  const openLoadingModal = (examData) => {
    setExamInfo(examData);
    openModal('popup_loading');
  };

  return (
    <>
      <Main openModal={openModal} />
      <TeacherLoginModal isOpen={activeModal === 'popup_teacher'} onClose={closeModal} />
      <StudentLoginModal
        isOpen={activeModal === 'popup_student'}
        onClose={closeModal}
        openQRModal={() => openModal('popup_qrscanner')}
        setStudentInfo={setStudentInfo}
      />
      <QRScannerModal
        isOpen={activeModal === 'popup_qrscanner'}
        onClose={closeModal}
        openLoadingModal={openLoadingModal}
        studentInfo={studentInfo}
      />
      <LoadingModal isOpen={activeModal === 'popup_loading'} examInfo={examInfo} />
    </>
  );
};

export default LoginPage;

const Main = ({ openModal }) => {
  useEffect(() => {
    bodymovin.loadAnimation({
      container: document.getElementById('lottie1'),
      path: '/images/main/mobj01.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
    bodymovin.loadAnimation({
      container: document.getElementById('lottie2'),
      path: '/images/main/mobj02.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);
  return (
    <div>
      <main>
        <div className="wrap">
          <div className="mcon">
            <h1 className="title">
              <span>출발선이 평등한 행복한 충남교육</span>
              <span><em>컴퓨터 기반</em> 평가 문제은행</span>
              <img src="../images/main/logo_main.svg" alt="온평가 로고" />
            </h1>
            <ul className="btn_group">
              <li>
                <a className="btn login teacher" onClick={() => openModal('popup_teacher')}>
                  <span>
                    <span className="first_word" data-hover="선생님">선생님</span>
                    <span className="second_word" data-hover="로그인">로그인</span>
                  </span>
                </a>
              </li>
              <li>
                <a className="btn login student" onClick={() => openModal('popup_student')}>
                  <span>
                    <span className="first_word" data-hover="학생">학생</span>
                    <span className="second_word" data-hover="로그인">로그인</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <ObjectGroup />
        </div>
        <BackgroundGroup />
      </main>
      <Footer />
    </div>
  );
};
const ObjectGroup = () => (
  <div className="obj_group">
    <span id="lottie2" className="lottie mobj02"></span>
    <span id="lottie1" className="lottie mobj01"></span>
    <span className="obj num1"></span>
    <span className="obj num2"></span>
    <span className="obj num3"></span>
    <span className="obj num5"></span>
    <span className="obj num6">
      <i className="shape num1"></i>
      <i className="shape num2"></i>
      <i className="shape num3"></i>
    </span>
    <span className="obj num7"></span>
    <span className="obj num8"></span>
    <span className="obj num9"></span>
    <span className="obj num10"></span>
    <span className="obj objBg"></span>
  </div>
);
const BackgroundGroup = () => (
  <div className="bg_group">
    <span className="purple num1"></span>
    <span className="purple num2"></span>
    <span className="wave num1"></span>
    <span className="wave num2"></span>
  </div>
);

const TeacherLoginModal = ({ isOpen, onClose }) => {
  const { login, navigate, location, loginTeacherId, setLoginTeacherId, loginTeacherPassword, setLoginTeacherPassword, setTeacherInfo } = useOn2024ComplexContext();
  const [error, setError] = useState(null);
  const idInputRef = useRef(null);

  useEffect(() => {
    if (isOpen && idInputRef.current) {
      idInputRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleTeacherLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const { data, error } = await supabase
        .from('teacher')
        .select(`*`)
        .eq('username', loginTeacherId)
        .single();
      if (error) throw error;
      const { data: schoolData, error: schoolError } = await supabase
        .from('school')
        .select(`*`)
        .eq('school_id', data?.school_id)
        .single();
      if (schoolError) throw schoolError;
      if (data.teacher_id === undefined) {
        setError('아이디 또는 비밀번호가 일치하지 않습니다.');
        return;
      }
      if (data.teacher_id !== undefined) {
        const { error } = await login(data.email, loginTeacherPassword);
        if (error) throw error;
        setTeacherInfo({ ...data, schoolData });
        const { from } = location.state || { from: { pathname: "/sub0101" } };
        navigate(from);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('로그인 중 오류가 발생했습니다.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTeacherLogin(e);
    }
  };

  return (
    <div className="modal modal-popup active main_modal" id="popup_teacher">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content">
        <button type="button" className="btn btn-ani btn-secondary modal-close" onClick={onClose}>닫기</button>
        <div className="popup-wrap">
          <div className="con-head">
            <h3>선생님 로그인</h3>
          </div>
          <div className="con-wrap">
            <ul className="login_input">
              <li>
                <input
                  ref={idInputRef}
                  type="text"
                  placeholder="아이디를 입력해주세요."
                  value={loginTeacherId}
                  onChange={(e) => setLoginTeacherId(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </li>
              <li>
                <input
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  value={loginTeacherPassword}
                  onChange={(e) => setLoginTeacherPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </li>
            </ul>
            <div className="login_alert">
              {error && <i className="sicon ico7"></i>}
              {error && error}
            </div>
            <div className="btn_group">
              <a className="btn btn-ani btn-primary" onClick={handleTeacherLogin}>로그인</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StudentLoginModal = ({ isOpen, onClose, openQRModal, setStudentInfo }) => {
  const [classCode, setClassCode] = useState('');
  const [classNumber, setClassNumber] = useState('');
  const [error, setError] = useState(null);
  const classCodeInputRef = useRef(null);
  const [teacherId, setTeacherId] = useState('');


  // const useBeforeUnload = (callback) => {
  //   useEffect(() => {
  //     const handleBeforeUnload = (event) => {
  //       callback();
  //       // 이벤트를 취소하는 코드, 사용자에게 메시지 보여주기 (특정 브라우저에서 동작)
  //       event.preventDefault();
  //       event.returnValue = '';
  //     };

  //     window.addEventListener('beforeunload', handleBeforeUnload);

  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  //   }, [callback]);
  // };

  // 브라우저 종료 또는 새로고침 감지하는 함수(초기 컴포넌트 마운트 되었을 때는 다행히 실행되지 않음)
  // useBeforeUnload(() => {
  //   console.log("브라우저 종료 또는 새로고침 감지");
  //   console.log(`classCode: ${classCode}`);
  //   console.log(`classNumber: ${classNumber}`);
  // });

  useEffect(() => {
    if (isOpen && classCodeInputRef.current) {
      classCodeInputRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleStudentLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const { data: isSetting, error: settingError } = await supabase
        .from('exam_question_setting')
        .select('*')
        .eq('delete_yn', 'N')
        .eq('class_code', classCode) // 이번년도 year 추가
        .single();

      if (settingError || !isSetting) {
        setError(`${classCode} 학급코드로 생성된 시험이 존재하지 않습니다.`);
        return false;
      }

      const { data: isStudent, error } = await supabase
        .from('student')
        .select('*')
        .eq('student_number', classNumber)
        .eq('teacher_id', isSetting.teacher_id)
        .eq('delete_yn', 'N')
        .single();

      if (error || !isStudent) {
        console.log(`error: ${JSON.stringify(error)}`);
        console.log(`data: ${JSON.stringify(isStudent)}`);
        setError('학급번호가 올바르지 않거나 학생 정보가 존재하지 않습니다.');
        return;
      }

      setStudentInfo({ classCode, classNumber });  // 학생 정보 설정
      openQRModal();  // QR 스캐너 모달 열기

    } catch (error) {
      console.error('Login error:', error);
      setError('학급코드로 생성된 시험을 조회하는 중 오류가 발생했습니다.');
    } finally {

    }

    try {


    } catch (error) {
      console.error('Login error:', error);
      setError('로그인 중 오류가 발생했습니다.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleStudentLogin(e);
    }
  };

  return (
    <div className="modal modal-popup active main_modal" id="popup_student">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content">
        <button type="button" className="btn btn-ani btn-secondary modal-close" onClick={onClose}>닫기</button>
        <div className="popup-wrap">
          <div className="con-head">
            <h3>학생 로그인</h3>
          </div>
          <div className="con-wrap">
            <ul className="login_input">
              <li>
                <input
                  ref={classCodeInputRef}
                  type="text"
                  placeholder="학급코드를 입력하세요"
                  value={classCode}
                  onChange={(e) => setClassCode(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </li>
              <li>
                <input
                  type="text"
                  placeholder="학급번호를 입력하세요."
                  value={classNumber}
                  onChange={(e) => setClassNumber(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </li>
            </ul>
            <div className="login_alert">
              {error && <i className="sicon ico7"></i>}
              {error && error}
            </div>
            <div className="btn_group">
              <a className="btn btn-ani btn-primary" onClick={handleStudentLogin}>시작하기</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const QRScannerModal = ({ isOpen, onClose, openLoadingModal, studentInfo }) => {
  const [cameraError, setCameraError] = useState(false);
  const [key, setKey] = useState(0); // QrScanner 컴포넌트를 강제로 리렌더링하기 위한 key
  const [isProcessing, setIsProcessing] = useState(false); // 중복 스캔 방지 플래그 추가
  const initializeCamera = () => {
    if (isOpen) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          setCameraError(false);
          setKey(prevKey => prevKey + 1); // QrScanner 컴포넌트 리렌더링
        })
        .catch(() => {
          setCameraError(true);
          alert('카메라 접근을 허용해야 시험에 참여할 수 있습니다.');
        });
    }
  };

  console.log(studentInfo?.classCode, studentInfo?.classNumber)
  useEffect(() => {
    initializeCamera();
  }, [isOpen]);
  const handleScan = async (data) => {
    if (data && !isProcessing) {  // isProcessing 플래그를 확인하여 중복 스캔 방지
      setIsProcessing(true);  // 처리 시작
      let urlString = '';
      if (typeof data === 'object' && data.text) {
        urlString = data.text;
      } else if (typeof data === 'string') {
        urlString = data;
      }
      try {
        const url = new URL(urlString);

        console.log(url.searchParams);
        const year = url.searchParams.get('year');
        const teacher_id = url.searchParams.get('teacher_id');
        const sub = url.searchParams.get('subject');
        const episode_number = url.searchParams.get('episode_number');
        const class_code = url.searchParams.get('class_code');
        // 디코딩 및 따옴표 제거
        const decodeAndTrim = (value) => decodeURIComponent(value || '').replace(/^['"]|['"]$/g, '');
        const decodedYear = decodeAndTrim(year);
        const decodedTeacherId = decodeAndTrim(teacher_id);
        const decodedSubject = subject[decodeAndTrim(sub)];
        const decodedEpisodeNumber = decodeAndTrim(episode_number);
        const decodedClassCode = decodeAndTrim(class_code);
        // 숫자형 데이터 변환 및 유효성 검사
        const parseIntSafe = (value) => {
          const parsed = parseInt(value);
          return isNaN(parsed) ? null : parsed;
        };
        const academic_year = parseIntSafe(decodedYear);
        const teacherId = parseIntSafe(decodedTeacherId);
        const episodeNumber = parseIntSafe(decodedEpisodeNumber);
        // 모든 필수 파라미터가 유효한지 확인
        if (academic_year === null || teacherId === null || episodeNumber === null || !decodedSubject || !decodedClassCode) {
          console.error('Invalid parameters:', JSON.stringify({ academic_year, teacherId, subject: decodedSubject, episodeNumber, class_code: decodedClassCode }, null, 2));
          alert('QR 코드 정보가 올바르지 않습니다. 다시 확인해 주세요.');
          return;
        }

        if(studentInfo.classCode !== decodedClassCode) {
          alert('학급코드 정보가 올바르지 않습니다. 다시 입력해주세요.');
          return onClose();
        }

        console.log(academic_year, teacherId, episodeNumber, decodedSubject)
        // Supabase 쿼리 실행
        try {
          const { data: settingData, error: settingError } = await supabase
            .from('exam_question_setting')
            .select('*')
            .eq('class_code', decodedClassCode)
            .eq('year', academic_year)
            .eq('teacher_id', teacherId)
            .eq('subject', decodedSubject)
            .eq('episode_number', episodeNumber)
            .eq('delete_yn', 'N')
            console.log("settingData", settingData?.[0]?.id)

          const { data: participationData, error: participationError } = await supabase
            .from('question_creation_participation')
            .select('student_id')
            .eq('exam_question_setting_id', settingData?.[0]?.id);
          console.log("participationData", participationData?.[0]?.student_id)

          const { data: studentData, error: studentError } = await supabase
            .from('student')
            .select('*')
            .eq('teacher_id', teacherId)
            .eq('student_number', studentInfo.classNumber)
            .eq('delete_yn', 'N')
            .in('student_id', participationData?.map((student) => student.student_id));

          if(!studentData?.length) {
            alert('시험에 참여하는 학생이 아닙니다. 다시 로그인 해주세요.');
            onClose();
            return null;
          }
          console.log("studentData", studentData?.[0]?.student_name)

          if (studentData.length) {
            try {
              // 먼저 해당 학생이 이미 접속 중인지 확인
              const { data: existingAttendance, error: attendanceError } = await supabase
                .from('notification_student_question_attendance')
                .select('*')
                .eq('academic_year', academic_year)
                .eq('teacher_id', teacherId)
                .eq('subject_code', subject[decodedSubject])
                .eq('episode_number', episodeNumber)
                .eq('class_code', decodedClassCode)
                .eq('student_id', studentData?.[0]?.student_id)
                .eq('student_type', "1") // 이미 접속한 학생 여부 확인
                .single();
              if (attendanceError && attendanceError.code !== 'PGRST116') {
                // PGRST116은 조회된 행이 없는 경우 발생하므로 오류로 간주하지 않음
                throw attendanceError;
              }
              if (existingAttendance) {
                // 이미 접속 중인 학생일 경우 알림 표시
                alert('이미 접속 중인 학생입니다. 선생님에게 문의해 주세요.');
                return;
              }
              // notification_student_question_attendance 테이블에 데이터 삽입
              const { data, error } = await supabase
                .from('notification_student_question_attendance')
                .upsert({
                  academic_year: academic_year,
                  teacher_id: teacherId,
                  subject_code: decodedSubject,
                  episode_number: episodeNumber,
                  class_code: decodedClassCode,
                  student_id: studentData?.[0]?.student_id,
                  student_status: "1",
                  student_type: "1"
                }, { onConflict: ['academic_year', 'teacher_id', 'subject_code', 'episode_number', 'class_code', 'student_id'] });
              if (error) throw error;
              console.log('학생 참석 정보가 성공적으로 등록되었습니다.');
              onClose(); // QR 스캐너 모달 닫기
              // openLoadingModal(questionCreationData[0]); // 로딩 모달 열기 (시험 정보 전달)
              openLoadingModal({
                grade: settingData[0].grade,
                term: settingData[0].term,
                units: [settingData[0].unit1, settingData[0].unit2, settingData[0].unit3]
                  .filter((unit) => unit !== null && unit !== 0).join(','),
                subject_code: settingData[0]?.subject,
                class_code: decodedClassCode,
                exam_question_setting_id: settingData?.[0]?.id,
                student_id: studentData?.[0]?.student_id,
                teacher_id: studentData?.[0]?.teacher_id
              }); // 로딩 모달 열기 (시험 정보 전달)
            } catch (error) {
              console.error('데이터 처리 중 오류 발생:', JSON.stringify(error));
              if (error.code === '23505') { // error.code = 23505, 중복 데이터 삽입 오류 코드
                console.log('이미 등록된 학생 참석 정보입니다. 중복 등록을 시도했습니다.');
                onClose(); // QR 스캐너 모달 닫기

                openLoadingModal({
                  grade: settingData[0].grade,
                  term: settingData[0].term,
                  units: [settingData[0].unit1, settingData[0].unit2, settingData[0].unit3].filter((unit) => unit !== null && unit !== 0).join(','),
                  subject_code: settingData[0]?.subject,
                  class_code: decodedClassCode,
                  exam_question_setting_id: settingData?.[0]?.id,
                  student_id: studentData?.[0]?.student_id,
                  teacher_id: studentData?.[0]?.teacher_id
                });
                // openLoadingModal(questionCreationData[0]); // 로딩 모달 열기 (시험 정보 전달)
              } else {
                alert('학생 참석 정보 등록 중 오류가 발생했습니다. 관리자에게 문의해주세요.');
              }
            }
          } else {
            alert('시험 정보가 존재하지 않습니다. 다시 확인해 주세요.');
          }
        } catch (error) {
          console.error('Supabase query error:', error);
          alert('시험 정보 조회 중 오류가 발생했습니다. 관리자에게 문의해주세요.');
        }
      } catch (error) {
        console.error('URL parsing error:', error);
        alert('QR 코드 정보가 올바르지 않습니다. 다시 확인해 주세요.');
      } finally {
        // QR 스캔 작업이 완전히 종료되면 false 처리
        setIsProcessing(false);
      }
    }
  };
  const handleError = (err) => {
    console.error('QR 코드 스캔 에러:', err);
  };
  if (!isOpen) return null;

  return (
    <div className="modal modal-popup active main_modal" id="popup_qrscanner">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content">
        <button type="button" className="btn btn-ani btn-secondary modal-close" onClick={onClose}>닫기</button>
        <div className="popup-wrap">
          <div className="con-head">
            <h3>QR 코드 스캔</h3>
          </div>
          <div className="con-wrap">
            {cameraError ? (
              <div>
                <p>카메라 접근을 허용해야 시험에 참여할 수 있습니다.</p>
                <button onClick={initializeCamera}>다시 시도</button>
              </div>
            ) : (
              <QrScanner
                key={key} // 강제 리렌더링을 위한 key prop
                delay={300}
                style={{ height: 240, width: 320 }}
                onError={handleError}
                onScan={handleScan}
                constraints={{
                  video: { facingMode: { exact: "environment" } }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingModal = ({ isOpen, examInfo }) => {
  const {  navigate, location } = useOn2024ComplexContext();

  useEffect(() => {
    if (!isOpen || !examInfo) return;

    // Supabase 리스너 설정
    const channel = supabase
      .channel(`exam_start_${examInfo.class_code}`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notification_exam_start',
          filter: `class_code=eq.${examInfo.class_code}`
        },
        (payload) => {
          console.log('페이지 이동을 시도합니다.');
          console.log('페이지로드 수신: ' + JSON.stringify(payload));
          console.log('현재 위치: ' + JSON.stringify(location));
          console.log(examInfo)

          navigate('/examLayout', { state: { exam_question_setting_id: examInfo.exam_question_setting_id, student_id: examInfo.student_id, teacher_id: examInfo.teacher_id } });


          console.log('네비게이션 시도 완료' );
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [isOpen, examInfo, navigate, location]);

  if (!isOpen || !examInfo) return null;

  return (
    <div className="modal modal-popup active main_modal" id="popup_loading">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content">
        <div className="popup-wrap">
          <span className="flag"><span>{examInfo.units}단원</span></span>
          <div className="con-head">
            <h3><i className="micon ico2"></i>{examInfo.grade}학년 {examInfo.term}학기</h3>
          </div>
          <div className="con-wrap">
            <div className="loading">
              <div className="droplet_spinner">
                <div className="droplet"></div>
                <div className="droplet"></div>
                <div className="droplet"></div>
              </div>
              <span><em>모두 접속</em>할 때까지 기다려주세요.</span>
            </div>
            <ul className="test_info">
              <li className="subject">
                <i></i>
                <span>{Object.keys(subject)[Number(examInfo.subject_code) - 1]}</span>
              </li>
              <li className="time">
                <i></i>
                <span>{examInfo.duration || '40'}분</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};