// MainLayout.jsx
import React, { useState } from 'react';
import SubHeader from './SubHeader';
import Footer from './Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sub0101 from '../Sub0101';
import Sub0203 from '../Sub0203';
import Sub0201 from '../Sub0201';
import Sub0301 from '../Sub0301';
import Sub0401 from '../Sub0401';
import Sub040101 from '../Sub040101';
import { useOn2024ComplexContext } from '../../common/Context';
import PublisherModal from '../detailContents/sub040101/PublisherModal';
import Sub020301 from '../Sub020301';
import Sub020306 from '../Sub020306';
import Sub020303 from '../Sub020303';
import Sub020304 from '../Sub020304';
import Sub020305 from '../Sub020305';
import Sub020101 from '../Sub020101';
import Sub020102 from '../Sub020102';
import Sub999 from '../Sub999';
import ConnectItems from '../../components/type/ConnectItems';
import DragAndDrop from '../../components/type/DragAndDrop';
import Hotspot from '../../components/type/Hotspot';
import Connectitem from '../../components/type/ConntectItem';
import Sub0302 from '../Sub0302';

const MainLayout = () => {
  const location = useLocation();
  const { showPublisherModal, setShowPublisherModal, setSelectedPublisher } = useOn2024ComplexContext();

  return (
    <div id="container" className="sub">
      <SubHeader />
      <section id="content" className= {location.pathname === '/sub0301' ? "create_wrap" : location.pathname === '/sub0401' ? 'manager_wrap state' : location.pathname === '/sub040101' ? 'manager_wrap state student' : location.pathname === '/sub020306' ? 'grading_wrap' : location.pathname === '/sub020304' ? 'send_wrap' : location.pathname === '/sub020305' ? 'send_wrap evaluating' : location.pathname === '/sub0202' ? 'prev_wrap' : location.pathname === '/sub0101' ? 'class_wrap' : location.pathname === '/sub0201' ? 'create_wrap' : location.pathname === '/sub0203' ? 'list_wrap exam_list' :  location.pathname === '/sub020102' ? 'prev_wrap' :  location.pathname === '/sub020301' ? 'list_wrap' : ''}>
        <Routes>
          <Route path="/sub0101" element={<Sub0101 />} />
          <Route path='/sub999' element={<Sub999 />} />
          <Route path="/sub0201" element={<Sub0201 />} />
          <Route path="/sub020101" element={<Sub020101 />} />
          <Route path="/sub020102" element={<Sub020102 />} />
          <Route path="/sub0203" element={<Sub0203 />} />
          <Route path="/sub020301" element={<Sub020301 />} />
          <Route path="/sub020303" element={<Sub020303 />} />
          <Route path="/sub020304" element={<Sub020304 />} />
          <Route path="/sub020305" element={<Sub020305 />} />
          <Route path="/sub020306" element={<Sub020306 />} />
          <Route path="/sub0301" element={<Sub0301 />} />
          <Route path="/sub0302" element={<Sub0302 />} />
          <Route path="/sub0401" element={<Sub0401 />} />
          <Route path="/sub040101" element={<Sub040101 />} />
          <Route path="/connect-items" element={<ConnectItems />} />
          <Route path="/drag-and-drop" element={<DragAndDrop />} />
          <Route path="/hotspot" element={<Hotspot />} />
          <Route path="/connect-item" element={<Connectitem />} />


        </Routes>
      </section>
      <PublisherModal
        isOpen={showPublisherModal}
        onClose={() => setShowPublisherModal(false)}
        onSelect={(publisher) => {
          setSelectedPublisher(publisher);
          setShowPublisherModal(false);
        }}
      />
      <Footer />
    </div>
  );
};

export default MainLayout;