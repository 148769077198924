import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AlertDialog } from "./detailContents/sub020305/AlertDialog";
import { supabase } from '../supabaseClient';
import { useOn2024ComplexContext } from '../common/Context';
import { subjectName } from '../enum/enum';

const InfoCard = ({ targetClass, subject, submissionStatus, remainingTime }) => (
  <ul className="info_card">
    <li className="card__item">
      <span className="item num1"><b>평가대상</b></span>
      <span className="item num2"><b>{targetClass}</b></span>
    </li>
    <li className="card__item">
      <span className="item num1"><b>평가과목</b></span>
      <span className="item num2"><b>{subject}</b></span>
    </li>
    <li className="card__item">
      <span className="item num1"><b>제출현황</b></span>
      <span className="item num2"><b>{submissionStatus.submitted}</b> / {submissionStatus.total}명</span>
    </li>
    <li className="card__item">
      <span className="item num1"><b>남은시간</b></span>
      <span className="item num2"><b>{remainingTime}</b></span>
    </li>
  </ul>
);

const StudentStatus = ({ students, isAlertOpen, changeGradingPage, setIsAlertOpen, isPaused, confirmPauseResume }) => {
  console.log(students)
  return (
    <div className="connect_stat">
      {isPaused && (
        <div className="pause_pop_wrap active">
          <div className="pause_pop">
            <div className="bubble under">
              평가가 <em>일시정지</em> 되었습니다.
            </div>
            <p>평가를 진행하려면 <br /> 아래 <img src="/images/common/playbtn_img.png" alt="재생 버튼" />를 클릭하세요.</p>
            <img src="/images/common/pause_img.png" alt="일시정지 이미지" />
          </div>
        </div>
      )}
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        title={isPaused ? '평가 재개' : '평가 일시 정지'}
        isPaused={isPaused}
        onConfirm={confirmPauseResume}
      />
      <div className="connect_item_wrap">
        {students.map(student => (
          <a key={student.id} className={`connect_item ${student.status}`} onClick={(e) => changeGradingPage(e, student.id, student.status)} style={{ cursor: student.status === 'fin' && 'pointer' }}>
            <i></i><span>{student.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

const ProgressChart = ({ progress }) => (
  <div className="graph_wrap">
    <strong className="chart_tt"><span><i className="gicon ico7"></i></span>진행률</strong>
    <div className="infoChart_wrap">
      <span className="ingChart" style={{ width: `${progress}%` }}></span>
    </div>
    <span className="ingChart_tt">{progress}%</span>
  </div>
);

const Sub020305 = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState('40분 00초');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { teacherInfo, navigate, location } = useOn2024ComplexContext();
  const { exam_question_setting_id } = location.state || {};
  const [academicYear, setAcademicYear] = useState();
  const [classCode, setClassCode] = useState();
  const [grade, setGrade] = useState();
  const [classNum, setClassNum] = useState();
  const [subjectKor, setSubjectKor] = useState();
  const [submitted, setSubmitted] = useState(0);
  const [connectedStudentCount, setConnectedStudentCount] = useState();
  const [units, setUnits] = useState([]);
  const [examStartDateTime, setExamStartDateTime] = useState(null);
  const [allSubmitted, setAllSubmitted] = useState(false);

  const queryClient = useQueryClient();

  const convertToKoreanTime = (dateTime) => {
    if (!dateTime) return null;
    const date = new Date(dateTime);
    return new Date(date.getTime() + 9 * 60 * 60 * 1000);
  };

  const calculateRemainingTime = () => {
    if (!examStartDateTime) return 40 * 60; // 40 minutes in seconds

    const now = new Date();
    const startTime = convertToKoreanTime(examStartDateTime);
    const elapsedSeconds = Math.floor((now - startTime) / 1000);
    const totalExamSeconds = 40 * 60; // 40 minutes in seconds

    return Math.max(totalExamSeconds - elapsedSeconds, 0);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}분 ${String(remainingSeconds).padStart(2, '0')}초`;
  };

  const fetchExamData = async (exam_question_setting_id) => {
    try {
      const { data: examSettingData, error: examSettingError } = await supabase
        .from('exam_question_setting')
        .select('*')
        .eq('delete_yn', 'N')
        .eq('id', exam_question_setting_id);

      if (examSettingError) throw examSettingError;

      const examSetting = examSettingData[0];
      setAcademicYear(examSetting.year);
      setClassCode(examSetting.class_code);
      setGrade(examSetting.grade);
      setClassNum(examSetting.class);
      setSubjectKor(subjectName[examSetting.subject]);

      const unitsArray = [];
      if (examSetting.unit1) unitsArray.push(examSetting.unit1);
      if (examSetting.unit2) unitsArray.push(examSetting.unit2);
      if (examSetting.unit3) unitsArray.push(examSetting.unit3);

      setUnits(unitsArray);

      // Fetch exam start time
      const { data: examStartData, error: examStartError } = await supabase
        .from('notification_exam_start')
        .select('exam_start_datetime')
        .eq('class_code', examSetting.class_code)
        .eq('academic_year', examSetting.year)
        .eq('teacher_id', teacherInfo.teacher_id)
        .single();

      if (examStartError) throw examStartError;

      if (examStartData) {
        setExamStartDateTime(examStartData.exam_start_datetime);
      }

      return examSettingData[0];
    } catch (error) {
      console.error('Error fetching exam data:', error);
      throw error;
    }
  };

  const fetchStudentData = async () => {
    try {
      const { data: attendanceData, error: attendanceError } = await supabase
        .from('notification_student_question_attendance')
        .select('*')
        .eq('academic_year', academicYear)
        .eq('teacher_id', teacherInfo.teacher_id)
        .eq('class_code', classCode)
        .order('student_id', { ascending: true });

      if (attendanceError) throw attendanceError;

      const { data: studentData, error: studentError } = await supabase
        .from('student')
        .select('*')
        .eq('delete_yn', 'N')
        .eq('teacher_id', teacherInfo.teacher_id)
        .order('student_id', { ascending: true });

      if (studentError) throw studentError;

      const formattedData = attendanceData.map((attend) => {
        const student = studentData.find(s =>
          s.year === String(attend.academic_year) &&
          s.teacher_id === attend.teacher_id &&
          s.student_id === attend.student_id
        );

        return {
          id: attend.student_id,
          name: student ? student.student_name : 'Unknown',
          status: (() => {
            let returnClass = '';
            if (attend.student_status === '3') {
              returnClass = 'no'; // 10분 이상 응답 없는 상태
            } else if (attend.student_status === '4') {
              returnClass = 'fin'; // 제출 완료
            } else if (attend.student_type === '1') {
              returnClass = 'on'; // 접속 중 상태
            } else if (attend.student_type === '2' || attend.student_type === '3') {
              returnClass = 'off'; // 결시 상태(2: 중간 결시, 3: 결시)
            }

            return returnClass;
          })()
        };
      });

      const connectedStudents = attendanceData.filter(student => student.student_type === '1').length;
      setConnectedStudentCount(connectedStudents);

      const initialSubmittedCount = formattedData.filter(student => student.status === 'fin').length;
      setSubmitted(initialSubmittedCount);

      const progressNumber = Math.round((initialSubmittedCount / connectedStudents) * 100);
      setProgress(progressNumber);

      checkExamFinish(progressNumber);

      return formattedData;
    } catch (error) {
      console.error('Error fetching student data:', error);
      throw error;
    }
  };

  const { data: examData, isLoading: examLoading, error: examError } = useQuery({
    queryKey: ['examData', exam_question_setting_id],
    queryFn: () => fetchExamData(exam_question_setting_id),
  });

  const { data: studentsData, isLoading: studentsLoading, error: studentsError } = useQuery({
    queryKey: ['studentsData', academicYear, teacherInfo.teacher_id, classCode],
    queryFn: fetchStudentData,
    enabled: !!academicYear && !!classCode,
  });

  const updateExamPauseStatus = useMutation({
    mutationFn: async (isPaused) => {
      if(!classCode || !academicYear) return null;
      const { data, error } = await supabase
        .from('notification_exam_start')
        .update({ is_pause: isPaused })
        .eq('teacher_id', teacherInfo.teacher_id)
        .eq('class_code', classCode)
        .eq('academic_year', academicYear)
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['examData', exam_question_setting_id]);
    },
  });

  useEffect(() => {
    if (isPaused !== undefined) {
      updateExamPauseStatus.mutate(isPaused);
    }
  }, [isPaused]);

  const updateProgress = (newSubmittedCount) => {
    if (connectedStudentCount > 0) {
      setProgress(Math.round((newSubmittedCount / connectedStudentCount) * 100));
    }
  };


  const updateGradingStatus = useMutation({
    mutationFn: async () => {
      const { data, error } = await supabase
        .from('exam_question_setting')
        .update({ distribution_status: 3 })
        .eq('id', exam_question_setting_id)
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['examData', exam_question_setting_id]);
    },
  });

  const checkAllSubmissionsComplete = (currentSubmitted) => {
    if (currentSubmitted >= connectedStudentCount) {
      setAllSubmitted(true);
    }
  }

  const checkExamFinish = (totalProgress) => {
    if (totalProgress === 100) {
      setTimeout(() => {
        alert('모든 학생이 평가를 제출하였습니다.');
        updateGradingStatus.mutate();
        navigate('/sub0203');
      }, 1000);
    }
  }

  useEffect(() => {
    if (!academicYear || !teacherInfo.teacher_id || !classCode) return;

    const channel = supabase.channel('custom-all-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notification_student_question_attendance',
        },
        (payload) => {
          const newStatus = payload.new?.student_status;

          if (newStatus === '4') {
            setSubmitted(prev => {
              const newSubmittedCount = prev + 1;
              updateProgress(newSubmittedCount);
              checkAllSubmissionsComplete(newSubmittedCount);
              return newSubmittedCount;
            });
          } else if (newStatus === '3') {
            console.log('학생이 제출하지 않았습니다.');
          }

          queryClient.invalidateQueries(['studentsData', academicYear, teacherInfo.teacher_id, classCode]);
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notification_exam_start',
          filter: `class_code=eq.${classCode} AND academic_year=eq.${academicYear} AND teacher_id=eq.${teacherInfo.teacher_id}`,
        },
        (payload) => {
          const updatedData = payload.new;
          setIsPaused(updatedData.is_pause);
          if (updatedData.exam_start_datetime) {
            setExamStartDateTime(updatedData.exam_start_datetime);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [academicYear, teacherInfo.teacher_id, classCode, queryClient, connectedStudentCount]);

  const handlePauseResume = () => {
    setIsAlertOpen(true);
  };

  const confirmPauseResume = () => {
    setIsPaused(!isPaused);
    setIsAlertOpen(false);
  };

  const changeGradingPage = async (e, id, state) => {
    e.preventDefault();
    if(state !== 'fin') return null;

    const { error } = await supabase
      .from('question_creation_participation')
      .update({ completed_status: 1 })
      .eq('exam_question_setting_id', exam_question_setting_id)
      .eq('student_id', id);

    if(error) {
      console.error(error)
    }

    navigate('/sub020306', {state: {
      exam_question_setting_id,
      student_id: id
    }})
  }

  if (examLoading || studentsLoading) return <div>Loading...</div>;
  if (examError) return <div>Error loading exam data: {examError.message}</div>;
  if (studentsError) return <div>Error loading student data: {studentsError.message}</div>;

  return (
    <>
      <div className="layout">
        <InfoCard
          targetClass={`${grade}학년 ${classNum}반`}
          subject={`${subjectKor}`}
          submissionStatus={{ submitted: submitted, total: connectedStudentCount }}
          remainingTime={remainingTime}
        />

        <div className="title_box">
          <strong className="subtitle">
            <i className="hico hico2"></i>
            평가 진행현황
            <span><i className="badge badge4"></i>제출완료 학생을 클릭하시면 문항응답을 볼 수 있습니다.</span>
          </strong>
        </div>

        <div className="connect_wrap">
          <div className="connect_summary">
            <div className="unit__wrap">
              {
                units.map((unit, index) => (
                  <span key={index} className='unit__item'>{unit}단원</span>
                ))
              }
            </div>
            <ul className="badge_wrap">
              <li><i className="badge badge1">접속</i></li>
              <li><i className="badge badge2">결시</i></li>
              <li><i className="badge badge3">10분 이상 무응답</i></li>
              <li><i className="badge badge4">제출완료</i></li>
            </ul>
          </div>

          <StudentStatus
            students={studentsData || []}
            isAlertOpen={isAlertOpen}
            changeGradingPage={changeGradingPage}
            setIsAlertOpen={setIsAlertOpen}
            isPaused={isPaused}
            confirmPauseResume={confirmPauseResume}
          />

          <ProgressChart progress={progress} />

          <div className="btn_group">
            <button
              className={`btn btn-ani ${isPaused ? 'btn-outline-primary begin' : 'btn-delete stop'}`}
              onClick={handlePauseResume}
            >
              {isPaused ? '이어하기' : '일시정지'}
              <i className={`icon ${isPaused ? 'ico_play' : 'ico_pause'}`}></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sub020305;