import { useDrag } from "react-dnd";

const Q4DragItem = ({ item }) => {
    const img = item?.components?.find(component => component.type === 'img');
    const div = item?.components?.find(component => component.type === 'div');
    const textArray = item?.components?.filter(component => component.type === 'text');
    const span = item?.components?.find(component => component.type === 'span');

    if (img) {
        return (
            <DragImage item={item} src={img.src} />
        );
    }
    if (div) {
        return (
            <DragCard item={item}>
                <div class="dargcard">
                    {div.value}
                </div>
            </DragCard>
        )
    }
    if (textArray) {
        return (
            <DragText item={item}>
                {span && <span className={span.className}>{span.value}</span>}
                {textArray?.map(t => t.value)}
            </DragText>
        )
    } 
}

const DragImage = ({ item, src }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
    type: 'img',
    item: item,
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    }),
    }));

    return (
        <div ref={drag} style={{ display: 'inline-flex', alignItems: 'center', cursor: 'move' }}>
            <img src={src} alt={src} style={{ cursor: 'pointer', opacity: isDragging ? 0.5 : 1, }}
            />
        </div>
    );
};

const DragText = ({ item, children }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'text',
        item: item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    
    return (
        <div
            className='draggable'
            ref={drag}
            style={{
                cursor: 'move',
                opacity: isDragging ? 0.5 : 1,
            }}
        >
            {children}
        </div>
    );
};

const DragCard = ({ item, children }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'text',
        item: item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div
            className='draggable'
            ref={drag}
            style={{
                cursor: 'move',
                opacity: isDragging ? 0.5 : 1,
            }}
        >
            {children}
        </div>
    );
}

export default Q4DragItem;