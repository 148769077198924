import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Sub0401 = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [activeInnerTab, setActiveInnerTab] = useState("tabin10");
  const [activeSubjectTab, setActiveSubjectTab] = useState("tabin1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [chartData, setChartData] = useState({
    growthTrend: { labels: [], datasets: [] },
    subjectAverages: { labels: [], datasets: [] },
    subjectTrend: { labels: [], datasets: [] },
    unitAchievement: { labels: [], datasets: [] },
  });

  const toggleTestItem = (itemId) => {
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
    }
  };

  const renderTestItems = () => {
    return [1, 2, 3].map((item) => (
      <React.Fragment key={item}>
        <tr className={`test_item ${expandedItem === `test_item${item}` ? 'active' : ''}`} id={`test_item${item}`}>
          <th scope="row">01</th>
          <td>2.지구와 달의 운동</td>
          <td>[6과09-01]</td>
          <td>낮과 밤이 생기는 까닭</td>
          <td>
            <div className="txt_tooltip_wrap">
              <a className="tooltip">100%</a>
              <div className="tooltip_box">
                <ul>
                  <li className="correct"><b>정반응</b>김하나, 이두나, 한세나, 김가윤, 김나윤, 김다윤, 김라율...</li>
                  <li className="wrong"><b>오반응</b>사나, 최오나, 정유나</li>
                  <li><b>미응답</b>홍OO, 김OO, 이OO</li>
                </ul>
              </div>
            </div>
          </td>
          <td>80%</td>
          <td>단순선택</td>
          <td>
            <button 
              onClick={() => toggleTestItem(`test_item${item}`)} 
              className={`btnView ${expandedItem === `test_item${item}` ? 'ov' : ''}`}
            >
              자세히보기
            </button>
          </td>
        </tr>
        {expandedItem === `test_item${item}` && (
          <tr className="test_detail">
            <td colSpan="8">
              <ul className="tit_list">
                <li><b>문항난이도</b>하</li>
                <li><b>영역</b>지구와 우주</li>
                <li><b>맥락</b>순수과학</li>
                <li><b>역량</b>과학 원리의 이해 및 적용 능력</li>
              </ul>
              <div className="qinfo_wrap">
                <div>
                  <div className="q_content">
                    <h4 className="q_tit">
                      <em className="question_number">1번</em>
                      <span className="txt">가방의 수에 따른 국어책의 수와 수학책의 수를 나눗셈으로 비교하려고 합니다. 알맞은 말을 골라 ○표 하세요.</span>
                    </h4>
                    {/* 문항 내용 */}
                  </div>
                </div>
                <ul className="answerwrap">
                  {/* 답변 옵션들 */}
                </ul>
              </div>
              <div className="comment_wrap">
                <b>해설</b>
                <div className="comment">
                  발표를 할 때는 듣는 사람을 바라보며 알맞은 목소리로 말하고, 중요한 내용은 쓰면서 듣고, 발표를 들을 때에는 발표하는 친구 얼굴을 보면서 바른 자세로 듣고, 궁금한 내용이 있으면 손을 들고 기회를 얻어 질문합니다.
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    // 차트 데이터 로딩 (실제 API 호출로 대체 필요)
    setChartData({
      growthTrend: {
        labels: ['', '원본', '1차', '2차', '3차', '4차', '5차', '6차', '7차', '8차', '9차', '10차'],
        datasets: [
          {
            label: "국어",
            data: [0, 20, 30, 20, 50, 40, 50, 90, 60, 80, 50, 55],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderWidth: 1,
          },
          {
            label: "수학",
            data: [0, 32, 22, 52, 42, 22, 55, 95, 65, 85, 55, 60],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            borderWidth: 1,
          },
          {
            label: "영어",
            data: [0, 38, 28, 58, 48, 28, 58, 98, 88, 68, 58, 88],
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderWidth: 1,
          },
        ],
      },
      subjectAverages: {
        labels: ["국어", "수학", "영어", "과학", "사회"],
        datasets: [{
          label: '과목별 평균',
          backgroundColor: [
            'rgba(201, 133, 255, .3)',
            'rgba(66, 211, 177, .3)',
            'rgba(255, 174, 53, .3)',
            'rgba(230, 142, 171, .3)',
            'rgba(133, 196, 255, .3)',
          ],
          borderColor: [
            'rgba(201, 133, 255, 1)',
            'rgba(66, 211, 177, 1)',
            'rgba(255, 174, 53, 1)',
            'rgba(230, 142, 171, 1)',
            'rgba(133, 196, 255, 1)',
          ],
          borderWidth: 1,
          barThickness: 24, //그래프 굵기
          borderRadius: 24, // 그래프 둥글기
          data: [300, 400, 500, 785, 900],
        }]
      },
      subjectTrend: {
        labels: ['', '원본', '1차', '2차', '3차', '4차', '5차', '6차', '7차', '8차', '9차', '10차'],
        datasets: [
          {
            label: "점수",
            data: [0, 20, 30, 20, 50, 40, 50, 90, 60, 80, 50, 55],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderWidth: 1,
          },
          {
            label: "최고평균",
            data: [0, 38, 28, 58, 48, 28, 58, 98, 88, 68, 58, 88],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            borderWidth: 1,
          },
          {
            label: "최저평균",
            data: [0, 32, 22, 52, 42, 22, 55, 95, 65, 85, 55, 60],
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderWidth: 1,
          },
        ],
      },
      unitAchievement: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            data: [85, 75, 90, 70, 80],
            backgroundColor: [
              'rgba(201, 133, 255, .3)',
              'rgba(66, 211, 177, .3)',
              'rgba(255, 174, 53, .3)',
              'rgba(230, 142, 171, .3)',
              'rgba(133, 196, 255, .3)',
            ],
            borderColor: [
              'rgba(201, 133, 255, 1)',
              'rgba(66, 211, 177, 1)',
              'rgba(255, 174, 53, 1)',
              'rgba(230, 142, 171, 1)',
              'rgba(133, 196, 255, 1)',
            ],
            borderWidth: 1,
            barThickness: 24, //그래프 굵기
            borderRadius: 24, // 그래프 둥글기
            data: [300, 400, 500, 785, 900],
          },
        ],
      },
    });
  }, []);

  const renderGrowthTrendChart = () => (
    <Line
      data={chartData.growthTrend}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: "bottom" },
          title: { display: false, text: "성적 변화 추이" },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: { stepSize: 20 },
          },
        }
      }}
    />
  );

  const renderSubjectAveragesChart = () => (
    <Bar
      data={chartData.subjectAverages}
      options={{
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: { display: false, text: "과목별 평균점수" },
        },
        scales: {
          x: {
            min: 0,
            max: 1000,
            ticks: { stepSize: 100 },
          },
        }
      }}
    />
  );

  const renderSubjectTrendChart = () => (
    <Line
      data={chartData.subjectTrend}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: "bottom" },
          title: { display: false, text: "성적 변화 추이" },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: { stepSize: 20 },
          },
        }
      }}
    />
  );

  const renderUnitAchievementChart = () => (
    <Bar
      data={chartData.unitAchievement}
      options={{
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: { display: false, text: "단원별 성취도" },
        },
      }}
    />
  );

  const renderDoughnutChart = () => (
    <Doughnut
      data={{
        labels: ["정답", "오답"],
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ["#3157DD", "transparent"],
            borderWidth: 0,
            borderRadius: 30,
          },
        ],
      }}
      options={{
        responsive: true,
        cutout: '85%',
        plugins: {
          legend: { display: false },
        },
      }}
    />
  );

  return (
    <div className="layout">
      <div className="btn_group">
        <a href="#" className="btn btn-ani btn-outline-secondary">
          <i className="sicon ico_save"></i>저장하기
        </a>
      </div>
      <div className="tabs">
        <div className="tab-header">
          <button onClick={() => setActiveTab("tab1")} className={activeTab === "tab1" ? "active" : ""}>
            성장기록
          </button>
          <button onClick={() => setActiveTab("tab2")} className={activeTab === "tab2" ? "active" : ""}>
            회차별
          </button>
          <button onClick={() => setActiveTab("tab3")} className={activeTab === "tab3" ? "active" : ""}>
            과목별
          </button>
        </div>

        {/* 성장기록 탭 */}
        {activeTab === "tab1" && (
          <div className="tab-body" id="tab1">
            <div className="info_box">
              <div className="item_subject">
                <span className="tit">
                  <i className="ico_logo type1">온평가</i>
                </span>
                <ul className="student_info">
                  <li>
                    <span>학교명</span>충남초등학교
                  </li>
                  <li>
                    <span>학년</span>5
                  </li>
                  <li>
                    <span>반</span>1
                  </li>
                </ul>
              </div>
              <div className="text_box col4">
                {["국어", "사회", "수학", "과학", "영어"].map((subject, index) => (
                  <div className="item" key={index}>
                    <b className="title">{subject}</b>
                    <div className="numcycle clickOpen">
                      <p>
                        <strong>
                          <em>{index + 1}</em>회차{" "}
                        </strong>
                        <span>2024.07.16</span>
                      </p>
                      <button className="bn">횟수 더보기</button>
                      {/* 횟수 더보기 상세 내용 */}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="totalcommt">
              <strong>종합의견</strong>
              <ul>
                <li>
                  <b>6-1</b>반은 총 <b>10</b>회의 평가를 실시하였습니다.
                </li>
                <li>
                  최근에 실시한 <b>수학</b>과목 평가의 정답률은 <b>82%</b>로 이전의 평가에 비해 <b>높아</b>졌습니다.
                </li>
                <li>
                  <b>6-1</b>반은 <b>수학</b>과목의 성적이 높으며, <b>영어</b>과목의 성적이 낮은 편입니다. (<b>사회</b>
                  과목의 평가에 응시한 기록이 없습니다. <b>사회</b>과목을 평가하시는 것을 추천합니다.)
                </li>
              </ul>
            </div>

            <div className="chart_group">
              <div className="chart_item num5">
                <div className="title_box">
                  <strong className="subtitle">
                    <i className="hico2 hico10"></i>성적 변화 추이
                  </strong>
                </div>
                <div className="chart_item_con">
                  <div className="graph_wrap">{renderGrowthTrendChart()}</div>
                </div>
              </div>

              <div className="chart_item num5">
                <div className="title_box">
                  <strong className="subtitle">
                    <i className="hico2 hico9"></i>과목별 평균점수
                  </strong>
                </div>
                <div className="chart_item_con">
                  <div className="graph_wrap">{renderSubjectAveragesChart()}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 회차별 탭 */}
        {activeTab === "tab2" && (
          <div className="tab-body" id="tab2">
            <div className="tabin">
              <div className="tabtop">
                <ul className="student_info">
                  <li>
                    <span>학교명</span>충남초등학교
                  </li>
                  <li>
                    <span>학년</span>5
                  </li>
                  <li>
                    <span>반</span>1
                  </li>
                </ul>
                <div className="tabin-header">
                  {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((num) => (
                    <button
                      key={num}
                      onClick={() => setActiveInnerTab(`tabin${num}`)}
                      className={activeInnerTab === `tabin${num}` ? "active" : ""}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              </div>
              {activeInnerTab === "tabin10" && (
              <div className="tabin-body" id="tabin10">
                <div className="info_box">
                  <div className="item_subject">
                    <span className="tit">
                      <i className="ico_logo type1">온평가</i>
                      <b>과학</b>
                    </span>
                    <ul className="subject_list">
                      <li>1. 지구와 달의 운동</li>
                      <li>2. 여러 가지 기체</li>
                    </ul>
                  </div>
                  <div className="text_box col4">
                    <div className="item">
                      <b>응시일</b>
                      <p>2025.4.3</p>
                    </div>
                    <div className="item ico2">
                      <b>총 문항 수</b>
                      <p>20문항</p>
                      <span>상 5 / 중 10 / 하 5</span>
                    </div>
                    <div className="item ico3">
                      <b>응시인원</b>
                      <p>22명</p>
                      <span>총원 23 / 결원 1</span>
                    </div>
                    <div className="item ico4">
                      <b>평균응답시간</b>
                      <p><button onClick={() => setIsModalOpen(true)}>28분</button></p>
                    </div>
                    <div className="doughnut-chart" id="doughnutChart">
                      <span>전체정답률<b>75%</b></span>
                      {renderDoughnutChart()}
                    </div>
                  </div>
                </div>
                <div className="totalcommt">
                  <strong>종합의견</strong>
                  <ul>
                    <li><b>과학</b>과 평가 결과 전체 정답률은 <b>65%</b>로 <b>보통</b> 수준의 성취를 보이고 있습니다. </li>
                    <li>학급 학생들은 <b>낮과 밤이 생기는 까닭</b>을 잘 알고 관련된 문항의 정답률이 높은 모습을 보이고 있으며, </li>
                    <li><b>지구의 공전과 별자리의 관계, 계절에 따른 별자리</b> 관련 문항을 풀이하는 데 어려움을 보이고 있습니다.</li>
                  </ul>
                </div>
                <div className="title_box">
                  <strong className="subtitle">평가 문항정보</strong>
                  <div className="select_wrap">
                    <select className="select" id="select">
                      <option selected="">높은정답률</option>
                      <option value="">낮은 정답률 순</option>
                    </select>
                    <a className="btn btn-ani btn-outline-secondary" href="#"><i className="ico ico-reset" aria-hidden="true"></i>초기화</a>
                  </div>
                </div>
                <table className="tbl_basic center table-hover">
                  <caption>평가문항정보</caption>
                  <colgroup>
                    <col style={{width:"5%"}} />
                    <col style={{width:"14%"}} />
                    <col style={{width:"14%"}} />
                    <col style={{width:"auto"}} />
                    <col style={{width:"7%"}} />
                    <col style={{width:"7%"}} />
                    <col style={{width:"7%"}} />
                    <col style={{width:"5%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">번호</th>
                      <th scope="col">단원</th>
                      <th scope="col">성취기준</th>
                      <th scope="col">내용</th>
                      <th scope="col">정답률(학급)</th>
                      <th scope="col">정답률(충남)</th>
                      <th scope="col">문항유형</th>
                      <th scope="col">보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTestItems()}
                  </tbody>
                </table>
              </div>
            )}
              {/* 다른 회차 탭 내용 */}
            </div>
          </div>
        )}

        {/* 과목별 탭 */}
        {activeTab === "tab3" && (
          <div className="tab-body" id="tab3">
            <div className="tabin">
              <div className="tabin-header">
                {["국어", "수학", "사회", "과학", "영어"].map((subject, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveSubjectTab(`tabin${index + 1}`)}
                    className={activeSubjectTab === `tabin${index + 1}` ? "active" : ""}
                  >
                    {subject}
                  </button>
                ))}
              </div>
              {activeSubjectTab === "tabin1" && (
                <div className="tabin-body" id="tabin1">
                  <div className="info_box type3">{/* ... (이전 코드는 그대로 유지) */}</div>
                  <div className="chart_group">
                    <div className="chart_item num5">
                      <div className="title_box">
                        <strong className="subtitle">
                          <i className="hico2 hico10"></i>성적 변화 추이
                        </strong>
                      </div>
                      <div className="chart_item_con">
                        <ul className="legend_wrap">
                          <li className="total">
                            <b>과목평균점수</b>
                            <p>88점</p>
                          </li>
                          <li>
                            <div>
                              <b>
                                <span className="c_blue">최고</span>평균
                              </b>
                              <p>92점</p>
                            </div>
                            <div>
                              <b>
                                <span className="c_red">최저</span>평균
                              </b>
                              <p>32점</p>
                            </div>
                          </li>
                        </ul>
                        <div className="graph_wrap">{renderSubjectTrendChart()}</div>
                      </div>
                    </div>

                    <div className="chart_item num5">
                      <div className="title_box">
                        <strong className="subtitle">
                          <i className="hico2 hico9"></i>단원별 성취도
                        </strong>
                      </div>
                      <div className="chart_item_con">
                        <ul className="legend_wrap type2">
                          <li>
                            <b>
                              성취도가 <span className="c_blue">높은</span>단원
                            </b>
                            <p>4. 주장과 근거를 판단해요.</p>
                          </li>
                          <li>
                            <b>
                              성취도가 <span className="c_red">낮은</span>단원
                            </b>
                            <p>3. 짜임새 있게 구성해요.</p>
                          </li>
                        </ul>
                        <div className="graph_wrap">{renderUnitAchievementChart()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* 다른 과목 탭 내용 (필요시 추가) */}
            </div>
          </div>
        )}

        {/* 회차별 탭 추가 내용 */}
        {activeTab === "tab2" && (
          <div className="tab-body" id="tab2">
            {/* ... (이전 코드는 그대로 유지) */}
          </div>
        )}

        {/* 모달 (필요시 구현) */}
        {/* <div id="modal-tab" className="modal">
          모달 내용
        </div> */}
      </div>
    </div>
  );
};

export default Sub0401;
