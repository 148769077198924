import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient'; // supabase 클라이언트 임포트
import { subject, qtype } from '../../enum/enum';
import { isDocument } from '@testing-library/user-event/dist/utils';
import QuestionContainer from '../../components/question/QuestionContainer';

const ExamQuestionDetail = () => {
  const { id } = useParams(); // URL에서 id 파라미터를 받아옵니다.

  const [bankData, setBankData] = useState({
    isVisible: true,
    grade: '',
    term: '',
    unit: '',
    subject: '',
    textBook: '',
    area: '',
    context: '',
    competencies: '',
    unitName: '',
    property: '',
    level: '',
    exam_file_code: '',
    examNumber: '',
    examSubNumber: '',
    qtitle: '',
    qtype: '',
    answer: '',
    explanation: '',
    useYn: '',
    detail_type: ''
  });


  const [exampleData, setExampleData] = useState([]);
  const [viewExampleData, setViewExampleData] = useState([]);
  const gradeArray = ['1', '2', '3', '4', '5', '6'];
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setBankData(prevBankData => ({
      ...prevBankData,
      options: viewExampleData.map(item => item)
    }));
  }, [viewExampleData]);

  // useEffect로 데이터 가져오기
  useEffect(() => {
    const fetchExamQuestion = async () => {
      const { data, error } = await supabase
        .from('exam_question_bank')
        .select('*')
        .eq('id', id)
        .single(); // id로 단일 데이터를 가져옵니다.

      if (error) {
        console.error('Error fetching data:', error);
        return;
      }

      if (data) {
        setBankData({
          isVisible: data.use_yn === 'Y' ? true : false,
          grade: data.grade || '',
          term: data.term || '',
          unit: data.unit || '',
          subject: Object.entries(subject).find(([key, val]) => val === data.subject)?.[0] || '',
          textBook: data.text_book || '',
          area: data.area || '',
          context: data.context || '',
          competencies: data.competencies || '',
          unitName: data.unit_name || '',
          property: data.property || '',
          level: data.level || '',
          exam_file_code: data.exam_file_code || '',
          examNumber: data.exam_number || '',
          examSubNumber: (data.exam_number).split('-').slice(-1) || '',
          qtitle: data.qtitle || '',
          qtype: Object.entries(qtype).find(([key, val]) => val === data.qtype)?.[1] || '',
          answer: data.answer || '',
          explanation: data.explanation || '',
          detail_type: data.detail_type || '',
          options: data.options || [],
        });
      }
    };




    // 수정할 문항 set
    const fetchQuestionExample = async () => {
      const { data, error } = await supabase
        .from('exam_question_example')
        .select('*')
        .eq('p_id', id)
        .order('sort', { ascending: true });

      if (error) {
        console.error('Error fetchQuestionExample fetching data:', error);
        return;
      }

      if (data) {
        setExampleData(data);
        setViewExampleData(data.map(item => ({ ...item }))); // 새로운 객체로 복사
      }
    };

    fetchExamQuestion();
    fetchQuestionExample();
  }, [id]);

  // PlusMinusButton 컴포넌트
  const PlusMinusButton = ({ onPlusClick, onMinusClick, index, isLast, showPlusOnly }) => (
    <div style={{ width: '50px', display: 'flex', justifyContent: 'space-around', marginLeft: '20px' }}>
      {(isLast || showPlusOnly) && viewExampleData.length < 5 && (
        <button onClick={onPlusClick}>
          <i className="icon ico_plus">추가</i>
        </button>
      )}
      {!showPlusOnly && viewExampleData.length > 0 && (
        <button onClick={() => onMinusClick(index)}>
          <i className="icon ico_minus">삭제</i>
        </button>
      )}
    </div>
  );



  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBankData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked :
        name === 'qtype' ? parseInt(value, 10) :
          value
    }));
  };

  const handleExampleChange = (index, value) => {
    setViewExampleData(prev => {
      const updatedExamples = [...prev];
      updatedExamples[index].example = value; // 인덱스를 통해 해당 보기 데이터를 업데이트
      return updatedExamples;
    });

    setBankData(prev => ({
      ...prev,
      options: prev.options.map((option, i) => i === index ? value : option)
    }));
  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(prev => [...prev, ...newFiles]);
  };

  const handleFileDelete = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  // 초기화 버튼 클릭 시 보기 초기화
  const handleReset = () => {
    setViewExampleData([...exampleData]); // 초기 데이터를 다시 설정
  };

  // 보기 추가 핸들러
  const handlePlusClick = () => {
    if (viewExampleData.length < 5) { // 최대 5개로 제한
      setViewExampleData((prev) => [
        ...prev,
        { example: '', sort: prev.length + 1 } // 새로 추가할 보기는 빈 값과 정렬 순서를 기본으로 추가
      ]);
      setBankData(prev => ({
        ...prev,
        options: [...prev.options, '']
      }));
    }
  };

  // 보기 삭제 핸들러
  const handleMinusClick = (index) => {
    setViewExampleData((prev) => prev.filter((_, i) => i !== index));
    setBankData(prev => ({
      ...prev,
      options: prev.options.filter((_, i) => i !== index)
    }));
  };


  const handleSave = async () => {
    const updates = [];
    const inserts = [];
    const deletes = [];

    const updateData = {
      use_yn: bankData.isVisible ? 'Y' : 'N',
      grade: bankData.grade,
      term: bankData.term,
      unit: bankData.unit,
      subject: subject[bankData.subject], // enum 값으로 변환
      text_book: bankData.textBook,
      area: bankData.area,
      context: bankData.context,
      competencies: bankData.competencies,
      unit_name: bankData.unitName,
      property: bankData.property,
      level: bankData.level,
      exam_file_code: bankData.examFileCode,
      exam_number: bankData.examNumber,
      qtitle: bankData.qtitle,
      qtype: qtype[bankData.qtype],
      answer: bankData.answer,
      explanation: bankData.explanation
    };

    // 문제 은행 답안 업데이트
    try {
      const { data, error } = await supabase
        .from('exam_question_bank')
        .update(updateData)
        .eq('id', id);

      if (error) throw error;

    } catch (error) {
      console.error('Error updating data:', error);
    }

    // 문제 은행 보기 업데이트
    // 가장 큰 ID 값 가져오기 -> insert할 때 필요
    const { data: lastIdResult, error: lastIdError } = await supabase
      .from('exam_question_example')
      .select('id')
      .order('id', { ascending: false })
      .limit(1);

    if (lastIdError) {
      console.error('Error fetching last id:', lastIdError);
      alert('데이터 저장 중 오류가 발생했습니다.');
      return;
    }

    // 가장 큰 ID 값에 +1을 해서 새로운 ID 생성
    const lastId = lastIdResult.length > 0 ? lastIdResult[0].id : 0;
    let newId = lastId + 1;

    // viewExampleData와 exampleData를 비교하여 업데이트, 삽입, 삭제 항목 식별
    viewExampleData.forEach((example, index) => {
      const sort = index + 1; // sort 번호를 input 순서대로 할당
      if (example.id) {
        // 기존 데이터 업데이트
        const oldExample = exampleData.find(e => e.id === example.id);
        if (oldExample && (oldExample.example !== example.example || oldExample.sort !== sort)) {
          updates.push({
            id: example.id,
            example: example.example,
            exam_number: example.exam_number,
            sort: sort,
            last_date: new Date(),
            p_id: example.p_id,
            use_yn: example.use_yn
          });
        }
      } else {
        // 새로운 데이터 삽입 (새로 생성한 ID 사용)
        inserts.push({
          id: newId++, // 새로운 ID를 사용하고, 다음 ID로 증가
          exam_number: bankData.examNumber,
          sort: sort,
          example: example.example,
          use_yn: bankData.isVisible ? 'Y' : 'N',
          reg_date: new Date(),
          last_date: new Date(),
          p_id: id
        });
      }
    });

    // 삭제된 항목 식별
    exampleData.forEach(oldExample => {
      if (!viewExampleData.some(e => e.id === oldExample.id)) {
        deletes.push(oldExample.id);
      }
    });

    try {
      // 삭제 작업 실행
      if (deletes.length > 0) {
        const { error: deleteError } = await supabase
          .from('exam_question_example')
          .delete()
          .in('id', deletes);
        if (deleteError) throw deleteError;
      }

      // 삽입 작업 실행
      if (inserts.length > 0) {
        const { error: insertError } = await supabase
          .from('exam_question_example')
          .insert(inserts);
        if (insertError) throw insertError;
      }

      // 업데이트 작업 실행
      if (updates.length > 0) {
        const { error: updateError } = await supabase
          .from('exam_question_example')
          .upsert(updates, { onConflict: ['id'] });
        if (updateError) throw updateError;
      }

      alert('문제은행 수정 완료');

      // 변경 사항 저장 후 데이터 다시 로드
      const { data: newData, error: fetchError } = await supabase
        .from('exam_question_example')
        .select('*')
        .eq('p_id', id)
        .order('sort', { ascending: true });
      if (fetchError) throw fetchError;

      setExampleData(newData); // 새로 로드한 데이터를 설정
      setViewExampleData(newData.map(item => ({ ...item }))); // 화면에 표시할 데이터 업데이트
    } catch (error) {
      console.error('Error saving data:', error);
      alert('데이터 저장 중 오류가 발생했습니다.');
    }
  };

  console.log(`bankData: ${JSON.stringify(bankData)}`);

  return (
    <>
      <div className="cms_con_header">
        <h2>문항자료 등록/수정 <small>자료를 등록(수정)합니다.</small></h2>
        <div id="location">
          <Link to="/" className="item home"><span>홈</span></Link>
          <Link to="/question-management" className="item">문항관리</Link>
          <Link to="/question-material" className="item">문항자료 관리</Link>
          <span className="item">문항자료 등록/수정</span>
        </div>
      </div>

      <div className="create_box line_box" style={{ width: '100%' }} >
        <div className="create__section num1">
          <h3>기본정보</h3>
          <div className="form_group">
            <strong>출력여부</strong>
            <div>
              <input
                type="checkbox"
                id="toggle"
                checked={bankData.isVisible}
                onChange={handleInputChange}
                name="isVisible"
              />
              <label htmlFor="toggle" className="toggleSwitch">
                <span className="toggleButton"></span>
              </label>
            </div>
          </div>

          <div className="form_group">
            <strong>학년 선택</strong>
            <ul className="form_item_box">
              <li>
                <div className="select_wrap">
                  <select
                    className="select"
                    id="grade"
                    name="grade"
                    value={bankData.grade || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">선택</option>
                    {
                      gradeArray.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                      ))
                    }
                  </select>
                  <label htmlFor="grade">학년</label>
                </div>
              </li>
              <li>
                <input
                  type="text"
                  className="form_input sm"
                  name="term"
                  value={bankData.term || ''}
                  onChange={handleInputChange}
                />
                <label>학기</label>
              </li>
              <li>
                <input
                  type="text"
                  className="form_input sm"
                  name="unit"
                  value={bankData.unit || ''}
                  onChange={handleInputChange}
                />
                <label>단원</label>
              </li>
            </ul>
          </div>

          <div className="form_group">
            <strong>과목 선택</strong>
            <ul className="form_item_box">
              {['국어', '수학', '사회', '과학', '영어'].map((subject, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    id={`subjectkey${index + 1}`}
                    name="subject"
                    value={subject}
                    checked={bankData.subject === subject}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`subjectkey${index + 1}`} className="box_btn">{subject}</label>
                </li>
              ))}
            </ul>
          </div>

          <div className="form_group">
            <strong>출판사</strong>
            <ul className="form_item_box">
              {['자체제작', 'YBM김', 'YBM최', '대교', '동아', '천재함', '기타'].map((textBook, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    id={`publikey${index}`}
                    name="textBook"
                    value={textBook}
                    checked={bankData.textBook === textBook}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`publikey${index}`}>{textBook}</label>
                </li>
              ))}
            </ul>
          </div>

          <div className="form_group">
            <strong>문항정보</strong>
            <ul className="form_item_box">
              <li>
                <label>영역</label>
                <input
                  type="text"
                  className="form_input md"
                  name="area"
                  value={bankData.area || ''}
                  onChange={handleInputChange}
                />
              </li>
              <li>
                <label>맥락</label>
                <input
                  type="text"
                  className="form_input md"
                  name="context"
                  value={bankData.context || ''}
                  onChange={handleInputChange}
                />
              </li>
              <li>
                <label>역량</label>
                <input
                  type="text"
                  className="form_input md"
                  name="competencies"
                  value={bankData.competencies || ''}
                  onChange={handleInputChange}
                />
              </li>
            </ul>
          </div>

          <div className="form_group">
            <strong>단원명</strong>
            <input
              type="text"
              className="form_input"
              name="unitName"
              value={bankData.unitName || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="form_group">
            <strong>성취기준</strong>
            <input
              type="text"
              className="form_input"
              name="property"
              value={bankData.property || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="form_group">
            <strong>출제수준</strong>
            <ul className="form_item_box">
              {['상', '중', '하'].map((level, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    id={`level_radio${index + 1}`}
                    name="level"
                    value={level}
                    checked={bankData.level === level}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`level_radio${index + 1}`}>{level}</label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="create__section num2">
          <h3>문항내용</h3>
          <div className="form_group">
            <strong>문항코드</strong>
            <input
              type="text"
              className="form_input md"
              name="exam_file_code"
              value={bankData.exam_file_code || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="form_group">
            <strong>문항번호</strong>
            <ul className="form_item_box">
              <li>
                <input
                  type="text"
                  className="form_input md"
                  name="examNumber"
                  value={bankData.examNumber || ''}
                  onChange={handleInputChange}
                />
              </li>
              <li>
                <input
                  type="text"
                  className="form_input sm"
                  name="examSubNumber"
                  value={bankData.examSubNumber || ''}
                  onChange={handleInputChange}
                />
                <label>번</label>
              </li>
            </ul>
          </div>

          <div className="form_group_wrap">
            <div className="form_group">
              <strong>문항자료</strong>
              <div className="filebox">
                <input type="file" id="file1" onChange={handleFileUpload} multiple />
                <label htmlFor="file1" className="btn btn-ani btn-primary">파일추가</label>
                <div className="file_list">
                  {files.map((file, index) => (
                    <div className="file_item" key={index}>
                      <span className="file_name">{file.name}</span>
                      <div className="btn_group">
                        <button className="btn save">저장</button>
                        <button className="btn del" onClick={() => handleFileDelete(index)}>파일삭제</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="form_group">
            <strong>문제</strong>
            <input
              type="text"
              className="form_input"
              name="qtitle"
              value={bankData.qtitle || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="form_group">
            <strong>보기</strong>
            <div>
              {viewExampleData.length === 0 ? (
                <div className='dis_flex' style={{ alignItems: 'center' }}>
                  <span>등록된 [보기]가 존재하지 않습니다.</span>
                  <PlusMinusButton onPlusClick={handlePlusClick} showPlusOnly={true} />
                </div>
              ) : (
                <>
                  {viewExampleData.map((item, index) => (
                      <div div key={index} className="dis_flex" >
                        <input
                          type="text"
                          className="form_input"
                          value={item.example || ''}
                          onChange={(e) => handleExampleChange(index, e.target.value)}
                          style={{ marginTop: '4px' }}
                        />
                        <PlusMinusButton
                          onPlusClick={handlePlusClick}
                          onMinusClick={handleMinusClick}
                          index={index}
                          isLast={index === viewExampleData.length - 1}
                        />
                      </div>
                    ))
                  }
                </>
              )}
            </div>
          </div>
          {/* 초기화 버튼 */}
          <div className="form_group" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button onClick={handleReset} className="btn btn-ani btn-secondary">초기화</button>
          </div>
          {/* {exampleData.length > 0 &&
            <div className="form_group" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button onClick={handleReset} className="btn btn-ani btn-secondary">초기화</button>
            </div>
          } */}


          <div className="form_group">
            <strong>출제유형</strong>
            <ul className="form_item_box">
              {['라디오버튼', '체크박스', '핫스폿', '끌어놓기', '짝연결하기', '아래로펼치기', '단답입력', '서술입력'].map((type, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    id={`question_type${index + 1}`}
                    name="qtype"
                    value={index+1}
                    checked={bankData.qtype === (index + 1)}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`question_type${index + 1}`}>{type}</label>
                </li>
              ))}
            </ul>
          </div>

          <div className="form_group flex-top">
            <strong>미리보기</strong>
            <div>
              <QuestionContainer key={id} answers={[]} currentQuestion={0} question={bankData} handleAnswerChange={() => []} selectedQType={bankData.qtype} selectedDetailType={bankData.detail_type} />
            </div>
          </div>

          <div className="form_group">
            <strong>정답</strong>
            <input
              type="text"
              className="form_input"
              name="answer"
              value={bankData.answer || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="create__section num3">
          <div className="form_group flex-top">
            <strong>해설</strong>
            <textarea
              name="explanation"
              value={bankData.explanation || ''}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="btn_group">
        <Link to="/admin/exam_question_list" className="btn btn-ani btn-secondary lg">목록으로</Link>
        <button onClick={handleSave} className="btn btn-ani btn-primary lg">저장</button>
      </div>
    </>
  );
};

export default ExamQuestionDetail;