import React, { useState } from 'react';
// import '../css/question.css';

const QuestionFooter = ({ setCurrentQuestion }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupCompleteOpen, setIsPopupCompleteOpen] = useState(false);

  const modalOpen = (modalId) => {
    if (modalId === 'popup_opne') {
      setIsPopupOpen(true);
    } else if (modalId === 'popup_opne2') {
      setIsPopupCompleteOpen(true);
    }
  };

  const modalClose = (modalId) => {
    if (modalId === 'popup_opne') {
      setIsPopupOpen(false);
    } else if (modalId === 'popup_opne2') {
      setIsPopupCompleteOpen(false);
    }
  };

  return (
    <div>
      <div id="question_footer">
        <div className="q_footer">
          <button className="btn btn-secondary save_prev" onClick={() => setCurrentQuestion(prev => prev - 1)}>
            이전문제
            <i className="icon arr_b"></i>
          </button>
          <button className="btn btn-primary save_next" onClick={() => setCurrentQuestion(prev => prev + 1)}>
            저장 후 다음문제
            <i className="icon arr_b"></i>
          </button>
        </div>

        {/* 제출팝업 :: 미답변 있을때 */}
        <div className={`modal modal-popup question_modal ${isPopupOpen ? 'active' : ''}`} id="popup_opne">
          <div className="modal-background is-click-disabled"></div>
          <div className="modal-content">
            <div className="popup-wrap">
              <span className="flag"><span>1단원</span></span>
              <div className="con-head">
                <h3><i className="micon ico2"></i>5학년 1학기</h3>
              </div>
              <div className="con-wrap">
                <strong><em>미답변</em> 문항이 있습니다. <br />그대로 답안을 제출하시겠습니까?</strong>
                <div className="no_answer">
                  <span>미답변</span>
                  <ul className="no_answer_list">
                    <li className="no_answer_item">2</li>
                    <li className="no_answer_item">3</li>
                    <li className="no_answer_item">12</li>
                  </ul>
                </div>
                <div className="btn_group">
                  <button type="button" className="btn btn-ani btn-secondary" onClick={() => modalClose('popup_opne')}>
                    제출취소
                  </button>
                  <a className="btn btn-ani btn-primary" onClick={() => modalOpen('popup_opne2')}>
                    제출하기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 제출팝업 :: 완료 */}
        <div className={`modal modal-popup question_modal ${isPopupCompleteOpen ? 'active' : ''}`} id="popup_opne2">
          <div className="modal-background is-click-disabled"></div>
          <div className="modal-content">
            <div className="popup-wrap">
              <span className="flag"><span>1단원</span></span>
              <div className="con-head">
                <h3><i className="micon ico2"></i>5학년 1학기</h3>
              </div>
              <div className="con-wrap">
                <span className="confirm_img"></span>
                <strong><em>답안</em> 제출이 완료 되었습니다.</strong>
                <div className="btn_group">
                  <button type="button" className="btn btn-ani btn-primary" onClick={() => modalClose('popup_opne2')}>
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionFooter;
