import React from 'react';
import { subject } from '../../enum/enum';

const ExamRow = ({
  exam,
  isSelected,
  onSelect,
  onDelete,
  onEdit,
  onPreview,
  onDistribute,
  onTestInProgress,
  onGrade
}) => {

// 한국 시간으로 변경
const changeKoreanTime = (dateString) => {
  const date = new Date(dateString);
  const koreaTime = new Date(date.getTime() + (9 * 60 * 60 * 1000)); // UTC+9
  
  const year = koreaTime.getFullYear();
  const month = String(koreaTime.getMonth() + 1).padStart(2, '0');
  const day = String(koreaTime.getDate()).padStart(2, '0');
  const hours = String(koreaTime.getHours()).padStart(2, '0');
  const minutes = String(koreaTime.getMinutes()).padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

  const renderDistributionStatus = (status) => {
    switch (status) {
      case 1:
        return <a href="#" className="btn btn-ani btn-primary sm" onClick={(e) => { onDistribute(exam.id); e.preventDefault() }}>배포하기</a>;
      case 2:
        return <a href="#" className="btn btn-ani btn-outline-primary sm" onClick={(e) => { onTestInProgress(exam.id); e.preventDefault() }}>평가중</a>;
      case 3:
        return <span className="state tx end">완료</span>;
      default:
        return '-';
    }
  };

  const renderGradingStatus = (status) => {
    switch (status) {
      case 1:
        return <span className="state tx score1">채점 전</span>;
      case 2:
        return <a className="state tx score6" onClick={(e) => onGrade(e, exam.id, exam.class_code)} style={{cursor: 'pointer'}}>미완료</a>;
      case 3:
        return <span className="state tx end" >완료</span>;
      default:
        return '-';
    }
  };

  const renderNullable = (string) => {
    return string ? string : '-';
  };

  return (
    <tr>
      <th scope="row">
        <input
          type="checkbox"
          id={`check${exam?.id}`}
          checked={isSelected}
          onChange={() => onSelect(exam?.id)}
          disabled={!exam?.is_editable}
        />
        <label htmlFor={`check${exam.id}`}></label>
      </th>
      <td>{renderNullable(exam.grade)}</td>
      <td>{renderNullable(exam.class)}</td>
      <td className="td_left">
        <b>{renderNullable(exam.grade)}학년 {renderNullable(exam.term)}학기 {Object.keys(subject).find(key => subject[key] === parseInt(exam.subject))} ({renderNullable(exam.text_book)}) 평가지</b>
        {exam.question_count}문제 {exam.unit1 && `| ${[exam.unit1, exam.unit2, exam.unit3].filter(Boolean).filter(u => u !== '0').join('단원, ')}단원`}
        {exam.ai_generated && <i className="state tx sm ico_ai">AI출제</i>}
      </td>
      <td>{changeKoreanTime(exam.reg_date)}</td>
      <td>
        {exam.is_editable ? (
          <a href="#" className="micon ico5" onClick={(e) => { onEdit(exam?.id); e.preventDefault() }} style={{cursor: 'pointer'}}>수정</a>
        ) : (
          <span className="micon ico5 disabled">수정</span>
        )}
      </td>
      <td><a href="#" className="micon ico7" onClick={(e) => { onPreview(exam?.id); e.preventDefault() }} style={{cursor: 'pointer'}}>보기</a></td>
      <td>
        {renderDistributionStatus(exam.distribution_status)}
      </td>
      <td>{renderGradingStatus(exam.grading_status)}</td>
      <td>
        {exam.submitStudents < 1 ? (
          <a href="#" className="micon ico8" onClick={(e) => { onDelete(exam?.id); e.preventDefault() }} style={{cursor: 'pointer'}}>삭제</a>
        ) : (
          <span className="micon ico8 disabled">삭제</span>
        )}
      </td>
    </tr>
  );
};

export default ExamRow;