import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const Sub0301 = () => {
  const [formData, setFormData] = useState({
    grade: '',
    semester: '',
    subject: '',
    publishers: [],
    units: [],
    questionCount: '',
    difficulty: '',
    selectedStudents: [],
  });

  const [students, setStudents] = useState([]);
  const [showPublishers, setShowPublishers] = useState(false);
  const [showUnits, setShowUnits] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    const { data, error } = await supabase.from('student').select('*');
    if (error) console.error('Error fetching students:', error);
    else setStudents(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'subject') {
      setShowPublishers(value === 'eng');
      setShowUnits(true);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === 'publishers' || name === 'units') {
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter(item => item !== value)
      }));
    } else if (name === 'selectedStudents') {
      setFormData(prevData => ({
        ...prevData,
        selectedStudents: checked
          ? [...prevData.selectedStudents, value]
          : prevData.selectedStudents.filter(id => id !== value)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const { data, error } = await supabase.from('assessments').insert([formData]);
    // if (error) console.error('Error creating assessment:', error);
    // else console.log('Assessment created successfully:', data);
  };

  const handleAllStudentsCheck = (e) => {
    const { checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      selectedStudents: checked ? students.map(s => s.id) : []
    }));
  };

  return (
  <>
    <div className="layout">
      <ul className="create_box line_box">
        {/* 학년 */}
        <li className="create__item num1">
          <span className="item__title"><em className="number">1</em>학년</span>
          <div className="item__box">
            <div className="select_wrap">
              <select
                name="grade"
                value={formData.grade}
                onChange={handleInputChange}
                className="select"
              >
                <option value="">선택</option>
                {[6, 5, 4, 3, 2].map(grade => (
                  <option key={grade} value={grade}>{grade}</option>
                ))}
              </select>
              <label>학년</label>
            </div>
            <div className="select_wrap">
              <select
                name="semester"
                value={formData.semester}
                onChange={handleInputChange}
                className="select"
              >
                <option value="">선택</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
              <label>학기</label>
            </div>
          </div>
        </li>
        {/* 과목 */}
        <li className="create__item num2">
          <span className="item__title"><em className="number">2</em>과목</span>
          <div className="item__box flex-col">
            <ul className="subject__select">
              {['국어', '수학', '사회', '과학', '영어'].map((subject, index) => (
                <li key={subject}>
                  <input
                    type="radio"
                    id={`subjectkey${index + 1}`}
                    name="subject"
                    value={subject === '영어' ? 'eng' : 'eng-no'}
                    onChange={handleInputChange}
                    checked={formData.subject === (subject === '영어' ? 'eng' : 'eng-no')}
                  />
                  <label htmlFor={`subjectkey${index + 1}`} className="box_btn">{subject}</label>
                </li>
              ))}
            </ul>
            {showPublishers && (
              <div className="publisher_select">
                <span><em className="required"><span className="sr-only">필수선택</span></em>출판사 선택</span>
                <ul>
                  {['YBM 김', 'YBM 최', '대교', '동아', '천재 함'].map((publisher, index) => (
                    <li key={publisher}>
                      <input
                        type="checkbox"
                        id={`publikey${index + 1}`}
                        name="publishers"
                        value={publisher}
                        onChange={handleCheckboxChange}
                        checked={formData.publishers.includes(publisher)}
                      />
                      <label htmlFor={`publikey${index + 1}`}>{publisher}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </li>
        {/* 출제범위 */}
        <li className="create__item num3">
          <span className="item__title"><em className="number">3</em>출제 범위</span>
          <div className="item__box flex-col">
            <div className="unit__select_wrap">
              {!showUnits && <span className="empty_tt"><i></i>과목을 선택하세요.</span>}
              {showUnits && (
                <ul className="unit__select">
                  {[
                    "What grade are you in?",
                    "I have a cold.",
                    "When is the club festival?",
                    "Where is the post office?",
                    "I'm going to see a movie.",
                    "He has short curly hair.",
                    "He has short curly hair.",
                    "I'm going to see a movie.",
                    "Where is the post office?",
                    "When is the club festival?"
                  ].map((unit, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        id={`unitkey${index + 1}`}
                        name="units"
                        value={unit}
                        onChange={handleCheckboxChange}
                        checked={formData.units.includes(unit)}
                      />
                      <label htmlFor={`unitkey${index + 1}`} className="unit_btn">
                        <span><em>{index + 1} 단원</em>{unit}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <p className="description">※복수 단원 선택 가능합니다.</p>
          </div>
        </li>
        {/* 문제수 */}
        <li className="create__item num4">
          <span className="item__title"><em className="number">4</em>문제수</span>
          <div className="item__box">
            <ul className="subject__select">
              {[10, 15, 20, 25].map((count, index) => (
                <li key={count}>
                  <input
                    type="radio"
                    id={`countkey${index + 1}`}
                    name="questionCount"
                    value={count}
                    onChange={handleInputChange}
                    checked={formData.questionCount === count.toString()}
                  />
                  <label htmlFor={`countkey${index + 1}`} className="box_btn">{count}개</label>
                </li>
              ))}
            </ul>
          </div>
        </li>
        {/* 난이도 */}
        <li className="create__item num5">
          <span className="item__title"><em className="number">5</em>난이도</span>
          <div className="item__box flex-col">
            <ul className="subject__select">
              {['AI출제', '수동출제'].map((level, index) => (
                <li key={level}>
                  <input
                    type="radio"
                    id={`levelkey${index + 1}`}
                    name="difficulty"
                    value={level}
                    onChange={handleInputChange}
                    checked={formData.difficulty === level}
                  />
                  <label htmlFor={`levelkey${index + 1}`} className="box_btn">{level}</label>
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
      
      {/* 학생 목록 */}
      <div className="table_wrap">
        <table className="tbl_basic center table-hover">
          <caption>학생 목록</caption>
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  id="allcheck"
                  name="check"
                  onChange={handleAllStudentsCheck}
                  checked={formData.selectedStudents.length === students.length}
                />
                <label htmlFor="allcheck"></label>
              </th>
              <th scope="col">번호</th>
              <th scope="col">이름</th>
              <th scope="col">비고</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.id}>
                <th scope="row">
                  <input
                    type="checkbox"
                    id={`check${index}`}
                    name="selectedStudents"
                    value={student.id}
                    onChange={handleCheckboxChange}
                    checked={formData.selectedStudents.includes(student.id)}
                  />
                  <label htmlFor={`check${index}`}></label>
                </th>
                <td>{index + 1}</td>
                <td>{student.name}</td>
                <td>{student.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>     
      {/* 선택 확인 */}
      <div className="totla_wrap line_box">
        <span><i className="micon ico9"></i>평가지 선택 확인</span>
        <div>
          <strong className="totla__title">
            <b>{formData.grade}</b>학년 <b>{formData.semester}</b>학기 
            <b>{formData.subject === 'eng' ? '영어' : formData.subject}</b>
            {formData.publishers.length > 0 && `(${formData.publishers.join(', ')})`} 
            평가지 문제수 <b>{formData.questionCount}</b>개
          </strong>
          <ul className="totla__info">
            <li className="totla__item num1">
              <ul>
                <li><em>선택 학생수</em> <b>{formData.selectedStudents.length}</b>명 / {students.length}명</li>
              </ul>
            </li>
            <li className="totla__item num2">
              <em>출제범위</em>
              <div className="unit__wrap">
                {formData.units.map((unit, index) => (
                  <span key={index} className="unit__item">{index + 1}단원 {unit}</span>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="btn_group">
        <button type="button" className="btn btn-ani btn-secondary lg" onClick={handleSubmit}>임시저장</button>
        <button type="button" className="btn btn-ani btn-primary lg" onClick={() => {/* 미리보기 로직 */}}>미리보기</button>
      </div>
  </>
  );
};

export default Sub0301;