import React from 'react';

const ActionButtons = ({ screenType, onSave, onPreview, onDetail }) => {
  return (
    <div className="btn_group">
      {screenType !== "preview" && 
        <>
          <button type="button" className="btn btn-ani btn-secondary lg" onClick={onSave}>
            임시저장
          </button>
          <button type="button" className="btn btn-ani btn-primary lg" onClick={onPreview}>
            미리보기
          </button>
        </>
      }
      {screenType === "preview" && <button type="button" className="btn btn-ani btn-primary lg" onClick={onDetail}>
        상세보기
      </button>}
    </div>
  );
};

export default ActionButtons;