import React, { useEffect } from 'react';

const QuestionTypeSelection = ({ questionTypes, onChange }) => {
  const handleTypeChange = (type) => {
    let newMultiple = questionTypes.multiple;
    let newShortAnswer = questionTypes.shortAnswer;

    if (type === 'multiple') {
      if (newMultiple === 0 && newShortAnswer === 0) {
        newMultiple = 100;
        newShortAnswer = 0;
      } else if (newMultiple === 0 && newShortAnswer > 0) {
        newMultiple = 50;
        newShortAnswer = 50;
      } else {
        newMultiple = 0;
        if (newShortAnswer > 0) newShortAnswer = 100;
      }
    } else {
      if (newShortAnswer === 0 && newMultiple === 0) {
        newShortAnswer = 100;
        newMultiple = 0;
      } else if (newShortAnswer === 0 && newMultiple > 0) {
        newShortAnswer = 50;
        newMultiple = 50;
      } else {
        newShortAnswer = 0;
        if (newMultiple > 0) newMultiple = 100;
      }
    }

    onChange('questionTypes', { multiple: newMultiple, shortAnswer: newShortAnswer });
  };

  return (
    <li className="create__item num4">
      <span className="item__title">
        <em className="number">4</em>출제 유형
      </span>
      <div className="item__box flex-col">
        <ul className="subject__select">
          <li>
            <input
              type="checkbox"
              id="categkey1"
              name="categ_check"
              checked={questionTypes.multiple > 0}
              onChange={() => handleTypeChange('multiple')}
            />
            <label htmlFor="categkey1" className="box_btn">
              선택형
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              id="categkey2"
              name="categ_check"
              checked={questionTypes.shortAnswer > 0}
              onChange={() => handleTypeChange('shortAnswer')}
            />
            <label htmlFor="categkey2" className="box_btn">
              서답형
            </label>
          </li>
          <li>
            <div className="select_wrap">
              <select
                className="select"
                id="select3"
                value={questionTypes.shortAnswer}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  onChange('questionTypes', { multiple: 100 - value, shortAnswer: value });
                }}
              >
                {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}%
                  </option>
                ))}
              </select>
              <label htmlFor="select3">%</label>
            </div>
          </li>
        </ul>
        <p className="description">※단답형, 완결형, 논문형(논술형, 서술형)</p>
      </div>
    </li>
  );
};

export default QuestionTypeSelection;
