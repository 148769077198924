// On2024ComplexProvider.js
import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const On2024ComplexContext = createContext();

export const On2024ComplexProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [loginTeacherId, setLoginTeacherId] = useState("");
  const [loginTeacherPassword, setLoginTeacherPassword] = useState("");
  const { login, user } = useAuth();
  const { loginStudent } = useAuth();
  const { adminLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [showPublisherModal, setShowPublisherModal] = useState(false);
  const [selectedPublisher, setSelectedPublisher] = useState("");
  const [adminInfo, setAdminInfo] = useState(null);

  // 라우팅 관련
  const [examId, setExamId] = useState(null); //0101 학급정보 -> "보기"버튼 -> 012301 채점목록

  return (
    <On2024ComplexContext.Provider
      value={{
        activeModal,
        setActiveModal,
        loginTeacherId,
        setLoginTeacherId,
        loginTeacherPassword,
        setLoginTeacherPassword,
        login,
        user,
        loginStudent,
        navigate,
        location,
        teacherInfo,
        setTeacherInfo,
        showPublisherModal,
        setShowPublisherModal,
        selectedPublisher,
        setSelectedPublisher,
        // 라우팅 관련
        examId,
        setExamId,
        setAdminInfo,
        adminInfo,
        adminLogin
      }}
    >
      {children}
    </On2024ComplexContext.Provider>
  );
};

export const useOn2024ComplexContext = () => useContext(On2024ComplexContext);
