import React, { useState, useEffect } from 'react';
import { useOn2024ComplexContext } from '../common/Context';
import { supabase } from '../supabaseClient';
import { subject } from '../enum/enum';
import { useQuery } from '@tanstack/react-query';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import QuestionContainer from '../components/question/QuestionContainer';
import useQuestions from '../js/custom-hooks/useQuestions';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Sub020101 = () => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questions, setQuestions] = useState([])
  const [examData, setExamData] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [examRegister, setExamRegister] = useState(true);
  const { navigate, location, teacherInfo } = useOn2024ComplexContext();
  const examSettingId = location?.state?.exam_question_setting_id;
  const screenType = location?.state?.screenType;
  const [examNum, setExamNum] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);

  const { questions: questionsForPreview } = useQuestions(examSettingId);

  const fetchQuestionBank = async () => {
    if (!examNum || screenType === "preview") return null;

    const grade_term_units = examData.units.map((unit) => `${examData.grade}-${examData.term}-${unit}`);
    const year = new Date().getFullYear();
    let query = supabase
      .from('exam_question_bank')
      .select('*')
      .eq('year', year)
      .in('grade_term_unit', grade_term_units)
      .eq('subject', examData.subject);

    if (examData.text_book.length) {
      query = query.in('text_book', examData.text_book);
    }

    const { data, error } = await query;

    if (error) {
      throw new Error('Error fetching question bank: ' + error.message);
    }
    console.log({data})

    const { data: optionsData } = await supabase
      .from('exam_question_example')
      .select()
      .in('p_id', data.map(q => q.id))

    const joinedQuestionsAndAnswers = data.map(q => {
      return {...q,
      options: optionsData.filter(a => Number(a.p_id) === q.id)}
    })
    setAllQuestions(joinedQuestionsAndAnswers)
  

    const isShortAnswer = (qtype) => qtype === 7 || qtype === 8;

    // Filter and select questions for each unit
    const selectedQuestions = examNum.flatMap((unitExamNum, unitIndex) => {
      const unitQuestions = joinedQuestionsAndAnswers.filter(q => q.grade_term_unit === grade_term_units[unitIndex]);

      const selectQuestions = (questions, count, isShortAnswerType) => {
        return questions
          .filter(q => isShortAnswerType ? isShortAnswer(q.qtype) : !isShortAnswer(q.qtype))
          .sort(() => Math.random() - 0.5)
          .slice(0, count);
      };

      return [
        ...selectQuestions(unitQuestions.filter(q => q.level === '상'), unitExamNum.multiple.high, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '중'), unitExamNum.multiple.medium, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '하'), unitExamNum.multiple.low, false),
        ...selectQuestions(unitQuestions.filter(q => q.level === '상'), unitExamNum.shortAnswer.high, true),
        ...selectQuestions(unitQuestions.filter(q => q.level === '중'), unitExamNum.shortAnswer.medium, true),
        ...selectQuestions(unitQuestions.filter(q => q.level === '하'), unitExamNum.shortAnswer.low, true)
      ];
    });

    return selectedQuestions;
  };

  const updateDistibutionStatus = async (classCode) => {
    if (classCode) {
      const { data, error } = await supabase
      .from('exam_question_setting')
      .update({
        class_code : classCode,
        distribution_status: 1,
        grading_status: 1
      })
      .eq('id', examSettingId)

      if (error) {
        console.error('Error updating data:', error)
        return null
      }

      return data
    } else {
      const { data, error } = await supabase
      .from('exam_question_setting')
      .update({
        distribution_status: 1,
        grading_status: 1
      })
      .eq('id', examSettingId)

      if (error) {
        console.error('Error updating data:', error)
        return null
      }

      return data
    }
  }

  const { data: questionBank, isLoading: isQuestionBankLoading, error: questionBankError, refetch } = useQuery({
    queryKey: ['questionBank', examData, examNum],
    queryFn: fetchQuestionBank,
    enabled: !!examData?.grade && !!examData?.term && !!examData?.subject && !!examNum,
  });

  useEffect(() => {
    if (examData && examNum) {
      refetch();
    }
  }, [examData, examNum, refetch]);

  useEffect(() => {
    const data = location?.state?.examData
    console.log(data)
    if (data) {
      const multipleNum = Math.round(data.questionTypes.multiple * data.questionCount * 0.01);
      const transformedData = {
        grade: Number(data.grade),
        term: Number(data.semester),
        subject: Number(subject[data.subject]),
        text_book: data.publisher,
        units: data.units,
        questionTypes: {
          multiple: multipleNum,
          shortAnswer: data.questionCount - multipleNum
        },
        totalQuestions: data.questionCount,
        difficulty: data.difficulty,
      };
      setExamRegister(!location.state.classCode);
      setExamData(transformedData);
    }
  }, [location?.state?.examData]);

  useEffect(() => {
    if (questionBank) {
      setQuestions(questionBank);
    }
  }, [questionBank]);

  useEffect(() => {
    if (!examData || !examData.units || examData.units.length === 0) return;

    const totalQuestions = examData.questionTypes.multiple + examData.questionTypes.shortAnswer;
    const unitQuestions = examData.units.map((_, index) => {
      if (index === 0) return 10;
      if (index === 1) return 10;
      if (index === 2) return 5;
      return 0;
    }).filter(q => q > 0);

    let remainingQuestions = totalQuestions;
    const adjustedUnitQuestions = unitQuestions.map((q, index) => {
      if (index === unitQuestions.length - 1) {
        return remainingQuestions;
      }
      const adjustedQ = Math.min(q, remainingQuestions);
      remainingQuestions -= adjustedQ;
      return adjustedQ;
    });

    const getQuestionsForDifficulty = (totalForType, difficulty) => {
      return Math.round(totalForType * examData.difficulty[difficulty] * 0.01);
    };

    const examNumByUnit = adjustedUnitQuestions.map(unitTotal => {
      const multipleForUnit = Math.round(unitTotal * (examData.questionTypes.multiple / totalQuestions));
      const shortAnswerForUnit = unitTotal - multipleForUnit;

      return {
        multiple: {
          high: getQuestionsForDifficulty(multipleForUnit, 'high'),
          medium: getQuestionsForDifficulty(multipleForUnit, 'medium'),
          low: multipleForUnit - getQuestionsForDifficulty(multipleForUnit, 'high') - getQuestionsForDifficulty(multipleForUnit, 'medium')
        },
        shortAnswer: {
          high: getQuestionsForDifficulty(shortAnswerForUnit, 'high'),
          medium: getQuestionsForDifficulty(shortAnswerForUnit, 'medium'),
          low: shortAnswerForUnit - getQuestionsForDifficulty(shortAnswerForUnit, 'high') - getQuestionsForDifficulty(shortAnswerForUnit, 'medium')
        }
      };
    });

    setExamNum(examNumByUnit);
  }, [examData]);

  const toggleQuestionSelection = (id) => {
    setSelectedQuestions(prev =>
      prev.includes(id) ? prev.filter(qId => qId !== id) : [...prev, id]
    );
  };

  const deleteSingleQuestion = (question) => {
    setSelectedQuestions([question.id])
    handleDelete([question.id])
  }

  const generateClassCode = async () => {
    const randomLetter = String.fromCharCode(97 + Math.floor(Math.random() * 26));
    const randomNumber = Math.floor(10000 + Math.random() * 90000);
    let classCode = randomLetter + randomNumber

    const year = new Date().getFullYear();
    const { data, error } = await supabase
      .from('exam_question_setting')
      .select('class_code')
      .eq('year', year)
      .eq('teacher_id', teacherInfo.teacher_id)
      .eq('class_code', classCode)
      .eq('delete_yn', 'N')

    if (error) {
        throw new Error('Error fetching class code: ' + error.message);
    }

    if(data?.length) classCode = await generateClassCode();

    return classCode;
  }

  const handleDelete = (deleteList) => {
    console.log('selectedQuestions', selectedQuestions)
    const updatedQuestions = questions.filter(q => !deleteList.includes(q.id));
    const deletedQuestions = questions.filter(q => deleteList.includes(q.id));

    const replacementQuestions = []
    deletedQuestions.forEach(deletedQ => {
      const sameTypeQuestions = allQuestions.filter(q =>
        q.grade_term_unit === deletedQ.grade_term_unit &&
        q.level === deletedQ.level &&
        ((q.qtype === 7 || q.qtype === 8) === (deletedQ.qtype === 7 || deletedQ.qtype === 8)) &&
        !updatedQuestions.some(uq => uq.id === q.id) &&
        !selectedQuestions.includes(q.id) &&
        !replacementQuestions.some(uq => uq.id === q.id) &&
        !deleteList.includes(q.id)
      );
      console.log(replacementQuestions)

      if (sameTypeQuestions.length > 0) {
        const randomId = sameTypeQuestions[Math.floor(Math.random() * sameTypeQuestions.length)]
        replacementQuestions.push(randomId) 
      }
    })
    const filteredQuestions = replacementQuestions.filter(q => q !== null);

    if(deletedQuestions.length > filteredQuestions.length) return alert('선택된 단원의 문항수가 부족하여 삭제할 수 없습니다.')

    const finalQuestions = [...updatedQuestions, ...filteredQuestions];
    setQuestions(finalQuestions);
    setSelectedQuestions([]);
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedQuestions(questions.map(q => q.id));
    } else {
      setSelectedQuestions([]);
    }
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleSave = async () => {
    try {
      const toInsert = questions.map((q, idx) => ({
        exam_question_setting_id: examSettingId,
        exam_bank_id: q.id,
        question_number: idx + 1,
        school_id: teacherInfo.school_id,
        teacher_id: teacherInfo.teacher_id
      }));

      await supabase
      .from('exam_question_selection')
      .delete()
      .eq('exam_question_setting_id', examSettingId)

      await supabase
      .from('exam_question_selection')
      .insert(toInsert)

      if (examRegister) {
        const class_code = await generateClassCode();
        await updateDistibutionStatus(class_code);
      } else {
        await updateDistibutionStatus()
      }

      console.log('Questions saved successfully');
    } catch (error) {
      console.error('Error saving questions:', error);
    }
    navigate("/sub0203")
  };

  if (!examData || !questions) return <div>Loading...</div>;
  if (questionBankError) return <div>Error loading question bank: {questionBankError.message}</div>;

  return (
    <>
      <div className="layout">
        <ExamSummary
          examData={examData}
          questions={screenType === 'preview' ? questionsForPreview : questions}
          selectedQuestions={selectedQuestions}
          toggleQuestionSelection={toggleQuestionSelection}
          handleDelete={handleDelete}
          handleSelectAll={handleSelectAll}
          screenType={screenType}
        />
        <div className="prev_box">
          <QuestionPreview
            questions={screenType === 'preview' ? questionsForPreview : questions}
            deleteSingleQuestion={deleteSingleQuestion}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}
            screenType={screenType}
          />
        </div>
      </div>
      <div className="btn_group">
        {screenType !== "preview" && <>
          <button className="btn btn-ani btn-secondary lg" onClick={()=> navigate("/sub0201", {state: { exam_question_setting_id: examSettingId }})}>이전으로</button>
          <button className="btn btn-ani btn-primary lg" onClick={handleSave}>문제저장</button>
        </>}
        {screenType === "preview" && <button className="btn btn-ani btn-primary lg" onClick={() => navigate("/sub0203")}>확인</button>}
      </div>
    </>
  );
};

const ExamSummary = ({ examData, selectedQuestions, toggleQuestionSelection, handleDelete, questions, handleSelectAll, screenType }) => {
  if (!examData || !questions) {
    return <div>Loading exam data...</div>;
  }
  const isShortAnswer = (qtype) => qtype === 7 || qtype === 8;

  const multipleCount = questions.filter(q => !isShortAnswer(q.qtype)).length;
  const shortAnswerCount = questions.filter(q => isShortAnswer(q.qtype)).length;

  const high = questions.filter(q => q.level === "상").length;
  const medium = questions.filter(q => q.level === "중").length;
  const low = questions.filter(q => q.level === "하").length;

  const chartData = {
    labels: ['상', '중', '하'],
    datasets: [{
      backgroundColor: ['rgb(255, 86, 86)', 'rgb(0, 211, 171)', 'rgb(255, 184, 62)'],
      barThickness: 40,
      borderRadius: 6,
      borderSkipped: false,
      data: [high, medium, low],
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    }
  };

  return (
    <div className="prev_info">
      <div className="info_box">
        <div className="text_box">
          <strong className="totla__title">
            <b>{`${examData.grade}학년 ${examData.term}학기 ${Object.keys(subject)[Number(examData.subject)-1]}${examData.subject === 3 ? `(${examData.text_book.join(', ')})` : ''}`}</b>
          </strong>
          <span><em>총 문제수</em><b>{questions.length}</b>문제</span>
          <ul>
            <li>선택형 {multipleCount}</li>
            <li>서답형 {shortAnswerCount}</li>
          </ul>
        </div>
        <div className="graph_wrap">
          <div className="infoChart_wrap">
            <Bar data={chartData} options={chartOptions} />
          </div>
          <ul>
            <li>상</li>
            <li>중</li>
            <li>하</li>
          </ul>
        </div>
      </div>
      <QuestionList
        questions={questions || []}
        selectedQuestions={selectedQuestions}
        toggleSelection={toggleQuestionSelection}
        onDelete={() => handleDelete(selectedQuestions)}
        handleSelectAll={handleSelectAll}
        screenType={screenType}
      />
    </div>
  );
};

const QuestionList = ({ questions = [], selectedQuestions, toggleSelection, onDelete, handleSelectAll, screenType }) => {
  const isPreview = screenType === "preview";
  return (
    <div className="info_list">
      <div className="title_box">
        <strong className="subtitle">
          <i className="hico hico1"></i>
          문제목록
        </strong>
        {!isPreview && (
          <button type="submit" className="btn btn-ani btn-quaternary" onClick={onDelete}>
            <i className="sicon ico3"></i>선택삭제
          </button>
        )}
      </div>
      <div className="table_wrap">
        <table className="tbl_basic center table-hover">
          <caption>문제 목록</caption>
          <colgroup>
            <col style={{width: "10%"}} />
            <col style={{width: "10%"}} />
            <col style={{width: "40%"}} />
            <col style={{width: "10%"}} />
            <col style={{width: "10%"}} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  id="allcheck"
                  name="check"
                  checked={selectedQuestions.length === questions.length}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
                <label htmlFor="allcheck"></label>
              </th>
              <th scope="col">번호</th>
              <th scope="col">평가내용</th>
              <th scope="col">난이도</th>
              <th scope="col">단원</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={question.id}>
                <th scope="row">
                  {!isPreview && (
                    <input
                      type="checkbox"
                      id={`check${index}`}
                      name="check"
                      checked={selectedQuestions.includes(question.id)}
                      onChange={() => toggleSelection(question.id)}
                    />
                  )}
                  {!isPreview && <label htmlFor={`check${index}`}></label>}
                </th>
                <td>{index + 1}</td>
                <td>{question.qtitle}</td>
                <td>{question.level}</td>
                <td>{question.unit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

  const QuestionPreview = ({ questions, deleteSingleQuestion, sortOrder, onSortChange, screenType }) => {
    const isPreview = screenType === "preview";
    const sortedQuestions = [...questions].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a?.type?.localeCompare(b.type);
      } else {
        return b?.type?.localeCompare(a.type);
      }
    });

    return (
      <>
        <div className="title_box">
        <strong className="subtitle2">미리보기</strong>
        {!isPreview && (
          <div className="select_wrap">
            <select className="select" onChange={onSortChange} value={sortOrder}>
              <option value="asc">문제유형 오름차순</option>
              <option value="desc">문제유형 내림차순</option>
            </select>
          </div>
        )}
      </div>
      <div className="prev_list">
        {
          sortedQuestions.map((question, index) => (
            <div key={question.id} className='prev__item'>
              <QuestionContainer answers={[]} currentQuestion={index} question={question} handleAnswerChange={() => []} />
              <ul className="btn_group">
                <li>
                  <i className="state tx">{(question?.qtype === 7 || question?.qtype === 8) ? "서답형" : "선택형"}</i>
                  <i className={`state tx diff${question?.level === '상' ? 1 : question?.level === '중' ? 2 : 3}`}>{question?.level}</i>
                  <span className="state tx gr">{question?.context}</span>
                </li>
                <li>
                  <a href="#" className="btn btn-ani btn-blank report"><i className="sicon ico7"></i>오류신고</a>
                  {!isPreview && <button type="submit" className="sicon ico6" onClick={() => deleteSingleQuestion(question)} >삭제</button>}
                </li>
              </ul>
            </div>
          ))
        }
      </div>
      </>
    );
  };

export default Sub020101;