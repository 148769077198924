import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { useOn2024ComplexContext } from "../../common/Context";

const useAnswers = (examQuestionSettingId, dataTableName) => {
    const { location } = useOn2024ComplexContext()
    const student_id = location?.state?.student_id || 126;
    const [answers, setAnswers] = useState([]);
    const [gradingData, setGradingData] = useState([]);

    useEffect(() => {
        fetchTempAnswers();
    }, []);

    const fetchTempAnswers = useCallback(async () => {
        let questionCount = 25;
        try {
            const { data } = await supabase // 문제 개수 알아내기
                .from('exam_question_setting')
                .select('question_count')
                .match({
                    id: examQuestionSettingId,
                    delete_yn: 'N',
                })
                .single();
            if (data) {
                questionCount = data.question_count;
            }
        } catch (err) {
            console.error(err);
        }
        const mappedAnswers = new Array(questionCount).fill('');
        const mappedGradingStatus = new Array(questionCount).fill(null);
        try {
            if (examQuestionSettingId && student_id) {
                const { data } = await supabase
                    .from(dataTableName)
                    .select()
                    .match({
                        exam_question_setting_id: examQuestionSettingId,
                        student_id,
                    })
                    .order('question_number');
                if (data) {
                    data.forEach(item => {
                        mappedAnswers[item.question_number - 1] = item.answer;
                    });
                    data.forEach(item => {
                        mappedGradingStatus[item.question_number - 1] = item.grading || null;
                    });
                }
            } 
        } catch (err) {
            console.error(err);
        } finally {
            setAnswers(mappedAnswers);
            setGradingData(mappedGradingStatus);
        }
    }, []);
    
    const handleAnswers = useCallback((index, value) => {
        setAnswers(prevAnswers => {
            const newAnswers = [...prevAnswers];
            newAnswers[index] = value;
            console.log('Answers:', newAnswers);
            return newAnswers;
        });
    }, []);

    const resetAnswers = useCallback(() => {
        setAnswers([]);
    }, []);

    return { answers, gradingData, setGradingData, handleAnswers, resetAnswers };
}

export default useAnswers;