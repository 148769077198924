import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { useOn2024ComplexContext } from "../common/Context";
import { supabase } from "../supabaseClient";
import QuestionContainer from "../components/question/QuestionContainer";
import useAnswers from "../js/custom-hooks/useAnswers";
import useQuestions from "../js/custom-hooks/useQuestions";

const AnswerModal = ({ show, onClose, answerData, questionNum }) => {
  if (!show) return null;

  return (
    <div className="modal modal-popup answerPop active" id="popup_answer">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content">
        <button type="button" className="modal-close bn_close bk" onClick={onClose}>
          <X />
        </button>
        <div className="popup-wrap">
          <div className="con-head">
            <h3>정답</h3>
          </div>
          <div className="con-wrap">
            <ul className="answerSee_info">
              <li>문항번호 : {questionNum + 1}</li>
              <li>난이도 : {answerData.level}</li>
            </ul>
            <div className="answerSee_item">{answerData.explanation}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sub020306 = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null)
  const { location, teacherInfo, navigate } = useOn2024ComplexContext()
  const {exam_question_setting_id, student_id} = location?.state;

  const { questions } = useQuestions(exam_question_setting_id);
  const { answers, gradingData, setGradingData } = useAnswers(exam_question_setting_id, 'student_answer_sheet');

  const fetchStudentInfo = async () => {
    const { data: studentData, error: studentError } = await supabase
      .from('student')
      .select('*')
      .eq('delete_yn', 'N')
      .eq('student_id', student_id)
      setStudentInfo(studentData[0])
    
    if(studentError) {
      console.error(studentError)
    }
  }

  useEffect(() => {
    fetchStudentInfo()
  }, [])

  const handlePrevQuestion = () => {
    setCurrentQuestionIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
  };

  const toggleAnswerModal = () => {
    setShowAnswerModal(!showAnswerModal);
  };

  const toggleEditButtons = () => {
    setShowEditButtons(!showEditButtons);
  };

  const editGrading = (value) => {
    setGradingData(prev => {
      const newGradings = [...prev]
      newGradings[currentQuestionIndex] = value
      return newGradings
    })
  }

  const saveAnswers = async (type) => {
    gradingData.forEach(async (grade, idx) => {
      const { error } = await supabase
        .from('student_answer_sheet')
        .update({
          grading: grade, 
          is_temp_save: type === "temp" ? "Y" : "N"
        })
        .eq('exam_question_setting_id', exam_question_setting_id)
        .eq('student_id', student_id)
        .eq('question_number', idx+1)
        .eq('teacher_id', teacherInfo.teacher_id)

      if(error) return console.error(`${idx}${error}`)
    })

    if(type === "complete") {
      const { error: completeGradingError } = await supabase
        .from('question_creation_participation')
        .update({ completed_status: 2 })
        .eq('exam_question_setting_id', exam_question_setting_id)
        .eq('student_id', student_id);

      if(completeGradingError) return console.error(completeGradingError)

      const { data: classCode, error: classCodeError } = await supabase
        .from('exam_question_setting')
        .select('class_code')
        .eq('delete_yn', 'N')
        .eq('id', exam_question_setting_id)

      if(classCodeError) return console.error(classCodeError)

      navigate("/sub020301", { state: { exam_question_setting_id, class_code: classCode[0].class_code } });
    }
  }

  const currentQuestion = questions?.[currentQuestionIndex];
  if(questions) <div>Loading...</div>

  return (
    <>
      <div className="layout">
        {studentInfo && <StudentInfo studentData={studentInfo} teacherInfo={teacherInfo} />}
        <GradingTable gradingData={gradingData} />
        <div className="publish_prev grading" id="grading_prev_wrap">
          <AnswerModal show={showAnswerModal} onClose={toggleAnswerModal} answerData={currentQuestion} questionNum={currentQuestionIndex} />

          <div className="btn_group">
            <button className="answerBtn see" onClick={toggleAnswerModal}>
              <i></i>정답보기
            </button>
            <div id="answerEdit_wrap">
              <button className="answerBtn edit" onClick={toggleEditButtons}>
                <i></i>채점수정
              </button>
              {showEditButtons && (
                <div className="editBtn_wrap">
                  <button className="editBtn_true" onClick={() => editGrading("Y")}>
                    <i className="sicon ico_o">정답</i>
                  </button>
                  <button className="editBtn_wrong" onClick={() => editGrading("N")}>
                    <i className="sicon ico_x">오답</i>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div id="q_slide">
            <button className="slick-prev slick-arrow" onClick={handlePrevQuestion}>
              <ChevronLeft />
            </button>
            <QuestionContainer answers={answers} question={currentQuestion} grading={gradingData?.[currentQuestionIndex]} currentQuestion={currentQuestionIndex} handleAnswerChange={() => answers} />
            <button className="slick-next slick-arrow" onClick={handleNextQuestion}>
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>
      <div className="btn_group">
        <button className="btn btn-ani btn-secondary lg" onClick={() => saveAnswers("temp")}>임시저장</button>
        <button className="btn btn-ani btn-primary lg" onClick={() => saveAnswers("complete")}>채점완료</button>
      </div>
    </>
  );
};

const StudentInfo = ({ teacherInfo, studentData }) => (
  <ul className="student_info">
    <li>
      <span>학교명</span>
      {teacherInfo.school_id === 1 && "충남초등학교"}
    </li>
    <li>
      <span>학년</span>
      {studentData.grade}
    </li>
    <li>
      <span>반</span>
      {studentData.class_number }
    </li>
    <li>
      <span>번호</span>
      {studentData.student_number}
    </li>
    <li>
      <span>이름</span>
      {studentData.student_name}
    </li>
  </ul>
);

const GradingTable = ({ gradingData }) => {
  const correctCount = gradingData.filter(a => a === "Y").length;
  const incorrectCount = gradingData.length - correctCount;

  return (
    <div className="grading_table">
      <ul className="info_wrap">
        <li>
          <i className="sicon ico_o"></i>정답 : {correctCount}개
        </li>
        <li>
          <i className="sicon ico_x"></i>오답 : {incorrectCount}개
        </li>
      </ul>
      <div className="inner responseTable">
        <table className="tbl_basic type3 center">
          <thead>
            <tr>
              {gradingData.map((_, index) => (
                <th key={index} scope="col">
                  {index + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {gradingData.map((grade, index) => (
                <td key={index}>
                  <i className={`sicon ico_${grade === "Y" ? "o" : "x"}`}>
                    {grade === "Y" ? "정답" : "오답"}
                  </i>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sub020306;
