import React from 'react';
import ReactDOM from 'react-dom/client';
// Import CSS modules
import './css/common.css';
import './css/contents.css';
import './css/slick.css';
import './css/cms.css';
import './css/main.css';
import './css/question.css';
import './css/question_ind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


