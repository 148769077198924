// MainLayout.jsx
import React, { useState } from 'react';
import SubHeader from './SubHeader';
import Footer from './Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useOn2024ComplexContext } from '../../common/Context';
import PublisherModal from '../detailContents/sub040101/PublisherModal';
import ExamQuestionList from '../admin/ExamQuestionList';
import ExamQuestionDetail from '../admin/ExamQuestionDetail';

const AdminMainLayout = () => {
  const location = useLocation();
  const { showPublisherModal, setShowPublisherModal, setSelectedPublisher } = useOn2024ComplexContext();

  return (
    <div id="container" className="sub">
      <SubHeader />
      <section id="content" >
        <Routes>
          <Route path={`exam_question_list`} element={<ExamQuestionList />} />
          <Route path={`exam_question_detail/:id`} element={<ExamQuestionDetail />} />
        </Routes>
      </section>
      <PublisherModal
        isOpen={showPublisherModal}
        onClose={() => setShowPublisherModal(false)}
        onSelect={(publisher) => {
          setSelectedPublisher(publisher);
          setShowPublisherModal(false);
        }}
      />
      <Footer />
    </div>
  );
};

export default AdminMainLayout;