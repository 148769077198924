import { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { useOn2024ComplexContext } from "../../common/Context";

const useQuestions = (examQuestionSettingId) => {
    const { location, teacherInfo } = useOn2024ComplexContext()
    const [questions, setQuestions] = useState([]);
    
    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const { data: questionSelectionData, error } = await supabase
            .from('exam_question_selection')
            .select('exam_bank_id, question_number, id')
            .eq('exam_question_setting_id', examQuestionSettingId)
            .eq('teacher_id', (teacherInfo?.teacher_id || 2))
            .order('question_number', { ascending: true });
        if (error) throw error;

        const examBankIds = questionSelectionData.map(item => item.exam_bank_id);

        const { data: questionBankData } = await supabase
            .from('exam_question_bank')
            .select()
            .in('id', examBankIds)

        const combinedQuestionData = questionSelectionData.map(selection => {
            const questionBankItem = questionBankData.find(question => question.id === selection.exam_bank_id);
            return {
                ...questionBankItem,
                exam_question_selection_id: selection.id,
                question_number: selection.question_number
            }
        })

        if (combinedQuestionData?.length) {
            const { data: optionsData } = await supabase
                .from('exam_question_example')
                .select()
                .in('p_id', combinedQuestionData.map(q => q.id))

                const joinedQuestionsAndAnswers = combinedQuestionData.map(q => ({
                    ...q,
                    options: optionsData.filter(a => Number(a.p_id) === q.id)
                }))
            setQuestions(joinedQuestionsAndAnswers)
            console.log('questions', joinedQuestionsAndAnswers)
        } else {
            setQuestions([])
        }
    }
    
    return { questions };
}

export default useQuestions;