// components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (  
    <footer>
      <img src="../images/main/cne_logo.svg" alt="충청남도교육청교육과정평가정보원 로고" />
      <span>Copyright ⓒ 2024 edus충남. All Rights Reserved.</span>
    </footer>
  );
};

export default Footer;