// common.js

// 모달팝업 열기
export function modalOpen(modal_id) {
    document.querySelector(`.modal#${modal_id}`).classList.add("active");
    document.body.classList.add('open');
}

// 모달팝업 닫기
export function modalClose(modal_id) {
    document.querySelector(`.modal#${modal_id}`).classList.remove("active");
    document.body.classList.remove('open');
}

// 체크박스 전체 선택, 해제
export function initializeCheckboxes() {
    const allCheckBox = document.querySelector("#allcheck");
    if (allCheckBox) {
        allCheckBox.addEventListener("click", () => {
            document.querySelectorAll("input[name=check]").forEach(el => el.checked = allCheckBox.checked);
        });
    }

    document.querySelectorAll("input[name=check]").forEach(el => {
        el.addEventListener("click", () => {
            const total = document.querySelectorAll("input[name=check]").length;
            const checked = document.querySelectorAll("input[name=check]:checked").length;
            document.querySelector("#allcheck").checked = total === checked;
        });
    });
}

// 탭 컨텐츠 숨기기
export function initializeTabs() {
    document.querySelectorAll(".tab-body").forEach(el => el.style.display = "none");

    document.querySelectorAll(".tabs").forEach(tabs => {
        const firstButton = tabs.querySelector(".tab-header button");
        if (firstButton) {
            firstButton.classList.add("active");
            document.getElementById(firstButton.getAttribute("rel")).style.display = "block";
        }

        tabs.querySelectorAll(".tab-header button").forEach(button => {
            button.addEventListener("click", () => {
                tabs.querySelectorAll(".tab-header button").forEach(btn => btn.classList.remove("active"));
                button.classList.add("active");

                const activeTab = button.getAttribute("rel");
                tabs.querySelectorAll(".tab-body").forEach(tab => tab.style.display = "none");
                document.getElementById(activeTab).style.display = "block";
            });
        });
    });
}

// 2번째 탭 컨텐츠 숨기기
export function initializeTabins() {
    document.querySelectorAll(".tabin-body").forEach(el => el.style.display = "none");

    document.querySelectorAll(".tabin").forEach(tabin => {
        const firstButton = tabin.querySelector(".tabin-header button");
        if (firstButton) {
            firstButton.classList.add("active");
            document.getElementById(firstButton.getAttribute("rel")).style.display = "block";
        }

        tabin.querySelectorAll(".tabin-header button").forEach(button => {
            button.addEventListener("click", () => {
                tabin.querySelectorAll(".tabin-header button").forEach(btn => btn.classList.remove("active"));
                button.classList.add("active");

                const activeTab = button.getAttribute("rel");
                tabin.querySelectorAll(".tabin-body").forEach(tab => tab.style.display = "none");
                document.getElementById(activeTab).style.display = "block";
            });
        });
    });
}

// 펼쳐보기
export function initializeAccordion() {
    document.querySelectorAll(".accordion").forEach(acc => {
        acc.addEventListener("click", () => {
            acc.classList.toggle("on");
            document.querySelectorAll(".accgrap").forEach(accgrap => {
                accgrap.style.display = acc.classList.contains("on") ? "none" : "block";
            });
        });
    });
}

// 커스텀 selectbox
export function initializeCustomSelectbox() {
    document.addEventListener('click', event => {
        const target = event.target;
        if (target.matches('.designSelect')) {
            target.classList.toggle('on');
        } else if (target.matches(".lists a")) {
            const ht = target.innerHTML;
            target.closest(".designSelect").querySelector("strong a").innerHTML = ht;
        }
    });
}

// 예문 안에 아무것도 없을 때 hidden 클래스 추가
export function hideEmptyWraps() {
    document.querySelectorAll('.q_wrap').forEach(wrap => {
        if (wrap.innerHTML.trim() === '') {
            wrap.classList.add('hidden');
        }
    });
}

// 파일찾기 커스텀
export function initializeFileCustom() {
    document.querySelectorAll("#file1, #file2, #file3, #file4").forEach(fileInput => {
        fileInput.addEventListener('change', () => {
            const fileName = fileInput.value;
            document.querySelector(`.upload-name${fileInput.id.slice(-1)}`).value = fileName;
        });
    });
}

// 초기화 함수 호출
export function initializeCommonFeatures() {
    initializeCheckboxes();
    initializeTabs();
    initializeTabins();
    initializeAccordion();
    initializeCustomSelectbox();
    hideEmptyWraps();
    initializeFileCustom();
}
