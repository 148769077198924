import QuestionContainer from '../../../components/question/QuestionContainer';

const Questions = ({ exams, currentSlide, setCurrentSlide }) => {

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };
  const handleNext = () => {
    if (currentSlide < exams.length - 1) {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const currentQuestion = exams[currentSlide];


  if (currentQuestion) {
    return (
        <>
          <div className="question_list_wrap">
            <ul className="slick-dots" style={{ display: 'flex' }} role="tablist">
              {exams.map((_, index) => (
                <li key={index} className={index === currentSlide ? 'slick-active' : ''} role="presentation">
                  <button
                    type="button"
                    role="tab"
                    aria-controls={`slick-slide0${index}`}
                    aria-label={`${index + 1} of ${exams.length}`}
                    tabIndex={index === currentSlide ? 0 : -1}
                    aria-selected={index === currentSlide}
                    onClick={() => setCurrentSlide(index)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
    
          <div id="q_slide" className="slick-initialized slick-slider slick-dotted">
            <button className="slick-prev slick-arrow" aria-label="Previous" type="button" onClick={handlePrevious}>
              Previous
            </button>
            <div className="slick-list">
              <div className="slick-track" style={{ opacity: 1, width: '100%', transform: 'translate3d(0px, 0px, 0px)' }}>
                <div
                  className="slick-slide slick-current slick-active"
                  data-slick-index={currentSlide}
                  aria-hidden="false"
                  style={{ width: '100%' }}
                >
                  <div>
                    <QuestionContainer question={currentQuestion} currentQuestion={currentSlide} handleAnswerChange={() => []} />
                  </div>
                  
                </div>
              </div>
            </div>
            <button className="slick-next slick-arrow" aria-label="Next" type="button" onClick={handleNext}>
              Next
            </button>
          </div>
        </>
      );
  }
  
};

export default Questions;
