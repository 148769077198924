import { useEffect, useState } from 'react';
import QuestionTitle from './QuestionTitle';
import QuestionDetailContainer from './QuestionDetailContainer';
import QuestionContent from './QuestionContent';
import DynamicQuestionImport from './DynamicQuestionImport';
import ConnectType1Import from './ConnectType1Import';
import ConnectType2Import from './ConnectType2Import';
import { useOn2024ComplexContext } from '../../common/Context';

function QuestionContainer({ grading, answers, currentQuestion, question, handleAnswerChange }) {
    const [ detailOptions, setDetailOptions ] = useState(question?.options || []);
    const [isCustom, setIsCustom] = useState(false);
    const [key, setKey] = useState(0);  // 새로운 state를 추가합니다.'
    const { location } = useOn2024ComplexContext();
    const newClassName = `q_content${grading === 'Y' ? ' grade_check true' : grading === 'N' ? ' grade_check wrong' : ''}`

    useEffect(() => {
      setDetailOptions(question?.options || []);

      const fetchHtmlFile = async () => {
        try {
          const test = await fetch(`/html/custom_type/${question?.exam_file_code}.html`);
          setIsCustom(test.ok);
        } catch (error) {
        }
      }

      fetchHtmlFile();
      setKey(prevKey => prevKey + 1);  // examFileCode가 변경될 때마다 key를 변경합니다.
    }, [question]);

    return (
      isCustom ? (
        <>
          {(question?.qtype !== 5) && 
            <DynamicQuestionImport 
              grading={grading}
              question={question}
              detailOptions={detailOptions} 
              examFileCode={question?.exam_file_code} 
              answers={answers} 
              currentQuestion={currentQuestion} 
              handleAnswerChange={handleAnswerChange} 
            />
          }
          {(question?.qtype === 5 && (question?.detail_type === "type1" || question?.detail_type === "type3")) && 
            <ConnectType1Import 
              key={`${key}${question?.detail_type}`}  // key prop을 추가합니다.
              grading={grading}
              question={question}
              examFileCode={question?.exam_file_code} 
              answers={answers} 
              currentQuestion={currentQuestion} 
              handleAnswerChange={handleAnswerChange} 
            />
          }
          {(question?.qtype === 5 && question?.detail_type === "type2") && 
            <ConnectType2Import 
              key={`${key}${question?.detail_type}`}  // key prop을 추가합니다.
              grading={grading}
              question={question}
              examFileCode={question?.exam_file_code} 
              answers={answers} 
              currentQuestion={currentQuestion} 
              handleAnswerChange={handleAnswerChange} 
            />
          }
        </>
      ) : (
        location.pathname === '/publishing' ? (
          <div className="question" id="question">
            <div className={newClassName} id={question?.exam_file_code}>
          <QuestionTitle question={question} currentQuestion={currentQuestion} />

          <div className="q_layout">
              <QuestionContent question={question} />
                    
            {!!detailOptions.length && 
              <QuestionDetailContainer 
                answers={answers} 
                currentQuestion={currentQuestion} 
                detailOptions={detailOptions} 
                qType={question?.qtype} 
                detailType={question?.detail_type} 
                handleAnswerChange={handleAnswerChange} 
              />
            }
          </div>
        </div>
          </div>
        ) : (
          <div className={newClassName}  id={question?.exam_file_code}>
            <QuestionTitle question={question} currentQuestion={currentQuestion} />

            <div className="q_layout">
                <QuestionContent question={question} />
                      
              {!!detailOptions.length && 
                <QuestionDetailContainer 
                  answers={answers} 
                  currentQuestion={currentQuestion} 
                  detailOptions={detailOptions} 
                  qType={question?.qtype} 
                  detailType={question?.detail_type} 
                  handleAnswerChange={handleAnswerChange} 
                />
              }
            </div>
          </div>
        )
      )
  );
}

export default QuestionContainer;