import React from 'react';

const GradeSelection = ({ grade, semester, onChange }) => {
  const grades = [1, 2, 3, 4, 5, 6];
  const semesters = [1, 2];

  return (
    <li className="create__item num1">
      <span className="item__title"><em className="number">1</em>학년</span>
      <div className="item__box">
        <div className="select_wrap">
          <select
            className="select"
            id="gradeSelect"
            value={grade}
            onChange={(e) => onChange('grade', e.target.value)}
            disabled
          >
            <option value="">선택</option>
            {grades.map((g) => (
              <option key={g} value={g}>{g}</option>
            ))}
          </select>
          <label htmlFor="gradeSelect">학년</label>
        </div>
        <div className="select_wrap">
          <select
            className="select"
            id="semesterSelect"
            value={semester}
            onChange={(e) => onChange('semester', e.target.value)}
          >
            <option value="">선택</option>
            {semesters.map((s) => (
              <option key={s} value={s}>{s}</option>
            ))}
          </select>
          <label htmlFor="semesterSelect">학기</label>
        </div>
      </div>
    </li>
  );
};

export default GradeSelection;