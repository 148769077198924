import { useEffect, useState, useCallback } from "react";
import {v4 as uuidv4} from 'uuid';

const useMultipleAnswers = (dropAreaId, initialAnswers, handleDrop) => {
    const [ droppedItems, setDroppedItems ] = useState(initialAnswers || []);

    useEffect(() => {
        setDroppedItems(initialAnswers);
    }, [dropAreaId]);


    useEffect(() => {
        handleDrop(dropAreaId, droppedItems);
    }, [droppedItems]);

    const addUniqueDragItem = (item) => {
        setDroppedItems(prevAnswers => {
            if (!prevAnswers.includes(item)) {
                return [...prevAnswers, item];
            }
            return prevAnswers;
        });
    }
    const addSingleDragItem = (item) => {
        setDroppedItems([item]);
    }
    const addDuplicateDragItem = (item) => {
        setDroppedItems(prevAnswers => {
            return [...prevAnswers, {...item, uuid: uuidv4()}];
        });
    }
    const removeClickedItem = (item) => {
        setDroppedItems(prevAnswers => {
            return prevAnswers.filter((prev) => prev.uuid !== item.uuid);
        });
    }

    const resetDroppedItems = useCallback(() => {
        setDroppedItems(initialAnswers);
    }, [initialAnswers]);

    return { droppedItems, addSingleDragItem, addUniqueDragItem, addDuplicateDragItem, removeClickedItem, resetDroppedItems };
}

export default useMultipleAnswers;