import { useOn2024ComplexContext } from "../../common/Context";
import DynamicImage from "../DynamicImage";

function QuestionContent({ question, children }) {
    const { location } = useOn2024ComplexContext();
    const processedContent = question?.qcontent?.replaceAll('\n', '<br />').replaceAll('\r', '&nbsp;') || ''
    const contentImages = question?.qcontent_imgs && (question?.qcontent_imgs?.split(',') || [question?.qcontent_imgs]) || []
    const contentText = question?.qcontent || ''

    const hasContent = contentText.length > 0 || contentImages.length > 0

    return (
        location.pathname === '/publishing' ? (
            <div className="question" id="question">
                {children ||
                hasContent && <div className="q_wrap">
                    <div className="examBox">
                        {/* <DynamicImage fileName={question?.qtitle_img} />
                        <div style={{ display: 'flex'}}>
                            {contentImages.map((name, idx) => (
                                <DynamicImage key={idx} fileName={name}  />
                            ))}
                        </div> */}
                        
                        <p dangerouslySetInnerHTML={{__html: processedContent }} />
                    </div>
                </div>
                }
            </div>
        ) : (
            children ||
            hasContent && <div className="q_wrap">
                <div className="examBox">
                    <p>{question?.qtitle_img}</p>
                        {/* <DynamicImage fileName={question?.qtitle_img} />
                        <div style={{ display: 'flex'}}>
                            {contentImages.map((name, idx) => (
                                <DynamicImage key={idx} fileName={name}  />
                            ))}
                        </div> */}
                    
                    <p dangerouslySetInnerHTML={{__html: processedContent }} />
                </div>
            </div>
        )
        
    )
}

export default QuestionContent;