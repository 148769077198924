import React, { useState, useEffect, useRef } from "react";
import { supabase } from "../supabaseClient";
import { useOn2024ComplexContext } from "../common/Context";
import ClassHistory from "./detailContents/sub0101/ClassHistory";

const Sub0101 = () => {
  const { teacherInfo } = useOn2024ComplexContext();
  const [selectedGrade, setSelectedGrade] = useState(teacherInfo.grade_manage);
  const [selectedClassNumber, setSelectedClassNumber] = useState(teacherInfo.class_manage);
  const [count, setCount] = useState(1);
  const [students, setStudents] = useState([]); // 유저에게 보이는 모든 학생들
  const [originalStudents, setOriginalStudents] = useState([]); // db에 존재하는 학생들
  const [newStudents, setNewStudents] = useState([]); // 아직 UI에만 존재하는 신규 학생들
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const currentYear = new Date().getFullYear();
  const studentRefs = useRef([]);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const { data, error } = await supabase
        .from("student")
        .select("*")
        .eq("delete_yn", "N")
        .eq("year", currentYear)
        .eq("teacher_id", teacherInfo.teacher_id)
        .order("student_number", { ascending: true });
      if (error) throw error;
      if (data) {
        setStudents(data);
        setOriginalStudents(JSON.parse(JSON.stringify(data)));
      }
    } catch (error) { 
      console.error("Error fetching students:", error);
    }
  };
  // '추가하기' 버튼
  const handleAddStudents = async () => {
    const nextAvailableStudentNumber = Math.max(...students.map(s => s.student_number), 0) + 1;
    const arrayStudent = Array(count).fill().map((_, index) => ({
      temp_id: `new-${Date.now()}-${index}`,
      student_name: '',
      student_number: nextAvailableStudentNumber + index,
      class_number: selectedClassNumber,
      grade: selectedGrade,
    }));
    setStudents(prev => [...prev, ...arrayStudent]);
    setNewStudents(arrayStudent);
    setCount(1);
  };

  // 학생 정보 텍스트 입력값 (이름, 비고)
  const handleInputChange = (index, field, value) => {
    const updatedStudents = students.map((student, i) =>
      i === index ? { ...student, [field]: value } : student
    );
    setStudents(updatedStudents);
  };

  // 개별 삭제 - 휴지통 버튼
  const handleDelete = async (student) => {
    // 아직 DB에 저장되지 않은 UI상의 신규학생일 경우 UI에서 삭제
    if (student.temp_id) {
      setStudents(prev => prev.filter(s => s.temp_id !== student.temp_id));
      setNewStudents(prev => prev.filter(s => s.temp_id !== student.temp_id));
      setSelectedStudentIds(prev => prev.filter(id => id !== student.temp_id));
    } else {
      if (window.confirm('정말 삭제하시겠습니까?')) {
        try {
          const { error } = await supabase
            .from('student')
            .update({ delete_yn: 'Y' })
            .eq('student_id', student.student_id);
          if (error) throw error;

          setStudents(prev => prev.filter(s => s.student_id !== student.student_id));
          setSelectedStudentIds(selectedStudentIds.filter(id => id !== student.student_id));
        } catch (error) {
          console.error('Error deleting student:', error);
          alert('학생 삭제 중 오류가 발생했습니다.');
          return;
        }
        alert('학생이 성공적으로 삭제되었습니다.');
      }
    }
  };
  // '저장' 버튼
  const handleSaveStudents = async () => {
    const incompleteStudents = students.filter(student => !student.student_name || !student.student_name.trim());

    if (incompleteStudents.length > 0) {
      const numbers = incompleteStudents.map(student => student.student_number).join(', ');
      alert(`${numbers}번 학생의 정보가 입력되지 않았습니다.`);
      const firstIncompleteStudentIndex = students.findIndex(student => !student.student_name || !student.student_name.trim());
      if (firstIncompleteStudentIndex !== -1) {
        studentRefs.current[firstIncompleteStudentIndex].focus();
      }
      return;
    }
    // 수정될 DB 학생 ONLY
    const changedStudents = students.filter(currentStudent => {
      const originalStudent = originalStudents.find(os => os.student_id === currentStudent.student_id);
      return originalStudent &&
        (originalStudent.student_name !== currentStudent.student_name ||
        originalStudent.notice !== currentStudent.notice);
    });
    // 생성될 신규 학생 ONLY
    const createdStudents = students.filter(currentStudent => {
      const newStudent = newStudents.find(ns => ns.temp_id === currentStudent.temp_id);
      return newStudent &&
        (newStudent.student_name !== currentStudent.student_name ||
          newStudent.notice !== currentStudent.notice);
    });

    try {
      if (changedStudents.length > 0) {
        for (const student of changedStudents) {
          const { error } = await supabase
            .from('student')
            .update({
              student_name: student.student_name,
              notice: student.notice || null,
            })
            .eq('delete_yn', 'N')
            .eq('student_id', student.student_id);
          if (error) throw error;
        }
      }
      if (createdStudents.length > 0) {
        const { error } = await supabase
          .from('student')
          .insert(createdStudents.map(student => ({
            year: currentYear,
            teacher_id: teacherInfo.teacher_id,
            grade: student.grade,
            class_number: student.class_number,
            student_number: student.student_number,
            student_name: student.student_name,
            notice: student.notice || null,
          })));
          if (error) throw error;
      }
      alert('학생 정보가 성공적으로 저장되었습니다.');
      fetchStudents();
      setNewStudents([]);
    } catch (error) {
      console.error('Error saving students:', error);
      alert('학생 정보 저장 중 오류가 발생했습니다.');
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIds(students.map(student => student.student_id || student.temp_id));
    } else {
      setSelectedStudentIds([]);
    }
  };

  const handleSelectStudent = (e, studentId) => {
    if (e.target.checked) {
      setSelectedStudentIds([...selectedStudentIds, studentId]);
    } else {
      setSelectedStudentIds(selectedStudentIds.filter(id => id !== studentId));
    }
  };
  // '선택삭제' 버튼
  const handleDeleteSelected = async () => {
    if (selectedStudentIds.length === 0) {
      alert('삭제할 학생을 선택해주세요.');
      return;
    }
    
    const newStudentsToDelete = students.filter(student => selectedStudentIds.includes(student.temp_id));
    const originalStudentsToDelete = students.filter(student => selectedStudentIds.includes(student.student_id));
    const namedStudents = originalStudentsToDelete.filter(student => student.student_name && student.student_name.trim() !== '');
    const unnamedStudents = originalStudentsToDelete.filter(student => !student.student_name || student.student_name.trim() === '');

    if (namedStudents.length > 0 && window.confirm(`${namedStudents.length}명의 학생을 삭제하시겠습니까?`)) {
      try {
        const { error } = await supabase
          .from('student')
          .update({ delete_yn: 'Y' })
          .in('student_id', namedStudents.map(s => s.student_id))
        if (error) throw error;
  
        setStudents(students.filter(student => !selectedStudentIds.includes(student.student_id)));
        setSelectedStudentIds([]);
        alert('선택한 학생들이 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('Error deleting students:', error);
        alert('학생 삭제 중 오류가 발생했습니다.');
      }
    }
    if (newStudentsToDelete.length > 0) {
      setStudents(prev => prev.filter(student => !selectedStudentIds.includes(student.temp_id)));
      setNewStudents(prev => prev.filter(student => !selectedStudentIds.includes(student.temp_id)));
      setSelectedStudentIds([]);
    } 
  };

  // '취소' 버튼
  const handleCancel = () => {
    fetchStudents();
    setNewStudents([]);
    setSelectedStudentIds([]);
  };

  return (
    <>
      <div className="layout">
        <div className="class_info">
          <ul className="class_info_item num1">
            <li><i className="micon ico2"></i>{teacherInfo.schoolData.school_name}</li>
            <li>
              <div className="select_wrap">
                <select
                  className="select"
                  id="select1"
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(Number(e.target.value))}
                >
                  <option value="">선택</option>
                  {[6, 5, 4, 3, 2, 1].map(num => (
                    <option key={num} value={num.toString()}>{num}</option>
                  ))}
                </select>
                <label htmlFor="select1">학년</label>
              </div>
              <div className="select_wrap">
                <select
                  className="select"
                  id="select2"
                  value={selectedClassNumber}
                  onChange={(e) => setSelectedClassNumber(e.target.value)}
                >
                  <option value="">선택</option>
                  {[6, 5, 4, 3, 2, 1].map(num => (
                    <option key={num} value={num.toString()}>{num}</option>
                  ))}
                </select>
                <label htmlFor="select2">반</label>
              </div>
            </li>
          </ul>
          <div className="class_info_item num2">
            <i className="micon ico1"></i>
            <span>학생추가</span>
            <button type="button" className="circle_btn" onClick={() => setCount(Math.max(1, count - 1))}>
              <i className="icon ico_minus">빼기</i>
            </button>
            <input
              type="text"
              className="count_txt_input"
              maxLength="3"
              id="count_tt"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value) || 1)}
            />
            <label htmlFor="count_tt">명</label>
            <button type="button" className="circle_btn" onClick={() => setCount(count + 1)}>
              <i className="icon ico_plus">더하기</i>
            </button>
            <a className="btn btn-ani btn-primary" onClick={handleAddStudents}>추가하기</a>
          </div>
        </div>

        <div className="class_list">
          <div className="title_box">
            <strong className="subtitle">
              <i className="hico hico3">학생목록</i>
              학생목록
            </strong>
            <div className="btn_group">
              <button type="button" className="btn btn-ani btn-quaternary" onClick={handleDeleteSelected}><i className="sicon ico3"></i>선택삭제</button>
              <button type="button" className="btn btn-ani btn-secondary" onClick={handleCancel}><i className="sicon ico2"></i>취소</button>
              <button type="button" className="btn btn-ani btn-primary" onClick={handleSaveStudents}><i className="sicon ico2"></i>저장</button>
            </div>
          </div>

          <div className="table_wrap">
            <table className="tbl_basic center table-hover">
              <caption>학생 목록</caption>
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "30%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      id="allcheck"
                      name="check"
                      checked={students.length === selectedStudentIds.length && students.length > 0}
                      onChange={handleSelectAll}
                    />
                    <label htmlFor="allcheck"></label>
                  </th>
                  <th scope="col">학년</th>
                  <th scope="col">반</th>
                  <th scope="col">번호</th>
                  <th scope="col">이름</th>
                  <th scope="col">비고</th>
                  <th scope="col">삭제</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student, index) => (
                  <tr key={student.student_id || student.temp_id || index}>
                    <th scope="row">
                      <input
                        type="checkbox"
                        id={`check${student.student_id || student.temp_id || index}`}
                        name="check"
                        checked={selectedStudentIds.includes(student.student_id || student.temp_id)}
                        onChange={(e) => handleSelectStudent(e, student.student_id || student.temp_id)}
                      />
                      <label htmlFor={`check${student.student_id || student.temp_id || index}`}></label>
                    </th>
                    <td>{student.grade}</td>
                    <td>{student.class_number}</td>
                    <td>{student.student_number}</td>
                    <td>
                      <input
                        type="text"
                        className="stdName"
                        ref={(el) => studentRefs.current[index] = el}
                        value={student.student_name || ''}
                        onChange={(e) => handleInputChange(index, 'student_name', e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={student.notice || ''}
                        onChange={(e) => handleInputChange(index, 'notice', e.target.value)}
                      />
                    </td>
                    <td><a href="#" className="micon ico8" onClick={(e) => { handleDelete(student); e.preventDefault(); }}>삭제</a></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <ClassHistory />
      </div>
    </>
  );
};

export default Sub0101;
