import React from 'react';

const UnitSelection = ({ units, availableUnits, onChange }) => {
  return (
    <li className="create__item num3">
      <span className="item__title"><em className="number">3</em>출제 범위</span>
      <div className="item__box flex-col">
        <div className="unit__select_wrap">
          {!availableUnits.length && <span className="empty_tt"><i></i>과목을 선택하세요.</span>}
          {!!availableUnits.length && <ul className="unit__select">
            {availableUnits.map((unitInfo) => {
              const unit = `${unitInfo.grade_term_unit.split("-")[2]}`
              return <li key={`${unit}${unitInfo.text_book}${unitInfo.unit_name}`}>
                  <input
                    type="checkbox"
                    id={`unitkey${unit}`}
                    name="unit_check"
                    checked={units.includes(unit)}
                    onChange={(e) => {
                      const newUnits = !e.target.checked
                        ? units.filter(id => id !== unit)
                        : units.length < 3 ? [...units, unit] : units
                      onChange('units', newUnits);
                    }}
                  />
                  <label htmlFor={`unitkey${unit}`} className="unit_btn">
                    <span><em>{unitInfo.grade_term_unit.split("-")[2]} 단원</em>{unitInfo.text_book && <span class="pbName">{unitInfo.text_book}</span>}{unitInfo.unit_name}</span>
                  </label>
                </li>
              })
            }
          </ul>}
        </div>
        <p className="description">※복수 단원 선택 가능합니다.</p>
      </div>
    </li>
  );
};

export default UnitSelection;