import React, { Suspense, useEffect, useState } from 'react';

function QuestionDetailContainer({ answers, currentQuestion, detailOptions, qType, detailType, handleAnswerChange }) {
    const [LazyComponent, setLazyComponent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (qType && detailType) {
            import(`./qtype-${qType}/Q${qType}D${detailType}`)
                .then(module => {
                    setLazyComponent(() => module.default);
                    setError(null);
                })
                .catch(err => {
                    console.error('Failed to load component:', err);
                    setError('Failed to load the question component.');
                });
        } else {
            setError('Question type or detail type is undefined.');
        }
    }, [qType, detailType]);

    if (error) {
        return <div className="error">Could not load examples</div>;
    }

    return (
        <div className="a_wrap">
            <Suspense fallback={<div>Loading.....</div>}>
                {LazyComponent && (
                    <LazyComponent 
                        answers={answers} 
                        currentQuestion={currentQuestion} 
                        options={detailOptions} 
                        handleAnswerChange={handleAnswerChange} 
                    />
                )}
            </Suspense>
        </div>
    );
}

export default QuestionDetailContainer;