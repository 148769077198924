import React, { useState, useEffect } from 'react';

const DifficultySelection = ({ difficultyNum, difficulty, onChange }) => {

  const handleChange = (level, value) => {
    const numValue = Number(value);
    const newDifficulty = { ...difficulty, [level]: numValue };
    onChange('difficulty', newDifficulty);
  };

  const percentOptions = Array.from({length: 11}, (_, i) => i * 10);

  return (
    <li className="create__item num6">
      <span className="item__title"><em className="number">6</em>난이도</span>
      <div className="item__box">
        <ul className="diffic_select">
          {[
            { level: '상', key: 'high' },
            { level: '중', key: 'medium' },
            { level: '하', key: 'low' }
          ].map(({ level, key }) => (
            <li key={level}>
              <div className="diffic_item">
                <span>{level}</span>
                <select 
                  className="select no-line"
                  value={difficulty[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                >
                  {percentOptions.map((value) => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
              </div>
              <em className="pertt">%</em>
            </li>
          ))}
        </ul>
        <div className="diffic_total">
          <div className="diffic_item">
            <span>총</span>
            <input type="text" value={difficulty.high + difficulty.medium + difficulty.low} className="no-line" readOnly />
          </div>
          <em className="pertt">%</em>
        </div>
        <div className="diffic_info">
          <ul>
            <li><i className="state tx diff1">상</i><b>{difficultyNum[0]}</b>개</li>
            <li><i className="state tx diff2">중</i><b>{difficultyNum[1]}</b>개</li>
            <li><i className="state tx diff3">하</i><b>{difficultyNum[2]}</b>개</li>
          </ul>
          <span>현재 선택하신 단원의 난이도별 총 개수 입니다.</span>
        </div>
      </div>
    </li>
  );
};

export default DifficultySelection;