import React, { useState, useEffect } from 'react';
import ExamHeader from './ExamHeader';
import { initializeCommonFeatures } from '../../js/common';
import { useOn2024ComplexContext } from '../../common/Context';
import { supabase } from '../../supabaseClient';
import QuestionContainer from '../../components/question/QuestionContainer';
import { useQuery } from '@tanstack/react-query';
import ExamFooter from './ExamFooter';
import ExamFooterModalIncomplete from './ExamFooterModalIncomplete';
import ExamFooterModalComplete from './ExamFooterModalComplete';
import useAnswers from '../../js/custom-hooks/useAnswers';
import { useNavigate } from 'react-router-dom';
import useQuestions from '../../js/custom-hooks/useQuestions';

const ExamLayout = () => {
  const { location } = useOn2024ComplexContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [updatedQuestionIndexes, setUpdatedQuestionIndexes] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupCompleteOpen, setIsPopupCompleteOpen] = useState(false);
  const exam_question_setting_id = location?.state?.exam_question_setting_id || 160;
  const student_id = location?.state?.student_id || 126;
  const teacher_id = location?.state?.teacher_id || 2;
  const [examData, setExamData] = useState(null);
  const [examStartDate, setExamStartDate] = useState(0)
  const [isAllAnswer, setIsAllAnswer] = useState(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const navigate = useNavigate();

  const { questions } = useQuestions(exam_question_setting_id);
  const { answers, handleAnswers } = useAnswers(exam_question_setting_id, 'student_answer_sheet_temp');

  useEffect(() => {
    initializeCommonFeatures();
    fetchExamData();
    setUpdatedQuestionIndexes([]);
    }, []);

  // 새로 추가된 함수
  const handleTimeUp = () => {
    setIsTimeUp(true);
    modalOpen('popup_opne2');
  };

  const updateAnswers = async (type) => {
    const updatedStudentAnswers = updatedQuestionIndexes.map(index => ({
      student_id,
      teacher_id,
      question_number: index + 1,
      exam_question_setting_id,
      answer: answers[index],
      exam_question_selection_id: questions[index]?.exam_question_selection_id
    }))

    const allStudentAnswers = answers.map((ans, idx) => ({
      student_id,
      teacher_id,
      question_number: idx + 1,
      exam_question_setting_id,
      answer: ans,
      exam_question_selection_id: questions[idx]?.exam_question_selection_id
    }));

    if (type === 'submit') {
      const gradedStudentAnswers = allStudentAnswers?.map((data, idx) => ({
        ...data,
        grading: data.answer === questions[idx]?.answer ? 'Y' : 'N',
      }))

      const { data: answerSheet, error } = await supabase
        .from('student_answer_sheet')
        .insert(gradedStudentAnswers);

        if (!error) {
          await supabase
            .from('noficiation_student_question_attendance')
            .update({student_status: '4'})
            .eq('student_id', student_id)
        }
      if (error?.code === '23505') {
        setIsAlreadySubmitted(true);
      }
    }
    if (updatedQuestionIndexes.length > 0) {
      console.log('임시저장 완료')
      const { data: answerSheetTemp } = await supabase
      .from('student_answer_sheet_temp')
      .upsert(updatedStudentAnswers);
      setUpdatedQuestionIndexes([]);
    }
    
    return [];
  }

  const updateStudentStatus = async () => {
    const year = new Date().getFullYear();
    const { error } = await supabase
      .from('notification_student_question_attendance')
      .update({
        student_status: "4",
      })
      .match({
        academic_year: year,
        teacher_id,
        class_code: examData.class_code,
        subject_code: examData.subject_code,
        episode_number: examData.episode_number,
        student_id,
      });
    if(error) console.error(error)
  }

  useQuery({
    queryKey: ['saveAnswers'],
    queryFn: () => updateAnswers("presave"),
    refetchInterval: 15000, // 15초
  });

  //2024-08-29 11:37:45
  const fetchExamData = async () => {
    const year = new Date().getFullYear();
    const { data: examSettingData, error: examSettingDataError } = await supabase
      .from('exam_question_setting')
      .select('*')
      .eq('id', exam_question_setting_id)
      .eq('delete_yn', 'N')

    if (examSettingDataError) throw examSettingDataError;

    if (examSettingData?.length !== 0) {
      const examSettingDataItem = examSettingData[0]; 
      setExamData({
        grade: examSettingDataItem.grade,
        term: examSettingDataItem.term,
        units: [examSettingDataItem.unit1, examSettingDataItem.unit2, examSettingDataItem.unit3]
        .filter((unit) => unit !== null && unit !== '0').join(','),
        class_code: examSettingDataItem?.class_code,
        subject_code: examSettingDataItem?.subject,
        episode_number: examSettingDataItem?.episode_number,
      });
    }

    const { data: examStartDateData, error: examStartDateError } = await supabase
      .from('notification_exam_start')
      .select('exam_start_datetime')
      .eq('academic_year', year)
      .eq('teacher_id', teacher_id)
      .eq('subject_code', examSettingData[0]?.subject)
      .eq('episode_number', examSettingData[0]?.episode_number)
      .eq('class_code', examSettingData[0]?.class_code)

    setExamStartDate(examStartDateData[0].exam_start_datetime)
    if (examStartDateError) throw examStartDateError;
  }

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentQuestionIndex === questions.length - 1) {

      if (answers.some((ans) => ans === '' || ans === null)) {
        setIsAllAnswer(false);
      } else {
        setIsAllAnswer(true);
      }
      modalOpen('popup_opne'); // 제출 컨펌 모달
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleAnswerChange = (value) => {
    handleAnswers(currentQuestionIndex, value)
    setUpdatedQuestionIndexes(prev => {
      if (!prev.includes(currentQuestionIndex)) {
        return [...prev, currentQuestionIndex];
      }
      return prev;
    });
  };

  const modalOpen = async (modalId) => {
    if (modalId === 'popup_opne') {
      setIsPopupOpen(true);
    } else if (modalId === 'popup_opne2') {
      await updateAnswers("submit");
      await updateStudentStatus();
      setIsPopupCompleteOpen(true);

    }
  };

  const modalClose = (modalId) => {
    if (modalId === 'popup_opne') {
      setIsPopupOpen(false);
    } else if (modalId === 'popup_opne2') {
      setIsPopupCompleteOpen(false);
    }
  };

  const submitAnswers = async () => {
    modalClose('popup_opne');
    modalOpen('popup_opne2');
  };

  const handleQuestionClick = (questionIndex) => {
    setCurrentQuestionIndex(questionIndex);
    modalClose('popup_opne');
  };

  if(!questions?.length || !examData) return <div>...Loading</div>

  return (
    <div className="exam-layout" style={{overflowY: 'auto', WebkitOverflowScrolling: 'touch '}}>
      <ExamHeader
        exam_question_setting_id={exam_question_setting_id}
        examStartDateTime={examStartDate}
        questionInfo={questions[currentQuestionIndex]}
        totalQuestions={questions.length}
        currentQuestion={currentQuestionIndex}
        answers={answers}
        onQuestionClick={handleQuestionClick}  // 핸들러 전달
        onTimeUp={handleTimeUp}
      />
      <div id="question" className="question">
        <QuestionContainer
          answers={answers}
          currentQuestion={currentQuestionIndex}
          question={questions[currentQuestionIndex]}
          handleAnswerChange={handleAnswerChange}
        />
      </div>
      <div id="question_footer">
        <ExamFooter
          currentQuestion={currentQuestionIndex}
          questionsInfo={questions}
          goToPreviousQuestion={goToPreviousQuestion}
          goToNextQuestion={goToNextQuestion}
        />
        <ExamFooterModalIncomplete
          examData={examData}
          isPopupOpen={isPopupOpen}
          modalClose={modalClose}
          submitAnswers={submitAnswers}
          answers={answers}
          isAllAnswer={isAllAnswer}
          onQuestionClick={handleQuestionClick}
        />
        <ExamFooterModalComplete
          examData={examData}
          isPopupCompleteOpen={isPopupCompleteOpen}
          modalClose={modalClose}
          isAlreadySubmitted={isAlreadySubmitted}
        />
      </div>
    </div>
  );
};

export default ExamLayout;
