import { set } from "date-fns";
import QuestionContainer from "./components/question/QuestionContainer";
import { supabase } from "./supabaseClient";
import { useEffect, useState, useRef } from "react";
import { qtype } from "./enum/enum";
import QuestionFooter from "./components/QuestionFooter";

function Publishing() {
    const [ questions, setQuestions ] = useState([]);
    const [ examFileCode, setExamFileCode ] = useState('');
    const [ selectedQType, setSelectedQType ] = useState(questions[0]?.qtype || '1');
    const [ selectedDetailType, setSelectedDetailType ] = useState(questions[0]?.detail_type || 'type1');
    const [ currentQuestion, setCurrentQuestion ] = useState(0);

    useEffect(() => {
        fetchQuestions();
    }, [selectedQType, selectedDetailType]);

    const buttonConfig = [{
        qtype: qtype['라디오버튼'],
        numOfTypes: 10,
    }, {
        qtype: qtype['체크박스'],
        numOfTypes: 10,
    }, {
        qtype: qtype['핫스폿'],
        numOfTypes: 24,
    }, {
        qtype: qtype['끌어놓기'],
        numOfTypes: 13,
    }, {
        qtype: qtype['짝연결하기'],
        numOfTypes: 4,
    }, {
        qtype: qtype['아래로펼치기'],
        numOfTypes: 6,
    }, {
        qtype: qtype['단답입력'],
        numOfTypes: 10,
    }, {
        qtype: qtype['서술입력'],
        numOfTypes: 3,
    }]

    const fetchQuestions = async () => {
        if (examFileCode) {
            const { data } = await supabase
            .from('exam_question_bank')
            .select()
            .eq('exam_file_code', examFileCode.trim())
            if (data?.length) {
                setQuestions(data)
                data[0].qtype && setSelectedQType(data[0].qtype)
                data[0].detail_type && setSelectedDetailType(data[0].detail_type)
                setCurrentQuestion(0)
            } else {
                setQuestions([])
            }
        } else {
            const { data } = await supabase
            .from('exam_question_bank')
            .select()
            .eq('qtype', selectedQType)
    
            if (data?.length) {
                setQuestions(data)
                data[0].examFileCode && setExamFileCode(data[0].examFileCode)
                setCurrentQuestion(0)
            } else {
                setQuestions([])
            }
        }
        
    }




    console.log({ examFileCode, selectedQType, selectedDetailType })
    const handleText = (e) => {
        setExamFileCode(e.target.value)
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchQuestions();
        }
    }

    return (
        <div className="exam-layout">
            <TempHeader examFileCode={examFileCode} setExamFileCode={setExamFileCode}selectedQType={selectedQType} setSelectedQType={setSelectedQType} selectedDetailType={selectedDetailType} handleText={handleText} handleKeyPress={handleKeyPress} question={questions?.[currentQuestion]} />
            <QuestionContainer answers={[]} question={questions?.[currentQuestion]} currentQuestion={currentQuestion} handleAnswerChange={() => []} />
            <QuestionFooter setCurrentQuestion={setCurrentQuestion} />
        </div>
    );
}













function TempHeader({ examFileCode, setExamFileCode, selectedQType, setSelectedQType,selectedDetailType, handleText, handleKeyPress, question }) {
    const inputRef = useRef(null);

    const handleClick = () => {
        setExamFileCode('');
        if (inputRef.current) {
          inputRef.current.focus(); // Focus on the input when the button is clicked
        }
    };
    const handleChange = (e) => {
        setSelectedQType(e.target.value);
        setExamFileCode('');
    };

    return (
        <div id="question_header">
            <header>
                <button type="button" className="bn_close bk">나가기</button>
                <div className="hd_subject">
                <h2 className="ico_logo type3">온평가</h2>
                <strong>과목</strong>
                {/* <span className="state tx">0학년 0학기 0단원</span> */}
                <p>속성</p>
                <input ref={inputRef} style={{ width: "128px"}} value={examFileCode} onChange={handleText} onKeyDown={handleKeyPress} />
                <button className="btn" style={{ color: 'white', margin: 0, padding: 0 }} onClick={handleClick}>X</button>
                <select style={{color: 'white'}} value={selectedQType} onChange={handleChange}>
                    <option style={{color: 'black'}} value={1}>1-라디오</option>
                    <option style={{color: 'black'}} value={2}>2-체크</option>
                    <option style={{color: 'black'}} value={3}>3-핫스폿</option>
                    <option style={{color: 'black'}} value={4}>4-끌어</option>
                    <option style={{color: 'black'}} value={5}>5-연결</option>
                    <option style={{color: 'black'}} value={6}>6-펼쳐</option>
                    <option style={{color: 'black'}} value={7}>7-단답</option>
                    <option style={{color: 'black'}} value={8}>8-서답</option>
                </select>
                <span>{question?.detail_type}</span>
                {question && <span>{question.exam_file_code}</span>}
                </div>

                <div className="q_info">
                <div className="conut_wrap">
                    <span className="time">
                    <i className="sicon ico_time">시계</i><em id="remain_time">00분 00초</em>
                    </span>
                    <span className="count">
                    <em>1</em> / 3 문항
                    </span>
                </div>

                <div className="question_list_wrap">
                    <span>문제풀이현황</span>

                    <input type="checkbox" id="toggle" />
                    <label htmlFor="toggle" className="toggleSwitch"><span className="toggleButton"></span></label>

                    <div className="question_list_box">
                    <div className="title">
                        <strong>전체문항</strong>
                        <ul>
                        <li>답변완료</li>
                        <li>미답변</li>
                        <li>현재위치</li>
                        </ul>
                    </div>
                    <ul className="question_list">
                        {Array.from({ length: 25}, () => Math.floor(Math.random() * 100)).map((_, index) => (
                        <li key={index}>
                            <a
                            href="#"
                            className="qnum"
                            >
                            1
                            </a>
                        </li>
                        ))}
                    </ul>

                    <span><b>00</b>문제 남았어요.</span>
                    </div>
                </div>
                </div>
            </header>
            {/* {isPaused && (
                <div className="exam-overlay">
                <div className="overlay-content">
                    시험이 일시 중지되었습니다. 잠시만 기다려 주세요.
                </div>
                </div>
            )} */}
            </div>
    )
}

export default Publishing;