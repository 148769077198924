function ExamFooterModalIncomplete({ examData, isPopupOpen, modalClose, submitAnswers, answers, isAllAnswer, onQuestionClick }) {
    return (
        <div className={`modal modal-popup question_modal ${isPopupOpen ? 'active' : ''}`} id="popup_opne">
            <div className="modal-background is-click-disabled"></div>
            <div className="modal-content">
                <div className="popup-wrap">
                <span className="flag"><span>{`${examData.units}단원`}</span></span>
                <div className="con-head">
                    <h3><i className="micon ico2"></i>{`${examData.grade}학년 ${examData.term}학기`}</h3>
                </div>
                <div className="con-wrap">
                    <strong>
                    {!isAllAnswer && (
                        <>
                        <em>미답변</em> 문항이 있습니다. <br />
                        </>
                    )}
                    그대로 답안을 제출하시겠습니까?
                    </strong>
                    {!isAllAnswer && (
                    <div className="no_answer">
                        <span>미답변</span>
                        <ul className="no_answer_list">
                        {answers.map((answer, index) =>
                            (answer === '' || answer === null) ? 
                                <li key={index} className="no_answer_item" onClick={() => onQuestionClick(index)} style={{ cursor: 'pointer'}}>{index + 1}</li> : null
                        )}
                        </ul>
                    </div>
                    )}
                    <div className="btn_group">
                    <button type="button" className="btn btn-ani btn-secondary" onClick={() => modalClose('popup_opne')}>
                        제출취소
                    </button>
                    <a className="btn btn-ani btn-primary" onClick={submitAnswers}>
                        제출하기
                    </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ExamFooterModalIncomplete;