import { DivideIcon } from "lucide-react";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// 특정 클래스명을 가진 div에 스타일 적용
const CommonStyleDiv = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    background: #fff;
`;

const Type1 = ({ list, left, right, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue); // 부모 컴포넌트에 선택된 값을 전달
  };

  return (
    <div className="hsradioWrap">
      <div>
        <span>{list}</span>
        <input
          type="radio"
          name="hsexam"
          id="hsexam1"
          value={left}
          onChange={handleOptionChange}
          checked={selectedOption === left}
        />
        <label htmlFor="hsexam1">{left}</label>
        <input
          type="radio"
          name="hsexam"
          id="hsexam2"
          value={right}
          onChange={handleOptionChange}
          checked={selectedOption === right}
        />
        <label htmlFor="hsexam2">{right}</label>
      </div>
    </div>
  );
};

// 핫스폿 type2 리스트 형태
const Type2 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <ul className="hsradioWrap type2">
      {options.map((option, index) => (
        <li key={index}>
          <input
            type="radio"
            name="hsexam2"
            id={`example${index}`}
            value={option.label}
            onChange={handleOptionChange}
            checked={selectedOption === option.label}
          />
          <label htmlFor={`example${index}`}>{option.label}</label>
          <img src={option.imgSrc} alt={option.label} />
          <span>{option.description}</span>
        </li>
      ))}
    </ul>
  );
};

const type2Options = [
  {
    label: "수아",
    imgSrc: "/images/q_common/hotspot_eximg1.png",
    description: "내가 좋아하는 음식과 싫어하는 음식을 기준으로 분류했어.",
  },
  {
    label: "지나",
    imgSrc: "/images/q_common/hotspot_eximg2.png",
    description: "쓰레기 종류를 기준으로 캔류, 병류, 비닐류, 종이류로 분류했어.",
  },
  {
    label: "휘민",
    imgSrc: "/images/q_common/hotspot_eximg3.png",
    description: "쓰레기 모양을 기준으로 둥근 것과 네모난 것으로 분류했어.",
  },
];

// 핫스폿 type3 카드 형태
const Type3 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <ul className="hsradioWrap type3">
      {options.map((option, index) => (
        <li key={index}>
          <input
            type="radio"
            name="hsradioexamT3"
            id={`hsradioexamT3_${index}`}
            value={option.label}
            onChange={handleOptionChange}
            checked={selectedOption === option.label}
          />
          <label htmlFor={`hsradioexamT3_${index}`}>
            <div className="cardBox photo" style={{ alignItems: 'center', gap: '1rem', padding: '22px', maxWidth: 'unset'}}>
              <div className="photoBox">
                <img src={option.imgSrc} alt={option.label} />
              </div>
              <p>{option.description}</p>
            </div>
          </label>
        </li>
      ))}
    </ul>
  );
};


// Type3 예제 옵션들
const type3Options = [
  {
    label: "1",
    imgSrc: "/images/q_common/cardbox_eximg1.png", // 여기에 이미지 경로를 맞춰주세요.
    description: "기름 13L로 221km를 갈 수 있는 가 자동차",
  },
  {
    label: "2",
    imgSrc: "/images/q_common/hotspot_eximg5.png", // 여기에 이미지 경로를 맞춰주세요.
    description: "기름 18L로 342km를 갈 수 있는 나 자동차",
  },
  {
    label: "3",
    imgSrc: "/images/q_common/hotspot_eximg5.png", // 여기에 이미지 경로를 맞춰주세요.
    description: "기름 13L로 221km를 갈 수 있는 가 자동차",
  },
];



// 핫스폿 type4 문장형
const Type4 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type4">
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <input
            type="radio"
            name="hsradioexamT4"
            id={`hsradioexamT4_${index}`}
            value={option.label}
            onChange={handleOptionChange}
            checked={selectedOption === option.label}
          />
          <label htmlFor={`hsradioexamT4_${index}`} className="noBox">
            <u>{option.text}</u>
          </label>
          {index < options.length - 1 && (
            <span>&nbsp;{option.separator}&nbsp;</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

// Type4 예제 옵션들
const type4Options = [
  { label: "1", text: "①의사 결정을 할 때 모든 사람의 의견이 일치하기는 어렵다.", separator: "" },
  { label: "2", text: "②다수의 의견이 소수의 의견보다 항상 옳기 때문에 다수의 의견을 따르는 방법이다.", separator: "다수결의 원칙은" },
  { label: "3", text: "③다수결 원칙을 사용하면 의견을 빠르고 쉽게 결정할 수 있으며,", separator: "" },
  { label: "4", text: "④많은 사람의 의견을 따라 결정을 한 것이라서 불만이 상대적으로 적다.", separator: "" },
  { label: "5", text: "⑤소수의 의견도 존중해야 한다.", separator: "하지만 다수결의 원칙으로 결정을 하더라도" },
];



// Type5 문장형 왼쪽 정렬, 중앙배치
const Type5 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type5">
      <strong className="sub_tt">식물은 어떻게 자랄까?</strong>
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <span>
            {option.textBefore}
            <input
              type="radio"
              name="hsradioexamT5"
              id={`hsradioexamT5_${index}`}
              value={option.label}
              onChange={handleOptionChange}
              checked={selectedOption === option.label}
            />
            <label htmlFor={`hsradioexamT5_${index}`} className="noBox">
              <u>{option.text}</u>
            </label>
            {option.textAfter}
          </span>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

// Type5 예제 옵션들
const type5Options = [
  { label: "1", textBefore: "덩굴손이", text: "①꼬불꼬불", textAfter: "쭈욱," },
  { label: "2", textBefore: "빙글빙글 따라가면", text: "②무엇이", textAfter: "있을까?" },
  { label: "3", textBefore: "우아,", text: "③탐스러운", textAfter: "포도가 열렸어!" },
  { label: "4", textBefore: "알맹이가 송알송알,", text: "④보랏빛", textAfter: "포도야." },
  { label: "5", textBefore: "", text: "⑤새콤달콤", textAfter: "아주 맛나." },
];




// 핫스폿 type6 이미지 위에 말풍선, 카드박스
const Type6 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type6" style={{ position: 'relative' }}>
      <div className="btn_group">
        {options.map((option, index) => (
          <div
            key={index}
            className={`btn_item num${index + 1}`}
            style={{ top: option.top, left: option.left }}
          >
            <input
              type="radio"
              name="hsradioexamT6"
              id={`hsradioexamT6_${index}`}
              value={option.text}
              onChange={handleOptionChange}
              checked={selectedOption === option.label}
            />
            <label htmlFor={`hsradioexamT6_${index}`}>
              <div className={`bubble ${option.bubblePosition}`}>
                {option.label} {option.text}
              </div>
            </label>
          </div>
        ))}
      </div>
      <img src={options[0].imgSrc} alt="Background" />
    </div>
  );
};

// Type6 예제 옵션들
const type6Options = [
  {
    label: "①",
    text: "이승만은 물러가라!",
    bubblePosition: "under",
    top: "16%",
    left: "14%",
    imgSrc: "/images/q_common/hstype6_eximg.png",
  },
  {
    label: "②",
    text: "계엄군은 광주를 떠나라!",
    bubblePosition: "under",
    top: "16%",
    left: "40%",
    imgSrc: "/images/q_common/hstype6_eximg.png",
  },
  {
    label: "③",
    text: "독재 정치 강화하라!",
    bubblePosition: "under",
    top: "16%",
    left: "68%",
    imgSrc: "/images/q_common/hstype6_eximg.png",
  },
  {
    label: "④",
    text: "유신 헌법 유지하라!",
    bubblePosition: "top",
    top: "60%",
    left: "17%",
    imgSrc: "/images/q_common/hstype6_eximg.png",
  },
  {
    label: "⑤",
    text: "대통령 간선제로 헌법을 바꾸자!",
    bubblePosition: "top",
    top: "60%",
    left: "53%",
    imgSrc: "/images/q_common/hstype6_eximg.png",
  },
];

// Type7: 시 형태
const Type7 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="sc-cBYhjr jvuPyZ">
      <div className="hsradioWrap type7 poemBox">
        <div className="txt_group">
          <strong className="sub_tt">소나기 반성문</strong>
          <span className="txtRight">송찬호</span>
        </div>
        <p className="poemTxt">
          구름 침대에서
          <input
            type="radio"
            name="hsradioexamT7"
            id="hsradioexamT7_1"
            value={options[0].text}
            onChange={handleOptionChange}
            checked={selectedOption === options[0].text}
          />
          <label htmlFor="hsradioexamT7_1" className="noBox">
            <u>{options[0].label} {options[0].text}</u>
          </label>
          뛰어놀지 않기<br />
          구름 배게 서로 집어 던지지 않기<br />
          천둥처럼 문
          <input
            type="radio"
            name="hsradioexamT7"
            id="hsradioexamT7_2"
            value={options[1].text}
            onChange={handleOptionChange}
            checked={selectedOption === options[1].text}
          />
          <label htmlFor="hsradioexamT7_2" className="noBox">
            <u>{options[1].label} {options[1].text}</u>
          </label>
          닫지 않기<br />
          마른 빨래
          <input
            type="radio"
            name="hsradioexamT7"
            id="hsradioexamT7_3"
            value={options[2].text}
            onChange={handleOptionChange}
            checked={selectedOption === options[2].text}
          />
          <label htmlFor="hsradioexamT7_3" className="noBox">
            <u>{options[2].label}{options[2].text}</u>
          </label>
          밟고 다니지 않기<br />
          애호박 놀라 꼭지 떨어질라, 너른 호박 잎새로
          <input
            type="radio"
            name="hsradioexamT7"
            id="hsradioexamT7_4"
            value={options[3].text}
            onChange={handleOptionChange}
            checked={selectedOption === options[3].text}
          />
          <label htmlFor="hsradioexamT7_4" className="noBox">
            <u>{options[3].label}{options[3].text}</u>
          </label>
          몰려 다니지 않기<br /><br />
          그럼, 엄마<br />
          우리가
          <input
            type="radio"
            name="hsradioexamT7"
            id="hsradioexamT7_5"
            value={options[4].text}
            onChange={handleOptionChange}
            checked={selectedOption === options[4].text}
          />
          <label htmlFor="hsradioexamT7_5" className="noBox">
            <u>{options[4].label}{options[4].text}</u>
          </label>
          야?<br />
          가랑비처럼 숨죽여 지내야 해?
        </p>
      </div>
    </div>
  );
};

const type7Options = [
  { label: "①", text: "쿵쿵" },
  { label: "②", text: "쾅쾅" },
  { label: "③", text: "후드득후드득" },
  { label: "④", text: "우르르" },
  { label: "⑤", text: "가랑비" },
];


// Type8 숫자카드
const Type8 = ({ options, onOptionChange }) => {
  const [selectedId, setSelectedId] = useState(null);

  const handleOptionChange = (event) => {
    const selectedId = event.target.id;
    setSelectedId(selectedId);
    onOptionChange(event.target.value);
  };

  return (
    <div className="hsradioWrap type8 numcard_box">
      <ul className="numcard_tr">
        <li className="numcard_th mathsymbol mathsymbol3"></li>
        {options.headers.map((header, index) => (
          <li className="numcard_th" key={index}>{header}</li>
        ))}
      </ul>
      {options.rows.map((row, rowIndex) => (
        <ul className="numcard_tr" key={rowIndex}>
          <li className="numcard_th">{row.rowHeader}</li>
          {row.values.map((value, colIndex) => (
            <li className="numcard_td" key={colIndex}>
              <input
                type="radio"
                name="hsradioexamT8"
                id={`hsradioexamT8_${rowIndex}_${colIndex}`}
                value={value}
                onChange={handleOptionChange}
                checked={selectedId === `hsradioexamT8_${rowIndex}_${colIndex}`}
              />
              <label className="noBox" htmlFor={`hsradioexamT8_${rowIndex}_${colIndex}`}>
                {value}
              </label>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};


const type8Options = {
  headers: ["5", "6", "7", "8"],
  rows: [
    { rowHeader: "5", values: ["25", "30", "34", "40"] },
    { rowHeader: "6", values: ["30", "36", "42", "48"] },
    { rowHeader: "7", values: ["35", "42", "49", "56"] },
    { rowHeader: "8", values: ["40", "48", "56", "64"] },
  ],
};


// Type9-1  대화형
const Type9And1 = ({ options, onOptionChange }) => {
  const [selectedId, setSelectedId] = useState(null);

  const handleOptionChange = (event) => {
    const selectedId = event.target.id;
    setSelectedId(selectedId);
    onOptionChange(event.target.value);
  };

  return (
    <div className="hsradioWrap type9">
      <div className="bgBox phone">
        <div className="bgCont">
          <ul className="chatBox">
            {options.map((option, index) => (
              <li className="chat_item" key={index}>
                <span className={`chat_img ${option.gender}`}></span>
                <input
                  type="radio"
                  name="hsradioexamT9"
                  id={`hsradioexamT9_${index}`}
                  value={option.text}
                  onChange={handleOptionChange}
                  checked={selectedId === `hsradioexamT9_${index}`}
                />
                <label htmlFor={`hsradioexamT9_${index}`}>
                  <div className="bubble">
                    <span className="bullet circle">{index + 1}</span>
                    {option.text}
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const type9And1Options = [
  {
    gender: "male1",
    text: "촛불집회는 사회 문제 해결을 평화롭게 요구하는 방법이야.",
  },
  {
    gender: "female1",
    text: "촛불집회는 사회 문제를 해결할 수 없어.",
  },
];


// type9-2 대화형 좌우
const Type9And2 = ({ options, onOptionChange }) => {
  const [selectedId, setSelectedId] = useState(null);

  const handleOptionChange = (event) => {
    const selectedId = event.target.id;
    setSelectedId(selectedId);
    onOptionChange(event.target.value);
  };

  return (
    <div className="hsradioWrap type9">
      <div className="bgBox phone">
        <div className="bgCont">
          <ul className="chatBox type2">
            {options.map((option, index) => (
              <li className={`chat_item ${option.position}`} key={index}>
                <span className={`chat_img ${option.gender}`}></span>
                <input
                  type="radio"
                  name="hsradioexamT9_2"
                  id={`hsradioexamT9_2_${index}`}
                  value={option.text}
                  onChange={handleOptionChange}
                  checked={selectedId === `hsradioexamT9_2_${index}`}
                />
                <label htmlFor={`hsradioexamT9_2_${index}`}>
                  <div className="bubble">
                    <span className="bullet circle">{index + 1}</span>
                    {option.text}
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const type9And2Options = [
  {
    position: "left",
    gender: "male1",
    text: "촛불집회는 사회 문제 해결을 평화롭게 요구하는 방법이야.",
  },
  {
    position: "right",
    gender: "female1",
    text: "촛불집회는 사회 문제를 해결할 수 없어.",
  },
];

// Type10 보고서 형식
const Type10 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type10">
      <div className="txt_group">
        <strong className="sub_tt"><span className="blankBox sm"></span> 의 특징 알기</strong>
        <strong className="sub_tt"><span className="blankBox sm"></span> : 지구의 모습을 아주 작게 줄여 나타낸 형</strong>
      </div>
      <ul>
        {options.map((option, index) => (
          <li key={index}>
            <input
              type="radio"
              name="hsradioexamT10"
              id={`hsradioexamT10_${index}`}
              value={option.text}
              onChange={handleOptionChange}
              checked={selectedOption === option.text}
            />
            <label htmlFor={`hsradioexamT10_${index}`} className="noBox">
              <u>{option.label} {option.text}</u>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const type10Options = [
  {label:'①',  text: "축소와 확대가 가능하다." },
  {label:'②',  text: "세계의 모습을 한눈에 볼 수 있다." },
  {label:'③',  text: "가지고 다니기 편리하다는 장점이 있다." },
  {label:'④',  text: "평면에 나타났기 때문에 실제와 다른 점도 있다." },
  {label:'⑤',  text: "대부분 위선과 경선이 표시되어 있어 위치를 정확하게 알 수 있다." },
];


// Type11 좌우 다단
const Type11 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type11">
      <div className="inner">
        <div className="txt_group">
          <strong className="sub_tt">&lt;우리 경제생활의 변화&gt;</strong>
        </div>
        {options.slice(0, 3).map((option, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              name="hsradioexamT11"
              id={`hsradioexamT11_${index + 1}`}
              value={option.text}
              onChange={handleOptionChange}
              checked={selectedOption === option.text}
            />
            <label htmlFor={`hsradioexamT11_${index + 1}`} className="noBox">
              &nbsp;&nbsp;&nbsp;
              <u>{option.label} {option.text}</u>
            </label>
            <br />
          </React.Fragment>
        ))}
      </div>
      <div className="inner">
        {options.slice(3).map((option, index) => (
          <React.Fragment key={index + 3}>
            <input
              type="radio"
              name="hsradioexamT11"
              id={`hsradioexamT11_${index + 4}`}
              value={option.text}
              onChange={handleOptionChange}
              checked={selectedOption === option.text}
            />
            <label htmlFor={`hsradioexamT11_${index + 4}`} className="noBox">
              &nbsp;&nbsp;&nbsp;
              <u>{option.label} {option.text}</u>
            </label>
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const type11Options = [
  { label: "①", text: "우리나라는 다른 나라와 경제적으로 많은 교류를 하고 있다. 그로 인해 우리 경제 생활의 모습은 변화하였다." },
  { label: "②", text: "우리나라 사람들이 다른 나라에서 생산된 옷을 쉽게 구 할 수 있는 것은, 의생활의 변화 모습이다." },
  { label: "③", text: "과거와 비교해 우리나라의 아파트 건물 구조가 다른 나라와 많이 차이 나는 것은 주생활이 변화한 모습이다." },
  { label: "④", text: "식생활에서의 변화 모습은 다른 나라에 가지 않고도 우리나라에서 그 나라의 음식을 먹을 수 있는 것이다." },
  { label: "⑤", text: "다른 나라에서 만든 영화를 우리나라 영화관에서 관람할 수 있는 것은 여가생활이 변화한 사례이다." },
];

// Type12 검색 레이아웃
const Type12 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type12">
      <div className="searchBox">
        <span className="searchTxt">사회 공동의 문제 해결 방법</span>
        <span className="searchBtn">
          <i className="micon ico13"></i>
        </span>
      </div>
      <div className="searchCon">
        <ul>
          {options.map((option, index) => (
            <li key={index}>
              <input
                type="radio"
                name="hsradioexamT12"
                id={`hsradioexamT12_${index}`}
                value={option.text}
                onChange={handleOptionChange}
                checked={selectedOption === option.text}
              />
              <label htmlFor={`hsradioexamT12_${index}`}>
                <div className="cardBox photo flex-col">
                  <div className="photoBox">
                    <img src={option.imgSrc} alt={option.label} />
                  </div>
                  <p>
                    <span className="bullet circle">{option.label}</span>&nbsp;
                    {option.text}
                  </p>
                </div>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const type12Options = [
  {
    label: "1",
    text: "서명운동 1",
    imgSrc: "/images/q_common/cardbox_eximg3.png", // 이 경로를 실제 이미지 경로로 변경하세요.
  },
  {
    label: "2",
    text: "서명운동 2",
    imgSrc: "/images/q_common/cardbox_eximg3.png",
  },
  {
    label: "3",
    text: "서명운동 3",
    imgSrc: "/images/q_common/cardbox_eximg3.png",
  },
  {
    label: "4",
    text: "서명운동 4",
    imgSrc: "/images/q_common/cardbox_eximg3.png",
  },
  {
    label: "5",
    text: "서명운동 5",
    imgSrc: "/images/q_common/cardbox_eximg3.png",
  },
];


// Type13 이미지 위에 버튼일때만 사용
const Type13 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type13" id="hsradioexam13">
      <div className="btn_group">
        {options.map((option, index) => (
          <div
            key={index}
            className={`btn_item num${index + 1}`}
            style={{ top: option.top, left: option.left }}
          >
            <input
              type="radio"
              name="hsradioexamT13"
              id={`hsradioexamT13_${index}`}
              value={option.label}
              onChange={handleOptionChange}
              checked={selectedOption === option.label}
            />
            <label htmlFor={`hsradioexamT13_${index}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <img src={options[0].imgSrc} alt="Background" />
    </div>
  );
};

const type13Options = [
  {
    label: "1번",
    top: "12%",
    left: "17%",
    imgSrc: "/images/q_common/hstype13_eximg.png", // 실제 이미지 경로로 변경하세요.
  },
  {
    label: "2번",
    top: "21%",
    left: "36%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "3번",
    top: "38%",
    left: "13%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "4번",
    top: "18%",
    left: "73%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "5번",
    top: "51%",
    left: "86%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
];


// Type14 카드형 안에서 선택
const Type14 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <ul className="hsradioWrap type14">
      {options.map((option, index) => (
        <li className="cardBox" key={index}>
          <span className={`chat_img blue ${option.gender}`}></span>
          <p>
            {option.text}
            <input
              type="radio"
              name="hsradioexamT14"
              id={`hsradioexamT14_${index}`}
              value={option.value}
              onChange={handleOptionChange}
              checked={selectedOption === option.value}
            />
            <label htmlFor={`hsradioexamT14_${index}`} className="noBox">
              <u> {option.label} {option.choice}</u>
            </label>
          </p>
        </li>
      ))}
    </ul>
  );
};

const type14Options = [
  {
    gender: "female1",
    label: '①',
    text: "나는 다른 나라를 살펴볼 때 지구본이 편해.",
    value: "아무래도 가지고 다니기 쉬우니까.",
    choice: "아무래도 가지고 다니기 쉬우니까.",
  },
  {
    gender: "male1",
    label: '②',
    text: "나는 세계지도를 자주 사용해.",
    value: "실제 나라의 위치와 모양이 비교적 정확하니까.",
    choice: "실제 나라의 위치와 모양이 비교적 정확하니까.",
  },
  {
    gender: "male1",
    text: "",
    label: '③',
    value: "보통 전 세계의 모습을 한눈에 파악하고 싶을 때 사용해.",
    choice: "보통 전 세계의 모습을 한눈에 파악하고 싶을 때 사용해.",
  },
  {
    gender: "female2",
    label: '④',
    text: "아무래도 가장 간편한 것은 디지털 영상 지도 아닐까?",
    value: "다양한 기기에서 사용하기는 어렵지만 종이로 되어 있어 들고 다니기 편해.",
    choice: "다양한 기기에서 사용하기는 어렵지만 종이로 되어 있어 들고 다니기 편해.",
  },
  {
    gender: "female2",
    label: '⑤',
    text: "",
    value: "실제 지구처럼 둥근 모양이라 정확도도 높은 편이지.",
    choice: "실제 지구처럼 둥근 모양이라 정확도도 높은 편이지.",
  },
];


// Type15 테이블 안에서 선택
const Type15 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event, categoryIndex, textIndex) => {
    const newSelectedOption = { categoryIndex, textIndex };
    setSelectedOption(newSelectedOption);

    const texts = options[categoryIndex].texts[textIndex];
    onOptionChange(texts);
  };

  return (
    <div className="hsradioWrap type15">
      <div className="txt_group">
        <strong className="sub_tt">주제: 빈곤과 기아 문제</strong>
        <span className="txtRight">OO초등학교 6학년 이OO</span>
      </div>
      <table className="tbl_basic type3 center">
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '70%' }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">구분</th>
            <th scope="col">내용</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, categoryIndex) => (
            <tr key={categoryIndex}>
              <th scope="row">{option.category}</th>
              <td className="td_left">
                {option.texts.map((text, textIndex) => (
                  option.answerIndices.includes(textIndex) ? (
                    <div key={textIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <input
                        type="radio"
                        name="hsradioexamT15"
                        id={`hsradioexamT15_${categoryIndex}_${textIndex}`}
                        value={text}
                        onChange={(event) => handleOptionChange(event, categoryIndex, textIndex)}
                        checked={selectedOption && selectedOption.categoryIndex === categoryIndex && selectedOption.textIndex === textIndex}
                        style={{ marginRight: '8px' }}
                      />
                      <label
                        htmlFor={`hsradioexamT15_${categoryIndex}_${textIndex}`}
                        className="noBox"
                        style={{ cursor: 'pointer' }}
                      >
                        <u>{option.label[option.answerIndices.indexOf(textIndex)]} {text}</u>
                      </label>
                    </div>
                  ) : (
                    text
                  )
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


// category 영역별로 답의 index를 지정해서 사용
const type15Options = [
  {
    category: "원인",
    texts: ["전쟁이 일어났기 때문이다.", "홍수나 태풍, 지진 등으로 집과 일터를 잃었기 때문이다."],
    label: ["①"],
    answerIndices: [1] // 두 번째 항목이 답
  },
  {
    category: "문제점",
    texts: ["어린이들이 돈을 벌기 위해 일을 해야 해서 교육을 받지 못한다.", "충분한 영양분을 공급받지 못하여 정신과 신체의 발달이 저해된다."],
    label: ["②", "③"],
    answerIndices: [0, 1] // 첫 번째와 두 번째 항목이 답
  },
  {
    category: "해결을 위한 노력",
    texts: ["모금 활동을 하고 식량, 의류 등을 지원한다.", "어린이들이 노동 대신 교육을 받을 수 있도록 지원한다.", "지구촌의 다양한 문화를 소개하고 체험할 수 있는 행사를 연다."],
    label: ["④", "⑤"],
    answerIndices: [1,2] // 두 번째와 세 번째 항목이 답
  },
];

// Type16 이미지 위에 투명 버튼
const Type16 = ({ options, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type16">
      <div className="btn_group">
        {options.map((option, index) => (
          <div
            key={index}
            className={`btn_item num${index + 1}`}
            style={{ left: option.left, bottom: option.bottom }}
          >
            <input
              type="radio"
              name="hsradioexamT16"
              id={`hsradioexamT16_${index + 1}`}
              value={option.label}
              onChange={handleOptionChange}
              checked={selectedOption === option.label}
            />
            <label style={{width: '6rem', height: '3.5rem', border: 'none' , background: 'transparent', fontSize: '0', textIndent: '-999px' } } htmlFor={`hsradioexamT16_${index + 1}`}>{option.label}</label>
          </div>
        ))}
      </div>
      <img src={options[0].imgSrc} alt="Background" />
    </div>
  );
};

// Type16 예제 옵션들
const type16Options = [
  {
    label: "진우",
    left: "14%", // -1
    bottom: "20%", // 12
    imgSrc: "/images/q_common/hstype16_eximg.png",
  },
  {
    label: "서현",
    left: "35%",
    bottom: "20%",
    imgSrc: "/images/q_common/hstype16_eximg.png",
  },
  {
    label: "하진",
    left: "58%",
    bottom: "20%",
    imgSrc: "/images/q_common/hstype16_eximg.png",
  },
  {
    label: "도아",
    left: "77%",
    bottom: "20%",
    imgSrc: "/images/q_common/hstype16_eximg.png",
  },
];


// Type17 이미지 위에 다중선택 말풍선
// 다중선택의 경우, (,)로 다중 답을 구분하지만 보기 문장에 ,가 입력되어 있는 경우는 어떻게 대응할지 고민 필요
const Type17 = ({ options, onOptionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (text) => {
    const newText = text.replaceAll('<br>', ' ');
    const newSelectedOptions = selectedOptions.includes(newText)
      ? selectedOptions.filter((option) => option !== newText)
      : [...selectedOptions, newText];

    setSelectedOptions(newSelectedOptions);
    onOptionChange(newSelectedOptions);
  };

  return (
    <div className="hsradioWrap type17">
      <div className="btn_group">
        {options.map((option, index) => (
          <div
            key={index}
            className={`btn_item num${index + 1}`}
            style={{ top: option.top, left: option.left }}
          >
            <input
              type="checkbox"
              name="hsradioexamT17"
              id={`hsradioexamT17_${index + 1}`}
              value={option.label}
              onChange={() => handleOptionChange(option.text)}
            />
            <label htmlFor={`hsradioexamT17_${index + 1}`}>
              <div className={`bubble ${option.bubblePosition}`}>
                {option.label}
                {/* 텍스트를 줄바꿈으로 분리하여 JSX로 변환 */}
                {option.text.split('<br>').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </label>
          </div>
        ))}
      </div>
      <img src={options[0].imgSrc} alt="Background" />
    </div>
  );
};


// Type17 예제 옵션들
const type17Options = [
  {
    label: "①",
    text: "한 지역의 기후는<br>일 년마다 다르게 나타나",
    bubblePosition: "under",
    top: "5%",
    left: "0",
    imgSrc: "/images/q_common/hstype17_eximg.png",
  },
  {
    label: "②",
    text: "한 지역에서 여러 해에<br>걸쳐 나타나는 평균적인 날씨야",
    bubblePosition: "top",
    top: "42%",
    left: "12%",
    imgSrc: "/images/q_common/hstype17_eximg.png",
  },
  {
    label: "③",
    text: "기후는 위도나 지형에 영향을 받아",
    bubblePosition: "under",
    top: "0",
    left: "35%",
    imgSrc: "/images/q_common/hstype17_eximg.png",
  },
  {
    label: "④",
    text: "사람들의 의식주 생활 모습은 기후에<br>큰 영향을 받지 않아",
    bubblePosition: "top",
    top: "42%",
    left: "48%",
    imgSrc: "/images/q_common/hstype17_eximg.png",
  },
  {
    label: "⑤",
    text: "적도에서 극지방으로 갈수록<br>한대/냉대/온대/열대 기후의<br>순으로 나타나",
    bubblePosition: "top",
    top: "60%",
    left: "73%",
    imgSrc: "/images/q_common/hstype17_eximg.png",
  },
];


// Type18 이미지 위에 다중선택 버튼
const Type18 = ({ options, onOptionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (label) => {
    const newSelectedOptions = selectedOptions.includes(label)
      ? selectedOptions.filter((option) => option !== label)
      : [...selectedOptions, label];
    setSelectedOptions(newSelectedOptions);
    onOptionChange(newSelectedOptions);
  };

  return (
    <div className="hsradioWrap type18">
      <div className="btn_group">
        {options.map((option, index) => (
          <div
            key={index}
            className={`btn_item num${index + 1}`}
            style={{ top: option.top, left: option.left }}
          >
            <input
              type="checkbox"
              name="hsradioexamT18"
              id={`hsradioexamT18_${index + 1}`}
              value={option.label}
              onChange={() => handleOptionChange(option.label)}
            />
            <label htmlFor={`hsradioexamT18_${index + 1}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <img src={options[0].imgSrc} alt="Background" />
    </div>
  );
};

const type18Options = [
  {
    label: "1번",
    top: "12%",
    left: "17%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "2번",
    top: "21%",
    left: "36%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "3번",
    top: "38%",
    left: "13%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "4번",
    top: "18%",
    left: "73%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
  {
    label: "5번",
    top: "51%",
    left: "86%",
    imgSrc: "/images/q_common/hstype13_eximg.png",
  },
];

// Type19 o,x 선택
const Type19 = ({ onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onOptionChange(selectedValue);
  };

  return (
    <div className="hsradioWrap type19">
      <input
        type="radio"
        name="hsradioexamT19"
        id="hsradioexamT19_1"
        value="1"
        onChange={handleOptionChange}
        checked={selectedOption === "1"}
      />
      <label htmlFor="hsradioexamT19_1">
        <img src="/images/q_common/img_o.svg" alt="O" />
      </label>

      <input
        type="radio"
        name="hsradioexamT19"
        id="hsradioexamT19_2"
        value="2"
        onChange={handleOptionChange}
        checked={selectedOption === "2"}
      />
      <label htmlFor="hsradioexamT19_2">
        <img src="/images/q_common/img_x.svg" alt="X" />
      </label>
    </div>
  );
};

// ResultArea 컴포넌트
const ResultArea = ({ results }) => (
  <div style={{ marginTop: '20px' }}>
    <h4>결과:</h4>
    {results.map((result, index) => (
      <p key={index}>{result}</p>
    ))}
  </div>
);


const Hotspot = () => {
  const [selectedType, setSelectedType] = useState('type1');
  const [results, setResults] = useState([]);

  useEffect(() => {
    // selectedType이 변경될 때 results를 초기화
    setResults([]);
  }, [selectedType]);

  const handleOptionChange = (selectedValue) => {
    setResults([`${selectedValue}`]);
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="type-select">컴포넌트 유형 선택:</label>
        <select
          id="type-select"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="type1">Type 1</option>
          <option value="type2">Type 2</option>
          <option value="type3">Type 3</option>
          <option value="type4">Type 4</option>
          <option value="type5">Type 5</option>
          <option value="type6">Type 6</option>
          <option value="type7">Type 7</option>
          <option value="type8">Type 8</option>
          <option value="type9And1">Type 9-1</option>
          <option value="type9And2">Type 9-2</option>
          <option value="type10">Type 10</option>
          <option value="type11">Type 11</option>
          <option value="type12">Type 12</option>
          <option value="type13">Type 13</option>
          <option value="type14">Type 14</option>
          <option value="type15">Type 15</option>
          <option value="type16">Type 16</option>
          <option value="type17">Type 17</option>
          <option value="type18">Type 18</option>
          <option value="type19">Type 19</option>

        </select>
      </div>
      <CommonStyleDiv>
        {selectedType === 'type1' && (
          <Type1
            list={'가방 수에 따라 국어책의 수와 수학책의 수의 비가'}
            left={'변합니다.'}
            right={'변하지 않습니다'}
            onOptionChange={handleOptionChange} // 결과를 전달할 함수
          />
        )}
        {selectedType === "type2" && (
          <Type2 options={type2Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type3" && (
          <Type3 options={type3Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type4" && (
          <Type4 options={type4Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type5" && (
          <Type5 options={type5Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type6" && (
          <Type6 options={type6Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type7" && (
          <Type7 options={type7Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type8" && (
          <Type8 options={type8Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type9And1" && (
          <Type9And1 options={type9And1Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type9And2" && (
          <Type9And2 options={type9And2Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type10" && (
          <Type10 options={type10Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === 'type11' && (
          <Type11 options={type11Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type12" && (
          <Type12 options={type12Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type13" && (
          <Type13 options={type13Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type14" && (
          <Type14 options={type14Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type15" && (
          <Type15 options={type15Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type16" && (
          <Type16 options={type16Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type17" && (
          <Type17 options={type17Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type18" && (
          <Type18 options={type18Options} onOptionChange={handleOptionChange} />
        )}
        {selectedType === "type19" && (
          <Type19 onOptionChange={handleOptionChange} />
        )}
      </CommonStyleDiv>
      <ResultArea results={results} />
    </div>
  );
};


export default Hotspot;
