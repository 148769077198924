import React, { useEffect } from 'react';

const EnhancedRefreshPrevention = ({ message = "변경사항이 저장되지 않을 수 있습니다. 정말 페이지를 떠나시겠습니까?" }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    const handleKeyDown = (event) => {
      if ((event.ctrlKey && event.key === 'r') || event.key === 'F5') {
        event.preventDefault();
        alert('새로고침이 비활성화되어 있습니다.');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [message]);

  return null;
};

export default EnhancedRefreshPrevention;