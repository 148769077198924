const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
    const handlePageChange = (e) => {
        e.preventDefault();

        const { name } = e.target;

        if (name === 'next' && currentPage < (totalPages - 1)) {
            setCurrentPage((prev) => prev + 1);
        } else if (name === 'prev' && currentPage > 0) {
            setCurrentPage((prev) => prev - 1);
        } else if (name === 'first') {
            setCurrentPage(0);
        } else if (name === 'last') {
            setCurrentPage(totalPages - 1);
        } else if (name === 'currentPage') {
            setCurrentPage(Number(e.target.innerText) - 1);  
        } else {
            return;
        }
    };
    return (
        <div className="paginate">
            <a href="#" name="first" className="dir first" onClick={handlePageChange}>첫페이지로 이동</a>
            <a href="#" name="prev" className="dir prev" onClick={handlePageChange}>이전페이지로 이동</a>
            <ul>
                {Array(totalPages).fill().map((page, index) => (
                    <li key={index}>
                        <a href="#" name="currentPage" className={index === currentPage ? 'active' : ''} onClick={handlePageChange}>{index + 1}</a>
                    </li>
                ))}
            </ul>
            <a href="#" name="next" className="dir next" onClick={handlePageChange}>다음페이지로 이동</a>
            <a href="#" name="last" className="dir last" onClick={handlePageChange}>마지막페이지로 이동</a>
        </div>
    );
};

export default Pagination;