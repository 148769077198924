import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { teacher, student, admin } from '../enum/enum';
import AdminLogin from '../adminLogin';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setLoading(false);

      const { data: { subscription } } = supabase.auth.onAuthStateChange(
        (_event, session) => {
          setUser(session?.user ?? null);
        }
      );

      return () => subscription.unsubscribe();
    };

    getSession();
  }, []);

  //선생님 로그인
  const login = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    //setUser(data.user);  
    setUserRole(teacher);
    return data;
  };

  //관리자 로그인
  const adminLogin = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    //setUser(data.user);  
    setUserRole(admin);
    return data;
  };

  //선생님/관리자 로그아웃
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    if (!error) {
      setUser(null);
    }
  };

  const loginStudent = (studentInfo) => {
    // 여기서는 간단히 학생 정보를 저장하고 인증 상태를 변경합니다.
    setUser(studentInfo);
    setUserRole('student');
  };

  const value = {
    user,
    loading,
    login,
    adminLogin,
    logout,
    loginStudent,
    userRole,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
