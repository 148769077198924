import React, { Suspense, useEffect, useState } from "react";
import QuestionContent from "./QuestionContent";
import parse, { domToReact } from 'html-react-parser';
import Q4DropArea from './Q4DropArea';
import Q4DragItem from "./Q4DragItem";
import {v4 as uuidv4} from 'uuid';
import Q4DragClock from "./Q4DragClock";
import QuestionDetailContainer from "./QuestionDetailContainer";
import QuestionTitle from "./QuestionTitle";

function DynamicQuestionImport({ grading, question, examFileCode, answers, currentQuestion, handleAnswerChange, detailOptions }) {
    const [htmlFileAsString, setHtmlFileAsString] = React.useState('');
    const [dragOptions, setDragOptions] = useState([]);
    const [dropOptions, setDropOptions] = useState([]);
    const [ formattedAnswers, setFormattedAnswers ] = useState([]);
    const [ radioOrCheckboxOptions, setRadioOrCheckboxOptions ] = useState([]);
    const [ selectOptions, setSelectOptions ] = useState([]);
    const [ textValues, setTextValues ] = useState(answers?.[currentQuestion]?.split(',') || ['']); // 단답형, 서술형 둘다 사용되는 문자열 배열
    let textAreaIndex = -1;

    const handleDrop = (id, items) => {
        const currentDropAreaIndex = id.split('_').pop();
        const formattedAnswerString = items.map(item => item.value).join('&');

        setFormattedAnswers(prev => {
            const newFormattedAnswers = [...prev];
            newFormattedAnswers[currentDropAreaIndex] = formattedAnswerString;
            handleAnswerChange(newFormattedAnswers.join(','));
            return newFormattedAnswers;
        });
        
    }
    useEffect(() => {
        const fetchHtmlFile = async () => {
            try {
                // const response = await fetch(`/html/custom_type/${examFileCode}.html`);
                const response = await fetch(`/html/custom_type/${examFileCode}.html`);
                const html = await response.text();
                const bodyContent = html.match(/<body[^>]*>([\s\S]*?)<\/body>/i)?.[1];
                setHtmlFileAsString(bodyContent);
                
                // Parse HTML to extract drag and drop options
                const parser = new DOMParser();
                const doc = parser.parseFromString(bodyContent, 'text/html');

                // 라디오 / 체크박스 옵션 추출
                const getAllRadioOrCheckboxInputs = () => {
                    const inputs = doc.querySelectorAll('input[type="checkbox"], input[type="radio"]');
                    const labelsFor = Array.from(doc.querySelectorAll('label')).map(label => label.attributes?.for?.textContent);

                    const radioOrCheckboxOptions = Array.from(inputs).map((input, index) => {
                        const id = input.attributes?.id?.textContent || `${examFileCode}_a${index + 1}`;
                        const name = Number(input.attributes?.name?.textContent.slice(-1)) ? input.attributes?.name?.textContent : `${examFileCode}_1`
                        return ({
                            id: id,
                            value: index + 1,
                            name: name,
                            labelFor: labelsFor[index]
                        })
                        
                    });
                    return radioOrCheckboxOptions;
                }

                setRadioOrCheckboxOptions(getAllRadioOrCheckboxInputs());

                // 드래그할 수 있는 요소들을 추출
                const getAllChildren = (selector) => {
                    const elements = doc.querySelectorAll(selector);
                    const childNodesList = Array.from(elements).map(element => element.childNodes);
                    const allOptions = childNodesList.map((childNodes, index) => ({
                        uuid: uuidv4(),
                        id: `${examFileCode}_drag_${index}`,
                        value: index + 1,
                        components: Array.from(childNodes).map(node => ({
                            type: node.localName || 'text',
                            value: node.textContent.trim(),
                            src: node.attributes?.src?.value,
                            className: node.className,
                        }))
                    }));

                    return allOptions;
                }
                const draggable = getAllChildren('.draggable, draggable img');
                
                setDragOptions(draggable);

                // 드롭할 수 있는 요소들을 추출
                const extractedDropOptions = Array.from(doc.querySelectorAll('.draginput')).map((el, index) => ({
                    id: `${examFileCode}_drop_${index}`,
                    value: index + 1,
                    label: el.textContent.trim()
                }));
                setDropOptions(extractedDropOptions);


                // 아래로펼치기 (select) 옵션 추출
                const selectOptions = Array.from(doc.querySelectorAll('select')).map((select, index) => {
                    const options = Array.from(select.querySelectorAll('option')).map((option, index) => ({
                        id: `${examFileCode}_option_${index}`,
                        value: option.textContent.trim(),
                        label: option.textContent.trim()
                    }));
                    return options;
                });
                setSelectOptions(selectOptions);

            } catch (error) {
                console.error('Error fetching HTML file:', error);
                setHtmlFileAsString('');
            }
        }
        if (examFileCode) fetchHtmlFile();
        setFormattedAnswers([]);
        setTextValues(answers?.[currentQuestion]?.split(',')?.length ? answers?.[currentQuestion]?.split(',') : ['']);
    }, [examFileCode]);

    if (!examFileCode) {
        return
    }

    const handleClick = (e, currentRadioGroupIndex) => {
        const currentAnswers = answers?.[currentQuestion]?.split(',') || [];

        if (e.target.type === 'radio') {
            currentAnswers[currentRadioGroupIndex] = e.target.value;
            handleAnswerChange(currentAnswers.join(','));
        } else if (e.target.type === 'checkbox') {
            if (currentAnswers.includes(e.target.value)) {
                const newAnswers = currentAnswers.filter(answer => answer !== e.target.value);
                handleAnswerChange(newAnswers.join(','));
            } else {
                handleAnswerChange([...currentAnswers, e.target.value].join(','));
            }
        }
    }

    const handleTextInputs = (e) => {
        if (e.target.value.includes(',')) {
            alert('쉼표(,)는 입력할 수 없습니다.');
            return;
        }
        const index = e.target.id
        const newTextValues = [...textValues];
        newTextValues[index] = e.target.value;
        setTextValues(newTextValues);
        handleAnswerChange(newTextValues.join(','));
    }

    const handleClockHands = (minute) => {
        handleAnswerChange(minute)
    }

    const replaceInner = (domNode) => {
        if(grading && domNode.attribs?.class === 'q_layout' && domNode.name === 'div') {
            return (
                <div className="q_layout">
                    <ul className="question_info">
                        <li>{question.context}</li>
                        <li>난이도 {question.level}</li>
                    </ul>
                    {domToReact(domNode.children, { replace: replaceInner})}
                </div>
            )
        }
        if (domNode.attribs?.class === 'q_tit' && question?.qtitle) {
            return (
                <QuestionTitle question={question} currentQuestion={currentQuestion} />
            )
        }
        if (domNode.attribs?.class === 'txt') {
            console.log('txt', domNode)
        }
        if (domNode.attribs?.class === 'q_wrap' && domNode.children.length === 1 && domNode.children[0].type === 'text') {
            const currentClasses = domNode.attribs.class ? domNode.attribs.class.split(' ') : [];
            if (!currentClasses.includes('hidden')) {
                currentClasses.push('hidden');
            }
            domNode.attribs.class = currentClasses.join(' ');
        }
        if (domNode.attribs?.class === 'a_wrap' && domNode.children.length === 1 && domNode.children[0].type === 'text') {
            return (
                <QuestionDetailContainer 
                answers={answers} 
                currentQuestion={currentQuestion} 
                detailOptions={detailOptions} 
                qType={question?.qtype} 
                detailType={question?.detail_type} 
                handleAnswerChange={handleAnswerChange} 
              />
            )
        }
        if (domNode.attribs?.type === 'radio') {
            const foundMatch = radioOrCheckboxOptions.find(opt => opt.id === domNode.attribs.id);
            const currentRadioGroupIndex = Number(foundMatch?.name?.slice(-1)) - 1 || 0
            return (
                foundMatch && (
                    <React.Fragment key={foundMatch.uuid}>
                        <input
                            type="radio"
                            name={foundMatch.name}
                            id={foundMatch.id}
                            value={foundMatch.value}
                            checked={!!answers?.[currentQuestion]?.split(',')?.some(answer => Number(answer) === foundMatch.value)}
                            onChange={(e) => handleClick(e, currentRadioGroupIndex)}
                        />
                    </React.Fragment>
                )
            );
        }
        if (domNode.attribs?.type === 'checkbox') {
            const foundMatch = radioOrCheckboxOptions.find(opt => opt.id === domNode.attribs.id);
            return (
                foundMatch && (
                    <React.Fragment key={foundMatch.uuid}>
                        <input
                            type="checkbox"
                            name={foundMatch.name}
                            id={foundMatch.id}
                            value={foundMatch.value}
                            checked={!!answers?.[currentQuestion]?.split(',').some(answer => Number(answer) === foundMatch.value)}
                            onChange={handleClick}
                        />
                    </React.Fragment>
                )
            );
        }
        if (domNode.attribs?.class === 'dragWrap type4') {
            const clockNode = domNode.children.find(node => node.attribs?.class === 'clock');
            const clockBgNode = clockNode.children.find(node => node.attribs?.class === 'clock_bg');
            const bgSrc = clockBgNode.children.find(node => node.attribs?.src).attribs.src;
            console.log('clockbgSrcBgNode', bgSrc)
            return (
                <Q4DragClock bgSrc={bgSrc} handleClockHands={handleClockHands} />
            )
        }
        if (domNode.attribs?.class === 'dragBtnBox') {
            return (
                <div {...domNode.attribs} style={{ touchAction: 'none' }}>
                    {domNode.children.map(child => replace(child))}
                </div>
            )
        }
        if (domNode.attribs?.class === 'draggable') {
            const domNodeId = domNode.attribs.id
            const foundCurrentDragItem = dragOptions.find(opt => opt.id == domNodeId);

            return (
                foundCurrentDragItem && (
                    <React.Fragment key={foundCurrentDragItem.id}>
                        <Q4DragItem
                            item={foundCurrentDragItem}
                        />
                    </React.Fragment>
                )
            );
        }
        if (domNode.attribs?.class?.includes('draginput')) {
            const allowMultiple = domNode.attribs.class.includes('many');
            const allowDuplicate = true;

            const foundCurrentDropArea = dropOptions.find(opt => opt.id === domNode.attribs.id);
            const currentAnswerValues = answers?.[currentQuestion]?.split(',')[dropOptions.indexOf(foundCurrentDropArea)]?.split('&')?.map(each => Number(each)) || [];

            const foundDragOptions = dragOptions.filter(opt => currentAnswerValues?.includes(opt.value)) || []

            const className = domNode.attribs?.class
            const inlineStyle = domNode.attribs?.style?.split(';').reduce((acc, style) => {
                const [key, value] = style.split(':');
                acc[key] = value;
                return acc;
            }, {});
            return (
                foundCurrentDropArea ? (
                    <React.Fragment key={foundCurrentDropArea.uuid}>
                        <Q4DropArea
                            dropAreaId={foundCurrentDropArea.id}
                            dropAreaLabel={foundCurrentDropArea.label}
                            dragItems={foundDragOptions}
                            className={className}
                            style={inlineStyle}
                            allowMultiple={allowMultiple}
                            allowDuplicate={allowDuplicate}
                            handleDrop={handleDrop}
                        />
                    </React.Fragment>
                ) : (
                    null
                )
            );
        }
        if (domNode.name === 'select') {
            const foundSelectOptions = selectOptions.find(opt => opt.id === domNode.attribs.id);
            const currentSelectIndex = selectOptions.indexOf(foundSelectOptions);
            const currentAnswerValues = answers?.[currentQuestion]?.split(',')[currentSelectIndex]?.split('&') || [];

            return (
                foundSelectOptions && (
                    <React.Fragment key={foundSelectOptions.uuid}>
                        <select
                            name={foundSelectOptions.id}
                            id={foundSelectOptions.id}
                            onChange={(e) => {
                                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                                handleAnswerChange(selectedOptions.join(','), currentSelectIndex);
                            }}
                        >
                            {foundSelectOptions.map(option => (
                                <option
                                    key={option.id}
                                    value={option.value}
                                    selected={currentAnswerValues.includes(option.value)}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </React.Fragment>
                )
            );
        }
        if (domNode.name === 'input' && domNode.attribs?.type === 'text') {
            textAreaIndex++;
            const attribs = domNode.attribs
            return (
                <input key={textAreaIndex} id={textAreaIndex} value={textValues[textAreaIndex] || ''} onChange={handleTextInputs} {...attribs} />
            )
        }
        if (domNode.name === 'textarea') {
            textAreaIndex++;
            const attribs = domNode.attribs
            return (
                <textarea key={textAreaIndex} id={textAreaIndex} value={textValues[textAreaIndex] || ''} onChange={handleTextInputs} {...attribs} />
            )
        }
    };
    
    const replace = (domNode) => {
        if(domNode.attribs?.class === 'q_content' && domNode.name === 'div') {
            const newClassName = `q_content${grading === 'Y' ? ' grade_check true' : grading === 'N' ? ' grade_check wrong' : ''}`
            return (
                <div {...domNode.attribs} className={newClassName}>
                    {domToReact(domNode.children, { replace: replaceInner })}
                </div>
            );
        }
        return replaceInner(domNode);
    };

    return (
        <Suspense fallback={<div>Loading.....</div>}>
            <QuestionContent>
                {htmlFileAsString ? parse(htmlFileAsString, { replace }) : null}
            </QuestionContent>
        </Suspense>
    )
}

export default DynamicQuestionImport;