import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import PublisherModal from "./PublisherModal";
import { useOn2024ComplexContext } from "../../../common/Context";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// 예시 데이터
const subjectsData = {
  국어: [
    {
      round: 1,
      studentName: "홍길동",
      school: "충남초등학교",
      grade: 5,
      class: 1,
      number: 6,
      units: [
        {
          name: "1. 문학의 눈으로 세상 보기",
          standard: "[6국05-01]",
          standardDescription:
            "문학은 가치 있는 내용을 언어로 표현하여 아름다움을 느끼게 하는 활동임을 이해하고 문학 활동을 한다.",
          evaluationElement: "문학 작품 감상하기",
          studentCorrectRate: 80,
          regionalCorrectRate: 82,
        },
        {
          name: "2. 토론의 절차와 방법",
          standard: "[6국01-03]",
          standardDescription: "절차와 규칙을 지키며 토론한다.",
          evaluationElement: "토론 규칙 이해하기",
          studentCorrectRate: 75,
          regionalCorrectRate: 78,
        },
      ],
      chartData: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            label: "학생 정답률",
            data: [80, 75, 85, 70, 78],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "지역 평균 정답률",
            data: [82, 78, 83, 75, 80],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
    },
    // 2~5회차 데이터 (유사한 구조로 반복)
  ],
  수학: [
    {
      round: 1,
      studentName: "홍길동",
      school: "충남초등학교",
      grade: 5,
      class: 1,
      number: 6,
      units: [
        {
          name: "1. 자연수의 혼합 계산",
          standard: "[6수01-01]",
          standardDescription: "자연수의 혼합 계산의 원리를 이해하고 그 계산을 할 수 있다.",
          evaluationElement: "혼합 계산 순서 이해하기",
          studentCorrectRate: 85,
          regionalCorrectRate: 83,
        },
        {
          name: "2. 분수와 소수",
          standard: "[6수01-05]",
          standardDescription: "분수와 소수의 관계를 이해하고 크기를 비교할 수 있다.",
          evaluationElement: "분수와 소수 변환하기",
          studentCorrectRate: 78,
          regionalCorrectRate: 80,
        },
      ],
      chartData: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            label: "학생 정답률",
            data: [85, 78, 82, 76, 80],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "지역 평균 정답률",
            data: [83, 80, 81, 79, 82],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
    },
    // 2~5회차 데이터 (유사한 구조로 반복)
  ],
  사회: [
    {
      round: 1,
      studentName: "홍길동",
      school: "충남초등학교",
      grade: 5,
      class: 1,
      number: 6,
      units: [
        {
          name: "1. 우리나라의 정치 발전",
          standard: "[6사04-01]",
          standardDescription: "대한민국 정부의 수립 과정을 이해하고, 대한민국 정부 수립의 의의를 파악한다.",
          evaluationElement: "정부 수립 과정 이해하기",
          studentCorrectRate: 72,
          regionalCorrectRate: 75,
        },
        {
          name: "2. 우리나라의 경제 발전",
          standard: "[6사06-03]",
          standardDescription: "우리나라 경제의 특징을 이해하고, 경제 성장 과정에서 나타난 특징을 분석한다.",
          evaluationElement: "경제 성장 과정 분석하기",
          studentCorrectRate: 68,
          regionalCorrectRate: 70,
        },
      ],
      chartData: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            label: "학생 정답률",
            data: [72, 68, 75, 70, 73],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "지역 평균 정답률",
            data: [75, 70, 73, 72, 74],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
    },
    // 2~5회차 데이터 (유사한 구조로 반복)
  ],
  과학: [
    {
      round: 1,
      studentName: "홍길동",
      school: "충남초등학교",
      grade: 5,
      class: 1,
      number: 6,
      units: [
        {
          name: "1. 물체의 운동",
          standard: "[6과07-01]",
          standardDescription: "일상생활에서 물체의 운동을 관찰하여 속력을 정성적으로 비교할 수 있다.",
          evaluationElement: "물체의 속력 비교하기",
          studentCorrectRate: 88,
          regionalCorrectRate: 85,
        },
        {
          name: "2. 식물의 구조와 기능",
          standard: "[6과03-01]",
          standardDescription: "식물의 각 부분의 구조와 기능을 이해하고 식물의 전체적인 구조를 설명할 수 있다.",
          evaluationElement: "식물 구조 이해하기",
          studentCorrectRate: 82,
          regionalCorrectRate: 80,
        },
      ],
      chartData: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            label: "학생 정답률",
            data: [88, 82, 85, 80, 83],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "지역 평균 정답률",
            data: [85, 80, 83, 82, 84],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
    },
    // 2~5회차 데이터 (유사한 구조로 반복)
  ],
  영어: [
    {
      round: 1,
      studentName: "홍길동",
      school: "충남초등학교",
      grade: 5,
      class: 1,
      number: 6,
      units: [
        {
          name: "1. Greetings and Introductions",
          standard: "[6영01-01]",
          standardDescription: "주변의 사람, 사물, 장소를 나타내는 말을 듣고 이해할 수 있다.",
          evaluationElement: "인사말 이해하기",
          studentCorrectRate: 90,
          regionalCorrectRate: 88,
        },
        {
          name: "2. Daily Routines",
          standard: "[6영02-03]",
          standardDescription: "일상생활에 관한 자신의 의견이나 감정을 말할 수 있다.",
          evaluationElement: "일과 표현하기",
          studentCorrectRate: 85,
          regionalCorrectRate: 83,
        },
      ],
      chartData: {
        labels: ["1단원", "2단원", "3단원", "4단원", "5단원"],
        datasets: [
          {
            label: "학생 정답률",
            data: [90, 85, 88, 83, 87],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "지역 평균 정답률",
            data: [88, 83, 86, 85, 84],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
    },
    // 2~5회차 데이터 (유사한 구조로 반복)
  ],
};

const SubjectTab = () => {
  const [activeSubject, setActiveSubject] = useState("");
  const [activeRound, setActiveRound] = useState(0);
  const { selectedPublisher, setSelectedPublisher, setShowPublisherModal, showPublisherModal } = useOn2024ComplexContext();
  

  useEffect(() => {
    const subjects = Object.keys(subjectsData);
    if (subjects.length > 0) {
      setActiveSubject(subjects[0]);
      setActiveRound(0);
    }
  }, []);

  const modalOpen = useCallback(() => {
    setShowPublisherModal(true);
    document.body.classList.add("open");
  }, []);

  const modalClose = useCallback(() => {
    setShowPublisherModal(false);
    document.body.classList.remove("open");
    document.body.removeAttribute("style");
  }, []);

  const handleSubjectClick = useCallback(
    (subject) => {
      if (subject === "영어" && !selectedPublisher) {
        modalOpen();
      } else {
        setActiveSubject(subject);
        setActiveRound(0);
      }
    },
    [selectedPublisher, modalOpen]
  );

  const handlePublisherSelect = useCallback(
    (publisher) => {
      setSelectedPublisher(publisher);
      setActiveSubject("영어");
      setActiveRound(0);
      modalClose();
    },
    [modalClose]
  );

  const renderChart = useCallback(() => {
    if (!activeSubject || !subjectsData[activeSubject] || !subjectsData[activeSubject][activeRound]) {
      return null;
    }

    const options = {
      indexAxis: 'y',
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "단원별 정답률",
        },
      },
    };

    return <Bar options={options} data={subjectsData[activeSubject][activeRound].chartData} />;
  }, [activeSubject, activeRound]);

  if (!activeSubject || !subjectsData[activeSubject]) {
    return <div>Loading...</div>;
  }

  const currentData = subjectsData[activeSubject][activeRound];

  return (
    <div className="tab-body" id="tab3">
      <div className="tabin">
        <div className="tabin-header">
          {Object.keys(subjectsData).map((subject) => (
            <button
              key={subject}
              className={activeSubject === subject ? "active" : ""}
              onClick={() => handleSubjectClick(subject)}
            >
              {subject}
            </button>
          ))}
        </div>
        <div className="tabin-body">
          <div className="tabcon">
            <div className="tit_info">
              <span className="tit">
                <b>{currentData.studentName}</b>의 <b>{currentData.round}회</b>차 기록
              </span>
              <ul className="student_info">
                <li>
                  <span>학교명</span>
                  {currentData.school}
                </li>
                <li>
                  <span>학년</span>
                  {currentData.grade}
                </li>
                <li>
                  <span>반</span>
                  {currentData.class}
                </li>
                <li>
                  <span>번</span>
                  {currentData.number}
                </li>
              </ul>
            </div>
            <div className="text_info">
              <div className="chart_item_con">
                <div className="graph_wrap">{renderChart()}</div>
              </div>

              <div className="title_box">
                <strong className="subtitle">평가 문항정보</strong>
              </div>
              <table className="tbl_basic center table-hover">
                <caption>평가문항정보</caption>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">단원</th>
                    <th scope="col">성취기준</th>
                    <th scope="col">평가요소</th>
                    <th scope="col">정답률(학생)</th>
                    <th scope="col">정답률(충남)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.units.map((unit, index) => (
                    <tr key={index}>
                      <td>{unit.name}</td>
                      <td>
                        <div className="txt_tooltip_wrap">
                          <a className="tooltip">{unit.standard}</a>
                          <div className="tooltip_box">{unit.standardDescription}</div>
                        </div>
                      </td>
                      <td>{unit.evaluationElement}</td>
                      <td>{unit.studentCorrectRate}%</td>
                      <td>{unit.regionalCorrectRate}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectTab;
