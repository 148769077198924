import React, { useState, useEffect } from 'react';

const PublisherModal = ({ isOpen, onClose, onSelect }) => {
  const [selectedPublisher, setSelectedPublisher] = useState('');

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('open');
    } else {
      document.body.classList.remove('open');
    }

    return () => {
      document.body.classList.remove('open');
      document.body.removeAttribute('style');
    };
  }, [isOpen]);

  const handleConfirm = () => {
    if (selectedPublisher) {
      onSelect(selectedPublisher);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`modal type2 modal-popup ${isOpen ? 'active' : ''}`} id="modal-company">
      <div className="modal-background is-click-disabled"></div>
      <div className="modal-content lg">
        <button type="button" className="modal-close bn_close bk" onClick={onClose}></button>
        <h2>출판사 선택</h2>
        <div className="popup-wrap create_box">
          <ul className="subject__select">
            {['천재(함)', 'YBM(김)', 'YBM(최)', '동아', '대교'].map((publisher, index) => (
              <li key={publisher}>
                <input
                  type="radio"
                  id={`e_company${index + 1}`}
                  name="e_company"
                  value={publisher}
                  checked={selectedPublisher === publisher}
                  onChange={(e) => setSelectedPublisher(e.target.value)}
                />
                <label htmlFor={`e_company${index + 1}`} className="box_btn">{publisher}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="btn_group">
          <a className="btn btn-ani btn-primary lg" onClick={handleConfirm}>확인</a>
        </div>
      </div>
    </div>
  );
};

export default PublisherModal;