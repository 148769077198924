import React, { useState, useRef, useEffect, useCallback } from 'react';

const styles = `
  .container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
  .game-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    position: relative;
    z-index: 2;
    top: 50px;
    align-items: center;
  }
  .row {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin-bottom: 20px;
  }
  .item-container {
    width: 150px;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .item {
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
    position: relative;
  }
  .item-left {
    background-color: #90cdf4;
  }
  .item-center {
    background-color: #9ae6b4;
  }
  .item-right {
    background-color: #d6bcfa;
  }
  .item.selected {
    background-color: #fbd38d;
  }
  .item.connected {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .reset-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px 20px;
    background-color: #e53e3e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 3;
  }
  .connections-list {
    background-color: #fff;
    border-top: 2px solid #ddd;
    padding: 10px;
    height: 300px;
    overflow-y: auto;
  }
  .connection-item {
    background-color: #edf2f7;
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .column-count-selector {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }
  .column-count-selector button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  .column-count-selector button.active {
    background-color: #90cdf4;
    color: white;
  }
  .connection-counter {
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 3;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .duplicate-toggle {
    position: absolute;
    top: 10px;
    left: 120px;
    padding: 10px 20px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 3;
  }
  .add-remove-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .add-remove-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4a5568;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ConnectItems = () => {
  const [columnCount, setColumnCount] = useState(2);
  const [items, setItems] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isDuplicateAllowed, setIsDuplicateAllowed] = useState(true);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const longPressTimer = useRef(null);

  const getMaxConnections = useCallback(() => {
    const leftColumnCount = items.filter(item => item.column === 'left').length;
    // return leftColumnCount * (columnCount - 1); // 2열에서는 leftColumnCount * 1, 3열에서는 leftColumnCount * 2
    return leftColumnCount;
  }, [items, columnCount]);


  const getTotalConnections = useCallback((conns = connections) => {
    if (columnCount === 2) {
      return conns.length;
    } else if (columnCount === 3) {
      // 3-way 연결만 카운트, 중복 연결 방지
      const uniqueConnections = new Set();

      conns.forEach(conn => {
        // 3개의 아이템이 모두 연결된 경우만
        if (conn.items.length === 3) {
          const signature = conn.items
            .map(item => `${item.column}-${item.id}`)
            .sort()
            .join('-');
          uniqueConnections.add(signature);
        }
      });

      return uniqueConnections.size;
    }
    return 0;
  }, [connections, columnCount]);




  useEffect(() => {
    resetItems(columnCount);
  }, [columnCount]);

  useEffect(() => {
    const updateCanvasSize = () => {
      if (canvasRef.current && containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    connections.forEach(({ points }) => {
      drawLine(ctx, points, 'blue');
    });

    if (selectedItems.length > 0 && selectedItems.length < columnCount) {
      const lastSelectedItem = selectedItems[selectedItems.length - 1];
      const itemRect = document.getElementById(`item-${lastSelectedItem.column}-${lastSelectedItem.id}`).getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const startPoint = {
        x: itemRect.left + itemRect.width / 2 - containerRect.left,
        y: itemRect.top + itemRect.height / 2 - containerRect.top
      };
      drawLine(ctx, [startPoint, mousePosition], 'red');
    }
  }, [connections, selectedItems, mousePosition, columnCount]);

  const resetItems = (count) => {
    const newItems = [];
    for (let i = 1; i <= 2; i++) {
      newItems.push({ id: i, text: `A${i}`, column: 'left', connectedTo: null });
      newItems.push({ id: i, text: `B${i}`, column: 'center' });
      if (count === 3) {
        newItems.push({ id: i, text: `C${i}`, column: 'right' });
      }
    }
    setItems(newItems);
    setConnections([]);
    setSelectedItems([]);
  };

  const drawLine = (ctx, points, color) => {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    points.slice(1).forEach(point => {
      ctx.lineTo(point.x, point.y);
    });
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.stroke();
  };

  const handleItemClick = (item) => {
    if (columnCount === 2) {
      if (selectedItems.length === 0 && item.column === 'left') {
        setSelectedItems([item]);
      } else if (selectedItems.length === 1 && selectedItems[0].column === 'left' && item.column === 'center') {
        connectItems([selectedItems[0], item]);
        setSelectedItems([]);
      } else {
        setSelectedItems([]);
      }
    } else if (columnCount === 3) {
      if (selectedItems.length === 0 && item.column === 'left') {
        setSelectedItems([item]);
      } else if (selectedItems.length === 1 && selectedItems[0].column === 'left' && item.column === 'center') {
        setSelectedItems([...selectedItems, item]);
        connectItems([...selectedItems, item]);
      } else if (selectedItems.length === 2 && selectedItems[1].column === 'center' && item.column === 'right') {
        connectItems([...selectedItems, item]);
        setSelectedItems([]);
      } else {
        setSelectedItems([]);
      }
    }
  };

  const connectItems = (newSelectedItems) => {
    const containerRect = containerRef.current.getBoundingClientRect();

    const points = newSelectedItems.map(selectedItem => {
      const itemRect = document.getElementById(`item-${selectedItem.column}-${selectedItem.id}`).getBoundingClientRect();
      return {
        x: itemRect.left + itemRect.width / 2 - containerRect.left,
        y: itemRect.top + itemRect.height / 2 - containerRect.top,
      };
    });

    let updatedConnections = [...connections];

    if (!isDuplicateAllowed) {
      updatedConnections = updatedConnections.filter(conn => {
        const connItemsSet = new Set(conn.items.map(item => `${item.column}-${item.id}`));
        const newItemsSet = new Set(newSelectedItems.map(item => `${item.column}-${item.id}`));

        // 두 집합이 일치하면 제거 (중복 제거)
        return ![...connItemsSet].every(item => newItemsSet.has(item));
      });
    }

    const newConnection = {
      points,
      items: newSelectedItems,
    };

    // 현재 연결 수가 최대 연결 수를 초과하는지 확인
    if (getTotalConnections(updatedConnections) + 1 <= getMaxConnections()) {
      updatedConnections.push(newConnection);
      setConnections(updatedConnections);

      setItems(prevItems => prevItems.map(item =>
        newSelectedItems.some(si => si.id === item.id && si.column === item.column)
          ? { ...item, connectedTo: newSelectedItems.filter(si => si.column !== item.column).map(si => si.id) }
          : item
      ));
    } else {
      alert('최대 연결 수를 초과할 수 없습니다.');
      setSelectedItems([]);  // 드래그를 취소하고 선택된 아이템 상태 초기화
    }
  };




  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleLongPressStart = () => {
    longPressTimer.current = setTimeout(() => {
      setSelectedItems([]);
    }, 500);
  };

  const handleLongPressEnd = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  const handleReset = () => {
    resetItems(columnCount);
  };

  const toggleDuplicateAllowed = () => {
    setIsDuplicateAllowed(!isDuplicateAllowed);
    setConnections([]);
    setSelectedItems([]);
  };

  const handleAddItem = (column) => {
    setItems((prevItems) => {
      const columnItems = prevItems.filter(item => item.column === column);
      const newId = columnItems.length + 1;
      let newText = '';

      switch (column) {
        case 'left':
          newText = `A${newId}`;
          break;
        case 'center':
          newText = `B${newId}`;
          break;
        case 'right':
          newText = `C${newId}`;
          break;
        default:
          newText = `${column.charAt(0).toUpperCase()}${newId}`;
      }

      return [...prevItems, { id: newId, text: newText, column }];
    });
  };

  const handleRemoveItem = (column) => {
    const columnItems = items.filter(item => item.column === column);

    if (columnItems.length <= 1) {
      alert('더 이상 항목을 삭제할 수 없습니다.');
      return;
    }

    const lastItem = columnItems[columnItems.length - 1];

    setConnections((prevConnections) =>
      prevConnections.filter((conn) => !conn.items.some((item) => item.id === lastItem.id && item.column === column))
    );

    setItems((prevItems) =>
      prevItems.filter((item) => !(item.column === column && item.id === lastItem.id))
    );
  };

  const ColumnCountSelector = () => (
    <div className="column-count-selector">
      <button
        onClick={() => setColumnCount(2)}
        className={columnCount === 2 ? 'active' : ''}
      >
        2열
      </button>
      <button
        onClick={() => setColumnCount(3)}
        className={columnCount === 3 ? 'active' : ''}
      >
        3열
      </button>
    </div>
  );

  return (
    <>
      <style>{styles}</style>
      <div
        ref={containerRef}
        className="container"
        onMouseMove={handleMouseMove}
        onMouseDown={handleLongPressStart}
        onMouseUp={handleLongPressEnd}
        onTouchStart={handleLongPressStart}
        onTouchEnd={handleLongPressEnd}
      >
        <canvas ref={canvasRef} className="canvas" />
        <button className="reset-button" onClick={handleReset}>
          초기화
        </button>
        <button className="duplicate-toggle" onClick={toggleDuplicateAllowed}>
          {isDuplicateAllowed ? '중복 불가하기' : '중복 허용하기'}
        </button>
        <ColumnCountSelector />
        <div className="connection-counter">
          연결: {getTotalConnections()} / {getMaxConnections()}
        </div>
        <div className="game-area">
          <div className="row">
            <div className="column">
              {items
                .filter((item) => item.column === 'left')
                .map((item) => (
                  <div
                    key={`${item.column}-${item.id}`}
                    id={`item-${item.column}-${item.id}`}
                    className={`item item-left ${selectedItems.some(
                      (si) => si.id === item.id && si.column === 'left'
                    )
                      ? 'selected'
                      : ''
                      }`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item.text}
                  </div>
                ))}
              <div className="add-remove-buttons">
                <button className="add-remove-button" onClick={() => handleAddItem('left')}>+</button>
                <button className="add-remove-button" onClick={() => handleRemoveItem('left')}>-</button>
              </div>
            </div>
            <div className="column">
              {items
                .filter((item) => item.column === 'center')
                .map((item) => (
                  <div
                    key={`${item.column}-${item.id}`}
                    id={`item-${item.column}-${item.id}`}
                    className={`item item-center ${selectedItems.some(
                      (si) => si.id === item.id && si.column === 'center'
                    )
                      ? 'selected'
                      : ''
                      }`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item.text}
                  </div>
                ))}
              <div className="add-remove-buttons">
                <button className="add-remove-button" onClick={() => handleAddItem('center')}>+</button>
                <button className="add-remove-button" onClick={() => handleRemoveItem('center')}>-</button>
              </div>
            </div>
            {columnCount === 3 && (
              <div className="column">
                {items
                  .filter((item) => item.column === 'right')
                  .map((item) => (
                    <div
                      key={`${item.column}-${item.id}`}
                      id={`item-${item.column}-${item.id}`}
                      className={`item item-right ${selectedItems.some(
                        (si) => si.id === item.id && si.column === 'right'
                      )
                        ? 'selected'
                        : ''
                        }`}
                      onClick={() => handleItemClick(item)}
                    >
                      {item.text}
                    </div>
                  ))}
                <div className="add-remove-buttons">
                  <button className="add-remove-button" onClick={() => handleAddItem('right')}>+</button>
                  <button className="add-remove-button" onClick={() => handleRemoveItem('right')}>-</button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="connections-list">
          <h3>연결된 쌍:</h3>
          {connections
            .filter(conn => (columnCount === 3 ? conn.items.length === 3 : conn.items.length === 2))
            .map((connection, index) => (
              <div key={index} className="connection-item">
                {connection.items.map((item) => item.text).join(' - ')}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ConnectItems;
