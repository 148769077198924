import { usePreview } from "react-dnd-preview";

function Q4DragPreview() {
    const { display, item, style } = usePreview();
    if (!display) {
        return null;
    }
    
    const img = item?.components?.find(component => component.type === 'img');
    const div = item?.components?.find(component => component.type === 'div');
    const textArray = item?.components?.filter(component => component.type === 'text');
    const span = item?.components?.find(component => component.type === 'span');

    const previewStyle = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: style.x,
        top: style.y,
        ...style
    };

    let content;
    if (img) {
        content = <img src={img.src} alt={`Image ${img.src}`} style={{ maxWidth: '100%', maxHeight: '100%' }} className={img.className} />;
    } else if (div) {
        content = <div className={div.className}>{div.value}</div>;
    } else if (textArray) {
        content = (
        <>
            {span && <span className={span.className}>{span.value}</span>}
            {textArray?.map((t, index) => <span key={index}>{t.value}</span>)}
        </>
        );
    }

    return <div style={previewStyle}>{content}</div>;
}

export default Q4DragPreview;