import { subject } from "../../../enum/enum";
import { getKeyByValue } from "../../../js/helper-functions/convertEnum";
const SummaryHeader = ({ exams, summaryDetails }) => {
  // TODO
  const availableUnits = Array.from(new Set(exams.map((exam) => exam.unit))).sort((a, b) => a - b);

  return (
    <ul className="summary_wrap">
      <li className="item_subject">
        <i className="ico_logo type1">온평가</i>
        <b>{getKeyByValue(subject, summaryDetails.subject)}</b>
        <span className="state tx">{summaryDetails.grade}학년 {summaryDetails.term}학기</span>
      </li>
      <li>
        <div className="unit__wrap">
          {/* // TODO */}
          {availableUnits.map((unit) => (
          <span key={unit} className="unit__item">{unit}단원</span>
          ))}
        </div>
      </li>
    </ul>
  );
};

export default SummaryHeader;
