import React, { useEffect, useState } from 'react';
import ExamRow from './viewModel/ExamRow';
import { supabase } from '../supabaseClient';
import { useOn2024ComplexContext } from '../common/Context';
import Pagination from '../components/Pagination';

//출제내역
const Sub0203 = () => {
  const [exams, setExams] = useState([]);  // 전체 내역
  const [visibleExams, setVisibleExams] = useState([]);  // 현재 페이지에 "보여지는" 내역
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectableIds, setSelectableIds] = useState([]);
  const [selectedExamIds, setSelectedExamIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const MAX_ROWS = 10;
  const { navigate, teacherInfo } = useOn2024ComplexContext();

  useEffect(() => {
    fetchExams();
  }, []);

  const computeVisiblePage = (allExams) => {
    const visible = allExams.slice(currentPage*MAX_ROWS, (currentPage+1)*MAX_ROWS);
    setVisibleExams(visible);
    setSelectableIds(visible.filter(exam => exam.is_editable).map(exam => exam.id));
  }

  useEffect(() => {
    computeVisiblePage(exams);
  }, [currentPage, exams]);

  const fetchExams = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('exam_question_setting')
        .select('*')
        .eq('delete_yn', 'N')
        .eq('teacher_id', teacherInfo.teacher_id)
        .order('reg_date', { ascending: false });

      if (error) throw error;

      const classCodes = [...new Set(data.map((d) => d.class_code))].filter((c) => c !== null);
      const year = [...new Set(data.map((d) => d.year))];

      const { data: submitStudents, error: submitStudentsError } = await supabase
        .from('notification_student_question_attendance')
        .select('class_code, academic_year, student_status')
        .eq('teacher_id', teacherInfo.teacher_id)
        .in('class_code', classCodes)
        .in('academic_year', year)
      if(submitStudentsError) console.error(submitStudentsError)
      
      const combinatedData = data.map((d) => {
        return {
          ...d,
          submitStudents: submitStudents.filter((s) => s.class_code === d.class_code && s.academic_year === d.year && s.student_status === '4').length
        }
      });

      setExams(combinatedData);
    } catch (error) {
      setError('Failed to fetch exams');
      console.error('Error fetching exams:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedExamIds(selectableIds);
    } else {
      setSelectedExamIds([]);
    }
  };

  const handleSelectExam = (id) => {
    setSelectedExamIds((prev) => (prev.includes(id) ? prev.filter((examId) => examId !== id) : [...prev, id]));
  };

  const onDelete = async (id) => {
    try {
      const { error } = await supabase
        .from('exam_question_setting')
        .update({ delete_yn: 'Y' })
        .eq('id', id)
      if (error) throw error;
      fetchExams();
    } catch (error) {
      console.error('Error deleting exam:', error);
    }
  };

  const onDeleteSelected = async () => {
    if (selectedExamIds.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }
    try {
      const { error } = await supabase
        .from('exam_question_setting')
        .update({ delete_yn: 'Y' })
        .in('id', selectedExamIds)
      if (error) throw error;
      fetchExams();
    } catch (error) {
      console.error('Error deleting exams:', error);
    }
  };

  const onEdit = (id) => {
    navigate("/sub0201", { state: { exam_question_setting_id: id, type: "edit"}})
  };

  const onPreview = (id) => {
    navigate("/sub0201", { state: { exam_question_setting_id: id, type: "preview"}})
  };

  const onDistribute = (id) => {
    navigate("/sub020303", { state: { exam_question_setting_id: id }})
  };

  const onTestInProgress = (id) => {
    navigate("/sub020305", { state: { exam_question_setting_id: id }})
  };

  const onGrade = (e, id, classCode) => {
    e.preventDefault();
    navigate("/sub020301", { state: { exam_question_setting_id: id, class_code: classCode }})
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
      <div className="layout">
        <div className="title_box">
          <strong className="subtitle">
            <i className="hico hico1" />
            목록
          </strong>
          <div className="btn_group">
            <button type="submit" className="btn btn-ani btn-quaternary" onClick={onDeleteSelected}><i className="sicon ico3" />선택삭제</button>
          </div>
        </div>
        <div className="table_wrap">
          <table className="tbl_basic tbl_list center table-hover">
            <caption>개요</caption>
            <colgroup>
              <col style={{width:"4%"}}/>
              <col style={{width:"5%"}}/>
              <col style={{width:"5%"}}/>
              <col style={{width:"40%"}}/>
              <col style={{width:"8%"}}/>
              <col style={{width:"6%"}}/>
              <col style={{width:"6%"}}/>
              <col style={{width:"7%"}}/>
              <col style={{width:"7%"}}/>
              <col style={{width:"5%"}}/>
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    id="allcheck"
                    checked={selectedExamIds?.length > 0 && selectedExamIds?.length === selectableIds?.length ? (JSON.stringify(selectedExamIds) === JSON.stringify(selectableIds)) : false}
                    onChange={handleSelectAll}
                  />
                  <label htmlFor="allcheck"></label>
                </th>
                <th scope="col">학년</th>
                <th scope="col">반</th>
                <th scope="col">평가지명</th>
                <th scope="col">생성일</th>
                <th scope="col" className="th_deep">
                  수정
                </th>
                <th scope="col" className="th_deep">
                  미리보기
                </th>
                <th scope="col" className="th_deep">
                  배포
                </th>
                <th scope="col" className="th_deep">
                  채점
                </th>
                <th scope="col" className="th_deep">
                  삭제
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleExams.map((exam) => (
                <ExamRow
                  key={exam.id}
                  exam={exam}
                  isSelected={selectedExamIds.includes(exam.id)}
                  onSelect={handleSelectExam}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onPreview={onPreview}
                  onDistribute={onDistribute}
                  onTestInProgress={onTestInProgress}
                  onGrade={onGrade}
                />
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
        currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(exams.length/MAX_ROWS)} />
      </div>
  );
};

export default Sub0203;