import React, { Suspense, useEffect, useRef, useState, useCallback } from 'react';
import QuestionContent from './QuestionContent';
import parse, { domToReact } from 'html-react-parser';
import QuestionTitle from './QuestionTitle';

const ConnectType2Import = ({ grading, question, examFileCode, answers, currentQuestion, handleAnswerChange }) => {
  const [htmlFileAsString, setHtmlFileAsString] = useState('');
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [connections, setConnections] = useState(answers?.[currentQuestion]?.split(', ') || []);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [dotPositions, setDotPositions] = useState({
    left: [],
    mid: [],
    right: [],
  });
  const [duplication, setDuplication] = useState(false);

  const checkDuplication = useCallback(() => {
    const elements = {};
    question.answer.split(', ').forEach((a) =>
      a.split('-').forEach((e) => {
        if (elements[e]) {
          setDuplication(true);
          return;
        }
        elements[e] = true;
      })
    );
  }, [question.answer]);

  useEffect(() => {
    checkDuplication();
  }, [examFileCode, checkDuplication]);

  const mergeConnections = (conns) => {
    const merged = [];
    const used = new Set();

    for (const conn of conns) {
      if (used.has(conn)) continue;
      
      const [start, end] = conn.split('-');
      let current = conn;
      used.add(conn);

      while (true) {
        const next = conns.find(c => !used.has(c) && c.startsWith(end + '-'));
        if (!next) break;
        
        used.add(next);
        current += '-' + next.split('-')[1];
      }

      merged.push(current);
    }

    return merged;
  };

  const splitConnections = (conns) => {
    return conns.flatMap(conn => {
      const parts = conn.split('-');
      return parts.slice(0, -1).map((part, index) => `${part}-${parts[index + 1]}`);
    });
  };

  useEffect(() => {
    const mergedConnections = mergeConnections(connections);
    if (JSON.stringify(mergedConnections) === JSON.stringify(answers?.[currentQuestion]?.split(', '))) return;
    handleAnswerChange(mergedConnections.join(', '));
  }, [connections]);

  useEffect(() => {
    if (answers?.[currentQuestion]?.length) {
      const splitAnswer = splitConnections(answers?.[currentQuestion].split(', '));
      setConnections(splitAnswer);
    } else {
      setConnections([]);
    }
    const fetchHtmlFile = async () => {
      try {
        const response = await fetch(`/html/custom_type/${examFileCode}.html`);
        const html = await response.text();
        const bodyContent = html.match(/<body[^>]*>([\s\S]*?)<\/body>/i)?.[1];
        setHtmlFileAsString(bodyContent);
      } catch (error) {
        console.error('Error fetching HTML file:', error);
        setHtmlFileAsString('');
      }
    };

    if (examFileCode) fetchHtmlFile();
  }, [examFileCode]);

  const getDataName = useCallback((element) => {
    const findBulletSpan = (node) => {
      if (
        node.type === 'tag' &&
        node.attribs &&
        node.attribs.class &&
        node.attribs.class.includes('bullet') &&
        node.attribs.class.includes('circle') &&
        node.attribs.class.includes('sm')
      ) {
        return node;
      }
      if (node.children) {
        for (let child of node.children) {
          const result = findBulletSpan(child);
          if (result) return result;
        }
      }
      return null;
    };

    const bulletSpan = findBulletSpan(element);
    if (bulletSpan && bulletSpan.children && bulletSpan.children.length > 0) {
      return bulletSpan.children[0].data ? bulletSpan.children[0].data.trim() : '';
    }
    return '';
  }, []);

  const updateDotPositions = useCallback(() => {
    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const fields = containerRef.current.querySelectorAll('.field');
    const newDotPositions = {
      left: [],
      mid: [],
      right: [],
    };

    fields.forEach((field) => {
      if (field.classList.contains('canvasWrap')) return;

      let column;
      if (field.classList.contains('left')) column = 'left';
      else if (field.classList.contains('mid')) column = 'mid';
      else if (field.classList.contains('right')) column = 'right';
      else return;

      const dots = field.querySelectorAll('.dot_l, .dot_r');

      if (dots.length > 0) {
        newDotPositions[column] = Array.from(dots).map((dot) => {
          const listItem = dot.closest('li');
          const bulletSpan = listItem.querySelector('.bullet.circle.sm');
          const dataName = bulletSpan ? bulletSpan.textContent.trim() : '';
          return {
            id: dot.id,
            dataName,
            x: dot.getBoundingClientRect().left - containerRect.left + dot.offsetWidth / 2,
            y: dot.getBoundingClientRect().top - containerRect.top + dot.offsetHeight / 2,
            side: dot.classList.contains('dot_l') ? 'left' : 'right',
          };
        });
      }
    });

    setDotPositions(newDotPositions);
  }, []);

  useEffect(() => {
    updateDotPositions();
    window.addEventListener('resize', updateDotPositions);
    return () => window.removeEventListener('resize', updateDotPositions);
  }, [updateDotPositions, htmlFileAsString]);

  const drawConnections = useCallback(() => {
    if (!canvasRef.current || !containerRef.current) return;

    const canvas = canvasRef.current;
    const container = containerRef.current;
    const rect = container.getBoundingClientRect();

    canvas.width = rect.width;
    canvas.height = rect.height;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    connections.forEach((connection) => {
      const [id1, id2] = connection.split('-');

      let dot1 = null;
      let dot2 = null;

      // Find the correct dots based on the connection rules
      if (dotPositions.left.some(dot => dot.dataName === id1 && dot.side === 'right')) {
        dot1 = dotPositions.left.find(dot => dot.dataName === id1 && dot.side === 'right');
        dot2 = dotPositions.mid.find(dot => dot.dataName === id2 && dot.side === 'left');
      } else if (dotPositions.mid.some(dot => dot.dataName === id1 && dot.side === 'right')) {
        dot1 = dotPositions.mid.find(dot => dot.dataName === id1 && dot.side === 'right');
        dot2 = dotPositions.right.find(dot => dot.dataName === id2 && dot.side === 'left');
      }

      if (dot1 && dot2) {
        ctx.beginPath();
        ctx.moveTo(dot1.x, dot1.y);
        ctx.lineTo(dot2.x, dot2.y);
        ctx.strokeStyle = '#90cdf4';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    });

    if (dragging && dragStart) {
      ctx.beginPath();
      ctx.moveTo(dragStart.x, dragStart.y);
      ctx.lineTo(mousePosition.x, mousePosition.y);
      ctx.strokeStyle = '#90cdf4';
      ctx.lineWidth = 2;
      ctx.stroke();
    }
  }, [connections, dragging, dragStart, mousePosition, dotPositions]);

  useEffect(() => {
    drawConnections();
  }, [drawConnections, connections, dragging, dragStart, mousePosition, dotPositions]);

  const handleStart = useCallback((e) => {
    e.preventDefault();
    const touch = e.touches ? e.touches[0] : e;
    const dot = touch.target;
    if (dot.classList.contains('dot_l') || dot.classList.contains('dot_r')) {
      const rect = containerRef.current.getBoundingClientRect();
      const dotRect = dot.getBoundingClientRect();
      const center = {
        x: dotRect.left - rect.left + dotRect.width / 2,
        y: dotRect.top - rect.top + dotRect.height / 2,
      };
      const column = dot.closest('.field').classList.contains('left')
        ? 'left'
        : dot.closest('.field').classList.contains('mid')
        ? 'mid'
        : 'right';
      const side = dot.classList.contains('dot_l') ? 'left' : 'right';
      const listItem = dot.closest('li');
      const bulletSpan = listItem.querySelector('.bullet.circle.sm');
      const dataName = bulletSpan ? bulletSpan.textContent.trim() : '';

      setDragStart({ ...center, column, side, id: dot.id, dataName });
      setMousePosition(center);
      setDragging(true);
    }
  }, []);

  const handleMove = useCallback(
    (e) => {
      if (containerRef.current && dragging) {
        e.preventDefault();
        const touch = e.touches ? e.touches[0] : e;
        if (!touch.clientX || !touch.clientY) return;
        const rect = containerRef.current.getBoundingClientRect();
        setMousePosition({
          x: touch.clientX - rect.left,
          y: touch.clientY - rect.top,
        });
      }
    },
    [dragging]
  );

  const isValidConnection = useCallback((column1, side1, column2, side2) => {
    if (
      (column1 === 'left' && side1 === 'right' && column2 === 'mid' && side2 === 'left') ||
      (column1 === 'mid' && side1 === 'left' && column2 === 'left' && side2 === 'right') ||
      (column1 === 'mid' && side1 === 'right' && column2 === 'right' && side2 === 'left') ||
      (column1 === 'right' && side1 === 'left' && column2 === 'mid' && side2 === 'right')
    ) {
      return true;
    }
    return false;
  }, []);

  const handleEnd = useCallback(
    (e) => {
      if (dragging && dragStart) {
        const touch = e.changedTouches ? e.changedTouches[0] : e;
        const endElement = document.elementFromPoint(touch.clientX, touch.clientY);
        const endDot = endElement?.closest('div[style*="cursor: pointer"]');
        
        if (endDot) {
          const endDataName = endDot.querySelector('i').getAttribute('data-name');
          const endColumn = endDot.closest('.field').classList.contains('left')
            ? 'left'
            : endDot.closest('.field').classList.contains('mid')
            ? 'mid'
            : 'right';
          const endSide = endDot.querySelector('.dot_l') ? 'left' : 'right';

          if (isValidConnection(dragStart.column, dragStart.side, endColumn, endSide)) {
            const newConnection = dragStart.column === 'left' || (dragStart.column === 'mid' && dragStart.side === 'right')
              ? `${dragStart.dataName}-${endDataName}`
              : `${endDataName}-${dragStart.dataName}`;

            setConnections((prev) => {
              if (prev.includes(newConnection)) return prev;
              const updatedConnections = [...prev, newConnection].sort();
              return updatedConnections;
            });
          } else {
            console.log('Invalid connection: Dots can only connect between adjacent columns in the correct direction');
          }
        }
      }
      setDragging(false);
      setDragStart(null);
    },
    [dragging, dragStart, isValidConnection]
  );

  const handleUnlink = useCallback((dataName) => {
    setConnections((prev) => prev.filter((conn) => !conn.includes(`${dataName}-`)));
  }, []);

  const handleDotInteraction = useCallback((e, dataName, side, column) => {
    if (e.type === 'touchstart') {
      // For touch events, preventDefault() should be called on the original event
      e.preventDefault();
    }
    const rect = containerRef.current.getBoundingClientRect();
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    const center = {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
    setDragStart({ ...center, column, side, id: e.currentTarget.id, dataName });
    setMousePosition(center);
    setDragging(true);
  }, []);

  const replaceInner = useCallback((domNode) => {
      if (domNode.attribs?.class === 'q_layout' && domNode.name === 'div') {
          return (
              <div className="q_layout">
                  {grading && (
                      <ul className="question_info">
                          <li>{question.context}</li>
                          <li>난이도 {question.level}</li>
                      </ul>
                  )}
                  {domToReact(domNode.children, { replace: replaceInner })}
              </div>
          );
      }
      if (domNode.attribs?.class?.includes('dot_l') || domNode.attribs?.class?.includes('dot_r')) {
        const side = domNode.attribs.class.includes('dot_l') ? 'left' : 'right';
        const listItem = domNode.parent;
        const dataName = getDataName(listItem);
        const column = listItem.parent.parent.attribs.class.includes('left') ? 'left' : listItem.parent.parent.attribs.class.includes('right') ? 'right' : 'mid';
        const id = `dot-${side}-${column}-${dataName}`;
            return (
          <div 
            style={{
              position: 'relative',
              width: '50px',  // Increased hitbox size
              height: '50px', // Increased hitbox size
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onMouseDown={(e) => handleDotInteraction(e, dataName, side, column)}
            onTouchStart={(e) => handleDotInteraction(e, dataName, side,column)}
          >
            <i
              id={id}
              className={domNode.attribs.class}
              data-name={dataName}
              style={{ 
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              }}
            ></i>
          </div>
        );
      }
      if (domNode.name === 'canvas') {
        return (
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}></canvas>
        );
      }
      if (domNode.attribs?.class === 'q_tit' && question?.qtitle) {
        return (
            <QuestionTitle question={question} currentQuestion={currentQuestion} />
        )
    }
      if (domNode.attribs?.class === 'q_wrap' && domNode.children.length === 1 && domNode.children[0].type === 'text') {
        const currentClasses = domNode.attribs.class ? domNode.attribs.class.split(' ') : [];
        if (!currentClasses.includes('hidden')) {
            currentClasses.push('hidden');
        }
        domNode.attribs.class = currentClasses.join(' ');
      }
      if (domNode.name === 'button' && domNode.attribs?.class === 'unlink') {
        const dataName = getDataName(domNode.parent);
        return <button className="unlink" data-name={dataName} onClick={() => handleUnlink(dataName)}></button>;
      }
      return domNode;
    },
    [handleStart, currentQuestion, handleUnlink]
  );

  const replace = (domNode) => {
    if(domNode.attribs?.class === 'q_content' && domNode.name === 'div') {
        const newClassName = `q_content${grading === 'Y' ? ' grade_check true' : grading === 'N' ? ' grade_check wrong' : ''}`
        return (
            <div {...domNode.attribs} className={newClassName}>
                {domToReact(domNode.children, { replace: replaceInner })}
            </div>
        );
    }
    return replaceInner(domNode);
};

  useEffect(() => {
    const container = containerRef.current;
    if (containerRef.current) {
      container.addEventListener('mousemove', handleMove);
      container.addEventListener('touchmove', handleMove, { passive: false });
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);

      return () => {
        container.removeEventListener('mousemove', handleMove);
        container.removeEventListener('touchmove', handleMove);
        document.removeEventListener('mouseup', handleEnd);
        document.removeEventListener('touchend', handleEnd);
      };
    }
  }, [handleMove, handleEnd]);  

  if (!examFileCode) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading.....</div>}>
      <QuestionContent>
        <div
          ref={containerRef}
          style={{ position: 'relative' }}
          onMouseMove={handleMove}
          onMouseUp={handleEnd}
          onMouseLeave={handleEnd}
        >
          {parse(htmlFileAsString, { replace })}
        </div>
      </QuestionContent>
    </Suspense>
  );
};

export default ConnectType2Import;