import React from 'react';

const ExamSummary = ({ examData, students }) => {
  const {
    grade,
    semester,
    subject,
    publisher,
    units,
    questionTypes,
    questionCount,
    difficulty,
    selectedStudents
  } = examData;

  return (
    <div className="totla_wrap line_box">
      <span><i className="micon ico9"></i>평가지 선택 확인</span>
      <div>
        <strong className="totla__title">
          <b>{grade}</b>학년 <b>{semester}</b>학기 <b>{subject}{subject === "영어" ? `(${publisher.join(", ")})` : ''}</b> 평가지 문제수 <b>{questionCount}</b>개
        </strong>
        <ul className="totla__info">
          <li className="totla__item num1">
            <ul>
              <li><em>유형</em> 선택형 <b>{questionTypes.multiple}</b>%, 서답형 <b>{questionTypes.shortAnswer}</b>%</li>
              <li><em>난이도</em> 상 <b>{difficulty.high}</b>%, 중 <b>{difficulty.medium}</b>%, 하 <b>{difficulty.low}</b>%</li>
              <li><em>선택 학생수</em> <b>{selectedStudents.length}</b>명 / {students.length}명</li>
            </ul>
          </li>
          <li className="totla__item num2">
            <em>출제범위</em>
            <div className="unit__wrap">
              {units.map((unit, index) => (
                <span key={index} className="unit__item">{unit}단원</span>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExamSummary;