export const AlertDialog = ({ isOpen, onClose, title, isPaused, onConfirm }) => (
  isOpen && (
    <div className="alert-dialog-overlay">
      <div className="alert-dialog-content">
        <h2 className="alert-dialog-title">{title}</h2>
          {
            isPaused ? <p className="alert-dialog-description">평가를 재개하시겠습니까? <br/> 학생들이 <span>다시 답변을 입력할 수 있게 됩니다.</span></p>
              : <p className="alert-dialog-description">평가를 일시 정지하시겠습니까?<br/> 학생들은 <span>일시적으로 답변을 입력할 수 없게 됩니다.</span></p>
          }
        <div className="alert-dialog-actions">
          <button className="btn btn-outline-secondary" onClick={onClose}>취소</button>
          <button className="btn btn-primary" onClick={onConfirm}>확인</button>
        </div>
      </div>
    </div>
  )
);