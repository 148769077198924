import React, { useState } from 'react';
import axios from 'axios';
import '../css/common.css';
import '../css/chat.css';


const Onchat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const newMessages = [...messages, { text: input, user: true }];
    setMessages(newMessages);
    setInput('');

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: input }],
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
      //console.log(response);

      const botReply = response.data.choices[0].message.content.replace(/\n/g, "<br>");
      console.log(botReply);
      setMessages([...newMessages, { text: botReply, user: false }]);
    } catch (error) {
      console.error('OpenAI API 오류:', error);
      setMessages([...newMessages, { text: '오류가 발생했습니다.', user: false }]);
    }
  };

  return (
    <div className="onchat">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.user ? 'user' : 'bot'}`}>
            {message.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input type="text" value={input} onChange={(e) => setInput(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && sendMessage()} />
        {/*<textarea value={input} onChange={(e) => setInput(e.target.value)} />*/}
        <button className="btn btn-primary" onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Onchat;