function ExamFooterModalComplete({ isPopupCompleteOpen, examData, modalClose, isAlreadySubmitted }) {
    return (
        <div className={`modal modal-popup question_modal ${isPopupCompleteOpen ? 'active' : ''}`} id="popup_opne2">
            <div className="modal-background is-click-disabled"></div>
            <div className="modal-content">
                <div className="popup-wrap">
                <span className="flag"><span>{`${examData.units}단원`}</span></span>
                <div className="con-head">
                    <h3><i className="micon ico2"></i>{`${examData.grade}학년 ${examData.term}학기`}</h3>
                </div>
                <div className="con-wrap">
                    <span className="confirm_img"></span>
                    {isAlreadySubmitted ? (
                        <strong>이미 제출이 <em>완료</em> 되었습니다.</strong>
                    ) : (
                        <strong><em>답안</em> 제출이 완료 되었습니다.</strong>
                    )}
                    <div className="btn_group">
                    <button type="button" className="btn btn-ani btn-primary" onClick={() => modalClose('popup_opne2')}>
                        확인
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ExamFooterModalComplete;