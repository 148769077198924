// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';

const Header = () => {
  return (
    <div id="header">
      <div className="logo">
          <Link to="/">
            <img src="../images/common/logo_w.svg" alt="온평가 로고" />
          </Link>
        {/* <div className="util_menu">
          <Link to="/login" className="login">로그인</Link>
          <Link to="/join" className="join">회원가입</Link>
        </div> */}
      </div>
      <Menu />
    </div>
  );
};

export default Header;