import React, { useEffect } from 'react';

const StudentList = ({ students, selectedStudents, onChange }) => {
  useEffect(() => {
    onChange('selectedStudents', selectedStudents);
  }, []);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelectedStudents = isChecked ? students.map(student => student.student_id) : [];
    onChange('selectedStudents', newSelectedStudents);
  };

  const handleSelectStudent = (studentId) => {
    let newSelectedStudents;
    if (selectedStudents.includes(studentId)) {
      newSelectedStudents = selectedStudents.filter(id => id !== studentId);
    } else {
      newSelectedStudents = [...selectedStudents, studentId];
    }
    onChange('selectedStudents', newSelectedStudents);
  };

  const selectAll = selectedStudents.length === students.length;

  return (
    <div className="table_wrap">
      <table className="tbl_basic center table-hover">
        <caption>학생 목록</caption>
        <colgroup>
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"20%"}} />
          <col style={{width:"40%"}} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <input 
                type="checkbox" 
                id="allcheck" 
                name="check" 
                checked={selectAll}
                onChange={handleSelectAll}
              />
              <label htmlFor="allcheck"></label>
            </th>
            <th scope="col">번호</th>
            <th scope="col">이름</th>
            <th scope="col">비고</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.student_id}>
              <th scope="row">
                <input 
                  type="checkbox" 
                  id={`check${student.student_id}`} 
                  name="check"
                  checked={selectedStudents.includes(student.student_id)}
                  onChange={() => handleSelectStudent(student.student_id)}
                />
                <label htmlFor={`check${student.student_id}`}></label>
              </th>
              <td>{student.student_number}</td>
              <td>{student.student_name}</td>
              <td>{student.notice}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentList;