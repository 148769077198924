import React, { useState, useRef, useEffect, useMemo } from 'react';

const styles = `
  .container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .lineWrap {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .field {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .field.left, .field.right {
    width: 25%;
  }
  .field.mid {
    width: 30%;
  }
  .canvasWrap {
    width: 10%;
  }
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .linkList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .linkList li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .linkCon {
    // margin: 0 20px;
  }
  .FL-tt {
    font-size: 14px;
  }
  .connections-list {
    background-color: #fff;
    border-top: 2px solid #ddd;
    padding: 10px;
    height: 250px;
    overflow-y: auto;
  }
  .connection-item {
    background-color: #edf2f7;
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 5px;
    font-size: 14px;
  }
`;

const Dot = ({ itemId, position, onMouseDown, isConnectable }) => {
  return (
    <i
      id={`dot-${itemId}-${position}`}
      className="dot"
      onMouseDown={isConnectable ? onMouseDown : null}
      // style={{ cursor: isConnectable ? 'pointer' : 'not-allowed' }}
      style={{ cursor: 'pointer' }}
    ></i>
  );
};

const LinkItem = ({ item, onUnlink, onDotMouseDown, viewType, connections }) => {
  const isConnectable = (column, position) => {
    if (column === 'left') return position === 'right';
    if (column === 'right') return false;
    if (column === 'center') return true;
    return false;
  };

  return (
    <li key={item.id} data-name={item.id}>
      {(item.column === 'right' || item.column === 'center') && (
        <Dot
          itemId={item.id}
          position="left"
          onMouseDown={(e) => onDotMouseDown(e, item, 'left')}
          isConnectable={isConnectable(item.column, 'left')}
        />
      )}
      <div className="linkCon">
        <span className="FL-tt">{item.text}</span>
      </div>
      {item.column !== 'right' && (
        <button className="unlink" onClick={() => onUnlink(item.id)}></button>
      )}
      {(item.column === 'left' || item.column === 'center') && (
        <Dot
          itemId={item.id}
          position="right"
          onMouseDown={(e) => onDotMouseDown(e, item, 'right')}
          isConnectable={isConnectable(item.column, 'right')}
        />
      )}
    </li>
  );
};

const ConnectionItemBase = ({ viewType, isDuplicateAllowed }) => {
  const [items, setItems] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  const containerRef = useRef(null);
  const [dragStart, setDragStart] = useState(null);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const baseItems = [
      { id: 'a1', text: '발단', column: 'left' },
      { id: 'a2', text: '전개', column: 'left' },
    ];

    if (viewType === 'combine') {
      setItems([
        ...baseItems,
        { id: 'b1', text: '저승사자가 원님에게 이승에 보내 주는 대신 수고비를 내놓으라고 함', column: 'center' },
        { id: 'b2', text: '원님이 저승사자를 만나게 됨', column: 'center' },
        { id: 'c1', text: '원님이 수고비를 낸다', column: 'right' },
        { id: 'c2', text: '저승사자가 다시 돌아간다', column: 'right' },
      ]);
    } else {
      setItems([
        ...baseItems,
        { id: 'b1', text: '저승사자가 원님에게 이승에 보내 주는 대신 수고비를 내놓으라고 함', column: 'right' },
        { id: 'b2', text: '원님이 저승사자를 만나게 됨', column: 'right' },
      ]);
    }
    setConnections([]); // 뷰 타입이 변경될 때 연결 초기화
  }, [viewType]);

  useEffect(() => {
    const updateCanvasSize = () => {
      [canvasRef1, canvasRef2].forEach(ref => {
        if (ref.current && containerRef.current) {
          const { width, height } = containerRef.current.getBoundingClientRect();
          ref.current.width = width;
          ref.current.height = height;
        }
      });
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  const drawLine = (ctx, points, color) => {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    ctx.lineTo(points[1].x, points[1].y);
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.stroke();
  };

  useEffect(() => {
    [canvasRef1, canvasRef2].forEach(ref => {
      if (ref.current) {
        const ctx = ref.current.getContext('2d');
        ctx.clearRect(0, 0, ref.current.width, ref.current.height);
      }
    });

    connections.forEach(({ points }) => {
      const canvas = points[0].x < points[1].x ? canvasRef1.current : canvasRef2.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        drawLine(ctx, points, '#90cdf4');
      }
    });

    if (dragging && dragStart) {
      const canvas = dragStart.x < mousePosition.x ? canvasRef1.current : canvasRef2.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        drawLine(ctx, [dragStart, mousePosition], '#90cdf4');
      }
    }
  }, [connections, dragging, dragStart, mousePosition]);

  const getDotCenter = (dotElement) => {
    const rect = dotElement.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2 - containerRect.left,
      y: rect.top + rect.height / 2 - containerRect.top,
    };
  };

  const handleMouseMove = (e) => {
    if (containerRef.current && dragging) {
      const rect = containerRef.current.getBoundingClientRect();
      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleDotMouseDown = (e, item, position) => {
    e.preventDefault();
    const dot = e.target;
    const center = getDotCenter(dot);
    setDragStart(center);
    setMousePosition(center);
    setDragging(true);
    setSelectedItems([{ ...item, position }]);
  };

  const handleMouseUp = (e) => {
    if (dragging && selectedItems.length === 1) {
      const endDot = document.elementFromPoint(e.clientX, e.clientY);
      if (endDot && endDot.classList.contains('dot')) {
        const [, endItemId, endPosition] = endDot.id.split('-');
        const endItem = items.find(item => item.id === endItemId);
        if (endItem && isValidConnection(selectedItems[0], endItem, endPosition)) {
          connectItems([selectedItems[0], { ...endItem, position: endPosition }]);
        }
      }
    }
    setDragging(false);
    setDragStart(null);
    setSelectedItems([]);
  };

  const isValidConnection = (startItem, endItem, endPosition) => {
    if (startItem.column === endItem.column) return false;
    if (startItem.position === endPosition) return false;
    if (startItem.column === 'right') return false;
    if (endItem.column === 'left') return false;

    if (viewType === 'combine') {
      if (startItem.column === 'left' && endItem.column === 'right') return false;
    }

    return true;
  };


  const connectItems = (newSelectedItems) => {
    const [startItem, endItem] = newSelectedItems;
    let updatedConnections = [...connections];

    if (!isDuplicateAllowed) {
      // 중복 연결 확인
      const duplicateConnectionIndex = updatedConnections.findIndex(conn =>
        conn.items.some(item => item.id === endItem.id)
      );

      if (duplicateConnectionIndex !== -1) {
        // 중복된 아이템을 포함하는 전체 연결선 찾기
        const duplicateConnection = updatedConnections[duplicateConnectionIndex];
        const itemsToRemove = new Set(duplicateConnection.items.map(item => item.id));

        // 관련된 모든 연결 제거
        updatedConnections = updatedConnections.filter(conn =>
          !conn.items.some(item => itemsToRemove.has(item.id))
        );
      }
    }

    // 새로운 연결 추가
    const points = newSelectedItems.map((selectedItem) => {
      const dotElement = document.getElementById(`dot-${selectedItem.id}-${selectedItem.position}`);
      return getDotCenter(dotElement);
    });
    updatedConnections.push({ points, items: newSelectedItems });

    setConnections(updatedConnections);
  };

  const handleUnlink = (itemId) => {
    setConnections((prev) =>
      prev.filter((conn) => !conn.items.some((item) => item.id === itemId))
    );
  };

  const getConnectionText = (chain) => {
    return chain.map(item => item.text).join(' - ');
  };

  const memoizedChains = useMemo(() => {
    const getConnectedChains = () => {
      const chains = [];
      const usedConnections = new Set();

      connections.forEach((conn, index) => {
        if (usedConnections.has(index)) return;

        let chain = [conn.items[0], conn.items[1]];
        usedConnections.add(index);

        let lastItem = conn.items[1];
        let foundNext = true;

        while (foundNext) {
          foundNext = false;
          for (let i = 0; i < connections.length; i++) {
            if (usedConnections.has(i)) continue;

            const nextConn = connections[i];
            if (nextConn.items[0].id === lastItem.id) {
              chain.push(nextConn.items[1]);
              lastItem = nextConn.items[1];
              usedConnections.add(i);
              foundNext = true;
              break;
            }
          }
        }

        chains.push(chain);
      });

      return chains;
    };

    return getConnectedChains();
  }, [connections]);

  return (
    <div
      ref={containerRef}
      className="container"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className={`lineWrap ${viewType === 'combine' ? 'type2' : ''}`}>
        <div className="field left">
          <ul className="linkList">
            {items.filter(item => item.column === 'left').map(item => (
              <LinkItem
                key={item.id}
                item={item}
                onUnlink={handleUnlink}
                onDotMouseDown={handleDotMouseDown}
                viewType={viewType}
                connections={connections}
              />
            ))}
          </ul>
        </div>
        <div className="canvasWrap">
          <canvas ref={canvasRef1} className="canvas"></canvas>
        </div>
        {viewType === 'combine' && (
          <div className="field mid">
            <ul className="linkList">
              {items.filter(item => item.column === 'center').map(item => (
                <LinkItem
                  key={item.id}
                  item={item}
                  onUnlink={handleUnlink}
                  onDotMouseDown={handleDotMouseDown}
                  viewType={viewType}
                  connections={connections}
                />
              ))}
            </ul>
          </div>
        )}
        {viewType === 'combine' && (
          <div className="canvasWrap">
            <canvas ref={canvasRef2} className="canvas"></canvas>
          </div>
        )}
        <div className="field right">
          <ul className="linkList">
            {items.filter(item => item.column === 'right').map(item => (
              <LinkItem
                key={item.id}
                item={item}
                onUnlink={handleUnlink}
                onDotMouseDown={handleDotMouseDown}
                viewType={viewType}
                connections={connections}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="connections-list">
        <h3>연결된 쌍:</h3>
        {memoizedChains.map((chain, index) => (
          <div key={index} className="connection-item">
            {getConnectionText(chain)}
          </div>
        ))}
      </div>
    </div>
  );
};

const ConnectionItem = () => {
  const [viewType, setViewType] = useState('basic');
  const [isDuplicateAllowed, setIsDuplicateAllowed] = useState(false);

  const handleChangeView = (e) => {
    setViewType(e.target.value);
  };

  return (
    <>
      <style>{styles}</style>
      <div>
        <div>
          <button onClick={() => setIsDuplicateAllowed(!isDuplicateAllowed)}>
            {isDuplicateAllowed ? '중복 불가하기' : '중복 허용하기'} 버튼
          </button>
        </div>
        <div>
          {isDuplicateAllowed ? '중복 허용으로 설정됨' : '중복 불허용으로 설정됨'}
        </div>
        <div>
          <select value={viewType} onChange={handleChangeView}>
            <option value="basic">2열</option>
            <option value="combine">3열</option>
          </select>
        </div>
      </div>
      <ConnectionItemBase viewType={viewType} isDuplicateAllowed={isDuplicateAllowed} />
    </>
  );
};

export default ConnectionItem;
