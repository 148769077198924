import React, { useEffect, useState } from 'react';

const ClassCodeInput = ({ screenType, classCode, onSave }) => {
  const [newClassCode, setNewClassCode] = useState('a00000');
  const isPreview = screenType === "preview";

  useEffect(() => {
    setNewClassCode(classCode)
  }, [classCode])

  const handleInputChange = (e) => {
    setNewClassCode(e.target.value);
  };

  const handleSave = () => {
    if (onSave) {
      onSave(newClassCode);
    }
  };

  return (
    <div className="class_code_wrap line_box" style={{padding: 0}} >
      <div className="class_code_box" style={isPreview ? {padding: '40px 42px'} : {}}>
        <span><b>학급코드</b>※학급코드는 숫자 / 알파벳을 포함한 1~6글자로 생성합니다.</span>
        <input 
          type="text" 
          className="class_code" 
          value={newClassCode} 
          onChange={handleInputChange}
          maxLength={6}
          disabled={isPreview}
        />
      </div>
      {!isPreview && <div className="btn_group">
        <button 
          type="button" 
          className="btn btn-ani btn-primary" 
          onClick={handleSave}
          disabled={isPreview}
        >
          <i className="micon ico4"></i>저장하기
        </button>
      </div>}
    </div>
  );
};

export default ClassCodeInput;